import { useScreenDetector } from '@/hooks/useScreenDetector';
import { scaleFont } from '@/utils/scaleSizeAndFont';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import Button from './Button/Button';
import CheckboxInput from './CheckBoxInput';

interface SingleSelectStepProps {
    LogoComponent: React.FC;
    title: string;
    options: string[];
    withCheckbox?: boolean;
    withSkip?: boolean;
    onNext: () => void;
    blackText?: boolean;
    selectedUserOption?: number | null;
    onOptionSelect?: (option: number) => void;
    field?: string;
    blueBorder?: boolean;
    fromEdit?: boolean;
    indexObjects?: any[];
}

const SingleSelectStep: React.FC<SingleSelectStepProps> = ({
    LogoComponent,
    title,
    options,
    withCheckbox = false,
    withSkip = false,
    onNext,
    blackText = false,
    selectedUserOption = null,
    onOptionSelect,
    field,
    blueBorder = false,
    fromEdit = true,
    indexObjects,
}) => {
    const [selectedOption, setSelectedOption] = useState<number | null>(null);
    const timeoutIdRef = useRef<ReturnType<typeof setTimeout> | null>(null);

    const handleOptionChange = useCallback(
        (id: number) => {
            if (field) {
                localStorage.setItem(field, id.toString());
            }
            setSelectedOption(id);
            if (timeoutIdRef.current) {
                clearTimeout(timeoutIdRef.current);
            }
            timeoutIdRef.current = setTimeout(() => {
                onOptionSelect?.(id);
                onNext();
            }, 300);
        },
        [onNext, onOptionSelect, field]
    );

    useEffect(() => {
        if (selectedUserOption) {
            setSelectedOption(selectedUserOption);
        }
        return () => {
            if (timeoutIdRef.current) {
                clearTimeout(timeoutIdRef.current);
            }
        };
    }, [selectedUserOption]);
    const { isMobile } = useScreenDetector();
    return (
        <div
            className={twMerge(
                'flex h-full w-full flex-col justify-between px-4 pt-8',
                blackText && 'pt-0',
                fromEdit && 'px-0'
            )}
        >
            <div className='overflow-y-auto'>
                <div
                    className={twMerge(
                        ' flex flex-col justify-center',
                        blackText && ' mb-5'
                    )}
                >
                    <div className='flex justify-center'>
                        <LogoComponent />
                    </div>
                    <h1
                        className={twMerge(
                            'my-3 mb-4 self-center text-center font-extrabold text-white',
                            blackText && ' my-4 mb-0 text-black'
                        )}
                        style={{
                            fontSize: `${isMobile ? scaleFont(22) : 24}px`,
                        }}
                    >
                        {title}
                    </h1>
                </div>

                <div className={twMerge('flex flex-col overflow-y-auto')}>
                    {options.map((option, i) => {
                        const indexObject = indexObjects?.find(
                            (obj) => obj.value === option
                        );
                        return (
                            <CheckboxInput
                                key={option}
                                checked={
                                    selectedOption?.toString() ===
                                    (i + 1).toString()
                                }
                                onChange={() =>
                                    handleOptionChange(
                                        indexObject?.originalIndex || i + 1
                                    )
                                }
                                label={option}
                                withCheckbox={withCheckbox}
                                blueBorder={blueBorder}
                                withBorder
                                fromSSS
                                alignment='center'
                            />
                        );
                    })}
                </div>

                {withSkip && (
                    <div className='mt-2 flex justify-center pb-4 text-black/50'>
                        <Button
                            className='font-normal'
                            label='Skip'
                            onClick={() => handleOptionChange(0)}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default SingleSelectStep;
