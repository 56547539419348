import MobileLayout from '@/components/layouts/MobileLayout';
import PaymentMethod from '@/components/paywall/PaymentMethod';
import { ShowcaseItem } from '@/entities/payment';
import { AppRoutes } from '@/utils/routes';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

// pay-wall-standalone/?token=-YuBJ5nN4T3sM7M0ealVEGd2IRiHNyTcmqehxWyk&showcase=%7B%22most_popular_item_id%22%3A18%2C%22name%22%3A%22gold_default%22%2C%22id%22%3A6%2C%22items%22%3A%5B%7B%22default_price_usd%22%3A999%2C%22count%22%3A1%2C%22id%22%3A17%2C%22sku%22%3A%221m.subscription.bumpy.plus%22%2C%22value%22%3A1%2C%22unit_id%22%3A%22month%22%7D%2C%7B%22default_price_usd%22%3A2399%2C%22count%22%3A3%2C%22id%22%3A18%2C%22sku%22%3A%223m.subscription.bumpy.plus%22%2C%22value%22%3A3%2C%22unit_id%22%3A%22month%22%7D%2C%7B%22default_price_usd%22%3A3899%2C%22count%22%3A6%2C%22id%22%3A19%2C%22sku%22%3A%226m.subscription.bumpy.plus%22%2C%22value%22%3A6%2C%22unit_id%22%3A%22month%22%7D%2C%7B%22default_price_usd%22%3A5999%2C%22count%22%3A1%2C%22id%22%3A20%2C%22sku%22%3A%221y.subscription.bumpy.plus%22%2C%22value%22%3A12%2C%22unit_id%22%3A%22year%22%7D%5D%7D&selectedIndex=0&showTitle=0&redirectUrl=http%3A%2F%2Fcallback.bumpy
const PayWallStandalone = observer(() => {
    const navigate = useNavigate();
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');
    const showcase = searchParams.get('showcase');
    const selectedIndex = Number(searchParams.get('selectedIndex'));
    const redirectUrl = searchParams.get('redirectUrl');

    if (!token || !showcase || (!selectedIndex && selectedIndex !== 0)) {
        navigate(AppRoutes.Login);
        return;
    }
    console.log('isSuccess:', isSuccess, 'redirectUrl: ', redirectUrl);
    const basePrice = JSON.parse(showcase).items.find(
        (item: ShowcaseItem) => item.value === 1
    ).default_price_usd;
    const showcaseItem = JSON.parse(showcase).items[selectedIndex];
    useEffect(() => {
        if (isSuccess && redirectUrl) {
            window.location.href = redirectUrl;
        }
    }, [isSuccess, redirectUrl]);
    return (
        <MobileLayout className='gap-0 p-0'>
            <PaymentMethod
                isOpen
                showcaseItem={showcaseItem}
                subscribeType
                isSuccess={isSuccess}
                setIsSuccess={setIsSuccess}
                basePrice={basePrice}
                authToken={token}
            />
        </MobileLayout>
    );
});

export default PayWallStandalone;
