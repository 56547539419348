import { AppRoutes } from '@/utils/routes';
import { useNavigate } from 'react-router-dom';

const BuildInfo = () => {
    const navigate = useNavigate();

    return (
        <div className='flex min-h-screen flex-col items-center justify-center'>
            <h2 className='font-bold text-2xl'>Build Info:</h2>
            <span className='mb-8 px-2 py-1 font-bold text-xl'>
                {import.meta.env.VITE_BUILD_INFO ||
                    'Not available on this environment.'}
            </span>
            <button
                onClick={() => navigate(AppRoutes.Home)}
                className='rounded bg-verify px-4 py-2 text-white'
            >
                Go to Home Page
            </button>
        </div>
    );
};

export default BuildInfo;
