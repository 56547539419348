import React from 'react';
import { twMerge } from 'tailwind-merge';

interface CircularProgressProps {
    percentage: number;
    size: number;
    strokeWidth: number;
    bgcolor: string;
    color: string;
    notLoading?: boolean;
    fromDiscovery?: boolean;
}

const CircularProgress: React.FC<CircularProgressProps> = ({
    percentage,
    size,
    strokeWidth,
    color,
    bgcolor,
    notLoading = false,
    fromDiscovery = false,
}) => {
    const radius = (size - strokeWidth) / 2;
    const circumference = 2 * Math.PI * radius;
    const offset = !notLoading
        ? circumference - (percentage / 100) * circumference
        : 0;

    return (
        <div className='relative'>
            <svg
                className={twMerge(
                    ' transform',
                    fromDiscovery ? '-rotate-90' : 'rotate-90'
                )}
                width={size}
                height={size}
                viewBox={`0 0 ${size} ${size}`}
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
            >
                <defs>
                    <linearGradient
                        id='gradient'
                        x1='0%'
                        y1='0%'
                        x2='100%'
                        y2='0%'
                    >
                        <stop
                            offset='0%'
                            style={{ stopColor: '#f6b40b', stopOpacity: 1 }}
                        />
                        <stop
                            offset='50%'
                            style={{ stopColor: '#f8e377', stopOpacity: 1 }}
                        />
                        <stop
                            offset='100%'
                            style={{ stopColor: '#f6b40b', stopOpacity: 1 }}
                        />
                    </linearGradient>
                </defs>
                <circle
                    stroke={bgcolor}
                    strokeWidth={strokeWidth}
                    cx={size / 2}
                    cy={size / 2}
                    r={radius}
                    fill='none'
                />

                <circle
                    stroke={color}
                    strokeWidth={strokeWidth}
                    strokeLinecap='round'
                    cx={size / 2}
                    cy={size / 2}
                    r={radius}
                    fill='none'
                    strokeDasharray={circumference}
                    strokeDashoffset={offset}
                    className='transition-all duration-50'
                />
            </svg>
        </div>
    );
};

export default CircularProgress;
