import { editImageSrc } from '@/utils/editImageSrc';
import React from 'react';
import { twMerge } from 'tailwind-merge';

interface RoundedPhotoProps {
    src: string;
    alt: string;
    blur?: boolean;
    size: number;
    className?: string;
    onClick?: () => void;
    imageSize?: 'tiny' | 'small';
    mainAvatar?: boolean;
    onLoad?: () => void;
}

const Avatar: React.FC<RoundedPhotoProps> = ({
    src,
    alt,
    blur = false,
    size,
    className,
    onClick,
    imageSize,
    mainAvatar,
    onLoad,
}) => {
    const editAvatarSrc = (src: string): string => {
        if (!imageSize) return src;
        return editImageSrc(src, imageSize);
    };

    const correctSrc = !src.includes('undefined');
    const style = {
        width: `${size}px`,
        height: `${size}px`,
        borderRadius: '50%',
        overflow: 'hidden',
    };

    const imageStyle = {
        filter: blur ? 'blur(4px)' : 'none',
    };

    return (
        <div
            className={twMerge('relative rounded-full', className)}
            style={style}
            onClick={onClick}
        >
            {correctSrc ? (
                <img
                    src={editAvatarSrc(src)}
                    className='h-full w-full object-cover'
                    alt={alt}
                    style={imageStyle}
                    loading={!mainAvatar ? 'lazy' : undefined}
                    onLoad={() => {
                        if (onLoad) {
                            onLoad();
                        }
                    }}
                    onContextMenu={(e) => e.preventDefault()}
                />
            ) : (
                <div />
            )}
        </div>
    );
};

export default Avatar;
