import FailedIcon from '@/assets/icons/failed-icon.svg?react';
import SuccessIcon from '@/assets/icons/success-icon.svg?react';
import React, { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

interface TipProps {
    children?: ReactNode;
    aling?: 'left' | 'right' | 'top' | 'bottom';
    isSuccess?: boolean;
}

const Tip: React.FC<TipProps> = ({
    children,
    aling = 'bottom',
    isSuccess = true,
}) => {
    const alignmentClasses = {
        right: 'flex items-center',
        left: 'flex items-center flex-row-reverse',
        bottom: 'flex flex-col items-center',
        top: 'flex flex-col-reverse items-center',
    };

    const marginClasses = {
        right: 'ml-[-10px]',
        left: 'mr-[-10px]',
        bottom: 'mt-[-10px]',
        top: 'mb-[-10px]',
    };

    return (
        <div className={twMerge(alignmentClasses[aling], 'justify-center')}>
            <div className='z-10'>{children}</div>
            <div className={twMerge(marginClasses[aling], 'z-20')}>
                {isSuccess ? (
                    <SuccessIcon width={20} height={20} />
                ) : (
                    <FailedIcon width={20} height={20} />
                )}
            </div>
        </div>
    );
};

export default Tip;
