import CheckboxInput from '@/components/common/CheckBoxInput';
import UserDataFlowLayout from '@/components/layouts/UserDataFlowLayout';
import { scaleSize } from '@/utils/scaleSizeAndFont';
import React, { useCallback, useState } from 'react';
import StepHeader from './StepHeader';
import { UserInfoStepProps } from './userInfoConfig';

const GenderStep: React.FC<UserInfoStepProps> = ({ onNext, isLite }) => {
    const [gender, setGender] = useState(
        () => localStorage.getItem('gender') || ''
    );

    const onGenderChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const newGender = e.target.value;
            setGender(newGender);
            localStorage.setItem('gender', newGender);
            onNext();
        },
        [onNext]
    );

    return (
        <UserDataFlowLayout disableNextButton={!gender} withBarProgress>
            <div className='flex h-full flex-col'>
                <StepHeader
                    titleAction={isLite ? "Hi, let's get started." : ''}
                    title='What is your gender?'
                    description="It'll help us to find people for you"
                    barProgress={isLite ? 10 : 45}
                />
                <div
                    className='mb-4 flex flex-col px-4'
                    style={{
                        marginTop: `${scaleSize(30)}px`,
                    }}
                >
                    <CheckboxInput
                        checked={gender === 'M'}
                        onChange={() =>
                            onGenderChange({
                                target: { value: 'M' },
                            } as React.ChangeEvent<HTMLInputElement>)
                        }
                        label="I'm a male"
                    />
                    <CheckboxInput
                        checked={gender === 'F'}
                        onChange={() =>
                            onGenderChange({
                                target: { value: 'F' },
                            } as React.ChangeEvent<HTMLInputElement>)
                        }
                        label="I'm a female"
                    />
                </div>
            </div>
        </UserDataFlowLayout>
    );
};

export default GenderStep;
