import { UserDetailed } from '@/domain';
import userStore from '@/entities/user/store';
import {
    setRotate,
    setTranslateX,
    setTranslateY,
} from '@/utils/domTransformUtils';
import { handleGoToPayWall } from '@/utils/handleGoToPayWall';
import { AppRoutes } from '@/utils/routes';
import { useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

export const useSwipeAndReactions = (
    setIconPositions: React.Dispatch<React.SetStateAction<number>>,
    user: UserDetailed,
    isSending: boolean,
    setIsSending: React.Dispatch<React.SetStateAction<boolean>>,
    setIsOpenModalDots: React.Dispatch<React.SetStateAction<boolean>>,
    handleSendReaction: (type: string) => Promise<void>,
    isOpenModal: boolean,
    mainDivRef: React.RefObject<HTMLDivElement>,
    setShowLikeIcon: React.Dispatch<React.SetStateAction<boolean>>,
    childDivRef?: React.MutableRefObject<HTMLDivElement | null>
) => {
    const navigate = useNavigate();
    const startX = useRef<number>(0);
    const plusPlan = userStore.home?.me?.subscription?.type === 'plus';

    const goToInitialPositions = () => {
        if (mainDivRef.current) {
            setTranslateX(mainDivRef.current, 0);
            setTranslateY(mainDivRef.current, 0);
            setRotate(mainDivRef.current, 0);
        }
        setIconPositions(0);
    };

    const smoothResetPosition = (deltaX: number) => {
        const step = 5;
        let currentTranslateX = deltaX;
        let currentTranslateY = Math.abs(deltaX / 2.2);
        let currentRotateDegree = deltaX / 10;
        const animateReset = () => {
            if (mainDivRef.current) {
                if (Math.abs(currentTranslateX) > 0) {
                    currentTranslateX -= currentTranslateX / step;
                    setTranslateX(mainDivRef.current, currentTranslateX);
                    setIconPositions(currentTranslateX);
                }
                if (Math.abs(currentTranslateY) > 0) {
                    currentTranslateY -= currentTranslateY / step;
                    setTranslateY(mainDivRef.current, currentTranslateY);
                }
                if (Math.abs(currentRotateDegree) > 0) {
                    currentRotateDegree -= currentRotateDegree / step;
                    setRotate(mainDivRef.current, currentRotateDegree);
                }
            }

            if (
                Math.abs(currentTranslateX) > 0.5 ||
                Math.abs(currentTranslateY) > 0.5 ||
                Math.abs(currentRotateDegree) > 0.5
            ) {
                requestAnimationFrame(animateReset);
            } else {
                goToInitialPositions();
            }
        };
        requestAnimationFrame(animateReset);
    };

    const animateTranslation = (
        side: string,
        initialTranslate: number,
        step: number,
        onComplete: () => void
    ) => {
        const finalTranslateX = 600;
        let currentTranslateX = initialTranslate;
        let currentTranslateY = Math.abs(initialTranslate / 2);
        const animate = () => {
            if (Math.abs(currentTranslateX) < Math.abs(finalTranslateX)) {
                if (Math.abs(currentTranslateX) >= 560) {
                    setIsSending(false);
                }
                if (
                    side === 'top' &&
                    userStore.home.me.balance.super_smashes === 0
                ) {
                    navigate(`${AppRoutes.PayWall}/likes`);
                } else {
                    currentTranslateY += step;
                    currentTranslateX += side !== 'left' ? step : -step;
                    if (mainDivRef.current) {
                        setTranslateX(mainDivRef.current, currentTranslateX);
                        setTranslateY(mainDivRef.current, currentTranslateY);
                    }
                    requestAnimationFrame(animate);
                }
            } else {
                onComplete();
            }
        };

        requestAnimationFrame(animate);
    };

    const handleActions = (
        side: string,
        triggeredByActions?: boolean,
        startFrom = 0
    ) => {
        if (isSending) return;
        setIsSending(true);
        const reactions: Record<string, string> = {
            right: 'smash',
            left: 'pass',
            top: 'super_smash',
        };

        const reactionType = reactions[side];
        if (!reactionType) return;
        const step = 40;
        if (side === 'top' && userStore.home.me.balance.super_smashes > 0) {
            setShowLikeIcon(true);
            userStore.setMeData({
                ...userStore.home.me,
                balance: {
                    ...userStore.home.me.balance,
                    super_smashes: userStore.home.me.balance.super_smashes - 1,
                },
            });
            setIsSending(false);
            setTimeout(() => {
                setShowLikeIcon(false);
                handleSendReaction(reactionType);
            }, 1000);
        } else if (triggeredByActions) {
            rotateCard(side, () => {
                animateTranslation(side, 0, step, () => {
                    handleSendReaction(reactionType);
                    if (mainDivRef.current) {
                        setRotate(mainDivRef.current, 0);
                    }
                });
            });
        } else {
            animateTranslation(side, startFrom, step, () => {
                handleSendReaction(reactionType);
            });
        }
    };

    const rotateCard = (side: string, callback: () => void) => {
        const step = 2.5;
        let currentRotate = 0;
        const noSmash = userStore.home?.me?.balance?.smashes === 0;
        const animateRotation = () => {
            if (Math.abs(currentRotate) < 10 && side !== 'top') {
                currentRotate += side === 'right' ? step : -step;
                if (mainDivRef.current) {
                    setRotate(mainDivRef.current, currentRotate);
                }
                requestAnimationFrame(animateRotation);
            } else if (!noSmash || side !== 'right' || plusPlan) {
                callback();
            } else {
                handleGoToPayWall(navigate, 5);
            }
        };
        requestAnimationFrame(animateRotation);
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    const handleMouseDown = useCallback(
        (event: MouseEvent) => {
            startX.current = event.clientX;
            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', handleMouseUp);
        },
        [user]
    );

    const handleMouseMove = (event: MouseEvent) => {
        setIsOpenModalDots(false);
        if (isSending) return;
        const deltaX = event.clientX - startX.current;
        if (
            userStore.home?.me?.balance?.smashes === 0 &&
            deltaX > 30 &&
            !plusPlan
        ) {
            smoothResetPosition(deltaX);
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
            handleGoToPayWall(navigate, 5);
            return;
        }
        if (mainDivRef.current) {
            setTranslateX(mainDivRef.current, deltaX);
            setTranslateY(mainDivRef.current, Math.abs(deltaX / 2.2));
            setRotate(mainDivRef.current, deltaX / 10);
        }
        const modalElement = childDivRef
            ? childDivRef.current
            : mainDivRef.current;
        if (modalElement) {
            const centerPos = modalElement.clientWidth / 7;
            setIconPositions(Math.max(Math.min(deltaX, centerPos), -centerPos));
        }
    };

    const handleMouseUp = (event: MouseEvent) => {
        event.preventDefault();
        const deltaX = event.clientX - startX.current;

        if (deltaX > 150) handleActions('right', false, deltaX);
        else if (deltaX < -150) {
            handleActions('left', false, deltaX);
        } else {
            smoothResetPosition(deltaX);
        }
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
    };

    const startY = useRef(0);
    const isScrolling = useRef<null | boolean>(null);
    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    const handleTouchStart = useCallback(
        (event: TouchEvent) => {
            startX.current = event.touches[0].clientX;
            startY.current = event.touches[0].clientY;
            isScrolling.current = null;
            document.addEventListener('touchmove', handleTouchMove);
            document.addEventListener('touchend', handleTouchEnd);
        },
        [user]
    );

    const handleTouchMove = (event: TouchEvent) => {
        setIsOpenModalDots(false);
        if (isSending) return;
        const deltaX = event.touches[0].clientX - startX.current;
        const deltaY = event.touches[0].clientY - startY.current;
        if (isScrolling.current === null) {
            isScrolling.current = Math.abs(deltaY) > Math.abs(deltaX);
        }

        if (isScrolling.current) {
            return;
        }
        if (
            userStore.home?.me?.balance?.smashes === 0 &&
            deltaX > 50 &&
            !plusPlan
        ) {
            smoothResetPosition(deltaX);
            document.removeEventListener('touchmove', handleTouchMove);
            document.removeEventListener('touchend', handleTouchEnd);
            handleGoToPayWall(navigate, 5);
            return;
        }
        if (mainDivRef.current) {
            setTranslateX(mainDivRef.current, deltaX);
            setTranslateY(mainDivRef.current, Math.abs(deltaX / 2.2));
            setRotate(mainDivRef.current, deltaX / 10);
        }
        if (mainDivRef.current?.clientWidth) {
            const centerPos = mainDivRef.current.clientWidth / 8;
            setIconPositions(Math.max(Math.min(deltaX, centerPos), -centerPos));
        }
    };

    const handleTouchEnd = (event: TouchEvent) => {
        event.preventDefault();
        const deltaX = event.changedTouches[0].clientX - startX.current;
        if (isScrolling.current) {
            document.removeEventListener('touchmove', handleTouchMove);
            document.removeEventListener('touchend', handleTouchEnd);
            return;
        }
        if (deltaX > 150) handleActions('right', false, deltaX);
        else if (deltaX < -150) {
            handleActions('left', false, deltaX);
        } else {
            smoothResetPosition(deltaX);
        }
        document.removeEventListener('touchmove', handleTouchMove);
        document.removeEventListener('touchend', handleTouchEnd);
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        const mainDiv = mainDivRef.current;
        if (mainDiv && isOpenModal) {
            mainDiv.addEventListener('mousedown', handleMouseDown, {
                passive: true,
            });
            mainDiv.addEventListener('touchstart', handleTouchStart, {
                passive: true,
            });
        }
        return () => {
            if (mainDiv) {
                mainDiv.removeEventListener('mousedown', handleMouseDown);
                mainDiv.removeEventListener('touchstart', handleTouchStart);
            }
        };
    }, [handleMouseDown, handleTouchStart, isOpenModal]);

    return { handleActions, mainDivRef };
};
