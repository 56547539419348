import Drink from '@/assets/icons/drink.svg?react';
import Education from '@/assets/icons/education-level.svg?react';
import Important from '@/assets/icons/important-thing.svg?react';
import Kids from '@/assets/icons/kids.svg?react';
import MaritalStatus from '@/assets/icons/marital-status.svg?react';
import Pets from '@/assets/icons/pets.svg?react';
import Religion from '@/assets/icons/religion.svg?react';
import Ruller from '@/assets/icons/ruller.svg?react';
import Scales from '@/assets/icons/scales.svg?react';
import SexualOrient from '@/assets/icons/sexual-oriet.svg?react';
import Smoke from '@/assets/icons/smoke.svg?react';
import Sport from '@/assets/icons/sport.svg?react';
import StarSign from '@/assets/icons/star-sign.svg?react';
import { USER_INFO_STEPS } from '@/components/auth/userInfoSteps/userInfoConfig';
import {
    UserDrinking,
    UserEducationLevel,
    UserExercise,
    UserKids,
    UserMaritalStatus,
    UserPets,
    UserReligion,
    UserSexOrientation,
    UserSmoking,
    UserStarSign,
    UserValues,
} from '@/domain';
import { getEnumOptions } from '@/utils/getEnumOptions';

type LogoComponentProps = {
    height?: number;
    width?: number;
    alt?: string;
    fill?: string;
    className?: string;
};

export interface StepConfig {
    key: string;
    LogoComponent: React.FC<LogoComponentProps>;
    title: string;
    question: string;
    options?: any;
    value?: number;
}

export const stepConfigs: StepConfig[] = [
    {
        key: 'height_sm',
        LogoComponent: (props: LogoComponentProps) => (
            <Ruller fill='#000000' {...props} />
        ),
        title: 'Height',
        question: 'What is your height?',
        options: Array.from({ length: 130 }, (_, i) => 100 + i),
    },
    {
        key: 'weight_kg',
        LogoComponent: (props: LogoComponentProps) => (
            <Scales fill='#000000' {...props} />
        ),
        title: 'Weight',
        question: 'What is your weight?',
        options: Array.from({ length: 86 }, (_, i) => 40 + i),
    },
    {
        key: USER_INFO_STEPS?.SEX_ORIENTATION,
        LogoComponent: (props: LogoComponentProps) => (
            <SexualOrient fill='#000000' {...props} />
        ),
        title: 'Sexual orientation',
        question: 'What is your sexual orientation?',
        options: getEnumOptions(UserSexOrientation),
    },
    {
        key: USER_INFO_STEPS?.STAR_SIGN,
        LogoComponent: (props: LogoComponentProps) => (
            <StarSign fill='#000000' {...props} />
        ),
        title: 'Star sign',
        question: 'What is your star sign?',
        options: getEnumOptions(UserStarSign),
    },
    {
        key: USER_INFO_STEPS?.EXERCISE,
        LogoComponent: (props: LogoComponentProps) => (
            <Sport fill='#000000' {...props} />
        ),
        title: 'Exercise',
        question: 'Do you do sports?',
        options: getEnumOptions(UserExercise),
    },
    {
        key: USER_INFO_STEPS?.EDUCATION_LEVEL,
        LogoComponent: (props: LogoComponentProps) => (
            <Education fill='#000000' {...props} />
        ),
        title: 'Education level',
        question: 'What is your education level?',
        options: getEnumOptions(UserEducationLevel),
    },
    {
        key: USER_INFO_STEPS?.MARITAL_STATUS,
        LogoComponent: (props: LogoComponentProps) => (
            <MaritalStatus fill='#000000' {...props} />
        ),
        title: 'Marital status',
        question: 'What is your marital status?',
        options: getEnumOptions(UserMaritalStatus),
    },
    {
        key: USER_INFO_STEPS?.KIDS,
        LogoComponent: (props: LogoComponentProps) => (
            <Kids fill='#000000' {...props} />
        ),
        title: 'Kids',
        question: 'Do you have kids?',
        options: getEnumOptions(UserKids),
    },
    {
        key: 'drinking',
        LogoComponent: (props: LogoComponentProps) => (
            <Drink fill='#000000' {...props} />
        ),
        title: 'Drinking',
        question: 'Do you drink?',
        options: getEnumOptions(UserDrinking),
    },
    {
        key: 'smoking',
        LogoComponent: (props: LogoComponentProps) => (
            <Smoke fill='#000000' {...props} />
        ),
        title: 'Smoking',
        question: 'Do you smoke?',
        options: getEnumOptions(UserSmoking),
    },
    {
        key: USER_INFO_STEPS?.PETS,
        LogoComponent: (props: LogoComponentProps) => (
            <Pets fill='#000000' {...props} />
        ),
        title: 'Pets',
        question: 'Do you have pets?',
        options: getEnumOptions(UserPets),
    },
    {
        key: USER_INFO_STEPS?.RELIGION,
        LogoComponent: (props: LogoComponentProps) => (
            <Religion fill='#000000' {...props} />
        ),
        title: 'Religion',
        question: 'Do you identify a religion?',
        options: getEnumOptions(UserReligion, true),
    },
    {
        key: USER_INFO_STEPS?.VALUES,
        LogoComponent: (props: LogoComponentProps) => (
            <Important fill='#000000' {...props} />
        ),
        title: 'Values',
        question: 'What is the most important thing in life to you?',
        options: getEnumOptions(UserValues, true),
    },
];
