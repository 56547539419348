import { ComponentProps, ForwardedRef, ReactNode, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

interface InputProps extends ComponentProps<'input'> {
    ref?: ForwardedRef<HTMLInputElement>;
    className?: string;
    icon?: ReactNode;
    value?: string;
}

const TextInput = forwardRef<HTMLInputElement, InputProps>(
    ({ className, icon, value, ...rest }, ref) => {
        if (icon) {
            return (
                <div
                    className={twMerge(
                        'flex w-full items-center gap-1 rounded-xl bg-white p-3 ',
                        className
                    )}
                >
                    {icon}
                    <input
                        {...rest}
                        ref={ref}
                        className='w-full bg-white text-black focus:outline-none'
                        value={value}
                    />
                </div>
            );
        }

        return (
            <input
                {...rest}
                ref={ref}
                className={twMerge(
                    'w-full rounded-xl bg-white p-3 text-black focus:outline-none',
                    className
                )}
                value={value}
            />
        );
    }
);

export default TextInput;
