import { NavigateFunction } from 'react-router/dist/lib/hooks';

import {
    clearStorage,
    initializeWorker,
    storeData,
} from '@/workers/sharedWorkerInstance';
import { deleteToken } from '@shared/lib/firebase';

import { LOCAL_STORAGE } from './localStorage';
import { AppRoutes } from './routes';

export const handleLogout = async (
    id?: string,
    user_id?: string,
    until?: number
) => {
    if (localStorage.getItem('fireToken')) {
        await deleteToken();
    }
    clearStorage();
    localStorage.clear();

    let redirectUrl: string;

    if (!id) {
        redirectUrl = AppRoutes.Login;
    } else {
        redirectUrl = `${AppRoutes.ErrorBanned}?banId=${id}&userId=${user_id}&until=${until}`;
    }

    window.location.href = redirectUrl;
};

export const handleLoginSuccess = (
    loginResponse: any,
    navigate: NavigateFunction
) => {
    localStorage.clear();
    const token = loginResponse.token.access;
    const { home } = loginResponse;
    if (token && home) {
        localStorage.setItem(LOCAL_STORAGE.AuthToken, token);
        initializeWorker();
        storeData('authToken', token);
        storeData('userData', home);
        storeData('matches', {
            list_new: home.matches_new,
            list_open: home.matches_open,
            unreads: home.unreads,
            users: home.users,
        });

        navigate(AppRoutes.People);
    }
};
