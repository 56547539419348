import LikeReaction from '@/assets/icons/like-reaction.svg?react';
import { ShowcaseItem } from '@/entities/payment';
import { scaleFont, scaleSize } from '@/utils/scaleSizeAndFont';
import LogoIcon from '@assets/icons/logo-gold-gradient.svg?react';
import { twMerge } from 'tailwind-merge';

interface PaymentCardProps {
    showcaseItem: ShowcaseItem;
    subscribeType: boolean;
    price: number;
    sale: number;
}

const PaymentCard: React.FC<PaymentCardProps> = ({
    showcaseItem,
    subscribeType,
    price,
    sale,
}) => {
    const originalPrice = Math.floor(((price + 0.01) / (100 - sale)) * 100);
    const salePrice = `${Math.floor(originalPrice - price).toFixed(2)} (${sale}%)`;

    return (
        <div
            className={twMerge(
                'mt-4 flex w-full flex-col rounded-[10px]',
                subscribeType ? 'bg-lightGold' : 'bg-bgGray'
            )}
        >
            <div className='flex w-full items-center justify-between gap-1 border-b-2 border-b-placeholder p-4'>
                {subscribeType ? (
                    <div className='flex gap-1'>
                        <LogoIcon />
                        <span className='rounded-full bg-secondGold px-1 font-bold'>
                            Gold
                        </span>
                    </div>
                ) : (
                    <div className='flex items-center gap-1'>
                        <LikeReaction
                            width={scaleSize(20)}
                            height={scaleSize(20)}
                        />
                        <span
                            className='font-semibold text-like'
                            style={{
                                fontSize: scaleFont(20),
                            }}
                        >
                            Likes
                        </span>
                    </div>
                )}
                <span
                    className='font-bold text-black'
                    style={{
                        fontSize: scaleFont(16),
                    }}
                >
                    {`${showcaseItem.count} ${showcaseItem.unit_id}${showcaseItem.count > 1 ? 's' : ''}`}
                </span>
            </div>
            <div className='flex flex-col gap-2 border-b-2 border-b-placeholder p-4 text-navDarkGray'>
                <div className='flex w-full justify-between'>
                    <span className='flex gap-1'>Original Price:</span>
                    <span
                        className={
                            showcaseItem.value !== 1
                                ? 'font-semibold line-through'
                                : 'font-bold'
                        }
                    >
                        {`USD ${showcaseItem.value === 1 ? price : originalPrice - 0.01}`}
                    </span>
                </div>
                <div className='flex w-full justify-between'>
                    <span className='flex gap-1'>Save:</span>
                    <span
                        className={
                            showcaseItem.value === 1
                                ? 'font-semibold text-stroke'
                                : 'font-bold text-black'
                        }
                    >
                        {`USD ${showcaseItem.value === 1 ? '0.00' : salePrice} `}
                    </span>
                </div>
            </div>
            <div className='flex w-full justify-between p-4 text-black '>
                <span className='flex gap-1 font-bold '>Total:</span>
                <span className='font-bold'>{`USD ${price}`}</span>
            </div>
        </div>
    );
};

export default PaymentCard;
