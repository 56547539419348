import { Interests } from '@/domain';

export const interestsNames: Readonly<{
    [interest in Interests]: string;
}> = {
    [Interests.Hobbies_Poetry]: '✍ Poetry',
    [Interests.Hobbies_Prose]: '📝 Prose',
    [Interests.Hobbies_MakeUp]: '💄 Make Up',
    [Interests.Hobbies_Blogging]: '🤳 Blogging',
    [Interests.Hobbies_Shooting_TikTok]: '🎬 Shooting TikTok',
    [Interests.Hobbies_Drawing]: '🎨 Drawing',
    [Interests.Hobbies_Music]: '🎵 Music',
    [Interests.Hobbies_Photography]: '📸 Photography',
    [Interests.Hobbies_Design]: '✏ Design',
    [Interests.Hobbies_Tattoos]: '🃏 Tattoos',
    [Interests.Hobbies_Reading]: '📚 Reading',
    [Interests.Hobbies_TV]: '📺 TV',
    [Interests.Hobbies_YouTube]: '📼 YouTube',
    [Interests.Hobbies_Cooking]: '🧑‍🍳 Cooking',
    [Interests.Hobbies_Gardening]: '🌻 Gardening',
    [Interests.Hobbies_BoardGames]: '🎲 Board Games',
    [Interests.Hobbies_MindGames]: '❓ Mind Games',
    [Interests.Hobbies_Astronomy]: '💫 Astronomy',
    [Interests.Hobbies_Cars]: '🚗 Cars',
    [Interests.Hobbies_VideoGames]: '🎮 Video Games',
    [Interests.Hobbies_HealthyLifestyle]: '💪 Healthy Lifestyle',
    [Interests.Hobbies_Fishing]: '🎣 Fishing',
    [Interests.Hobbies_Shopping]: '🛍 Shopping',

    [Interests.Sport_Climbing]: '🧗 Climbing',
    [Interests.Sport_Badminton]: '🏸 Badminton',
    [Interests.Sport_Basketball]: '🏀 Basketball',
    [Interests.Sport_Bodybuilding]: '💪 Bodybuilding',
    [Interests.Sport_Running]: '🏃 Running',
    [Interests.Sport_Baseball]: '⚾ Baseball',
    [Interests.Sport_Boxing]: '🥊 Boxing',
    [Interests.Sport_Wrestling]: '🤼 Wrestling',
    [Interests.Sport_Cycling]: '🚴 Cycling',
    [Interests.Sport_WaterPolo]: '🤽 Water Polo',
    [Interests.Sport_Volleyball]: '🏐 Volleyball',
    [Interests.Sport_Handball]: '🤾 Handball',
    [Interests.Sport_Gymnastics]: '🤸 Gymnastics',
    [Interests.Sport_Rowing]: '🚣 Rowing',
    [Interests.Sport_Judo]: '🥋 Judo',
    [Interests.Sport_Joga]: '🧘 Yoga',
    [Interests.Sport_Athletics]: '🏃 Athletics',
    [Interests.Sport_Meditation]: '🧘 Meditation',
    [Interests.Sport_Pilates]: '🤸 Pilates',
    [Interests.Sport_Swimming]: '🏊 Swimming',
    [Interests.Sport_Surfing]: '🏄 Surfing',
    [Interests.Sport_ShootingSports]: '🔫 Shooting Sports',
    [Interests.Sport_Dancing]: '💃 Dancing',
    [Interests.Sport_Tennis]: '🎾 Tennis',
    [Interests.Sport_TableTennis]: '🏓 Table Tennis',
    [Interests.Sport_Triathlon]: '🦾 Triathlon',
    [Interests.Sport_Weightlifting]: '🏋 Weightlifting',
    [Interests.Sport_Fencing]: '🤺 Fencing',
    [Interests.Sport_Football]: '⚽ Football',
    [Interests.Sport_Hockey]: '🏒 Hockey',
    [Interests.Sport_eSports]: '💻 eSports',
    [Interests.Sport_Formula1]: '🏎 Formula 1',

    [Interests.Music_Folk]: '🎵 Folk',
    [Interests.Music_Country]: '🎵 Country',
    [Interests.Music_LatinAmerican]: '🎵 Latin American',
    [Interests.Music_Blues]: '🎵 Blues',
    [Interests.Music_RnB]: '🎵 RnB',
    [Interests.Music_Jazz]: '🎵 Jazz',
    [Interests.Music_Chanson]: '🎵 Chanson',
    [Interests.Music_Romance]: '🎵 Romance',
    [Interests.Music_ArtSong]: '🎵 Art Song',
    [Interests.Music_Electronic]: '🎵 Electronic',
    [Interests.Music_Rock]: '🎵 Rock',
    [Interests.Music_HipHop]: '🎵 HipHop',
    [Interests.Music_Reggae]: '🎵 Reggae',
    [Interests.Music_Funk]: '🎵 Funk',
    [Interests.Music_NewWave]: '🎵 New Wave',
    [Interests.Music_Soul]: '🎵 Soul',
    [Interests.Music_Disco]: '🎵 Disco',
    [Interests.Music_Pop]: '🎵 Pop',

    [Interests.Pets_Cats]: '🐈 Cats',
    [Interests.Pets_Dogs]: '🐕 Dogs',
    [Interests.Pets_Birds]: '🐦 Birds',
    [Interests.Pets_Fish]: '🐠 Fish',
    [Interests.Pets_Rabbits]: '🐇 Rabbits',
    [Interests.Pets_Snakes]: '🐍 Snakes',
    [Interests.Pets_Turtles]: '🐢 Turtles',
    [Interests.Pets_Hamsters]: '🐹 Hamsters',

    [Interests.GoingOut_Cafes]: '☕ Cafes',
    [Interests.GoingOut_Restaurants]: '🍲 Restaurants',
    [Interests.GoingOut_Karaoke]: '🎤 Karaoke',
    [Interests.GoingOut_Theatres]: '🎭 Theatres',
    [Interests.GoingOut_Bars]: '🍻 Bars',
    [Interests.GoingOut_Nightclubs]: '🪩 Nightclubs',
    [Interests.GoingOut_Museums]: '🏺 Museums',
    [Interests.GoingOut_Galleries]: '🖼 Galleries',
    [Interests.GoingOut_Concerts]: '🎙 Concerts',
    [Interests.GoingOut_HookahBars]: '💨 Hookah Bars',

    [Interests.FoodAndDrink_Coffee]: '☕ Coffee',
    [Interests.FoodAndDrink_Tea]: '🫖 Tea',
    [Interests.FoodAndDrink_Beer]: '🍺 Beer',
    [Interests.FoodAndDrink_Wine]: '🍷 Wine',
    [Interests.FoodAndDrink_Whiskey]: '🥃 Whiskey',
    [Interests.FoodAndDrink_Cocktails]: '🍸 Cocktails',
    [Interests.FoodAndDrink_Pizza]: '🍕 Pizza',
    [Interests.FoodAndDrink_Sushi]: '🍣 Sushi',
    [Interests.FoodAndDrink_FastFood]: '🍔 Fast Food',
    [Interests.FoodAndDrink_Grill]: '🍖 Grill',
    [Interests.FoodAndDrink_HomeFood]: '🥘 Home Food',
    [Interests.FoodAndDrink_Sweets]: '🍯 Sweets',
    [Interests.FoodAndDrink_Vegetarian]: '🥬 Vegetarian',
    [Interests.FoodAndDrink_Vegan]: '🌱 Vegan',

    [Interests.Travels_Seas]: '🌊 Seas',
    [Interests.Travels_Mountains]: '⛰ Mountains',
    [Interests.Travels_Nature]: '🌳 Nature',
    [Interests.Travels_Attractions]: '🗼 Attractions',
    [Interests.Travels_Cities]: '🌇 Cities',
    [Interests.Travels_Hiking]: '🧭 Hiking',
    [Interests.Travels_Camping]: '🏕 Camping',
    [Interests.Travels_Abroad]: '✈ Abroad',
    [Interests.Travels_Domestic]: '🧳 Domestic',

    [Interests.Other_HarryPotter]: '🪄 Harry Potter',
    [Interests.Other_WorkoutAtHome]: '🕴 Workout at Home',
    [Interests.Other_Instagram]: '🤳 Instagram',
    [Interests.Other_Sneakers]: '👟 Sneakers',
    [Interests.Other_LearningLanguages]: '🔤 Learning Languages',
    [Interests.Other_Gym]: '🏋 Gym',
    [Interests.Other_CoffeeMan]: '☕ Coffee Man',
    [Interests.Other_TeeMan]: '🫖 Tee Man',
    [Interests.Other_Cakes]: '🍰 Cakes',
    [Interests.Other_Freelancing]: '🧑‍💻 Freelancing',
    [Interests.Other_TrySomethingNew]: '🆕 Try Something New',
    [Interests.Other_HumanRights]: '⚖ Human Rights',
    [Interests.Other_IceCream]: '🍦 Ice Cream',
    [Interests.Other_DeliciousFood]: '🥘 Delicious Food',
    [Interests.Other_CS_GO]: '🔫 CS: GO',
    [Interests.Other_HealthyLifestyle]: '🍏 Healthy Lifestyle',
    [Interests.Other_ProperNutrition]: '🥗 Proper Nutrition',
    [Interests.Other_ContentCreation]: '📹 Content Creation',
    [Interests.Other_NatureProtection]: '🌳 Nature Protection',
    [Interests.Other_GirlsUnder25]: '👩 Girls Under 25',
    [Interests.Other_DoingNothing]: '🛋 Doing Nothing',
    [Interests.Other_Sleep]: '😴 Sleep',
    [Interests.Other_Disney]: '👸 Disney',
    [Interests.Other_Marvel]: '🛡 Marvel',
    [Interests.Other_DC]: '🦸 DC',
    [Interests.Other_Shopaholic]: '🛍 Shopaholic',
    [Interests.Other_Networking]: '🌐 Networking',
    [Interests.Other_PlayStation]: '🎮 PlayStation',
    [Interests.Other_XBox]: '🎮 XBox',
    [Interests.Other_WalkingInTheRain]: '🌧 Walking in the Rain',
    [Interests.Other_Happiness]: '😊 Happiness',
    [Interests.Other_TVShows]: '🎥 TV Shows',
    [Interests.Other_Entrepreneurship]: '💸 Entrepreneurship',
    [Interests.Other_Cryptocurrency]: '📈 Cryptocurrency',
    [Interests.Other_Astronomy]: '⭐ Astronomy',
    [Interests.Other_EcoActivism]: '🌎 Eco Activism',
    [Interests.Other_LGBTQ]: '🏳‍🌈 LGBTQ',
    [Interests.Other_BLM]: '✊🏿 BLM',
    [Interests.Other_Dragons]: '🐉 Dragons',
    [Interests.Other_WorldPeace]: '☮ World Peace',
    [Interests.Other_Twitter]: '💬 Twitter',
    [Interests.Other_NFT]: '🖼 NFT',
    [Interests.Other_Poker]: '🃏 Poker',
    [Interests.Other_Startups]: '🦄 Startups',
    [Interests.Other_Procrastination]: '🛌🏻 Procrastination',
    [Interests.Other_BeachVolleyball]: '🏐 Beach Volleyball',
    [Interests.Other_DontLikeCold]: "☀ Don't Like Cold",
};
