import { Match, User } from '@/domain';
import { WebMatchMessage } from '@/entities/match';
import { LOCAL_STORAGE } from '@/utils/localStorage';
import { SharedStateType } from '@/workers/sharedState';
import { WorkerActions } from './workerActions';

let workerRef: SharedWorker | Worker | null = null;
let isSharedWorker = true;

export const initializeWorker = () => {
    if (!workerRef) {
        if (typeof SharedWorker !== 'undefined') {
            workerRef = new SharedWorker(
                new URL('./sharedWorker.ts', import.meta.url),
                {
                    type: 'module',
                }
            );
            (workerRef as SharedWorker).port.start();
        } else if (typeof Worker !== 'undefined') {
            workerRef = new Worker(new URL('./webWorker.ts', import.meta.url), {
                type: 'module',
            });
            isSharedWorker = false;
        } else {
            console.error('No worker support available.');
            return;
        }

        const authToken =
            typeof localStorage !== 'undefined'
                ? localStorage.getItem(LOCAL_STORAGE.AuthToken)
                : null;

        if (authToken) {
            storeData('authToken', authToken);
            postMessageToWorker({
                action: WorkerActions.SocketConnect,
            });
        }
    }
};

const postMessageToWorker = (message: any) => {
    if (isSharedWorker && workerRef instanceof SharedWorker) {
        workerRef.port.postMessage(message);
    } else if (workerRef instanceof Worker) {
        workerRef.postMessage(message);
    }
};

// Listener management
export const addMessageListener = (
    handleMessage: (event: MessageEvent) => void
) => {
    if (isSharedWorker && workerRef instanceof SharedWorker) {
        workerRef.port.addEventListener('message', handleMessage);
    } else if (workerRef instanceof Worker) {
        workerRef.addEventListener('message', handleMessage);
    }
};

export const removeMessageListener = (
    handleMessage: (event: MessageEvent) => void
) => {
    if (isSharedWorker && workerRef instanceof SharedWorker) {
        workerRef.port.removeEventListener('message', handleMessage);
    } else if (workerRef instanceof Worker) {
        workerRef.removeEventListener('message', handleMessage);
    }
};

// Helper functions to interact with worker
export const storeData = (dataKey: keyof SharedStateType, data: any) => {
    postMessageToWorker({
        action: WorkerActions.StoreData,
        data,
        dataKey,
    });
};

export const clearStorage = () => {
    postMessageToWorker({
        action: WorkerActions.ClearStorage,
    });
};

export const addChatToMatches = (match: Match, user: User) => {
    postMessageToWorker({
        action: WorkerActions.AddNewChatToMatches,
        data: { match, user },
    });
};
export const sendChatMessage = (
    message: WebMatchMessage | null,
    selectedChatId: number
) => {
    postMessageToWorker({
        action: WorkerActions.SendChatMessage,
        data: { selectedChatId, message },
    });
};

export const asseptCompliment = async (selectedChatId: number) => {
    postMessageToWorker({
        action: WorkerActions.ApproveCompliment,
        data: { selectedChatId },
    });
};

export const sentEditChatMessage = (
    message: WebMatchMessage | null,
    selectedChatId: number
) => {
    postMessageToWorker({
        action: WorkerActions.SendEditChatMessage,
        data: { selectedChatId, message },
    });
};

export const deleteChatMessage = async (id: number, selectedChatId: number) => {
    postMessageToWorker({
        action: WorkerActions.DeleteChatMessage,
        data: { selectedChatId, id },
    });
};

export const paginateChat = (selectedChatId: number) => {
    postMessageToWorker({
        action: WorkerActions.PaginateChat,
        data: { selectedChatId },
    });
};

export const getData = (dataKey?: keyof SharedStateType): void => {
    postMessageToWorker({
        action: WorkerActions.GetData,
        dataKey,
    });
};

export const updateUserAction = async (key: string, value: any) => {
    postMessageToWorker({
        action: WorkerActions.UpdateUserDataProp,
        data: { propName: key, newValue: value },
    });
};

export const updateUserDataAction = (data: any) => {
    postMessageToWorker({
        action: WorkerActions.UpdateUserData,
        data,
    });
};

export const sendReactionMessage = (data: {
    id: number;
    reaction: number;
    chat_id: number;
}) => {
    postMessageToWorker({
        action: WorkerActions.SendReactionMessage,
        data,
    });
};

export const firstTranslateMessage = (data: {
    id: number;
    chat_id: number;
    tr_lang: string;
}) => {
    postMessageToWorker({
        action: WorkerActions.FirstTranslateMessage,
        data,
    });
};

export const translateMessage = (data: {
    id: number;
    chat_id: number;
    visible: boolean;
}) => {
    postMessageToWorker({
        action: WorkerActions.TranslateMessage,
        data,
    });
};

export const sensitiveRevealMessage = (data: {
    id: number;
    chat_id: number;
}) => {
    postMessageToWorker({
        action: WorkerActions.SensitiveRevealMessage,
        data,
    });
};

export const sendReadChat = (data: { chat_id: number; at: number }) => {
    postMessageToWorker({
        action: WorkerActions.SendRead,
        data,
    });
};

export const translateThisChat = (data: {
    id: number;
    state: boolean;
    tr_lang: string;
}) => {
    postMessageToWorker({
        action: WorkerActions.TranslateChat,
        data,
    });
};

export const fetchNewUsers = (data: {
    lastAt: number;
    type: 'NEW' | 'OPEN';
}) => {
    postMessageToWorker({
        action: WorkerActions.FetchUsers,
        data,
    });
};

export const updateUserImagesAction = (firstId: number, secondId: number) => {
    postMessageToWorker({
        action: WorkerActions.UpdateUserImages,
        data: { firstId, secondId },
    });
};

export const sentReport = async (
    id: number,
    comment?: string,
    reason?: string
) => {
    postMessageToWorker({
        action: WorkerActions.UpdateChatsList,
        data: { id, comment, reason },
    });
};

export const removeClosedChat = async (id: number) => {
    postMessageToWorker({
        action: WorkerActions.RemoveClosedChat,
        data: { id },
    });
};
