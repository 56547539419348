import CheckBox from '@/assets/icons/check-box.svg?react';
import { scaleFont, scaleSize } from '@/utils/scaleSizeAndFont';
import { twMerge } from 'tailwind-merge';
interface CheckboxInputProps {
    checked: boolean;
    onChange: () => void;
    label: string;
    description?: string;
    withCheckbox?: boolean;
    withBorder?: boolean;
    blueBorder?: boolean;
    blueBorderInput?: boolean;
    alignment?: 'center' | 'left' | 'right';
    icon?: React.ComponentType;
    type?: 'checkbox' | 'radio' | 'mark';
    fromSSS?: boolean;
    className?: string;
}

const CheckboxInput: React.FC<CheckboxInputProps> = ({
    checked,
    onChange,
    label,
    description,
    withCheckbox = false,
    withBorder = false,
    blueBorder = false,
    blueBorderInput = false,
    icon: Icon,
    type = 'radio',
    fromSSS = false,
    alignment,
    className,
}) => {
    let baseClasses = '';
    switch (type) {
        case 'checkbox':
            baseClasses = 'border-2 rounded';
            break;
        case 'radio':
            baseClasses = 'rounded-full border-2';
            break;
        case 'mark':
            baseClasses = 'border-0';
            break;

        default:
            baseClasses = '';
    }

    return (
        <div
            className={twMerge(
                'w-full rounded-[13px] border border-transparent',
                'active:border-active',
                fromSSS &&
                    (blueBorder || blueBorderInput) &&
                    'active:border-primary'
            )}
            style={{
                marginTop: `${blueBorderInput ? scaleSize(8) : scaleSize(12)}px`,
            }}
        >
            <div
                className={twMerge(
                    'flex w-full cursor-pointer select-none flex-row-reverse justify-between rounded-xl border border-transparent bg-white text-black',
                    'active:border-active',
                    withBorder && 'border-stroke',
                    withBorder && checked && 'border-primary',
                    alignment === 'center' && 'justify-center',
                    alignment === 'left' && 'justify-end',
                    alignment === 'right' && 'justify-start',
                    checked && blueBorder && 'border border-primary',
                    fromSSS && 'transition-all ',
                    fromSSS && blueBorder && 'transition-all',
                    fromSSS &&
                        (blueBorder || blueBorderInput) &&
                        'active:border-primary',
                    className
                )}
                onClick={onChange}
                style={{
                    padding: `${scaleSize(12)}px`,
                }}
            >
                <div
                    className={twMerge(
                        'flex items-center',
                        withBorder && Icon && 'items-start'
                    )}
                >
                    {withCheckbox && (
                        <>
                            <input
                                type='checkbox'
                                checked={checked}
                                onChange={onChange}
                                className='hidden'
                            />
                            <div
                                className={twMerge(
                                    baseClasses,
                                    'mr-2 flex h-5 w-5 items-center justify-center transition-all duration-300 sm:h-6 sm:w-6',
                                    blueBorderInput && ' border-primary',
                                    checked &&
                                        !withBorder &&
                                        ' border-cyan-500 bg-cyan-500'
                                )}
                            >
                                {type === 'mark' && checked && (
                                    <CheckBox fill='#51aed4' />
                                )}
                                {!withBorder && checked && <CheckBox />}
                            </div>
                        </>
                    )}
                </div>
                <div className='flex flex-col justify-center'>
                    <div className='flex items-center'>
                        {Icon && (
                            <div
                                className='mr-2'
                                style={{
                                    height: `${scaleSize(24)}px`,
                                    width: `${scaleSize(24)}px`,
                                }}
                            >
                                <Icon />
                            </div>
                        )}
                        <span
                            style={{
                                fontSize: `${fromSSS ? scaleFont(14) : scaleFont(18)}px`,
                                lineHeight: `${fromSSS ? scaleFont(18) : scaleFont(22)}px`,
                            }}
                        >
                            {label}
                        </span>
                    </div>
                    <span
                        className='w-full font-light text-gray-500'
                        style={{
                            fontSize: `${scaleFont(12)}px`,
                            lineHeight: `${scaleFont(22)}px`,
                        }}
                    >
                        {description}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default CheckboxInput;
