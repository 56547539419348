import { observer } from 'mobx-react-lite';
import { useRef } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { twMerge } from 'tailwind-merge';

import EarthIcon from '@/assets/icons/earth-icon.svg?react';
import NewHere from '@/assets/icons/general-new-here.svg?react';
import SharedIcon from '@/assets/icons/shared-icon.svg?react';
import Shield from '@/assets/icons/shield.svg?react';
import { User, isUserOnline } from '@/domain';
import { useScreenDetector } from '@/hooks/useScreenDetector';
import { analyticsEvent } from '@shared/services/analytics';

import { goals } from '../auth/userInfoSteps/GoalStep';

interface ProfilePreviewHeader {
    user: User | null;
    modal?: boolean;
    opacityVisible?: number;
    width?: number;
}

const ProfilePreviewHeader: React.FC<ProfilePreviewHeader> = observer(
    ({ user, modal = false, opacityVisible = 1, width = 632 }) => {
        const opacityRef: any = useRef();
        const userGoal = goals.find((item) => item.value === user?.goal);
        const newHere = user?.new_here;
        const { isMobile } = useScreenDetector();
        const maxLength = isMobile ? 12 : 30;
        const userName =
            user?.first_name?.length || 0 < maxLength
                ? user?.first_name
                : `${user?.first_name?.slice(
                      0,
                      maxLength / 2
                  )}...${user?.first_name?.slice(-maxLength / 3)}`;
        const copyLink = async () => {
            if (navigator.share) {
                try {
                    const url = `${window.location.origin}/push?badge=376&event_type=share_profile&user_nickname=${user?.nickname}`;
                    await navigator.share({
                        url: url,
                    });
                    analyticsEvent('Card_OptionShare_Press');
                } catch (error) {
                    console.error('Error sharing:', error);
                }
            } else {
                alert('Sharing is not supported on your device.');
            }
        };

        return (
            <div
                ref={opacityRef}
                className={twMerge(
                    '-translate-x-1/2 fixed left-1/2 z-[51] flex flex-col transition-transform'
                )}
                style={{
                    opacity: opacityVisible,
                    width: modal || isMobile ? width - 32 : width - 64,
                }}
            >
                <div
                    className={twMerge(
                        'z-30 mt-[15px] flex items-center justify-between'
                    )}
                >
                    <div className='flex items-center gap-2'>
                        {user?.country_code ? (
                            <ReactCountryFlag
                                svg
                                alt={`${user.country_code}`}
                                countryCode={`${user.country_code.toUpperCase()}`}
                                style={{
                                    borderRadius: '50%',
                                    width: '35px',
                                    height: '35px',
                                    objectFit: 'cover',
                                }}
                            />
                        ) : (
                            <EarthIcon width={35} height={35} />
                        )}
                        <p className=' text-white text-xl'>
                            {userName} {user?.age && `, ${user.age}`}
                        </p>
                        {user?.verified && (
                            <Shield className='h-[25px] w-[25px]' />
                        )}
                        {user && isUserOnline(user, Date.now()) && (
                            <div className='z-10 h-4 w-4 rounded-full bg-lime-400' />
                        )}
                    </div>
                    <SharedIcon className='cursor-pointer' onClick={copyLink} />
                </div>
                <div
                    className={twMerge(
                        'z-30 ml-[58px] flex w-fit items-center gap-2 rounded-full px-4 py-1 text-xs ',
                        newHere
                            ? 'bg-primary font-semibold text-white opacity-90'
                            : 'bg-lightPrimary opacity-70'
                    )}
                >
                    {newHere ? (
                        <>
                            <NewHere />
                            New here
                        </>
                    ) : (
                        <span className='flex gap-2'>
                            {userGoal?.icon({ width: 14, height: 14 })}
                            {userGoal?.label}
                        </span>
                    )}
                </div>
            </div>
        );
    }
);

export default ProfilePreviewHeader;
