import MDonwloads from '@/assets/icons/10m-downloads.svg?react';
import { useScreenDetector } from '@/hooks/useScreenDetector';
import { getMediaUrl } from '@/utils/getMediaUrl';
import { scaleFont, scaleSize } from '@/utils/scaleSizeAndFont';
import LogoWhiteIcon from '@assets/icons/logo-white-with-shadow.svg?react';
import { useState } from 'react';
import { twMerge } from 'tailwind-merge';
import LazyLoadVideo from '../LazyLoadVideo';
import MobileStoreButtons from './MobileStoreButtons';

const AppsSection = () => {
    const { isMobile } = useScreenDetector();
    const [videoLoaded, setVideoLoaded] = useState(false);

    const imageSrc = isMobile
        ? getMediaUrl('landing/bottom_mob.webp')
        : getMediaUrl('landing/bottom_desk.webp');
    const videoSrc = isMobile
        ? getMediaUrl('landing/Bottom_mob.webm')
        : getMediaUrl('landing/Bottom_desk.webm');

    return (
        <div className='relative h-[90vh] w-screen select-none sm:h-screen'>
            <div
                className={twMerge(
                    'absolute top-0 left-0 h-full w-full bg-center bg-cover transition-opacity',
                    videoLoaded ? 'opacity-0' : 'opacity-100'
                )}
                style={{
                    backgroundImage: `url(${imageSrc})`,
                }}
            />
            <LazyLoadVideo
                videoSrc={videoSrc}
                videoLoaded={videoLoaded}
                setVideoLoaded={setVideoLoaded}
            />
            <div className='absolute bottom-0 z-10 flex h-full w-full flex-col items-center text-secondary sm:bottom-12'>
                <div className='mb-16 flex h-full flex-col items-center justify-center'>
                    <LogoWhiteIcon
                        className='sm:mt-[200px]'
                        width={scaleSize(isMobile ? 120 : 200)}
                        height={scaleSize(isMobile ? 76 : 126)}
                        fill='white'
                    />
                    <MDonwloads />
                </div>
                <div className='flex h-full flex-col items-center pt-6'>
                    <span
                        className='mb-4 font-semibold'
                        style={{
                            fontSize: scaleFont(isMobile ? 20 : 24),
                            textShadow:
                                '2px 2px 5px rgba(0, 0, 0, 0.3), -2px -2px 5px rgba(0, 0, 0, 0.2)',
                        }}
                    >
                        Download now
                    </span>
                    <MobileStoreButtons />
                </div>
            </div>
        </div>
    );
};

export default AppsSection;
