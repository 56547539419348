import { useEffect, useState } from 'react';

export const useCountdown = (initialCountdown: number) => {
    const [countdown, setCountdown] = useState(initialCountdown);

    useEffect(() => {
        if (countdown > 0) {
            const timer = setTimeout(
                () => setCountdown((prev) => prev - 1),
                1000
            );
            return () => clearTimeout(timer);
        }
    }, [countdown]);

    return {
        countdown,
        setCountdown,
    };
};
