import { useCallback, useEffect, useState } from 'react';

import { signIn } from '@/api/auth';
import { EMAIL_SIGN_IN_STEPS } from '@/pages/EmailSignIn';
import { LOCAL_STORAGE } from '@/utils/localStorage';
import { isValidEmail } from '@/utils/validation';
import { analyticsEvent } from '@shared/services/analytics';

export const useEmailSignIn = (
    setStep: (step: EMAIL_SIGN_IN_STEPS) => void,
    onNext?: (isShorterFlow?: boolean) => void
) => {
    const [email, setEmail] = useState<string>(
        localStorage.getItem(LOCAL_STORAGE.EmailSignIn) || ''
    );
    const [isValidEmailField, setIsValidEmailField] = useState(true);
    const [noTries, setNoTries] = useState<boolean>(false);
    const [emailLimints, setEmailLimints] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        setIsValidEmailField(isValidEmail(email));
    }, [email]);

    const handleEmailChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setEmail(e.target.value);
        },
        []
    );

    const handleEmailSubmit = useCallback(async () => {
        setIsLoading(true);
        try {
            await signIn(email);
            localStorage.setItem(LOCAL_STORAGE.EmailSignIn, email);
            if (onNext) {
                onNext();
            } else setStep(EMAIL_SIGN_IN_STEPS.CODE);
        } catch (error: any) {
            analyticsEvent('EmailRequestError', {
                error: error.response?.data?.m || '',
            });
            if (error.response?.data?.m === 'e_email_verification') {
                setNoTries(true);
            } else if (error.response?.data?.m === 'e_email_limit_reached') {
                setEmailLimints(true);
            } else {
                setIsValidEmailField(false);
                console.error(error);
            }
        } finally {
            setIsLoading(false);
        }
    }, [email, setStep, onNext]);

    return {
        email,
        isValidEmailField,
        noTries,
        setNoTries,
        emailLimints,
        setEmailLimints,
        isLoading,
        handleEmailChange,
        handleEmailSubmit,
    };
};
