import createAxiosInstance from '../../api/axios';

export async function validateCard({
    bin,
    token,
}: { bin: string; token: string }) {
    const axiosInstance = createAxiosInstance({ token });
    const response = await axiosInstance.post('/card/validate', {
        bin,
    });

    return response;
}
