import AppStore from '@/assets/icons/app-store-icon.svg?react';
import GooglePlay from '@/assets/icons/google-play-icon.svg?react';
import { useScreenDetector } from '@/hooks/useScreenDetector';
import { getDeviceType } from '@/utils/getDeviceType';

const MobileStoreButtons = () => {
    const { isMobile } = useScreenDetector();
    const device = getDeviceType();
    return (
        <div className='flex w-full flex-col items-center gap-6 sm:flex-row'>
            {device !== 'Android' && (
                <a
                    href='https://apps.apple.com/us/app/bumpy-international-dating/id1455336523?platform=iphone'
                    target='_blank'
                    rel='noopener noreferrer'
                    aria-label='Apple store link'
                >
                    <AppStore
                        className='cursor-pointer'
                        width={isMobile ? 200 : 240}
                        height={isMobile ? 66 : 80}
                    />
                </a>
            )}
            {device !== 'iOS' && (
                <a
                    href='https://play.google.com/store/apps/details?id=app.bumpy.android&pli=1'
                    target='_blank'
                    rel='noopener noreferrer'
                    aria-label='GooglePlay link'
                >
                    <GooglePlay
                        className='cursor-pointer'
                        width={isMobile ? 200 : 240}
                        height={isMobile ? 66 : 80}
                    />
                </a>
            )}
        </div>
    );
};

export default MobileStoreButtons;
