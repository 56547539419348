import { useEffect, useRef, useState } from 'react';

interface LazyLoadVideoProps {
    videoSrc: string;
    videoLoaded: boolean;
    setVideoLoaded: React.Dispatch<React.SetStateAction<boolean>>;
}

const LazyLoadVideo: React.FC<LazyLoadVideoProps> = ({
    videoSrc,
    videoLoaded,
    setVideoLoaded,
}) => {
    const [isInView, setIsInView] = useState(false);
    const videoRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsInView(true);
                    observer.disconnect();
                }
            },
            { threshold: 0.1 }
        );

        if (videoRef.current) {
            observer.observe(videoRef.current);
        }

        return () => {
            if (observer && videoRef.current) {
                observer.unobserve(videoRef.current);
            }
        };
    }, []);

    return (
        <div ref={videoRef} className='relative h-full w-full'>
            {isInView && (
                <video
                    className={`absolute top-0 left-0 h-full w-full object-cover transition-opacity ${
                        videoLoaded ? 'opacity-100' : 'opacity-0'
                    }`}
                    autoPlay
                    muted
                    loop
                    playsInline
                    onLoadedData={() => setVideoLoaded(true)}
                >
                    <source src={videoSrc} type='video/webm' />
                </video>
            )}
        </div>
    );
};

export default LazyLoadVideo;
