import { getDeviceType } from './getDeviceType';

function getScaleSize(): number {
    const width = window.innerWidth;
    const scale = window.devicePixelRatio;
    if (width > 640) {
        return 1;
    }
    if (getDeviceType() === 'Android') {
        if (scale === 3 && width === 360) {
            return 0.8;
        }
    } else if (getDeviceType() === 'iOS') {
        if (scale <= 2) {
            return width < 330 ? 0.7 : 0.85;
        }
        if (scale <= 2.75) {
            return 0.9;
        }
        return 1;
    }
    return 1;
}

function getScaleFont(): number {
    const width = window.innerWidth;
    const scale = window.devicePixelRatio;
    if (width > 640) {
        return 1;
    }
    if (scale <= 2) {
        return 0.9;
    }
    if (scale <= 2.75) {
        return 0.94;
    }
    return 1;
}

const _scaleSize = getScaleSize();
const _scaleFont = getScaleFont();

export function scaleSize(size: number): number {
    return size * _scaleSize;
}

export function scaleFont(fontSize: number): number {
    return Math.ceil(fontSize * _scaleFont);
}

export function isFontScaled(): boolean {
    const fontScale = window.devicePixelRatio;
    return fontScale > 1.0;
}
