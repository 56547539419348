import EarthIcon from '@/assets/icons/earth-icon.svg?react';
import userStore from '@/entities/user/store';
import { scaleSize } from '@/utils/scaleSizeAndFont';
import { observer } from 'mobx-react-lite';
import ReactCountryFlag from 'react-country-flag';
import Avatar from '../common/CircleAvatar/Avatar';

interface NewViewNotificationsProps {
    data: any;
    subscription?: boolean;
}

const NewViewNotifications: React.FC<NewViewNotificationsProps> = observer(
    ({ data, subscription = false }) => {
        return (
            <>
                <div className='relative'>
                    {!data.data?.user ? (
                        <></>
                    ) : data.data?.user?.country_code ? (
                        <ReactCountryFlag
                            svg
                            className='absolute bottom-0 z-20'
                            alt={`${data.data?.user?.country_code}`}
                            countryCode={`${data.data?.user?.country_code}`}
                            style={{
                                borderRadius: '50%',
                                width: `${scaleSize(18)}px`,
                                height: `${scaleSize(18)}px`,
                                objectFit: 'cover',
                            }}
                        />
                    ) : (
                        <EarthIcon className='absolute bottom-0' />
                    )}
                    <div
                        className='absolute z-10 rounded-full border-2 border-white bg-lime-400'
                        style={{
                            right: `-${scaleSize(6)}px`,
                            top: `${scaleSize(10)}px`,
                            width: `${scaleSize(12)}px`,
                            height: `${scaleSize(12)}px`,
                        }}
                    />
                    <Avatar
                        src={`${data.data?.user.avatar_url || data.data?.user.avatar_urls[0]}?${userStore.home?.pub_urls_query}`}
                        alt='rounded avatar'
                        blur={!data.data?.list.visible}
                        size={scaleSize(40)}
                        imageSize='small'
                    />
                </div>
                <span style={{ fontSize: scaleSize(14) }}>
                    {subscription
                        ? `${data.data?.user?.first_name} just viewed your profile`
                        : 'Somebody viewed your profile 👀'}
                </span>
            </>
        );
    }
);

export default NewViewNotifications;
