import userStore from '@/entities/user/store';
import { nationalityCodes } from '@/utils/nationalityNames';
import { scaleFont, scaleSize } from '@/utils/scaleSizeAndFont';
import React, { ReactNode } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import Avatar from './CircleAvatar/Avatar';

interface MultiSelectItemProps {
    item: string;
    isSelected?: boolean;
    onClick: (item: string) => void;
    isUserDataFlow?: boolean;
    type?: string;
    logo?: ReactNode;
    match?: boolean;
    className?: string;
    code?: number;
    small?: boolean;
}

const MultiSelectItem: React.FC<MultiSelectItemProps> = ({
    item,
    isSelected = false,
    onClick,
    isUserDataFlow = false,
    type,
    logo,
    match = false,
    className,
    code,
    small,
}) => {
    let countryCode = '';
    const { nickname } = useParams<{ nickname: string }>();

    if (type === 'nat') {
        if (code) {
            countryCode =
                nationalityCodes[
                    code as unknown as keyof typeof nationalityCodes
                ];
        }
    }

    return (
        <div className='relative flex'>
            <div
                onClick={() => onClick(item)}
                className={twMerge(
                    'my-1 flex cursor-pointer items-center gap-1 rounded-full border px-4 py-2 font-normal text-black text-sm transition-all duration-200',
                    isSelected
                        ? 'border-primary bg-lightPrimary'
                        : 'border-black bg-white',
                    isSelected &&
                        isUserDataFlow &&
                        'border-white bg-interestActive',
                    className,
                    match &&
                        nickname !== userStore?.userData?.nickname &&
                        'bg-secondGold'
                )}
                style={{
                    fontSize: small ? scaleFont(12) : scaleFont(14),
                    paddingLeft: scaleSize(8),
                    paddingRight: scaleSize(8),
                    paddingTop: scaleSize(5),
                    paddingBottom: scaleSize(5),
                }}
            >
                {logo ? logo : null}
                {type === 'eth' && <p>🌐</p>}
                {type === 'nat' && countryCode && (
                    <ReactCountryFlag
                        countryCode={countryCode}
                        alt={`${countryCode}`}
                    />
                )}
                {type === 'lang' && <p>💬</p>}
                {item?.replace(/_/g, ' ')}
            </div>
            {match && nickname !== userStore?.userData?.nickname && (
                <Avatar
                    src={`${userStore.userData?.avatar_urls?.[0]}?${userStore.home?.pub_urls_query}`}
                    alt='rounded avatar'
                    imageSize='tiny'
                    size={scaleSize(20)}
                    className='-right-2 -top-1 absolute z-10 border border-white'
                />
            )}
        </div>
    );
};

export default MultiSelectItem;
