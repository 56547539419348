import Loader from '@/assets/icons/loading.svg?react';
import OrangeError from '@/assets/icons/orange-error.svg?react';
import { HTML5toTouch } from 'rdndmb-html5-to-touch';
import React, { useState, useEffect } from 'react';
import { DndProvider } from 'react-dnd-multi-backend';
import { twMerge } from 'tailwind-merge';
import RulesModal from '../../modals/RulesModal';
import Button from '../Button/Button';
import PhotoItem from './PhotoItemDraggable';
import PhotoValidateModal from './PhotoValidateModal';

interface PhotoListProps {
    initialPhotoCount: number;
    photoCount: number;
    onPhotoCountChange: (count: number) => void;
    onEditPage?: boolean;
}

const PhotoList: React.FC<PhotoListProps> = ({
    initialPhotoCount,
    photoCount,
    onPhotoCountChange,
    onEditPage = false,
}) => {
    const [photos, setPhotos] = useState<number[]>([]);
    const [photoStatus, setPhotoStatus] = useState<boolean[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [modalPhotoPreview, setModalPhotoPreview] = useState<
        string | undefined
    >(undefined);
    const [showRulesModal, setShowRulesModal] = useState<boolean>(false);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        const updatedStatus = new Array(photoStatus.length).fill(false);
        for (let i = 0; i < photoCount && i < updatedStatus.length; i++) {
            updatedStatus[i] = true;
        }
        setPhotoStatus(updatedStatus);
    }, [photoCount]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        const initialPhotos = Array.from(
            { length: initialPhotoCount },
            (_, index) => index
        );
        setPhotos(initialPhotos);
        const initialPhotoStatus = Array(initialPhotoCount)
            .fill(false)
            .map((_, index) => index < photoCount);
        setPhotoStatus(initialPhotoStatus);
    }, [initialPhotoCount]);

    const moveItem = (dragIndex: number, hoverIndex: number) => {
        if (!photoStatus[dragIndex] || !photoStatus[hoverIndex]) return;

        const dragItem = photos[dragIndex];
        const updatedPhotos = [...photos];
        updatedPhotos.splice(dragIndex, 1);
        updatedPhotos.splice(hoverIndex, 0, dragItem);

        setPhotos(updatedPhotos);
    };

    const handlePhotoChange = (index: number, hasPhoto: boolean) => {
        const newPhotoCount = hasPhoto ? photoCount + 1 : photoCount - 1;
        onPhotoCountChange(newPhotoCount);
        const updatedStatus = new Array(photoStatus.length).fill(false);

        for (let i = 0; i < newPhotoCount && i < updatedStatus.length; i++) {
            updatedStatus[i] = true;
        }

        setPhotoStatus(updatedStatus);

        if (hasPhoto) {
            if (photoCount === 0) {
                movePhotoToFirstPosition(index);
            }
        } else {
            const updatedPhotos = photos.filter((_, i) => i !== index);
            const newPhotoId = photos[index];
            updatedPhotos.push(newPhotoId);
            setPhotos(updatedPhotos);
        }
    };

    const movePhotoToFirstPosition = (index: number) => {
        const updatedPhotos = [...photos];
        const photo = updatedPhotos.splice(index, 1)[0];
        updatedPhotos.unshift(photo);
        setPhotos(updatedPhotos);
    };

    const addPhotoAtPosition = (index: number) => {
        if (index >= photos.length) return;

        const updatedPhotos = [...photos];
        const photo = updatedPhotos.splice(index, 1)[0];
        updatedPhotos.splice(photoCount, 0, photo);
        setPhotos(updatedPhotos);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setModalPhotoPreview(undefined);
    };

    const handleOpenRulesModal = () => {
        setShowRulesModal(true);
    };

    const handleCloseRulesModal = () => {
        setShowRulesModal(false);
    };

    const renderModal = () => (
        <>
            <div className='fixed inset-0 z-40 bg-black bg-opacity-50' />
            <PhotoValidateModal show={showModal} onClose={handleCloseModal}>
                <div className='mb-8 flex justify-center'>
                    <div className='absolute top-[180px] right-[130px]'>
                        <OrangeError width={30} height={30} />
                    </div>
                    <img
                        src={modalPhotoPreview}
                        alt='Photo_Modal_Preview'
                        className='h-44 w-36 rounded-2xl object-cover'
                    />
                </div>
                <div className='flex flex-col gap-4 text-center'>
                    <h2 className='font-bold text-black text-xl'>
                        Your photo is not allowed
                    </h2>
                    <h2 className='text-black'>
                        The photo cannot be validated. Learn more about our{' '}
                        <span
                            className='cursor-pointer text-blue-600'
                            onClick={handleOpenRulesModal}
                        >
                            rules
                        </span>
                    </h2>
                    <Button
                        variant='primary'
                        onClick={handleCloseModal}
                        label=' Retake a photo'
                    />

                    <Button label='View rules' onClick={handleOpenRulesModal} />

                    <a
                        href='mailto:support@bumpy.app'
                        className='text-gray-500 underline'
                    >
                        Contact customer support
                    </a>
                </div>
            </PhotoValidateModal>
        </>
    );

    const renderRulesModal = () => (
        <>
            <div className='fixed inset-0 z-40 bg-black bg-opacity-50' />
            <RulesModal show={showRulesModal} onClose={handleCloseRulesModal}>
                <div className='text-center'>
                    <h2 className='mb-4 font-bold text-2xl'>Rules</h2>
                    <p className='text-black'>
                        Here are the rules for uploading photos. Replace this
                        with your actual rules content.
                    </p>
                </div>
            </RulesModal>
        </>
    );

    return (
        <DndProvider options={HTML5toTouch}>
            <div
                className={twMerge(
                    'flex flex-wrap items-center justify-center gap-1 overflow-y-scroll pt-3 sm:content-center sm:gap-8'
                )}
            >
                {photos.map((id, index) => (
                    <PhotoItem
                        key={id}
                        id={id}
                        index={index}
                        moveItem={moveItem}
                        onPhotoChange={(hasPhoto) => {
                            handlePhotoChange(index, hasPhoto);
                            if (hasPhoto) {
                                addPhotoAtPosition(index);
                            }
                        }}
                        photoCount={photoCount}
                        photoStatus={photoStatus[index]}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        setShowModal={setShowModal}
                        setModalPhotoPreview={setModalPhotoPreview}
                        setPhotoStatus={setPhotoStatus}
                        onEditPage={onEditPage}
                    >
                        {isLoading && photoCount === index ? (
                            <div className='flex h-full w-full items-center justify-center rounded-xl border-white focus:border-black hover:border-black'>
                                <Loader width={50} height={50} />
                            </div>
                        ) : (
                            <span className='-mt-2 font-normal text-4xl text-primary'>
                                +
                            </span>
                        )}
                    </PhotoItem>
                ))}
            </div>
            {showModal && renderModal()}
            {showRulesModal && renderRulesModal()}
        </DndProvider>
    );
};

export default PhotoList;
