import ArrowBackDark from '@/assets/icons/arrow-back-dark.svg?react';
import ArrowBack from '@/assets/icons/arrow-back.svg?react';
import Cross from '@/assets/icons/cross.svg?react';

import ArrowRight from '@/assets/icons/arrow-forward-full.svg?react';
import { useScreenDetector } from '@/hooks/useScreenDetector';
import { scaleSize } from '@/utils/scaleSizeAndFont';

import React from 'react';

interface BackButtonProps {
    onGoBack: () => void;
    dark?: boolean;
    close?: boolean;
    arrow?: boolean;
    className?: string;
}

const BackButton: React.FC<BackButtonProps> = ({
    onGoBack,
    dark = false,
    close = false,
    arrow = false,
    className,
}) => {
    const { isMobile } = useScreenDetector();
    return (
        <button onClick={onGoBack} className={className}>
            {arrow ? (
                <ArrowRight className='rotate-180' />
            ) : close ? (
                <div>
                    <Cross
                        width={isMobile ? 28 : 36}
                        height={isMobile ? 28 : 36}
                        fill='#3F3F3F'
                    />
                </div>
            ) : dark ? (
                <ArrowBackDark width={scaleSize(36)} height={scaleSize(36)} />
            ) : (
                <ArrowBack width={scaleSize(48)} height={scaleSize(48)} />
            )}
        </button>
    );
};

export default React.memo(BackButton);
