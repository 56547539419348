export function getEnvVar(key: string, defaultValue?: string) {
    if (import.meta.env[key] === undefined) {
        if (defaultValue === undefined) {
            throw new Error(`Env variable ${key} is required`);
        }
        return defaultValue;
    }

    return import.meta.env[key];
}
