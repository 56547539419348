import { SupportedLang, UserDetailed, unpackBits } from '@/domain';

export const supportedCode2Languages: {
    [lang in any]: SupportedLang;
} = {
    de: SupportedLang.GERMAN,
    hi: SupportedLang.HINDI,
    fil: SupportedLang.FILIPINO,
    lt: SupportedLang.LITHUANIAN,
    hr: SupportedLang.CROATIAN,
    lv: SupportedLang.LATVIAN,
    hu: SupportedLang.HUNGARIAN,
    'zh-Hans': SupportedLang.CHINESE_HANS,
    uk: SupportedLang.UKRAINIAN,
    id: SupportedLang.INDONESIAN,
    ur: SupportedLang.URDU,
    ml: SupportedLang.MALAYALAM,
    mr: SupportedLang.MARATHI,
    ms: SupportedLang.MALAY,
    el: SupportedLang.GREEK,
    en: SupportedLang.ENGLISH,
    is: SupportedLang.ICELANDIC,
    it: SupportedLang.ITALIAN,
    am: SupportedLang.AMHARIC,
    iw: SupportedLang.HEBREW,
    es: SupportedLang.SPANISH,
    et: SupportedLang.ESTONIAN,
    eu: SupportedLang.BASQUE,
    ar: SupportedLang.ARABIC,
    'pt-PT': SupportedLang.PORTUGUESE_PORTUGAL,
    vi: SupportedLang.VIETNAMESE,
    ja: SupportedLang.JAPANESE,
    ro: SupportedLang.ROMANIAN,
    nl: SupportedLang.DUTCH,
    no: SupportedLang.NORWEGIAN,
    fi: SupportedLang.FINNISH,
    ru: SupportedLang.RUSSIAN,
    bg: SupportedLang.BULGARIAN,
    bn: SupportedLang.BENGALI,
    fr: SupportedLang.FRENCH,
    sk: SupportedLang.SLOVAK,
    sl: SupportedLang.SLOVENIAN,
    ca: SupportedLang.CATALAN,
    sr: SupportedLang.SERBIAN,
    kn: SupportedLang.KANNADA,
    sv: SupportedLang.SWEDISH,
    ko: SupportedLang.KOREAN,
    sw: SupportedLang.SWAHILI,
    'zh-TW': SupportedLang.CHINESE_TAIWAN,
    'pt-BR': SupportedLang.PORTUGUESE_BRAZIL,
    ta: SupportedLang.TAMIL,
    gu: SupportedLang.GUJARATI,
    cs: SupportedLang.CZECH,
    te: SupportedLang.TELUGU,
    th: SupportedLang.THAI,
    cy: SupportedLang.WELSH,
    pl: SupportedLang.POLISH,
    da: SupportedLang.DANISH,
    tr: SupportedLang.TURKISH,
};

export function getLangCodeCorrected(code: string): string {
    if (code === 'ua') {
        return 'uk';
    }
    return code as string;
}

export function unpackUserLanguages(user: UserDetailed): SupportedLang[] {
    if (user?.languages) {
        return unpackBits(user?.languages);
    }
    return unpackUserLanguagesLegacy(user);
}

export function unpackUserLanguagesLegacy(user: UserDetailed): SupportedLang[] {
    const result: SupportedLang[] = [];
    const lang = getLangCodeCorrected(user?.lang);

    if (user?.tr_lang && user?.tr_lang !== lang) {
        const langBit = supportedCode2Languages[user?.tr_lang as string];
        if (langBit) {
            result.push(langBit);
        }
    }
    result.push(supportedCode2Languages[lang]);
    return result;
}

interface Languages {
    [key: string]: string;
}

export const languages: Languages = {
    af: 'Afrikaans',
    sq: 'Albanian',
    am: 'Amharic',
    ar: 'Arabic',
    hy: 'Armenian',
    as: 'Assamese',
    ay: 'Aymara',
    az: 'Azerbaijani',
    bm: 'Bambara',
    eu: 'Basque',
    be: 'Belarusian',
    bn: 'Bengali',
    bho: 'Bhojpuri',
    bs: 'Bosnian',
    bg: 'Bulgarian',
    ca: 'Catalan',
    ceb: 'Cebuano',
    zh: 'Chinese (Simplified)',
    'zh-TW': 'Chinese (Traditional)',
    co: 'Corsican',
    hr: 'Croatian',
    cs: 'Czech',
    da: 'Danish',
    dv: 'Dhivehi',
    doi: 'Dogri',
    nl: 'Dutch',
    en: 'English',
    eo: 'Esperanto',
    et: 'Estonian',
    ee: 'Ewe',
    fil: 'Filipino',
    fi: 'Finnish',
    fr: 'French',
    fy: 'Frisian',
    gl: 'Galician',
    ka: 'Georgian',
    de: 'German',
    el: 'Greek',
    gn: 'Guarani',
    gu: 'Gujarati',
    ht: 'Haitian Creole',
    ha: 'Hausa',
    haw: 'Hawaiian',
    he: 'Hebrew',
    hi: 'Hindi',
    hmn: 'Hmong',
    hu: 'Hungarian',
    is: 'Icelandic',
    ig: 'Igbo',
    ilo: 'Iloko',
    id: 'Indonesian',
    ga: 'Irish',
    it: 'Italian',
    ja: 'Japanese',
    jv: 'Javanese',
    kn: 'Kannada',
    kk: 'Kazakh',
    km: 'Khmer',
    rw: 'Kinyarwanda',
    gom: 'Konkani',
    ko: 'Korean',
    kri: 'Krio',
    ku: 'Kurdish',
    ckb: 'Kurdish (Sorani)',
    ky: 'Kyrgyz',
    lo: 'Lao',
    la: 'Latin',
    lv: 'Latvian',
    ln: 'Lingala',
    lt: 'Lithuanian',
    lg: 'Luganda',
    lb: 'Luxembourgish',
    mk: 'Macedonian',
    mai: 'Maithili',
    mg: 'Malagasy',
    ms: 'Malay',
    ml: 'Malayalam',
    mt: 'Maltese',
    mi: 'Maori',
    mr: 'Marathi',
    'mni-Mtei': 'Meitei (Manipuri)',
    lus: 'Mizo',
    mn: 'Mongolian',
    my: 'Burmese',
    ne: 'Nepali',
    no: 'Norwegian',
    ny: 'Chichewa',
    or: 'Odia (Oriya)',
    om: 'Oromo',
    ps: 'Pashto',
    fa: 'Persian',
    pl: 'Polish',
    pt: 'Portuguese',
    pa: 'Punjabi',
    qu: 'Quechua',
    ro: 'Romanian',
    ru: 'Russian',
    sm: 'Samoan',
    sa: 'Sanskrit',
    gd: 'Scottish Gaelic',
    nso: 'Sepedi',
    sr: 'Serbian',
    st: 'Sesotho',
    sn: 'Shona',
    sd: 'Sindhi',
    si: 'Sinhala',
    sk: 'Slovak',
    sl: 'Slovenian',
    so: 'Somali',
    es: 'Spanish',
    su: 'Sundanese',
    sw: 'Swahili',
    sv: 'Swedish',
    tl: 'Tagalog',
    tg: 'Tajik',
    ta: 'Tamil',
    tt: 'Tatar',
    te: 'Telugu',
    th: 'Thai',
    ti: 'Tigrinya',
    ts: 'Tsonga',
    tr: 'Turkish',
    tk: 'Turkmen',
    ak: 'Akan',
    uk: 'Ukrainian',
    ur: 'Urdu',
    ug: 'Uyghur',
    uz: 'Uzbek',
    vi: 'Vietnamese',
    cy: 'Welsh',
    xh: 'Xhosa',
    yi: 'Yiddish',
    yo: 'Yoruba',
    zu: 'Zulu',
};
