import Thing from '@/assets/icons/important-thing.svg?react';
import { UserValues } from '@/domain';
import { getEnumOptions } from '@/utils/getEnumOptions';
import React from 'react';
import SingleSelectStep from '../../common/SingleSelectStep';
import UserDataFlowLayout from '../../layouts/UserDataFlowLayout';
import { UserInfoStepProps } from './userInfoConfig';

const MostImportantThingStep: React.FC<UserInfoStepProps> = ({ onNext }) => {
    return (
        <UserDataFlowLayout withBarProgress>
            <div className='-mb-[5px] h-[5px] w-full bg-white/50'>
                <div className='h-[5px] w-[80%] bg-white' />
            </div>
            <SingleSelectStep
                LogoComponent={Thing}
                title='What is the most important
                    thing in life for you?'
                options={getEnumOptions(UserValues)}
                withSkip
                onNext={onNext}
                field='values'
                fromEdit={false}
            />
        </UserDataFlowLayout>
    );
};

export default MostImportantThingStep;
