import { ShowcaseItem } from '@/entities/payment';

export function durationSubscriptionOrdinal(
    showcaseItem: ShowcaseItem
): string {
    let result = '';
    if (showcaseItem.count !== 1) {
        result += `${showcaseItem.count} `;
    }
    switch (showcaseItem.unit_id) {
        case 'week':
            result += 'weekly';
            break;
        case 'month':
            result += 'monthly';
            break;
        case 'year':
            result += 'annual;';
            break;
    }
    return result;
}

export function durationSubscription(showcaseItem: ShowcaseItem): string {
    if (showcaseItem.count === 1) {
        return showcaseItem.unit_id;
    }
    return `${showcaseItem.count} ${showcaseItem.unit_id}s`; //<<until we have english only
}
