import React, { useEffect } from 'react';

import { useScreenDetector } from '@/hooks/useScreenDetector';
import { LOCAL_STORAGE } from '@/utils/localStorage';
import { AppRoutes } from '@/utils/routes';
import { getData } from '@/workers/sharedWorkerInstance';
import Navigation from '@components/Navigation';

import Notifications from '../../components/notification/Notification';
import { useGenerateFirebaseToken } from '../hooks/useGenerateFirebaseToken';

const ProtectedLayout: React.FC<{
    children: React.ReactNode;
    withoutNav?: boolean;
}> = ({ children, withoutNav = false }) => {
    const { isMobile } = useScreenDetector();
    const AuthToken = localStorage.getItem(LOCAL_STORAGE.AuthToken);

    useGenerateFirebaseToken();

    useEffect(() => {
        if (!AuthToken) {
            window.location.href = AppRoutes.Login;
        }
        const timeoutId = setTimeout(() => {
            getData('userData');
            getData('matches');
        }, 0);
        return () => clearTimeout(timeoutId);
    }, [AuthToken]);

    useEffect(() => {
        const handleVisibilityChange = () => {
            const block =
                localStorage.getItem(LOCAL_STORAGE.BlockRefresh) === 'true';
            setTimeout(() => {
                if (
                    document.visibilityState === 'visible' &&
                    isMobile &&
                    !block
                ) {
                    window.location.reload();
                }
            }, 200);
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener(
                'visibilitychange',
                handleVisibilityChange
            );
        };
    }, [isMobile]);
    if (!AuthToken) {
        return;
    }
    return (
        <div className='flex min-h-screen w-screen flex-col'>
            <main className='flex w-screen items-center justify-center'>
                {children}
            </main>
            {!withoutNav && (
                <>
                    <Notifications />
                    <div className='z-30 flex justify-center'>
                        <Navigation />
                    </div>
                </>
            )}
        </div>
    );
};

export default ProtectedLayout;
