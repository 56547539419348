export function get<ReturnType = unknown>(key: string): ReturnType | null {
    const value = sessionStorage.getItem(key);

    if (value) {
        return JSON.parse(value);
    }

    return null;
}

export function set<SetType>(key: string, value: SetType): void {
    sessionStorage.setItem(key, JSON.stringify(value));
}

export function remove(key: string): void {
    sessionStorage.removeItem(key);
}
