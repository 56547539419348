import React, { useState } from 'react';

import CodeStep from '@/components/auth/emailSignInSteps/CodeStep';
import EmailStep from '@/components/auth/emailSignInSteps/EmailStep';

export enum EMAIL_SIGN_IN_STEPS {
    EMAIL = 'email',
    CODE = 'code',
}

const EmailSignIn: React.FC = () => {
    const [step, setStep] = useState<EMAIL_SIGN_IN_STEPS>(
        EMAIL_SIGN_IN_STEPS.EMAIL
    );

    const renderStepComponent = (step: EMAIL_SIGN_IN_STEPS) => {
        if (step === EMAIL_SIGN_IN_STEPS.EMAIL) {
            return <EmailStep setStep={setStep} />;
        }

        if (step === EMAIL_SIGN_IN_STEPS.CODE) {
            return <CodeStep setStep={setStep} />;
        }

        throw new Error(`Unknown step: ${step}`);
    };

    return (
        <div className='w-full text-secondary'>{renderStepComponent(step)}</div>
    );
};

export default EmailSignIn;
