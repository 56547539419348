import userStore from '@/entities/user/store';
import { updateUserImagesAction } from '@/workers/sharedWorkerInstance';

export const changePhotoPossition = (firstId: number, secondId: number) => {
    if (userStore?.userData?.avatars && firstId !== secondId) {
        try {
            updateUserImagesAction(
                userStore.userData.avatars[firstId],
                userStore.userData.avatars[secondId]
            );
        } catch (error) {
            console.log(error);
        }
    }
};
