import Slider from 'rc-slider';
import React, { useState } from 'react';
import 'rc-slider/assets/index.css';
import { Continent } from '@/domain';
import discoveryStore from '@/entities/discovery/store';
import { scaleSize } from '@/utils/scaleSizeAndFont';

interface ChangeLookingForProps {
    userValue: {
        match_gender: string;
        match_age_from: number;
        match_age_to: number;
        filter_verified_only: boolean;
        filter_continents: Continent[];
        discover_near: boolean;
    };
    setUserValue: React.Dispatch<React.SetStateAction<any>>;
}

const AgeRangeSelector: React.FC<ChangeLookingForProps> = ({
    userValue,
    setUserValue,
}) => {
    const [minAndMax, setMinAndMax] = useState<{ min: number; max: number }>({
        min: 18,
        max: 75,
    });
    const handleChange = (value: number | number[]) => {
        if (Array.isArray(value)) {
            const [minAge, maxAge] = value;
            if (
                userValue.match_age_from === minAge &&
                maxAge >= minAndMax.max
            ) {
                setUserValue(
                    (prev: {
                        match_gender: string;
                        match_age_from: number;
                        match_age_to: number;
                        filter_verified_only: boolean;
                        filter_continents: Continent[];
                        discover_near: boolean;
                    }) => ({
                        ...prev,
                        match_age_to: maxAge,
                    })
                );
                discoveryStore.updateMatchAgeTo(maxAge);
            }
            if (userValue.match_age_to === maxAge && minAge <= minAndMax.min) {
                setUserValue(
                    (prev: {
                        match_gender: string;
                        match_age_from: number;
                        match_age_to: number;
                        filter_verified_only: boolean;
                        filter_continents: Continent[];
                        discover_near: boolean;
                    }) => ({
                        ...prev,
                        match_age_from: minAge,
                    })
                );
                discoveryStore.updateMatchAgeFrom(minAge);
            }
        }
    };

    const handleBeforeChange = (value: number | number[]) => {
        if (Array.isArray(value)) {
            setMinAndMax({ min: value[1] - 7, max: value[0] + 7 });
        }
    };

    return (
        <div className='mx-auto flex w-full flex-col items-center rounded-xl border border-inactive bg-white px-4 py-4'>
            <div
                className='mb-4 flex w-full justify-between'
                style={{ fontSize: `${scaleSize(18)}px` }}
            >
                {`Between ${userValue.match_age_from} and ${userValue.match_age_to}`}
            </div>
            <Slider
                range
                min={18}
                max={75}
                step={1}
                value={[userValue.match_age_from, userValue.match_age_to]}
                onChange={handleChange}
                onBeforeChange={handleBeforeChange}
                trackStyle={{ backgroundColor: '#51aed4' }}
                railStyle={{ backgroundColor: '#e9ecef' }}
                handleStyle={[
                    {
                        borderColor: '#51aed4',
                        backgroundColor: '#51aed4',
                        width: scaleSize(28),
                        height: scaleSize(28),
                        marginTop: -scaleSize(14),
                        boxShadow: '0 0 0 2px #51aed4',
                        opacity: 1,
                    },
                    {
                        borderColor: '#51aed4',
                        backgroundColor: '#51aed4',
                        width: scaleSize(28),
                        height: scaleSize(28),
                        marginTop: -scaleSize(14),
                        boxShadow: '0 0 0 2px #51aed4',
                        opacity: 1,
                    },
                ]}
                className='w-full'
            />
        </div>
    );
};

export default AgeRangeSelector;
