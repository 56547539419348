import { Match, MatchInteractionType } from '@/domain';
import matchesStore from '@/entities/match/store';
import createAxiosInstance from './axios';

const interactionPayload = Math.random().toString();

export async function sendMessage(
    token: string,
    content: string,
    selectedChatId: number
): Promise<any> {
    const axiosInstance = createAxiosInstance({ token });
    const response = await axiosInstance.post(
        `/matches?ignore_limit=1&id=${selectedChatId}&show_tr=1`,
        {
            content,
        }
    );
    return response.data;
}

export async function deleteMessages(
    token: string,
    messageId: number
): Promise<any> {
    const axiosInstance = createAxiosInstance({ token });
    const response = await axiosInstance.delete(
        `/matches?message_id=${messageId}`
    );
    return response.data;
}

export async function sendEditMessage(
    token: string,
    content: string,
    messageId: number
): Promise<any> {
    const axiosInstance = createAxiosInstance({ token });
    const response = await axiosInstance.put(
        `/matches?message_id=${messageId}&ignore_limit=1`,
        {
            content,
        }
    );
    return response.data;
}
export async function sendEditMessageWithPhoto(
    token: string,
    message: string,
    messageId: number,
    photo: ArrayBuffer
): Promise<any> {
    const axiosInstance = createAxiosInstance({ token });
    const encodedMessage = encodeURIComponent(message);
    const response = await axiosInstance.put(
        `/matches?message_id=${messageId}&ignore_limit=1&content=${encodedMessage}`,
        photo
    );
    return response.data;
}

export async function fetchMatches(
    token: string,
    from?: string,
    userId?: number
): Promise<any> {
    const axiosInstance = createAxiosInstance({ token });
    const response = await axiosInstance.get(
        `/matches?${from ? `from=${from}` : ''}${userId ? `&id=${userId}` : ''}`
    );
    return response.data;
}

export async function fetchUsers(
    token: string,
    from?: number,
    status?: string
): Promise<any> {
    const axiosInstance = createAxiosInstance({ token });
    let url: string;
    if (status === 'OPEN') {
        url = `/matches?from=${from}&status=${status}&with_closed=1`;
    } else url = `/matches?from=${from}&status=${status}`;
    const response = await axiosInstance.get(url);
    return response.data;
}

export async function interaction(
    token: string,
    matchId: number,
    type: MatchInteractionType
): Promise<any> {
    const axiosInstance = createAxiosInstance({ token });
    const response = await axiosInstance.post('/matches/interaction', {
        match_id: matchId,
        type,
        payload: interactionPayload,
    });
    return response.data;
}

export async function finishInteraction(
    token: string,
    matchId: number,
    type: MatchInteractionType
): Promise<any> {
    const axiosInstance = createAxiosInstance({ token });
    const response = await axiosInstance.delete('/matches/interaction', {
        data: {
            match_id: matchId,
            type,
            payload: interactionPayload,
        },
    });
    return response.data;
}

export async function sendImage(
    token: string,
    content: string,
    matchId: number,
    imageBinary: string | ArrayBuffer
): Promise<any> {
    const axiosInstance = createAxiosInstance({ token });
    const response = await axiosInstance.post(
        `/matches?ignore_limit=1&content=${content}&interaction_payload=${interactionPayload}&id=${matchId}&show_tr=1`,
        imageBinary,
        {
            headers: {
                'Content-Type': 'image/jpg',
            },
        }
    );
    return response.data;
}

export async function sendReaction(
    token: string,
    id: number,
    reaction?: number
): Promise<any> {
    const axiosInstance = createAxiosInstance({ token });
    const response = await axiosInstance.patch(
        `/matches?id=${id}&reaction=${reaction || 0}&action=reaction`
    );
    return response.data;
}

export async function sendRead(token: string, id: number): Promise<any> {
    const axiosInstance = createAxiosInstance({ token });
    matchesStore.updateUnreadsMatches(matchesStore.matches.unreads.matches - 1);
    const response = await axiosInstance.put(`/matches?id=${id}`);
    return response.data;
}

export async function translateChat(
    token: string,
    id: number,
    state: boolean,
    tr_lang: string
): Promise<any> {
    const axiosInstance = createAxiosInstance({ token });
    matchesStore.setMatches({
        ...matchesStore.matches,
        list_open: matchesStore.matches.list_open.map((matches: Match) => {
            if (matches.id === id) {
                return { ...matches, tr: !state };
            }
            return matches;
        }),
    });
    const response = await axiosInstance.patch(
        `/matches?id=${id}&action=tr&state=${state ? 0 : 1}&tr_lang=${tr_lang}`
    );
    return response.data;
}
export async function reportMessage(
    token: string,
    data: {
        id: number;
        reason: string;
        comment: string;
        nMsgID?: number;
        At?: number;
        media?: string;
    }
): Promise<any> {
    const axiosInstance = createAxiosInstance({ token });
    const response = await axiosInstance.patch('/matches', data);
    return response.data;
}

export async function firstTranslateChatMessage(
    token: string,
    id: number,
    tr_lang: string
): Promise<any> {
    const axiosInstance = createAxiosInstance({ token });
    const response = await axiosInstance.patch(
        `/matches?id=${id}&action=tr_message&to_tr_lang=${tr_lang}`
    );
    return response.data;
}

export async function translateChatMessage(
    token: string,
    id: number,
    visible: boolean
): Promise<any> {
    const axiosInstance = createAxiosInstance({ token });
    const response = await axiosInstance.patch(
        `/matches?action=tr_v2_visible&visible=${visible ? '1' : '0'}&id=${id}`
    );
    return response.data;
}

export async function sensitiveRevealChatMessage(
    token: string,
    id: number
): Promise<any> {
    const axiosInstance = createAxiosInstance({ token });
    const response = await axiosInstance.patch(
        `/matches?action=nudity_reveal&id=${id}`
    );
    return response.data;
}

export async function approveCompliment(
    token: string,
    id: number
): Promise<any> {
    const axiosInstance = createAxiosInstance({ token });
    const response = await axiosInstance.patch(
        `/matches?action=compliment_approve&id=${id}`
    );
    return response.data;
}

export async function removeChat(token: string, id: number): Promise<any> {
    const axiosInstance = createAxiosInstance({ token });
    const response = await axiosInstance.patch(
        `/matches?action=remove_from_closed&id=${id}`
    );
    return response.data;
}
