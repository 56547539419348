import { useScreenDetector } from '@/hooks/useScreenDetector';
import { scaleFont } from '@/utils/scaleSizeAndFont';
import { ReactNode, memo } from 'react';
import { twMerge } from 'tailwind-merge';
import BackButton from '../auth/BackButton';

interface CheckboxProps {
    goBack: () => void;
    title: string;
    close?: boolean;
    withoutButton?: boolean;
    className?: string;
    icon?: ReactNode;
}

const PageHeader: React.FC<CheckboxProps> = ({
    goBack,
    title,
    close = false,
    withoutButton = false,
    className,
    icon,
}: CheckboxProps) => {
    const { isMobile } = useScreenDetector();
    return (
        <div
            className={twMerge(
                'relative z-10 flex min-h-12 items-center gap-6 bg-white px-4 py-2 shadow-md sm:min-h-[68px] sm:py-4',
                className
            )}
        >
            {!withoutButton && (
                <BackButton
                    className='absolute'
                    close={close}
                    arrow={!close}
                    onGoBack={goBack}
                />
            )}
            <h2
                className='my-auto w-full text-center font-semibold'
                style={{ fontSize: `${isMobile ? scaleFont(16) : 24}px` }}
            >
                {title}
            </h2>
            {icon && <div className='absolute right-4'>{icon}</div>}
        </div>
    );
};

export default memo(PageHeader);
