import { useScreenDetector } from '@/hooks/useScreenDetector';
import { scaleFont, scaleSize } from '@/utils/scaleSizeAndFont';
import React from 'react';

interface StepHeaderProps {
    titleAction?: string;
    title: string;
    description: string;
    barProgress: number;
}

const StepHeader: React.FC<StepHeaderProps> = ({
    titleAction,
    title,
    description,
    barProgress,
}) => {
    const { isMobile } = useScreenDetector();
    return (
        <>
            <div className='-mb-[5px] h-[5px] w-full bg-white opacity-50' />
            <div className={`h-[5px] w-[${barProgress}%] bg-white`} />
            <div className='px-4'>
                <div
                    className='font-extrabold'
                    style={{
                        marginTop: `${scaleSize(40)}px`,
                        fontSize: `${isMobile ? scaleFont(22) : 36}px`,
                    }}
                >
                    <p>{titleAction}</p>
                    <h1
                        style={{
                            fontSize: `${isMobile ? scaleFont(22) : 36}px`,
                        }}
                    >
                        {title}
                    </h1>
                </div>

                <h2
                    style={{
                        marginTop: `${scaleSize(12)}px`,
                        fontSize: `${scaleFont(17)}px`,
                        lineHeight: `${scaleFont(22)}px`,
                    }}
                >
                    {description}
                </h2>
            </div>
        </>
    );
};

export default StepHeader;
