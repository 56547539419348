import Sport from '@/assets/icons/sport.svg?react';
import { UserExercise } from '@/domain';
import { getEnumOptions } from '@/utils/getEnumOptions';
import React from 'react';
import SingleSelectStep from '../../common/SingleSelectStep';
import UserDataFlowLayout from '../../layouts/UserDataFlowLayout';
import { UserInfoStepProps } from './userInfoConfig';

const SportStep: React.FC<UserInfoStepProps> = ({ onNext }) => {
    return (
        <UserDataFlowLayout withBarProgress>
            <div className='-mb-[5px] h-[5px] w-full bg-white/50'>
                <div className='h-[5px] w-[40%] bg-white' />
            </div>
            <SingleSelectStep
                LogoComponent={Sport}
                title='Do you do sports?'
                options={getEnumOptions(UserExercise)}
                withSkip
                onNext={onNext}
                field='exercise'
                fromEdit={false}
            />
        </UserDataFlowLayout>
    );
};

export default SportStep;
