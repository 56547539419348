import { useEffect } from 'react';

import { eventHandlers } from '@/workers/handlers';
import {
    addMessageListener,
    initializeWorker,
    removeMessageListener,
} from '@/workers/sharedWorkerInstance';

type UseWorker = {
    skipInit?: boolean;
};

export function useWorker({ skipInit = false }: UseWorker) {
    useEffect(() => {
        if (skipInit) {
            return;
        }

        initializeWorker();

        const handleMessage = (event: MessageEvent) => {
            eventHandlers.forEach((handler) => handler(event));
        };
        addMessageListener(handleMessage);

        return () => {
            removeMessageListener(handleMessage);
        };
    }, [skipInit]);
}
