export enum SupportedLang { //DO NOT DELETE OR UPDATE, ONLY APPEND
  GERMAN,
  HINDI,
  FILIPINO,
  LITHUANIAN,
  CROATIAN,
  LATVIAN,
  HUNGARIAN,
  CHINESE_HANS,
  UKRAINIAN,
  INDONESIAN,
  URDU,
  MALAYALAM,
  MARATHI,
  MALAY,
  GREEK,
  ENGLISH,
  ICELANDIC,
  ITALIAN,
  AMHARIC,
  HEBREW,
  SPANISH,
  ESTONIAN,
  BASQUE,
  ARABIC,
  PORTUGUESE_PORTUGAL,
  VIETNAMESE,
  JAPANESE,
  ROMANIAN,
  DUTCH,
  NORWEGIAN,
  FINNISH,
  RUSSIAN,
  BULGARIAN,
  BENGALI,
  FRENCH,
  SLOVAK,
  SLOVENIAN,
  CATALAN,
  SERBIAN,
  KANNADA,
  SWEDISH,
  KOREAN,
  SWAHILI,
  CHINESE_TAIWAN,
  PORTUGUESE_BRAZIL,
  TAMIL,
  GUJARATI,
  CZECH,
  TELUGU,
  THAI,
  WELSH,
  POLISH,
  DANISH,
  TURKISH,
  LUXEMBOURGISH,
  PERSIAN_FARSI,
}

export const SupportedLanguagesBegin = SupportedLang.GERMAN;
export const SupportedLanguagesEnd = SupportedLang.PERSIAN_FARSI;
