export enum Interests { //DO NOT DELETE OR UPDATE, ONLY APPEND
  //Hobbies
  Hobbies_Poetry,
  Hobbies_Prose,
  Hobbies_MakeUp,
  Hobbies_Blogging,
  Hobbies_Shooting_TikTok,
  Hobbies_Drawing,
  Hobbies_Music,
  Hobbies_Photography,
  Hobbies_Design,
  Hobbies_Tattoos,
  Hobbies_Reading,
  Hobbies_TV,
  Hobbies_YouTube,
  Hobbies_Cooking,
  Hobbies_Gardening,
  Hobbies_BoardGames,
  Hobbies_MindGames,
  Hobbies_Astronomy,
  Hobbies_Cars,
  Hobbies_VideoGames,
  Hobbies_HealthyLifestyle,
  Hobbies_Fishing,
  Hobbies_Shopping,
  //Sport
  Sport_Climbing,
  Sport_Badminton,
  Sport_Basketball,
  Sport_Bodybuilding,
  Sport_Running,
  Sport_Baseball,
  Sport_Boxing,
  Sport_Wrestling,
  Sport_Cycling,
  Sport_WaterPolo,
  Sport_Volleyball,
  Sport_Handball,
  Sport_Gymnastics,
  Sport_Rowing,
  Sport_Judo,
  Sport_Athletics,
  Sport_Meditation,
  Sport_Pilates,
  Sport_Swimming,
  Sport_ShootingSports,
  Sport_Dancing,
  Sport_Tennis,
  Sport_TableTennis,
  Sport_Triathlon,
  Sport_Weightlifting,
  Sport_Fencing,
  Sport_Football,
  Sport_Hockey,
  Sport_eSports,
  Sport_Formula1,
  //Music
  Music_Folk,
  Music_Country,
  Music_LatinAmerican,
  Music_Blues,
  Music_RnB,
  Music_Jazz,
  Music_Chanson,
  Music_Romance,
  Music_ArtSong,
  Music_Electronic,
  Music_Rock,
  Music_HipHop,
  Music_Reggae,
  Music_Funk,
  Music_NewWave,
  Music_Soul,
  Music_Disco,
  Music_Pop,
  //Pets
  Pets_Cats,
  Pets_Dogs,
  Pets_Birds,
  Pets_Fish,
  Pets_Rabbits,
  Pets_Snakes,
  Pets_Turtles,
  Pets_Hamsters,
  //Going out
  GoingOut_Cafes,
  GoingOut_Restaurants,
  GoingOut_Karaoke,
  GoingOut_Theatres,
  GoingOut_Bars,
  GoingOut_Nightclubs,
  GoingOut_Museums,
  GoingOut_Galleries,
  GoingOut_Concerts,
  GoingOut_HookahBars,
  //Food and drink
  FoodAndDrink_Coffee,
  FoodAndDrink_Tea,
  FoodAndDrink_Beer,
  FoodAndDrink_Wine,
  FoodAndDrink_Whiskey,
  FoodAndDrink_Cocktails,
  FoodAndDrink_Pizza,
  FoodAndDrink_Sushi,
  FoodAndDrink_FastFood,
  FoodAndDrink_Grill,
  FoodAndDrink_HomeFood,
  FoodAndDrink_Sweets,
  FoodAndDrink_Vegetarian,
  FoodAndDrink_Vegan,
  //Travels
  Travels_Seas,
  Travels_Mountains,
  Travels_Nature,
  Travels_Attractions,
  Travels_Cities,
  Travels_Hiking,
  Travels_Camping,
  Travels_Abroad,
  Travels_Domestic,
  //Other
  Other_HarryPotter,
  Other_WorkoutAtHome,
  Other_Instagram,
  Other_Sneakers,
  Other_LearningLanguages,
  Other_Gym,
  Other_CoffeeMan,
  Other_TeeMan,
  Other_Cakes,
  Other_Freelancing,
  Other_TrySomethingNew,
  Other_HumanRights,
  Other_IceCream,
  Other_DeliciousFood,
  Other_CS_GO,
  Other_HealthyLifestyle,
  Other_ProperNutrition,
  Other_ContentCreation,
  Other_NatureProtection,
  Other_GirlsUnder25,
  Other_DoingNothing,
  Other_Sleep,
  Other_Disney,
  Other_Marvel,
  Other_DC,
  Other_Shopaholic,
  Other_Networking,
  Other_PlayStation,
  Other_XBox,
  Other_WalkingInTheRain,
  Other_Happiness,
  Other_TVShows,
  Other_Entrepreneurship,
  Other_Cryptocurrency,
  Other_Astronomy,
  Other_EcoActivism,
  Other_LGBTQ,
  Other_BLM,
  Other_Dragons,
  Other_WorldPeace,
  Other_Twitter,
  Other_NFT,
  Other_Poker,
  Other_Startups,
  Other_Procrastination,
  Other_BeachVolleyball,
  Other_DontLikeCold,
  // Appended
  Sport_Joga,
  Sport_Surfing,
}

export const InterestsEnd = Interests.Sport_Surfing;

export const InterestsCategories = {
  hobbies: [
    Interests.Hobbies_Poetry,
    Interests.Hobbies_Prose,
    Interests.Hobbies_MakeUp,
    Interests.Hobbies_Blogging,
    Interests.Hobbies_Shooting_TikTok,
    Interests.Hobbies_Drawing,
    Interests.Hobbies_Music,
    Interests.Hobbies_Photography,
    Interests.Hobbies_Design,
    Interests.Hobbies_Tattoos,
    Interests.Hobbies_Reading,
    Interests.Hobbies_TV,
    Interests.Hobbies_YouTube,
    Interests.Hobbies_Cooking,
    Interests.Hobbies_Gardening,
    Interests.Hobbies_BoardGames,
    Interests.Hobbies_MindGames,
    Interests.Hobbies_Astronomy,
    Interests.Hobbies_Cars,
    Interests.Hobbies_VideoGames,
    Interests.Hobbies_HealthyLifestyle,
    Interests.Hobbies_Fishing,
    Interests.Hobbies_Shopping,
  ],
  sport: [
    Interests.Sport_Climbing,
    Interests.Sport_Badminton,
    Interests.Sport_Basketball,
    Interests.Sport_Bodybuilding,
    Interests.Sport_Running,
    Interests.Sport_Baseball,
    Interests.Sport_Boxing,
    Interests.Sport_Wrestling,
    Interests.Sport_Cycling,
    Interests.Sport_WaterPolo,
    Interests.Sport_Volleyball,
    Interests.Sport_Handball,
    Interests.Sport_Gymnastics,
    Interests.Sport_Rowing,
    Interests.Sport_Judo,
    Interests.Sport_Joga,
    Interests.Sport_Athletics,
    Interests.Sport_Meditation,
    Interests.Sport_Pilates,
    Interests.Sport_Swimming,
    Interests.Sport_Surfing,
    Interests.Sport_ShootingSports,
    Interests.Sport_Dancing,
    Interests.Sport_Tennis,
    Interests.Sport_TableTennis,
    Interests.Sport_Triathlon,
    Interests.Sport_Weightlifting,
    Interests.Sport_Fencing,
    Interests.Sport_Football,
    Interests.Sport_Hockey,
    Interests.Sport_eSports,
    Interests.Sport_Formula1,
  ],
  music: [
    Interests.Music_Folk,
    Interests.Music_Country,
    Interests.Music_LatinAmerican,
    Interests.Music_Blues,
    Interests.Music_RnB,
    Interests.Music_Jazz,
    Interests.Music_Chanson,
    Interests.Music_Romance,
    Interests.Music_ArtSong,
    Interests.Music_Electronic,
    Interests.Music_Rock,
    Interests.Music_HipHop,
    Interests.Music_Reggae,
    Interests.Music_Funk,
    Interests.Music_NewWave,
    Interests.Music_Soul,
    Interests.Music_Disco,
    Interests.Music_Pop,
  ],
  pets: [
    Interests.Pets_Cats,
    Interests.Pets_Dogs,
    Interests.Pets_Birds,
    Interests.Pets_Fish,
    Interests.Pets_Rabbits,
    Interests.Pets_Snakes,
    Interests.Pets_Turtles,
    Interests.Pets_Hamsters,
  ],
  goingOut: [
    Interests.GoingOut_Cafes,
    Interests.GoingOut_Restaurants,
    Interests.GoingOut_Karaoke,
    Interests.GoingOut_Theatres,
    Interests.GoingOut_Bars,
    Interests.GoingOut_Nightclubs,
    Interests.GoingOut_Museums,
    Interests.GoingOut_Galleries,
    Interests.GoingOut_Concerts,
    Interests.GoingOut_HookahBars,
  ],
  foodAndDrink: [
    Interests.FoodAndDrink_Coffee,
    Interests.FoodAndDrink_Tea,
    Interests.FoodAndDrink_Beer,
    Interests.FoodAndDrink_Wine,
    Interests.FoodAndDrink_Whiskey,
    Interests.FoodAndDrink_Cocktails,
    Interests.FoodAndDrink_Pizza,
    Interests.FoodAndDrink_Sushi,
    Interests.FoodAndDrink_FastFood,
    Interests.FoodAndDrink_Grill,
    Interests.FoodAndDrink_HomeFood,
    Interests.FoodAndDrink_Sweets,
    Interests.FoodAndDrink_Vegetarian,
    Interests.FoodAndDrink_Vegan,
  ],
  travels: [
    Interests.Travels_Seas,
    Interests.Travels_Mountains,
    Interests.Travels_Nature,
    Interests.Travels_Attractions,
    Interests.Travels_Cities,
    Interests.Travels_Hiking,
    Interests.Travels_Camping,
    Interests.Travels_Abroad,
    Interests.Travels_Domestic,
  ],
  other: [
    Interests.Other_HarryPotter,
    Interests.Other_WorkoutAtHome,
    Interests.Other_Instagram,
    Interests.Other_Sneakers,
    Interests.Other_LearningLanguages,
    Interests.Other_Gym,
    Interests.Other_CoffeeMan,
    Interests.Other_TeeMan,
    Interests.Other_Cakes,
    Interests.Other_Freelancing,
    Interests.Other_TrySomethingNew,
    Interests.Other_HumanRights,
    Interests.Other_IceCream,
    Interests.Other_DeliciousFood,
    Interests.Other_CS_GO,
    Interests.Other_HealthyLifestyle,
    Interests.Other_ProperNutrition,
    Interests.Other_ContentCreation,
    Interests.Other_NatureProtection,
    Interests.Other_DoingNothing,
    Interests.Other_Sleep,
    Interests.Other_Disney,
    Interests.Other_Marvel,
    Interests.Other_DC,
    Interests.Other_Shopaholic,
    Interests.Other_Networking,
    Interests.Other_PlayStation,
    Interests.Other_XBox,
    Interests.Other_WalkingInTheRain,
    Interests.Other_Happiness,
    Interests.Other_TVShows,
    Interests.Other_Entrepreneurship,
    Interests.Other_Cryptocurrency,
    Interests.Other_Astronomy,
    Interests.Other_EcoActivism,
    Interests.Other_LGBTQ,
    Interests.Other_BLM,
    Interests.Other_Dragons,
    Interests.Other_WorldPeace,
    Interests.Other_Twitter,
    Interests.Other_NFT,
    Interests.Other_Poker,
    Interests.Other_Startups,
    Interests.Other_Procrastination,
    Interests.Other_BeachVolleyball,
    Interests.Other_DontLikeCold,
  ],
};
