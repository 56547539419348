import { Match } from '@/domain';
import matchesStore from '@/entities/match/store';
import { WorkerActions } from '../workerActions';

const handledActions = [
    WorkerActions.SocketMessage,
    WorkerActions.UpdateChat,
    WorkerActions.GetData,
    WorkerActions.UpdateChatsList,
    WorkerActions.UpdateMatches,
];

enum SocketMessageTypes {
    MatchMessage = 'match.message',
    Interaction = 'match.interaction',
}

export const handleMatchesEvent = (event: MessageEvent) => {
    const { action, data, dataKey } = event.data;

    if (!handledActions.includes(action)) return;

    if (action === WorkerActions.SocketMessage) {
        switch (data.res) {
            case SocketMessageTypes.Interaction:
                if (data.action === 'started') {
                    matchesStore.setIsUserInteracting(
                        true,
                        data.model.match_id
                    );
                } else if (data.action === 'stopped') {
                    matchesStore.setIsUserInteracting(
                        false,
                        data.model.match_id
                    );
                }
                break;
            case SocketMessageTypes.MatchMessage:
                if (
                    !matchesStore.newUserMessage &&
                    data.action === 'received'
                ) {
                    matchesStore.setNewUserMessage(data.model.sender_user_id);
                    setTimeout(() => matchesStore.setNewUserMessage(), 3000);
                }
                break;
            case 'match':
                if (
                    data.action === 'received' &&
                    data.model.event_type === 'new_match' &&
                    !matchesStore.newBumpUser
                ) {
                    matchesStore.setNewBumpUser(data.model.user);
                    setTimeout(() => matchesStore.setNewBumpUser(), 3000);
                } else if (data.action === 'deleted') {
                    const match = matchesStore.matches.list_new.find(
                        (match: Match) => match.id === data.model.match_id
                    );
                    if (match) {
                        matchesStore.setMatches({
                            ...matchesStore.matches,
                            list_new: matchesStore.matches.list_new.filter(
                                (m: Match) => m.id !== match.id
                            ),
                            list_open: [
                                match,
                                ...matchesStore.matches.list_open,
                            ],
                        });
                    }
                }
                break;
            case 'visits':
                if (
                    data.action === 'created' &&
                    (data.model.event_type === 'new_visit.invisible' ||
                        data.model.event_type === 'new_visit.visible')
                ) {
                    if (!matchesStore.newViewedUser) {
                        matchesStore.setNewViewedUser({
                            list: data.model.visit,
                            user: data.model.visitor || data.model.user,
                        });
                        setTimeout(() => matchesStore.setNewViewedUser(), 3000);
                    }
                    matchesStore.setViewedUsers({
                        list: [
                            data.model.visit,
                            ...matchesStore.liked_and_viewed.viewed.list,
                        ],
                        users: [
                            data.model.visitor || data.model.user,
                            ...matchesStore.liked_and_viewed.viewed.users,
                        ],
                        totalCount:
                            matchesStore.liked_and_viewed.viewed.users.length +
                            1,
                    });
                }
                break;
            case 'reaction':
                if (
                    data.action === 'received' &&
                    (data.model.event_type === 'new_reaction.hello.invisible' ||
                        data.model.event_type ===
                            'new_reaction.hello.visible' ||
                        data.model.event_type === 'new_reaction.like')
                ) {
                    if (!matchesStore.newLikedUser) {
                        matchesStore.setNewLikedUser({
                            list: data.model.reaction,
                            user: data.model.user,
                        });
                        setTimeout(() => matchesStore.setNewLikedUser(), 3000);
                    }
                    matchesStore.setLikedUsers({
                        list: [
                            data.model.reaction,
                            ...matchesStore.liked_and_viewed.liked.list,
                        ],
                        users: [
                            data.model.user,
                            ...matchesStore.liked_and_viewed.liked.users,
                        ],
                        total_count:
                            matchesStore.liked_and_viewed.liked.totalCount,
                    });
                }
                break;
            case 'reaction.total_count':
                matchesStore.setLikedUsers({
                    list: [...matchesStore.liked_and_viewed.liked.list],
                    users: [...matchesStore.liked_and_viewed.liked.users],
                    total_count: data.model.value,
                });
                break;
            case 'me.subscription.super_smashes':
                if (data.action === 'bonus') {
                    matchesStore.setBonusSuperSmash(data.model.super_smashes);
                    setTimeout(() => matchesStore.setBonusSuperSmash(), 3000);
                }
                break;
        }
    } else if (action === WorkerActions.UpdateChat) {
        matchesStore.setNewChat(data);
    } else if (action === WorkerActions.UpdateMatches) {
        matchesStore.setMatches({
            ...matchesStore.matches,
            list_new: [data.match, ...matchesStore.matches.list_new],
            users: [data.user, ...matchesStore.matches.users],
        });
    } else if (action === WorkerActions.GetData) {
        switch (dataKey) {
            case 'matches':
                matchesStore.setMatches(data);
                break;
        }
    } else if (action === WorkerActions.UpdateChatsList) {
        matchesStore.setMatches(data);
    }
};
