import { scaleSize } from '@/utils/scaleSizeAndFont';
import React, { ComponentProps } from 'react';
import { twMerge } from 'tailwind-merge';

interface ButtonProps extends ComponentProps<'button'> {
    variant?: 'primary' | 'secondary' | 'cancel';
    width?: string;
    height?: string;
    label?: string;
}

const Button: React.FC<ButtonProps> = ({
    variant = 'default',
    width = 'full',
    height = scaleSize(48),
    className,
    label,
    ...rest
}) => {
    let baseClasses = '';
    switch (variant) {
        case 'primary':
            baseClasses = `py-2 rounded-lg bg-primary font-bold text-white h-${height} w-${width}`;
            break;
        case 'secondary':
            baseClasses =
                'block h-[55px] w-[300px] rounded-xl border border-grayIcon bg-white px-4 py-2 font-bold text-black text-lg md:w-[530px]';
            break;
        case 'cancel':
            baseClasses = `text-navDarkGray rounded-lg bg-gray-300 p-1 font-semibold w-${width}`;
            break;
        default:
            baseClasses = 'mx-auto w-fit rounded-md px-4 py-2 font-bold';
    }

    return (
        <button className={twMerge(baseClasses, className)} {...rest}>
            {label}
            {rest.children}
        </button>
    );
};

export default Button;
