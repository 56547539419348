import Chat from '@/assets/icons/chat-nav.svg?react';
import LikedYou from '@/assets/icons/liked-you-nav.svg?react';
import People from '@/assets/icons/people-nav.svg?react';
import Profile from '@/assets/icons/profile.svg?react';
import { Match } from '@/domain';
import matchesStore from '@/entities/match/store';
import userStore from '@/entities/user/store';
import { useScreenDetector } from '@/hooks/useScreenDetector';
import { LOCAL_STORAGE } from '@/utils/localStorage';
import { AppRoutes } from '@/utils/routes';
import { scaleFont, scaleSize } from '@/utils/scaleSizeAndFont';
import { Observer, observer } from 'mobx-react-lite';
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

const NAVIGATION_TABS = [
    {
        icon: Profile,
        path: AppRoutes.Home,
        label: 'Profile',
    },
    {
        icon: People,
        path: AppRoutes.People,
        label: 'People',
    },
    {
        icon: LikedYou,
        path: AppRoutes.LikedPeople,
        label: 'Liked You',
    },
    {
        icon: Chat,
        path: AppRoutes.Chats,
        label: 'Chats',
    },
];

const Navigation: React.FC = observer(() => {
    const location = useLocation();
    const currentPath = location.pathname;

    const isCurrentPathValid = NAVIGATION_TABS.some(
        (tab) => tab.path === currentPath
    );
    const { isMobile } = useScreenDetector();
    const render = () => (
        <div className='flex w-full items-center justify-around'>
            {NAVIGATION_TABS.map((tab, index) => {
                let unreads: boolean;
                unreads = false;
                if (tab.label === 'Liked You') {
                    unreads = !(
                        userStore.home?.unreads?.visits === 0 &&
                        userStore.home?.unreads?.reactions === 0
                    );
                } else if (tab.label === 'Chats') {
                    unreads = !(
                        matchesStore.matches?.list_new?.every(
                            (match: Match) => match?.is_read
                        ) &&
                        matchesStore.matches?.list_open?.every(
                            (match: Match) => match?.is_read
                        )
                    );
                }
                return (
                    <div key={index} className='relative'>
                        {unreads && (
                            <div
                                className='-right-1 absolute z-10 rounded-full bg-red-400'
                                style={{
                                    width: `${scaleSize(10)}px`,
                                    height: `${scaleSize(10)}px`,
                                }}
                            />
                        )}
                        <NavLink
                            onClick={() => {
                                if (index === 0) {
                                    localStorage.setItem(
                                        LOCAL_STORAGE.Page,
                                        'block'
                                    );
                                } else {
                                    localStorage.removeItem(LOCAL_STORAGE.Page);
                                }
                            }}
                            to={tab.path}
                            className={({ isActive }) =>
                                twMerge(
                                    'flex h-14 flex-col items-center justify-center text-black opacity-30 hover:text-black hover:no-underline hover:opacity-80',
                                    (isActive ||
                                        (!isCurrentPathValid && index === 0)) &&
                                        'opacity-100'
                                )
                            }
                        >
                            <tab.icon
                                fill='#3F3F3F'
                                style={{
                                    height: `${isMobile ? scaleSize(28) : 44}px`,
                                    width: `${isMobile ? scaleSize(28) : 48}px`,
                                }}
                            />
                            <span
                                style={{
                                    fontSize: `${isMobile ? scaleFont(10) : 12}px`,
                                    lineHeight: `${isMobile ? scaleFont(16) : 22}px`,
                                }}
                            >
                                {tab.label}
                            </span>
                        </NavLink>
                    </div>
                );
            })}
        </div>
    );
    return (
        <nav className='fixed bottom-0 flex h-[60px] w-full max-w-[726px] items-center justify-between bg-white p-2 align-middle text-lg shadow-md sm:h-[72px] sm:text-2xl'>
            <div className='flex w-full items-center justify-around space-x-12'>
                <Observer>{render}</Observer>
            </div>
        </nav>
    );
});

export default Navigation;
