import { ErrorResponse } from '@/domain';
import { AppRoutes } from '@/utils/routes';
import axios, {
    AxiosInstance,
    AxiosResponse,
    AxiosError,
    InternalAxiosRequestConfig,
} from 'axios';

export const BASE_API_URL =
    import.meta.env.VITE_API_URL || 'https://api.bumpy.app';

console.log('BASE_API_URL:', BASE_API_URL);

const createAxiosInstance = ({
    token,
    customUrl,
    navigate,
    onNext,
}: {
    token?: string;
    customUrl?: string;
    navigate?: (path: string) => void;
    onNext?: () => void;
}): AxiosInstance => {
    const axiosInstance: AxiosInstance = axios.create({
        baseURL: customUrl || BASE_API_URL,
        headers: {
            'X-App-Version': '2.5.0', // let's keep it hardcoded for now
        },
    });

    axiosInstance.interceptors.request.use(
        (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
            if (token) {
                config.headers.Authorization = token;
            }
            return config;
        },
        (error: AxiosError): Promise<AxiosError> => {
            return Promise.reject(error);
        }
    );

    axiosInstance.interceptors.response.use(
        (response: AxiosResponse): AxiosResponse => response,
        (error: AxiosError): Promise<AxiosError> => {
            if (typeof window === 'undefined') {
                return Promise.reject(error);
            }

            if (error.response && error.response.status === 409) {
                const data: ErrorResponse = error.response
                    .data as ErrorResponse;
                switch (data.m) {
                    case 'e_user_not_registered':
                        if (onNext) {
                            onNext();
                        } else if (navigate) {
                            navigate(AppRoutes.UserInfo);
                        } else {
                            window.location.href = AppRoutes.UserInfo;
                        }
                        break;
                    case 'e_vpn':
                        window.location.href = AppRoutes.ErrorVPN;
                        break;
                    case 'e_banned':
                        window.location.href = `${AppRoutes.ErrorBanned}?banId=${data.info.ban.id}&userId=${data.info.ban.user_id}&until=${data.info.ban.until}`;
                        break;
                }
                console.error(error.response);
            } else if (error.response && error.response.status === 401) {
                window.location.href = AppRoutes.Login;
            }
            return Promise.reject(error);
        }
    );

    return axiosInstance;
};

export default createAxiosInstance;
