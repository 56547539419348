//takes from here
//https://stackoverflow.com/questions/14672746/how-to-compress-an-image-via-javascript-in-the-browser

export const maxSizePx = 1000;

export function downscaleImage(
    dataUrl: string,
    callback: BlobCallback,
    customMaxSize?: number
): void {
    const image = new Image();
    image.src = dataUrl;
    image.onload = () => {
        const maxSize = customMaxSize || maxSizePx;
        const oldWidth = image.width;
        const oldHeight = image.height;
        const newHeight = Math.floor((oldHeight / oldWidth) * maxSize);

        const canvas = document.createElement('canvas');
        canvas.width = maxSize;
        canvas.height = newHeight;

        console.log('[downscale]', {
            oldWidth,
            oldHeight,
            maxSize,
            newHeight,
        });

        const ctx = canvas.getContext('2d');
        ctx!.drawImage(image, 0, 0, maxSize, newHeight);
        canvas.toBlob(callback, 'image/jpeg', 0.75);
    };

    image.onerror = () => {
        alert('Image load error, please try someone else.');
        callback(null);
    };
}
