export enum Notifications { //DO NOT DELETE OR UPDATE, ONLY APPEND
  Message_Remote = 1 << 0,
  Message_Local = 1 << 1,
  Hello_Remote = 1 << 2,
  Hello_Local = 1 << 3,
  Like_Remote = 1 << 4,
  Like_Local = 1 << 5,
  Visit_Remote = 1 << 6,
  Visit_Local = 1 << 7,
  Match_Remote = 1 << 8,
  Match_Local = 1 << 9,
}
