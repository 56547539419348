import MultiSelect from '@/components/common/MultiSelect';
import { Interests, InterestsCategories } from '@/domain';
import React, { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

const SELECTED_INTERESTS_LIMIT = 15;

const interestsData = [
    { title: 'Hobbies', items: InterestsCategories.hobbies },
    { title: 'Sport', items: InterestsCategories.sport },
    { title: 'Music', items: InterestsCategories.music },
    { title: 'Pets', items: InterestsCategories.pets },
    { title: 'Going Out', items: InterestsCategories.goingOut },
    { title: 'Food and Drink', items: InterestsCategories.foodAndDrink },
    { title: 'Travels', items: InterestsCategories.travels },
    {
        title: 'Others',
        items: InterestsCategories.other.filter((interest) => interest !== 131),
    },
];

const arraysAreEqual = (arr1: Interests[], arr2: Interests[]) => {
    if (arr1.length !== arr2.length) return false;
    const sortedArr1 = [...arr1].sort();
    const sortedArr2 = [...arr2].sort();
    return sortedArr1.every((value, index) => value === sortedArr2[index]);
};

const InterestsMultiSelect: React.FC<{
    handleAction: (interests: Interests[]) => void;
    isUserDataFlow?: boolean;
    preselectedItems?: Interests[];
}> = ({ handleAction, isUserDataFlow = false, preselectedItems }) => {
    const [selectedInterests, setSelectedInterests] = useState<Interests[]>(
        preselectedItems || []
    );
    const counter = selectedInterests.length;
    const isDirty = !arraysAreEqual(selectedInterests, preselectedItems || []);
    useEffect(
        () => setSelectedInterests(preselectedItems || []),
        [preselectedItems]
    );

    return (
        <div className='mb-10'>
            {interestsData.map(({ title, items }) => (
                <div key={title} className={isUserDataFlow ? '' : 'mb-4'}>
                    <MultiSelect
                        title={title}
                        items={items}
                        selectedItems={selectedInterests}
                        setSelectedItems={setSelectedInterests}
                        counter={counter}
                        limit={15}
                        isUserDataFlow={isUserDataFlow}
                    />
                </div>
            ))}
            {isUserDataFlow ? (
                <div className='absolute right-6 bottom-6 z-10 flex justify-end rounded-xl'>
                    <button
                        onClick={() => {
                            handleAction(selectedInterests);
                        }}
                        className={twMerge(
                            'h-11 w-40 rounded-xl bg-white p-2.5 text-center font-bold text-black disabled:cursor-default disabled:text-black/50',
                            !isUserDataFlow && 'bg-primary text-white'
                        )}
                        disabled={!isDirty}
                    >
                        {`Save ${counter}/${SELECTED_INTERESTS_LIMIT}`}
                    </button>
                </div>
            ) : (
                <>
                    <div className='absolute bottom-4 left-0 z-10 flex w-full justify-center rounded-xl bg-gradient-to-b from-transparent to-white px-4'>
                        <button
                            disabled={!isDirty}
                            className='w-full max-w-[350px] rounded-lg bg-primary py-2 text-center font-bold text-white disabled:bg-inactiveBtn'
                            onClick={() => {
                                handleAction(selectedInterests);
                            }}
                        >
                            {`Save ${counter}/${SELECTED_INTERESTS_LIMIT}`}
                        </button>
                    </div>
                    <div className='absolute bottom-0 left-0 h-4 w-full bg-white' />
                </>
            )}
        </div>
    );
};

export default InterestsMultiSelect;
