import React, { ReactNode } from 'react';

interface RulesModalProps {
    show: boolean;
    onClose: () => void;
    children: ReactNode;
}

const RulesModal: React.FC<RulesModalProps> = ({ show, onClose }) => {
    if (!show) return null;

    return (
        <div className='fixed inset-0 z-50 flex overflow-auto bg-gray-800 bg-opacity-50'>
            <div className='relative m-auto flex w-96 max-w-md flex-col rounded-xl bg-white p-8'>
                <div className='mb-4 flex items-start justify-between'>
                    <h2 className='font-bold text-2xl text-black '>Rules</h2>
                    <button
                        className='text-gray-500 hover:text-gray-700 focus:outline-none'
                        onClick={onClose}
                    >
                        <svg
                            className='h-6 w-6'
                            fill='none'
                            viewBox='0 0 24 24'
                            stroke='currentColor'
                        >
                            <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                strokeWidth={2}
                                d='M6 18L18 6M6 6l12 12'
                            />
                        </svg>
                    </button>
                </div>
                <div className='text-black'>
                    <div className='flex flex-col gap-2'>
                        <p>
                            Feel comfortable and be able to be themselves. To do
                            this, the app has some restrictions and
                            prohibitions.
                        </p>
                        <p className='text-xs'>Forbidden to add to profile:</p>
                    </div>
                    <ul className='mt-2 list-disc pl-4'>
                        <li>A photo of other people, including celebrities</li>
                        <li>A photo that doesn't show your face</li>
                        <li>A photo of a pornographic nature</li>
                        <li>
                            A photo with text, QR codes, logos, watermarks, and
                            obvious traces of editing
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default RulesModal;
