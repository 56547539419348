import {
    approveCompliment,
    deleteMessages,
    fetchMatches,
    fetchUsers,
    firstTranslateChatMessage,
    removeChat,
    sendReaction,
    sendRead,
    sensitiveRevealChatMessage,
    translateChat,
    translateChatMessage,
} from '@/api/chat';
import {
    cancelBupm,
    cancelBupmReason,
    fetchUser,
    switchPhotos,
    updateUser,
    updateUserProp,
} from '@/api/user';
import { ErrorResponse, Match, MatchMessageFlags, User } from '@/domain';
import { WebMatchMessage } from '@/entities/match';
import matchesStore from '@/entities/match/store';
import { AppRoutes } from '@/utils/routes';
import {
    SharedState,
    SharedStateType,
    findChatById,
    removeMatchById,
    resetSharedState,
} from './sharedState';
import { postMessageToConnections } from './utils';
import { connectWebSocket } from './websocket';

export enum WorkerActions {
    ClearStorage = 'data:clear',
    StoreData = 'data:store',
    GetData = 'data:get',

    Error = 'error',

    Logout = 'logout',
    Redirect = 'redirect',
    Banned = 'banned',

    SendChatMessage = 'chat:send',
    SendEditChatMessage = 'chat:edit-send',
    DeleteChatMessage = 'chat:delete',
    UpdateChat = 'chat:update',
    PaginateChat = 'chat:paginate',
    UpdateChatsList = 'chat:chats-list',
    SendReactionMessage = 'chat:reaction',
    SendRead = 'chat:read',
    TranslateChat = 'chat:translate',
    TranslateMessage = 'chat:message-translate',
    FirstTranslateMessage = 'chat:first-message-translate',
    ApproveCompliment = 'chat:approve-compliment',
    RemoveClosedChat = 'chat:remove-chat',
    SensitiveRevealMessage = 'chat:sensitiveReveal',

    SocketConnect = 'socket:connect',
    SocketMessage = 'socket:message',
    SocketError = 'socket:error',
    SocketPing = 'socket:ping',
    SocketConnected = 'socket:connected',
    SocketClosed = 'socket:closed',
    UpdateUserDataProp = 'user:data-prop',
    UpdateUserData = 'user:data',
    UpdateUserImages = 'user:image',
    UpdateMatches = 'user:matches',
    AddNewChatToMatches = 'user:add-chat-to-matches',
    FetchUsers = 'user:fetch-users',
}

export const handleWorkerAction = async (
    event: MessageEvent<any>,
    port: MessagePort | typeof self,
    connections: MessagePort[] = []
) => {
    const { action, data, dataKey } = event.data;

    switch (action) {
        case WorkerActions.SocketConnect:
            connectWebSocket(
                SharedState.authToken,
                port as MessagePort,
                connections
            );
            break;
        case WorkerActions.ClearStorage:
            resetSharedState();
            sendMessage(port, {
                action: WorkerActions.ClearStorage,
                status: 'success',
            });
            break;
        case WorkerActions.UpdateChat:
            sendMessage(port, {
                action: WorkerActions.UpdateChat,
                data: findChatById(data.chatId),
            });
            break;
        case WorkerActions.UpdateMatches:
            await addNewMatch(data, connections, port as MessagePort);
            break;
        case WorkerActions.AddNewChatToMatches:
            await addNewChatToMatches(data, connections, port as MessagePort);
            break;
        case WorkerActions.PaginateChat:
            await paginateChat(data, port as MessagePort, connections);
            break;
        case WorkerActions.SendChatMessage:
            sendChatMessage(data, connections, port as MessagePort);
            break;
        case WorkerActions.SendEditChatMessage:
            sendEditChatMessage(data, connections, port as MessagePort);
            break;
        case WorkerActions.DeleteChatMessage:
            deleteChatMessage(data, connections, port as MessagePort);
            break;
        case WorkerActions.ApproveCompliment:
            approveUserCompliment(data, connections, port as MessagePort);
            break;
        case WorkerActions.SendReactionMessage:
            await sendReactionMessage(data, connections, port as MessagePort);
            break;
        case WorkerActions.FirstTranslateMessage:
            await firstTranslatelMessage(
                data,
                connections,
                port as MessagePort
            );
            break;
        case WorkerActions.TranslateMessage:
            await translatelMessage(data, connections, port as MessagePort);
            break;
        case WorkerActions.SensitiveRevealMessage:
            await sensitiveRevealMessage(
                data,
                connections,
                port as MessagePort
            );
            break;
        case WorkerActions.SendRead:
            await sendReadMessage(data, connections, port as MessagePort);
            break;
        case WorkerActions.TranslateChat:
            await translateThisChat(data, connections, port as MessagePort);
            break;
        case WorkerActions.StoreData:
            storeData(dataKey, data, port as MessagePort);
            break;
        case WorkerActions.GetData:
            await getData(dataKey, port as MessagePort);
            break;
        case WorkerActions.UpdateUserData:
            await updateUserData(data, connections, port as MessagePort);
            break;
        case WorkerActions.UpdateUserDataProp:
            await updateUserDataProp(data, connections, port as MessagePort);
            break;
        case WorkerActions.UpdateUserImages:
            await updateUserImages(data, connections, port as MessagePort);
            break;
        case WorkerActions.UpdateChatsList:
            await sentReport(data, connections, port as MessagePort);
            break;
        case WorkerActions.RemoveClosedChat:
            await removeClosed(data, connections, port as MessagePort);
            break;
        case WorkerActions.FetchUsers:
            await fetchNewUsers(data, connections, port as MessagePort);
            break;
        default:
            sendMessage(port, {
                action: WorkerActions.Error,
                message: 'Unknown action',
            });
    }
};

const sendMessage = (port: MessagePort | typeof self, message: any) => {
    port.postMessage(message);
};

const paginateChat = async (
    data: { selectedChatId: number },
    port: MessagePort,
    connections: MessagePort[]
) => {
    const currChat = findChatById(data.selectedChatId);
    if (currChat) {
        try {
            const fetchedNewMessages = await fetchMatches(
                SharedState.authToken,
                `${
                    currChat.last_messages[currChat.last_messages.length - 1]
                        .created_at
                }`,
                data.selectedChatId
            );
            if (fetchedNewMessages.result.list.length === 0) {
                currChat.fetched = true;
            } else {
                currChat.last_messages.push(...fetchedNewMessages.result.list);
            }
            if (connections.length > 0) {
                return postMessageToConnections(
                    connections,
                    WorkerActions.UpdateChat,
                    currChat
                );
            }
            port.postMessage({
                action: WorkerActions.UpdateChat,
                data: currChat,
            });
        } catch (error) {
            port.postMessage({
                action: WorkerActions.Error,
                message: `Error fetching chats: ${error}`,
            });
        }
    }
};

const sendChatMessage = (
    data: { selectedChatId: number; message: WebMatchMessage },
    connections: MessagePort[],
    port: MessagePort
) => {
    const chat = findChatById(data.selectedChatId);
    if (chat) {
        const existingMessageIndex = chat.last_messages.findIndex(
            (msg: WebMatchMessage) => {
                return (
                    (msg.content === data.message.content && msg.isLoading) ||
                    msg.id === data.message.id
                );
            }
        );
        if (existingMessageIndex !== -1) {
            chat.last_messages[existingMessageIndex] = data.message;
        } else {
            chat.last_messages.unshift(data.message);
        }
        chat.last_at = Date.now();
        if (connections.length > 0) {
            return postMessageToConnections(
                connections,
                WorkerActions.UpdateChat,
                chat
            );
        }
        port.postMessage({
            action: WorkerActions.UpdateChat,
            data: chat,
        });
    }
};

const approveUserCompliment = (
    data: { selectedChatId: number },
    connections: MessagePort[],
    port: MessagePort
) => {
    approveCompliment(SharedState.authToken, data.selectedChatId);
    const chat = findChatById(data.selectedChatId);
    if (chat.compliment) {
        chat.compliment = false;
        if (connections.length > 0) {
            return postMessageToConnections(
                connections,
                WorkerActions.UpdateChat,
                chat
            );
        }
        port.postMessage({
            action: WorkerActions.UpdateChat,
            data: chat,
        });
    }
};

const sendEditChatMessage = (
    data: { selectedChatId: number; message: WebMatchMessage },
    connections: MessagePort[],
    port: MessagePort
) => {
    const chat = findChatById(data.selectedChatId);

    if (chat) {
        const messageIndex = chat.last_messages.findIndex(
            (msg: WebMatchMessage) => msg.id === data.message.id
        );
        if (messageIndex !== -1) {
            chat.last_messages[messageIndex] = data.message;
        }

        if (connections.length > 0) {
            return postMessageToConnections(
                connections,
                WorkerActions.UpdateChat,
                chat
            );
        }
        port.postMessage({
            action: WorkerActions.UpdateChat,
            data: chat,
        });
    }
};

const deleteChatMessage = (
    data: { selectedChatId: number; id: number },
    connections: MessagePort[],
    port: MessagePort
) => {
    const chat = findChatById(data.selectedChatId);

    deleteMessages(SharedState.authToken, data.id);
    if (chat) {
        chat.last_messages = chat.last_messages.filter(
            (mes: WebMatchMessage) => mes.id !== data.id
        );
        if (chat.last_messages.length === 0) {
            matchesStore.setMatches({
                ...matchesStore.matches,
                list_open: matchesStore.matches.list_open.filter(
                    (match: Match) => {
                        return match.id !== Number(data.selectedChatId);
                    }
                ),
                list_new: [chat, ...matchesStore.matches.list_new],
            });
        }
        if (connections.length > 0) {
            return postMessageToConnections(
                connections,
                WorkerActions.UpdateChat,
                chat
            );
        }
        port.postMessage({
            action: WorkerActions.UpdateChat,
            data: chat,
        });
    }
};

const sendReactionMessage = async (
    data: {
        id: number;
        reaction: number;
        chat_id: number;
    },
    connections: MessagePort[],
    port: MessagePort
) => {
    const { id, reaction, chat_id } = data;
    const chat = findChatById(chat_id);
    const message = chat.last_messages.find(
        (message: WebMatchMessage) => message.id === id
    );
    message.reaction = reaction;

    await sendReaction(SharedState.authToken, id, reaction);

    if (connections.length > 0) {
        return postMessageToConnections(
            connections,
            WorkerActions.UpdateChat,
            chat
        );
    }
    port.postMessage({
        action: WorkerActions.UpdateChat,
        data: chat,
    });
};

const firstTranslatelMessage = async (
    data: {
        id: number;
        chat_id: number;
        tr_lang: string;
    },
    connections: MessagePort[],
    port: MessagePort
) => {
    const { id, chat_id, tr_lang } = data;
    const chat = findChatById(chat_id);
    const message = chat.last_messages.find(
        (message: WebMatchMessage) => message.id === id
    );
    const res = await firstTranslateChatMessage(
        SharedState.authToken,
        id,
        tr_lang
    );
    message.content_tr = res.result.content_tr;
    message.flags += MatchMessageFlags.FLAG_TR_V2_VISIBLE;

    if (connections.length > 0) {
        return postMessageToConnections(
            connections,
            WorkerActions.UpdateChat,
            chat
        );
    }
    port.postMessage({
        action: WorkerActions.UpdateChat,
        data: chat,
    });
};

const translatelMessage = async (
    data: {
        id: number;
        chat_id: number;
        visible: boolean;
    },
    connections: MessagePort[],
    port: MessagePort
) => {
    const { id, chat_id, visible } = data;
    const chat = findChatById(chat_id);
    const message = chat.last_messages.find(
        (message: WebMatchMessage) => message.id === id
    );
    await translateChatMessage(SharedState.authToken, id, visible);
    if (visible) {
        message.flags += MatchMessageFlags.FLAG_TR_V2_VISIBLE;
    } else message.flags -= MatchMessageFlags.FLAG_TR_V2_VISIBLE;

    if (connections.length > 0) {
        return postMessageToConnections(
            connections,
            WorkerActions.UpdateChat,
            chat
        );
    }
    port.postMessage({
        action: WorkerActions.UpdateChat,
        data: chat,
    });
};

const sensitiveRevealMessage = async (
    data: {
        id: number;
        chat_id: number;
    },
    connections: MessagePort[],
    port: MessagePort
) => {
    const { id, chat_id } = data;
    const chat = findChatById(chat_id);
    const message = chat.last_messages.find(
        (message: WebMatchMessage) => message.id === id
    );
    message.flags += MatchMessageFlags.FLAG_SENSITIVE_REVEALED;

    await sensitiveRevealChatMessage(SharedState.authToken, id);

    if (connections.length > 0) {
        return postMessageToConnections(
            connections,
            WorkerActions.UpdateChat,
            chat
        );
    }
    port.postMessage({
        action: WorkerActions.UpdateChat,
        data: chat,
    });
};

const sendReadMessage = async (
    data: { chat_id: number; at: number },
    connections: MessagePort[],
    port: MessagePort
) => {
    const { chat_id, at } = data;
    const chat = findChatById(chat_id);
    await sendRead(SharedState.authToken, chat_id);

    chat.is_read = true;
    chat.last_read_me_at = at;

    if (connections.length > 0) {
        return postMessageToConnections(
            connections,
            WorkerActions.UpdateChat,
            chat
        );
    }
    port.postMessage({
        action: WorkerActions.UpdateChat,
        data: chat,
    });
};

const translateThisChat = async (
    data: { id: number; state: boolean; tr_lang: string },
    connections: MessagePort[],
    port: MessagePort
) => {
    const { id, state, tr_lang } = data;
    const chat = findChatById(id);
    chat.tr = !state;
    await translateChat(SharedState.authToken, id, state, tr_lang);

    if (connections.length > 0) {
        return postMessageToConnections(
            connections,
            WorkerActions.UpdateChat,
            chat
        );
    }
    port.postMessage({
        action: WorkerActions.UpdateChat,
        data: chat,
    });
};

const storeData = (
    dataKey: keyof SharedStateType,
    data: string,
    port: MessagePort
) => {
    if (dataKey && dataKey in SharedState) {
        SharedState[dataKey] = data;
        port.postMessage({
            action: WorkerActions.StoreData,
            status: 'success',
            message: dataKey,
        });
    } else {
        port.postMessage({
            action: WorkerActions.Error,
            message: `Error storing ${dataKey}`,
        });
    }
};

const getData = async (dataKey: keyof SharedStateType, port: MessagePort) => {
    if (!(dataKey in SharedState)) {
        port.postMessage({
            action: WorkerActions.Error,
            message: `Error getting ${dataKey}`,
        });

        return;
    }

    if (SharedState[dataKey]) {
        port.postMessage({
            action: WorkerActions.GetData,
            data: SharedState[dataKey],
            dataKey,
        });
        return;
    }

    try {
        let fetchedData: { result: any; s: string } = {
            result: null,
            s: '',
        };
        if (dataKey === 'matches') {
            fetchedData = await fetchMatches(SharedState.authToken);
        } else if (dataKey === 'userData') {
            const browserLanguage = navigator.language || 'en';
            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            fetchedData = await fetchUser(SharedState.authToken, {
                'D-lang': `${browserLanguage}`,
                'X-Timezone': `${timeZone}`,
            });

            const { matches_new, matches_open, unreads, users } =
                fetchedData.result;
            SharedState.matches = {
                list_new: matches_new,
                list_open: matches_open,
                unreads,
                users,
            };
        }
        SharedState[dataKey] = fetchedData.result;
        port.postMessage({
            action: WorkerActions.GetData,
            data: SharedState[dataKey],
            dataKey,
        });
    } catch (error: any) {
        if (error.response?.status === 401) {
            port.postMessage({ action: WorkerActions.Logout });
        } else if (error.response?.data) {
            const data: ErrorResponse = error.response.data as ErrorResponse;
            switch (data.m) {
                case 'e_vpn':
                    port.postMessage({
                        action: WorkerActions.Redirect,
                        url: AppRoutes.ErrorVPN,
                    });
                    break;
            }
        } else {
            port.postMessage({
                action: WorkerActions.Error,
                message: `Error fetching ${dataKey}: ${error}`,
            });
        }
    }
};

const updateUserDataProp = async (
    data: {
        propName: string;
        newValue: any;
    },
    connections: MessagePort[],
    port: MessagePort
) => {
    const { propName, newValue } = data;
    const response = await updateUserProp(
        SharedState.authToken,
        propName,
        newValue
    );

    if (response.result.me && SharedState.userData) {
        SharedState.userData.me = response.result.me;
    }

    if (connections.length > 0) {
        return postMessageToConnections(
            connections,
            WorkerActions.GetData,
            SharedState.userData,
            'userData'
        );
    }
    port.postMessage({
        action: WorkerActions.GetData,
        data: SharedState.userData,
        dataKey: 'userData',
    });
};

const updateUserData = async (
    data: {
        tr_matches: boolean;
        tr_lang: string;
        school: string;
        school_info: string;
        work: string;
        work_info: string;
        dob: string;
    },
    connections: MessagePort[],
    port: MessagePort
) => {
    const response = await updateUser(SharedState.authToken, data);
    const { tr_matches } = data;

    // biome-ignore lint/suspicious/noPrototypeBuiltins: <explanation>
    if (data.hasOwnProperty('tr_matches')) {
        SharedState.matches.list_new.forEach((match: Match) => {
            match.tr = tr_matches;
        });
        SharedState.matches.list_open.forEach((match: Match) => {
            match.tr = tr_matches;
        });
    }

    if (response.result.me && SharedState.userData) {
        SharedState.userData.me = response.result.me;
    }
    if (connections.length > 0) {
        return postMessageToConnections(
            connections,
            WorkerActions.GetData,
            SharedState.userData,
            'userData'
        );
    }
    port.postMessage({
        action: WorkerActions.GetData,
        data: SharedState.userData,
        dataKey: 'userData',
    });
};

const sentReport = async (
    data: { id: number; comment?: string; reason?: string },
    connections: MessagePort[],
    port: MessagePort
) => {
    const { id, comment, reason } = data;
    if (comment && reason) {
        await cancelBupmReason(SharedState.authToken, {
            id: id,
            comment: comment,
            reason: reason,
        });
    } else {
        await cancelBupm(SharedState.authToken, id);
    }

    const chat = findChatById(id);
    chat.status = 'CLOSED';
    chat.closed_by_user_id = SharedState.userData?.me.user.id;
    chat.pending_delete_at = new Date(Date.now() + 3 * 24 * 60 * 60 * 1000);
    const matchIndexInNew = SharedState.matches.list_new.findIndex(
        (el: Match) => el.id === id
    );

    if (matchIndexInNew !== -1) {
        const matchToMove = SharedState.matches.list_new[matchIndexInNew];
        SharedState.matches.list_open.push(matchToMove);
        SharedState.matches.list_new.splice(matchIndexInNew, 1);
    }
    if (
        SharedState.matches.list_open.length === 0 &&
        SharedState.matches.list_new.length === 0
    ) {
        SharedState.matches.users = [];
    }
    if (connections.length > 0) {
        postMessageToConnections(connections, WorkerActions.UpdateChat, chat);
    } else {
        port.postMessage({
            action: WorkerActions.UpdateChat,
            data: chat,
        });
    }
    if (connections.length > 0) {
        return postMessageToConnections(
            connections,
            WorkerActions.GetData,
            SharedState.matches,
            'matches'
        );
    }
    port.postMessage({
        action: WorkerActions.GetData,
        data: SharedState.matches,
        dataKey: 'matches',
    });
};

const addNewChatToMatches = async (
    data: any,
    connections: MessagePort[],
    port: MessagePort
) => {
    const { match, user } = data;
    if (match.status === 'OPEN' || match.status === 'CLOSED') {
        SharedState.matches.list_open.push(match);
    } else {
        SharedState.matches.list_new.push(match);
    }
    SharedState.matches.users.unshift(user);
    if (connections.length > 0) {
        return postMessageToConnections(
            connections,
            WorkerActions.GetData,
            SharedState.matches,
            'matches'
        );
    }
    port.postMessage({
        action: WorkerActions.GetData,
        data: SharedState.matches,
        dataKey: 'matches',
    });
};

const addNewMatch = async (
    data: any,
    connections: MessagePort[],
    port: MessagePort
) => {
    const { match, user } = data;
    SharedState.matches.list_new.unshift(match);
    SharedState.matches.users.unshift(user);
    if (connections.length > 0) {
        return postMessageToConnections(
            connections,
            WorkerActions.GetData,
            SharedState.matches,
            'matches'
        );
    }
    port.postMessage({
        action: WorkerActions.GetData,
        data: SharedState.matches,
        dataKey: 'matches',
    });
};

const updateUserImages = async (
    data: { firstId: number; secondId: number },
    connections: MessagePort[],
    port: MessagePort
) => {
    const { firstId, secondId } = data;
    const response = await switchPhotos(
        SharedState.authToken,
        firstId,
        secondId
    );
    if (response.result.me && SharedState.userData) {
        SharedState.userData.me = response.result.me;
    }
    if (connections.length > 0) {
        return postMessageToConnections(
            connections,
            WorkerActions.GetData,
            SharedState.userData,
            'userData'
        );
    }
    port.postMessage({
        action: WorkerActions.GetData,
        data: SharedState.userData,
        dataKey: 'userData',
    });
};

const fetchNewUsers = async (
    data: { lastAt: number; type: 'NEW' | 'OPEN' },
    connections: MessagePort[],
    port: MessagePort
) => {
    const { lastAt, type } = data;
    const newUsers = await fetchUsers(SharedState.authToken, lastAt, type);
    if (type === 'NEW') {
        SharedState.matches.list_new = [
            ...SharedState.matches.list_new,
            ...newUsers.result.list_new,
        ];
    } else
        SharedState.matches.list_open = [
            ...SharedState.matches.list_open,
            ...newUsers.result.list_open,
        ];
    SharedState.matches.users = [
        ...SharedState.matches.users,
        ...newUsers.result.users,
    ];
    if (newUsers.result[type.toLowerCase()]?.length === 0) {
        SharedState.matches.isAllUsers = true;
        return;
    }

    if (connections.length > 0) {
        return postMessageToConnections(
            connections,
            WorkerActions.GetData,
            SharedState.matches,
            'matches'
        );
    }
    port.postMessage({
        action: WorkerActions.GetData,
        data: SharedState.matches,
        dataKey: 'matches',
    });
};

const removeClosed = async (
    data: { id: number },
    connections: MessagePort[],
    port: MessagePort
) => {
    const { id } = data;
    await removeChat(SharedState.authToken, id);
    const userId = findChatById(id).user_id;
    removeMatchById(id);
    SharedState.matches.users = SharedState.matches.users.filter(
        (user: User) => {
            if (user.id !== userId) {
                return user;
            }
        }
    );
    if (
        SharedState.matches.list_open.length === 0 &&
        SharedState.matches.list_new.length === 0
    ) {
        SharedState.matches.users = [];
    }
    if (connections.length > 0) {
        return postMessageToConnections(
            connections,
            WorkerActions.GetData,
            SharedState.matches,
            'matches'
        );
    }
    port.postMessage({
        action: WorkerActions.GetData,
        data: SharedState.matches,
        dataKey: 'matches',
    });
};
