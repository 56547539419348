import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { signIn } from '@/api/auth';
import { INITIAL_CODE_COUNTDOWN } from '@/components/auth/emailSignInSteps/CodeStep';
import { LOCAL_STORAGE } from '@/utils/localStorage';
import { AppRoutes } from '@/utils/routes';
import { initializeWorker, storeData } from '@/workers/sharedWorkerInstance';
import { analyticsEvent } from '@shared/services/analytics';
import {
    AUTH_EVENTS,
    analyticsSignIn,
    trackDefaultRegistrationEvent,
} from '@shared/services/analytics';

const INITIAL_TRIES_LEFT = 5;

export const useCodeSignIn = (
    email: string,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    onNext?: () => void,
    isLite?: boolean
) => {
    const navigate = useNavigate();
    const [code, setCode] = useState<string>('');
    const [isValidCode, setIsValidCode] = useState<boolean>(true);
    const existingTriesLeft = localStorage.getItem(LOCAL_STORAGE.CodeTriesLeft);
    const [triesLeft, setTriesLeft] = useState<number>(
        existingTriesLeft ? +existingTriesLeft : INITIAL_TRIES_LEFT
    );
    if (code && isValidCode) {
        localStorage.setItem('code', code.toString());
    }
    const handleCodeChange = useCallback((i: number, value: string) => {
        setCode((prevCode) => {
            const newCode = prevCode.split('');
            newCode[i] = value;
            return newCode.join('');
        });
    }, []);

    const handleCodeSubmit = useCallback(async () => {
        localStorage.setItem(LOCAL_STORAGE.Code, code.toString());
        try {
            setIsLoading(true);
            let signInData: any;
            if (isLite) {
                signInData = await signIn(email, 'in', code, navigate, onNext);
            } else {
                signInData = await signIn(email, 'in', code, navigate);
            }
            const token = signInData.result.token.access;
            if (token) {
                localStorage.setItem(LOCAL_STORAGE.AuthToken, token);
                localStorage.removeItem(LOCAL_STORAGE.EmailSignIn);
                localStorage.removeItem(LOCAL_STORAGE.Code);
                localStorage.removeItem(LOCAL_STORAGE.CodeCountdownStartedAt);
                localStorage.removeItem(LOCAL_STORAGE.CodeTriesLeft);
                initializeWorker();
                storeData('authToken', token);
                navigate(AppRoutes.Home);
                analyticsSignIn('email');
                trackDefaultRegistrationEvent(AUTH_EVENTS.SIGN_IN, {
                    method: 'email',
                });
            }
        } catch (error: any) {
            analyticsEvent('EmailConfirmError', {
                error: error.response?.data?.m,
            });
            setIsLoading(false);
            if (error.response?.data?.info) {
                const { tries_left, retry_left } = error.response.data.info;
                localStorage.setItem(
                    LOCAL_STORAGE.CodeCountdownStartedAt,
                    String(
                        Date.now() + retry_left - INITIAL_CODE_COUNTDOWN * 1000
                    )
                );
                localStorage.setItem(
                    LOCAL_STORAGE.CodeTriesLeft,
                    String(tries_left)
                );
                setCode('');
                setTriesLeft(tries_left);
                setIsValidCode(false);
            }
        }
    }, [email, code, navigate, setIsLoading, onNext, isLite]);
    return {
        code,
        handleCodeChange,
        handleCodeSubmit,
        setIsValidCode,
        isValidCode,
        setTriesLeft,
        triesLeft,
    };
};
