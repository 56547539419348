import { logEvent as firebaseLogEvent } from '@shared/lib/firebase';

import {
    DEFAULT_REGISTRATION_EVENT_PREFIX,
    LITE_REGISTRATION_EVENT_PREFIX,
} from './consts';

const makeAnalyticsEvent =
    (prefix: string) => (name: string, params?: { [name: string]: string }) => {
        firebaseLogEvent(`${prefix}_${name}`, params);
    };

export const trackDefaultRegistrationEvent = makeAnalyticsEvent(
    DEFAULT_REGISTRATION_EVENT_PREFIX
);
export const trackLiteRegistrationEvent = makeAnalyticsEvent(
    LITE_REGISTRATION_EVENT_PREFIX
);

type EVENT_PREFIX =
    | typeof DEFAULT_REGISTRATION_EVENT_PREFIX
    | typeof LITE_REGISTRATION_EVENT_PREFIX;

const makeTrackScreen = (prefix: EVENT_PREFIX) => (screen: string) => {
    firebaseLogEvent(`${prefix}_screen_${screen}`, {
        screen_name: screen,
        screen_class: screen,
    });
};

export const defaultRegistrationTrackScreen = makeTrackScreen(
    DEFAULT_REGISTRATION_EVENT_PREFIX
);
export const liteRegistrationTrackScreen = makeTrackScreen(
    LITE_REGISTRATION_EVENT_PREFIX
);
