import {hasFlag, Model} from './Model';

export enum MatchMessageType {
  // DO NOT MODIFY THIS ENUM, Only appending is allowed
  textOrImage,
  callPermissionRequest,
  callPermissionApprove,
  callPermissionDecline,
  callPermissionRevoke,
  callPermissionCanceled,
  callNewAudio,
  callNewVideo,
  callCanceledAudio,
  callCanceledVideo,
  callDeclinedAudio,
  callDeclinedVideo,
  callFailedAudio,
  callFailedVideo,
  callBusyAudio,
  callBusyVideo,
  compliment,
  gif,
}

export enum MatchMessageReaction {
  NONE,
  LIKE,
}

export enum MatchMessageFlags {
  /**
   * @deprecated
   */
  FLAG_TR_VISIBLE = 1,
  FLAG_TR_V2_VISIBLE = 1 << 1, //<< can be renamed to FLAG_TR_VISIBLE once previous bit 1 will be reused with different name
  /**
   * @deprecated
   */
  FLAG_TR_HIDDEN = 1 << 2,
  FLAG_SUSPICIOUS = 1 << 3,
  FLAG_SENSITIVE = 1 << 4,
  FLAG_SENSITIVE_REVEALED = 1 << 5,
  FLAG_MODIFIED = 1 << 6,
  FLAG_HAS_MEDIA = 1 << 7,
  FLAG_SUSPICIOUS_REVEALED = 1 << 8,
}

export type MatchMessage = Model & {
  match_id?: number;
  type: MatchMessageType;
  flags: MatchMessageFlags;
  sender_user_id: number;
  file_url?: string;
  interaction_payload?: string;
  content?: string;
  content_lang?: string;
  content_tr?: string;
  modified_at?: number;
  reaction?: MatchMessageReaction;
  reply_to_message_id?: number;
};

export function getMatchMessageText(message: MatchMessage): string {
  return message.content_tr &&
    hasFlag(message.flags, MatchMessageFlags.FLAG_TR_V2_VISIBLE)
    ? message.content_tr
    : message.content!;
}

export function isMatchMessageTranslatable(
  message: MatchMessage,
  toTrLang?: string,
): boolean {
  if (toTrLang && !message.content_tr && message.content_lang) {
    return toTrLang !== message.content_lang;
  }
  return false;
}

export function isMatchMessageReply(message: MatchMessage): boolean {
  return message.reply_to_message_id !== undefined;
}

export function hasMatchMessageReaction(message: MatchMessage): boolean {
  return (
    message.reaction !== undefined &&
    message.reaction !== MatchMessageReaction.NONE
  );
}
