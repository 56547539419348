export enum Ethnicities { //DO NOT DELETE OR UPDATE, ONLY APPEND
  Han_Chinese = 1,
  Indian,
  Bengali,
  Russian,
  Japanese,
  German,
  Javanese,
  Korean,
  French,
  Brazilian,
  Punjabi,
  Vietnamese,
  Turkish,
  Italian,
  Thai,
  Gujarati,
  Iranian,
  Egyptian,
  Filipino,
  Polish,
  Ukrainian,
  Mexican,
  Spanish,
  Dutch,
  Kurdish,
  Argentinian,
  Uzbek,
  Moroccan,
  Malaysian,
  Afghan,
  Sudanese,
  Algerian,
  Saudi,
  Ugandan,
  Romanian,
  Greek,
  Czech,
  Belgian,
  Swedish,
  Hungarian,
  Australian,
  Norwegian,
  Lithuanian,
  Finnish,
  Slovak,
  Danish,
  Bulgarian,
  Swiss,
  Serbian,
  Jordanian,
  Portuguese,
  Azerbaijani,
  Austrian,
  Israeli,
  Chilean,
  Somali,
  Nigerian,
  Kenyan,
  Ghanaian,
  Tanzanian,
  South_African,
  Ethiopian,
  Zimbabwean,
  Congolese,
  Cameroonian,
  Ivorian,
  Libyan,
  Senegalese,
  Tunisian,
  Malian,
  Malagasy,
  Lebanese,
  Armenian,
  Syrian,
  Iraqi,
  Georgian,
  Albanian,
  Mongolian,
  Nepali,
  Sri_Lankan,
  Bangladeshi,
  Taiwanese,
  Singaporean,
  New_Zealander,
  Cuban,
  Colombian,
  Venezuelan,
  Ecuadorian,
  Peruvian,
  Irish,
  Scots,
  Welsh,
  Catalonian,
  Basque,
  Sicilian,
  Corsican,
  Breton,
  Bavarian,
  Sardinian,
  Palauan,
  Berber,
  Amhara,
  Oromo,
  Tigrinya,
  Hausa,
  Yoruba,
  Igbo,
  Zulu,
  Swazi,
  Akan,
  Fulani,
  Luo,
  Kalenjin,
  Gikuyu,
  Tswana,
  Wolof,
  Mandinka,
  Jola,
  Baloch,
  Sindhi,
  Assamese,
  Odia,
  Marathi,
  Kannada,
  Malayali,
  Burmese,
  Khmer,
  Lao,
  Hmong,
  Uyghur,
  Zhuang,
  Manchu,
  Hui,
  Miao,
  Tibetan,
  Kazakh,
  Kyrgyz,
  Tajik,
  Turkmen,
  Ainu,
  Ryukyuan,
  Cebuano,
  Ilocano,
  Bisaya,
  Moro,
  Maori,
  Aboriginal_Australian,
  Torres_Strait_Islander,
  Samoan,
  Tongan,
  Fijian,
  Hawaiian,
  Chamorro,
  Carolinian,
  Papuan,
  Batak,
  Minangkabau,
  Acehnese,
  Buginese,
  Dayak,
  Sasak,
  Balinese,
  Sundanese,
  Pashtun,
  Bahamian,
  Caribbean,
  Palestinian,
  Macedonian,
}
export const EthnicitiesBegin = Ethnicities.Han_Chinese;
export const EthnicitiesEnd = Ethnicities.Macedonian;
