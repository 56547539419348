import Drink from '@/assets/icons/drink.svg?react';
import { getEnumOptions } from '@/utils/getEnumOptions';

import { UserDrinking } from '@/domain';
import React from 'react';
import SingleSelectStep from '../../common/SingleSelectStep';
import UserDataFlowLayout from '../../layouts/UserDataFlowLayout';
import { UserInfoStepProps } from './userInfoConfig';

const DrinkStep: React.FC<UserInfoStepProps> = ({ onNext }) => {
    return (
        <UserDataFlowLayout withBarProgress>
            <div className='-mb-[5px] h-[5px] w-full bg-white/50'>
                <div className='h-[5px] w-[60%] bg-white' />
            </div>
            <SingleSelectStep
                LogoComponent={Drink}
                title='Do you drink?'
                options={getEnumOptions(UserDrinking)}
                withSkip
                onNext={onNext}
                field='drinking'
                fromEdit={false}
            />
        </UserDataFlowLayout>
    );
};

export default DrinkStep;
