import React, { useState } from 'react';
import { twMerge } from 'tailwind-merge';

import GreyError from '@/assets/icons/grey-error.svg?react';
import ImageIcon from '@/assets/icons/image.svg?react';
import RedError from '@/assets/icons/red-error.svg?react';
import Button from '@/components/common/Button/Button';
import PhotoList from '@/components/common/PhotoDraggable/PhotoListDraggable';
import UserDataFlowLayout from '@/components/layouts/UserDataFlowLayout';
import TipsPhotoModal from '@/components/modals/TipsPhotoModal';
import { useScreenDetector } from '@/hooks/useScreenDetector';
import { scaleFont, scaleSize } from '@/utils/scaleSizeAndFont';
import { analyticsEvent, useAbRegistration } from '@shared/services/analytics';

import { UserInfoStepProps } from './userInfoConfig';

const PhotoUploadStep: React.FC<UserInfoStepProps> = ({ onNext }) => {
    const [photoCount, setPhotoCount] = useState(0);
    const handlePhotoCountChange = (count: number) => {
        setPhotoCount(count);
    };
    const [openModal, setOpenModal] = useState(false);
    const handleCloseModal = () => {
        setOpenModal(false);
    };
    const { isMobile } = useScreenDetector();
    const { trackAbRegistrationEvent } = useAbRegistration();

    return (
        <>
            <UserDataFlowLayout
                onNext={() => {
                    onNext();
                }}
                disableNextButton={photoCount < 1}
                withToolTip
                withBarProgress
            >
                <div className='overflow-auto'>
                    <div className='mb-[5px] h-[5px] w-full rounded bg-white/50'>
                        <div
                            className={twMerge(
                                'h-full rounded bg-white opacity-100 sm:mt-0',
                                photoCount > 0 ? 'w-full' : 'w-[95%]'
                            )}
                        />
                    </div>
                    <div className='flex flex-col overflow-auto px-4'>
                        <h1
                            className='font-extrabold text-white'
                            style={{
                                marginTop: `${scaleSize(40)}px`,
                                fontSize: `${isMobile ? scaleFont(22) : 36}px`,
                            }}
                        >
                            Time to show your face
                        </h1>
                        <h2
                            className='text-white'
                            style={{
                                marginTop: `${scaleSize(12)}px`,
                                fontSize: `${scaleFont(17)}px`,
                                lineHeight: `${scaleFont(22)}px`,
                            }}
                        >
                            It's you! Whether you're in a place you love or
                            doing what you love.
                        </h2>
                        <div
                            className='flex flex-col items-center justify-center self-center md:px-16'
                            style={{
                                marginTop: `${scaleSize(30)}px`,
                            }}
                        >
                            <div className='flex content-center items-center justify-between self-center'>
                                <PhotoList
                                    photoCount={photoCount}
                                    onPhotoCountChange={handlePhotoCountChange}
                                    initialPhotoCount={6}
                                />
                            </div>
                            {photoCount === 0 && (
                                <div className='mt-4 mb-12 flex items-center self-start sm:mb-0'>
                                    <RedError className='mr-2' />
                                    <p className='text-black'>
                                        Please add at least 1 photo to continue
                                    </p>
                                </div>
                            )}
                            {photoCount > 1 && (
                                <div className='mt-2 mb-12 flex items-center self-start sm:mb-0'>
                                    <GreyError
                                        className='mr-2'
                                        width={20}
                                        height={20}
                                    />
                                    <p className='text-black'>
                                        Hold & drag to reorder
                                    </p>
                                </div>
                            )}
                        </div>
                        <div className='absolute bottom-0 left-0 h-[66px] w-[100%] bg-white pt-1 sm:h-[72px]'>
                            <div className='ml-4 flex gap-2'>
                                <ImageIcon />
                                <div>
                                    <p
                                        className='text-black'
                                        style={{
                                            fontSize: `${scaleFont(15)}px`,
                                        }}
                                    >
                                        Need help choosing your photo?
                                    </p>
                                    <Button
                                        className='-ml-4 font-bold text-black underline decoration-black decoration-solid'
                                        onClick={() => {
                                            analyticsEvent(
                                                'StepAvatars_see_avatar_tips_pressed'
                                            );
                                            trackAbRegistrationEvent(
                                                'StepAvatars_see_avatar_tips_pressed'
                                            );
                                            setOpenModal(true);
                                        }}
                                        label='See "Photo" tips'
                                    />
                                </div>
                            </div>
                            <TipsPhotoModal
                                isOpen={openModal}
                                onCancel={handleCloseModal}
                            />
                        </div>
                    </div>
                </div>
            </UserDataFlowLayout>
        </>
    );
};

export default PhotoUploadStep;
