import Religion from '@/assets/icons/religion.svg?react';
import { UserReligion } from '@/domain';
import { getEnumOptions } from '@/utils/getEnumOptions';
import React from 'react';
import SingleSelectStep from '../../common/SingleSelectStep';
import UserDataFlowLayout from '../../layouts/UserDataFlowLayout';
import { UserInfoStepProps } from './userInfoConfig';

const ReligionStep: React.FC<UserInfoStepProps> = ({ onNext }) => {
    return (
        <UserDataFlowLayout withBarProgress>
            <div className='-mb-[5px] h-[5px] w-full bg-white/50'>
                <div className='h-[5px] w-[75%] bg-white' />
            </div>
            <SingleSelectStep
                LogoComponent={Religion}
                title='Do you identify a religion?'
                options={getEnumOptions(UserReligion)}
                withSkip
                onNext={onNext}
                field='religion'
                fromEdit={false}
            />
        </UserDataFlowLayout>
    );
};

export default ReligionStep;
