import discoveryStore, { ViewedUser } from '@/entities/discovery/store';

let staleCheckInterval: NodeJS.Timeout | undefined;

export function startStaleUserCleanup() {
    if (staleCheckInterval) {
        clearInterval(staleCheckInterval);
    }

    staleCheckInterval = setInterval(() => {
        const currentTime = new Date().getTime();
        const fifteenSecondsAgo = currentTime - 15000;

        discoveryStore.users_list.users_viewed.forEach((user: ViewedUser) => {
            if (user.time < fifteenSecondsAgo) {
                discoveryStore.deleteUserFromViewedUsers(user.id);
            }
        });
    }, 1000);
}

export function stopStaleUserCleanup() {
    if (staleCheckInterval) {
        clearInterval(staleCheckInterval);
        staleCheckInterval = undefined;
    }
}
