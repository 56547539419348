export type Id = {
  id: number;
};

export type IdMap<T> = {
  [id: number]: T;
};

export type Model = Id & {
  created_at: number;
};

export type AtLeastOne<T, U = {[K in keyof T]: Pick<T, K>}> = Partial<T> &
  U[keyof U];
// taken from https://stackoverflow.com/questions/48230773/how-to-create-a-partial-like-that-requires-a-single-property-to-be-set/48244432

export function createModelKeyExtractor<T extends Model>(
  modelName: string,
): (model: T) => string {
  return (model: T): string => {
    return `${modelName}_${model.id}`;
  };
}

export function sortByCreatedAtDesc<T extends Model>(a: T, b: T): number {
  if (a.created_at < b.created_at) {
    return 1;
  } else if (a.created_at > b.created_at) {
    return -1;
  }
  return 0;
}

export function hasFlag(value: number, flag: number): boolean {
  return (value & flag) !== 0;
}

export function noFlag(value: number, flag: number): boolean {
  return (value & flag) === 0;
}

export function toggleFlag(value: number, flag: number): number {
  if ((value & flag) === 0) {
    return value | flag;
  } else {
    return value ^ flag;
  }
}

export function setFlag(value: number, flag: number, enabled: boolean): number {
  if (enabled) {
    return value | flag;
  } else if ((value & flag) !== 0) {
    return value ^ flag;
  }
  return value;
}

export function areTopIdsSame<T extends Id>(a: T[], b: T[]): boolean {
  if (a.length === 0 && b.length === 0) {
    return true;
  }
  const length = Math.min(a.length, b.length);
  if (length === 0) {
    return false;
  }
  for (let i = 0; i < length; ++i) {
    if (a[i].id !== b[i].id) {
      return false;
    }
  }
  return true;
}

export function extractIds<T extends Id>(models: T[]): number[] {
  const result: number[] = [];
  for (const model of models) {
    result.push(model.id);
  }
  return result;
}

export function findById<T extends Id>(id: number, models: T[]): T {
  const result: T | undefined = findByIdOptional(id, models);
  if (result) {
    return result;
  }
  throw new Error('model not found');
}

export function findByIdOptional<T extends Id>(
  id: number,
  models: T[],
): T | undefined {
  return models.find((m) => {
    return m.id === id;
  });
}

export function indexOfId<T extends Id>(id: number, models: T[]): number {
  return models.findIndex((m) => m.id === id);
}

export function deleteById<T extends Id>(
  id: number,
  models: T[],
): T | undefined {
  const index = indexOfId(id, models);
  if (index === -1) {
    return undefined;
  }
  const removedModel = models[index];
  models.splice(index, 1);
  return removedModel;
}

export function mergeById<T extends Id>(lows: T[], highs: T[]): T[] {
  const dict = idsToDict(lows);
  for (const high of highs) {
    dict[high.id] = high;
  }
  return Object.values(dict);
}

export function idsToDict<T extends Id>(list: T[]): IdMap<T> {
  const dict = {};
  assignById(dict, list);
  return dict;
}

export function assignById<T extends Id>(dict: IdMap<T>, list: T[]): void {
  for (const item of list) {
    dict[item.id] = item;
  }
}

export function findByName<T extends {name: string}>(
  name: string,
  items: T[],
): T | undefined {
  for (const item of items) {
    if (item.name === name) {
      return item;
    }
  }
}

export function isArraySimpleEqual<T>(one: T[], two: T[]): boolean {
  if (one.length !== two.length) {
    return false;
  }

  for (let i = 0; i < one.length; ++i) {
    if (one[i] !== two[i]) {
      return false;
    }
  }
  return true;
}

export function isArrayEqual<T>(one: T[], two: T[]): boolean {
  if (one.length !== two.length) return false;

  const sortedOne = one.map((obj) => JSON.stringify(obj)).sort();
  const sortedTwo = two.map((obj) => JSON.stringify(obj)).sort();

  return sortedOne.every((item, index) => item === sortedTwo[index]);
}

/** taken from {@link https://stackoverflow.com/questions/16637051/adding-space-between-numbers}
 * @example `888 888`
 */
export function idToString(id: number): string {
  return id.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

const maxBits = 32;

export function unpackBits<T extends number>(pack: number[]): T[] {
  const result: T[] = [];
  // I wish to have ^^^ here 64, but JS's number max is 56
  // and to avoid some possible problems on various platforms/OS/devices
  // I've decided to have 32 only :(
  for (let numIndex = 0; numIndex < pack.length; ++numIndex) {
    const num = pack[numIndex];
    for (let bitIndex = 0; bitIndex < maxBits; ++bitIndex) {
      const bitValue = 1 << bitIndex;
      if ((num & bitValue) !== 0) {
        result.push((numIndex * maxBits + bitIndex) as T);
      }
    }
  }
  return result;
}

export function packBits<T extends number>(
  bits: T[],
  packSize: number,
): number[] {
  const result = [];

  for (let i = 0; i < packSize; ++i) {
    result.push(0);
  }

  for (const bit of bits) {
    const numIndex = Math.floor(bit / maxBits);
    const bitIndex = bit - numIndex * maxBits;
    result[numIndex] |= 1 << bitIndex;
  }

  return result;
}
