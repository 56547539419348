import { FC } from 'react';
import CodeStep from '../emailSignInSteps/CodeStep';
import EmailStep from '../emailSignInSteps/EmailStep';
import BirthdayStep from './BirthdayStep';
import BuildProfile from './BuildProfile';
import DrinkStep from './DrinkStep';
import EducationLevelStep from './EducationLevelStep';
import GenderStep from './GenderStep';
import GoalStep from './GoalStep';
import HeightStep from './HeightStep';
import InterestsStep from './InterestsStep';
import KidsStep from './KidsStep';
import LookingForStep from './LookingForStep';
import MaritalStatusStep from './MaritalStatusStep';
import MoreAboutYouStep from './MoreAboutYouStep';
import MostImportantThingStep from './MostImportantThingStep';
import NameStep from './NameStep';
import PetsStep from './PetsStep';
import PhotoUploadStep from './PhotoUploadStep';
import ReligionStep from './ReligionStep';
import SexualOrientationStep from './SexualOrientationStep';
import SmokeStep from './SmokeStep';
import SportStep from './SportStep';
import StarSignStep from './StarSignStep';
import WeightStep from './WeightStep';

export enum USER_INFO_STEPS {
    NAME = 'name',
    BIRTHDAY = 'birthday',
    GENDER = 'gender',
    LOOKING_FOR = 'looking_for',
    GOAL = 'goal',
    BUILD = 'build',
    INTERESTS = 'interests',
    HEIGHT = 'height',
    WEIGHT = 'weight',
    SEX_ORIENTATION = 'sex_orientation',
    STAR_SIGN = 'star_sign',
    EXERCISE = 'exercise',
    EDUCATION_LEVEL = 'education_level',
    MARITAL_STATUS = 'marital_status',
    KIDS = 'kids',
    DRINK = 'drink',
    SMOKE = 'smoke',
    PETS = 'pets',
    RELIGION = 'religion',
    VALUES = 'values',
    ABOUT = 'about',
    PHOTO_UPLOAD = 'photo_upload',
}
export enum USER_INFO_LITE_STEPS {
    GENDER = 'gender',
    LOOKING_FOR = 'looking_for',
    NAME = 'name',
    BIRTHDAY = 'birthday',
    GOAL = 'goal',
    EMAIL = 'email',
    CODE = 'code',
    BUILD = 'build',
    INTERESTS = 'interests',
    HEIGHT = 'height',
    WEIGHT = 'weight',
    SEX_ORIENTATION = 'sex_orientation',
    STAR_SIGN = 'star_sign',
    EXERCISE = 'exercise',
    EDUCATION_LEVEL = 'education_level',
    MARITAL_STATUS = 'marital_status',
    KIDS = 'kids',
    DRINK = 'drink',
    SMOKE = 'smoke',
    PETS = 'pets',
    RELIGION = 'religion',
    VALUES = 'values',
    ABOUT = 'about',
    PHOTO_UPLOAD = 'photo_upload',
}

export type UserInfoStepComponentType = FC<UserInfoStepProps>;

export const USER_INFO_COMPONENTS = {
    [USER_INFO_STEPS.NAME]: NameStep,
    [USER_INFO_STEPS.BIRTHDAY]: BirthdayStep,
    [USER_INFO_STEPS.GENDER]: GenderStep,
    [USER_INFO_STEPS.LOOKING_FOR]: LookingForStep,
    [USER_INFO_STEPS.GOAL]: GoalStep,
    [USER_INFO_STEPS.BUILD]: BuildProfile,
    [USER_INFO_STEPS.INTERESTS]: InterestsStep,
    [USER_INFO_STEPS.HEIGHT]: HeightStep,
    [USER_INFO_STEPS.WEIGHT]: WeightStep,
    [USER_INFO_STEPS.SEX_ORIENTATION]: SexualOrientationStep,
    [USER_INFO_STEPS.STAR_SIGN]: StarSignStep,
    [USER_INFO_STEPS.EXERCISE]: SportStep,
    [USER_INFO_STEPS.EDUCATION_LEVEL]: EducationLevelStep,
    [USER_INFO_STEPS.MARITAL_STATUS]: MaritalStatusStep,
    [USER_INFO_STEPS.KIDS]: KidsStep,
    [USER_INFO_STEPS.DRINK]: DrinkStep,
    [USER_INFO_STEPS.SMOKE]: SmokeStep,
    [USER_INFO_STEPS.PETS]: PetsStep,
    [USER_INFO_STEPS.RELIGION]: ReligionStep,
    [USER_INFO_STEPS.VALUES]: MostImportantThingStep,
    [USER_INFO_STEPS.ABOUT]: MoreAboutYouStep,
    [USER_INFO_STEPS.PHOTO_UPLOAD]: PhotoUploadStep,
};

export const USER_INFO_LITE_COMPONENTS = {
    [USER_INFO_LITE_STEPS.GENDER]: GenderStep,
    [USER_INFO_LITE_STEPS.LOOKING_FOR]: LookingForStep,
    [USER_INFO_LITE_STEPS.NAME]: NameStep,
    [USER_INFO_STEPS.BIRTHDAY]: BirthdayStep,
    [USER_INFO_STEPS.GOAL]: GoalStep,
    [USER_INFO_LITE_STEPS.EMAIL]: EmailStep,
    [USER_INFO_LITE_STEPS.CODE]: CodeStep,
    [USER_INFO_STEPS.BUILD]: BuildProfile,
    [USER_INFO_STEPS.INTERESTS]: InterestsStep,
    [USER_INFO_STEPS.HEIGHT]: HeightStep,
    [USER_INFO_STEPS.WEIGHT]: WeightStep,
    [USER_INFO_STEPS.SEX_ORIENTATION]: SexualOrientationStep,
    [USER_INFO_STEPS.STAR_SIGN]: StarSignStep,
    [USER_INFO_STEPS.EXERCISE]: SportStep,
    [USER_INFO_STEPS.EDUCATION_LEVEL]: EducationLevelStep,
    [USER_INFO_STEPS.MARITAL_STATUS]: MaritalStatusStep,
    [USER_INFO_STEPS.KIDS]: KidsStep,
    [USER_INFO_STEPS.DRINK]: DrinkStep,
    [USER_INFO_STEPS.SMOKE]: SmokeStep,
    [USER_INFO_STEPS.PETS]: PetsStep,
    [USER_INFO_STEPS.RELIGION]: ReligionStep,
    [USER_INFO_STEPS.VALUES]: MostImportantThingStep,
    [USER_INFO_STEPS.ABOUT]: MoreAboutYouStep,
    [USER_INFO_STEPS.PHOTO_UPLOAD]: PhotoUploadStep,
};

export interface UserInfoStepProps {
    onNext: (isShorterFlow?: boolean) => void;
    onBack: () => void;
    isLite?: boolean;
}
