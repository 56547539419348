import React, { useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { scaleFont } from '@/utils/scaleSizeAndFont';
import { analyticsEvent } from '@shared/services/analytics';

import Button from '../common/Button/Button';
import TipsAboutModal from './TipsAboutModal';

interface ButtonWithModalTipsProps {
    buttonText?: string;
    className?: string;
    small?: boolean;
    analyticsName: string;
}

const ButtonWithModalTips: React.FC<ButtonWithModalTipsProps> = ({
    buttonText = 'See profile safety tips',
    className,
    small,
    analyticsName,
}) => {
    const [openModal, setOpenModal] = useState(false);

    const handleOpenModal = () => {
        analyticsEvent(`${analyticsName}_see_safety_tips_pressed`);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    return (
        <>
            <Button
                className={twMerge(
                    'mt-3 ml-4w-fit text-left text-black underline',
                    className
                )}
                onClick={handleOpenModal}
                style={{
                    fontSize: `${small ? scaleFont(12) : scaleFont(15)}px`,
                }}
            >
                {buttonText}
            </Button>
            <TipsAboutModal isOpen={openModal} onCancel={handleCloseModal} />
        </>
    );
};

export default ButtonWithModalTips;
