import EarthIcon from '@/assets/icons/earth-icon.svg?react';
import { User } from '@/domain';
import userStore from '@/entities/user/store';
import { scaleSize } from '@/utils/scaleSizeAndFont';
import { observer } from 'mobx-react-lite';
import ReactCountryFlag from 'react-country-flag';
import Avatar from '../common/CircleAvatar/Avatar';

interface NewMessageNotificationsProps {
    data: User;
}

const NewMessageNotifications: React.FC<NewMessageNotificationsProps> =
    observer(({ data }) => {
        return (
            <>
                <div className='relative'>
                    {data?.country_code ? (
                        <ReactCountryFlag
                            svg
                            className='absolute bottom-0 z-20'
                            alt={`${data?.country_code}`}
                            countryCode={`${data?.country_code}`}
                            style={{
                                borderRadius: '50%',
                                width: `${scaleSize(18)}px`,
                                height: `${scaleSize(18)}px`,
                                objectFit: 'cover',
                            }}
                        />
                    ) : (
                        <EarthIcon className='absolute bottom-0' />
                    )}
                    <div
                        className='absolute z-10 rounded-full border-2 border-white bg-lime-400'
                        style={{
                            right: `-${scaleSize(6)}px`,
                            top: `${scaleSize(10)}px`,
                            width: `${scaleSize(12)}px`,
                            height: `${scaleSize(12)}px`,
                        }}
                    />
                    <Avatar
                        src={`${data?.avatar_urls?.[0]}?${userStore.home?.pub_urls_query}`}
                        alt='rounded avatar'
                        size={scaleSize(40)}
                        imageSize='small'
                    />
                </div>
                <span style={{ fontSize: scaleSize(14) }}>
                    {`${data?.first_name} sent you a message`}
                </span>
            </>
        );
    });

export default NewMessageNotifications;
