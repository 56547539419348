import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { DotButton, useDotButton } from './EmblaCarouselDotButton';

type PropType = {
    slides: ReactNode[];
    forwardedRef?: React.Ref<HTMLDivElement>;
    emblaApi: any;
    autoplay?: boolean;
    withoutDots?: boolean;
    extraMB?: number;
    extraPL?: number;
    className?: string;
    intervalTime?: number;
    slideWidth?: number;
    blackDot?: boolean;
    fakeDots?: boolean;
};

const EmblaCarousel: React.FC<PropType> = ({
    slides,
    forwardedRef,
    emblaApi,
    autoplay = false,
    withoutDots = false,
    extraMB = 0,
    extraPL = 0,
    className,
    intervalTime = 3000,
    slideWidth,
    blackDot,
    fakeDots = false,
}) => {
    const { selectedIndex, scrollSnaps, onDotButtonClick } =
        useDotButton(emblaApi);
    const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);
    const [scrollDirection, setScrollDirection] = useState<'next' | 'prev'>(
        'next'
    );

    useEffect(() => {
        if (autoplay && emblaApi) {
            const interval = setInterval(() => {
                if (emblaApi) {
                    if (scrollDirection === 'next') {
                        if (
                            emblaApi.selectedScrollSnap() ===
                            scrollSnaps.length - 1
                        ) {
                            setScrollDirection('prev');
                            emblaApi.scrollPrev();
                        } else {
                            emblaApi.scrollNext();
                        }
                    } else {
                        if (emblaApi.selectedScrollSnap() === 0) {
                            setScrollDirection('next');
                            emblaApi.scrollNext();
                        } else {
                            emblaApi.scrollPrev();
                        }
                    }
                }
            }, intervalTime);

            intervalRef.current = interval;

            return () => {
                if (intervalRef.current) {
                    clearInterval(intervalRef.current);
                }
            };
        }
    }, [autoplay, emblaApi, scrollSnaps.length, scrollDirection, intervalTime]);
    return (
        <section className='relative'>
            <div
                className={twMerge('overflow-hidden', className)}
                ref={forwardedRef}
            >
                <div
                    className='flex h-full'
                    style={{
                        marginBottom: extraMB,
                        paddingLeft: extraPL,
                    }}
                >
                    {slides.map((slide, index) => (
                        <div
                            className={twMerge(
                                'flex h-full w-full flex-none items-center justify-center',
                                slideWidth && `w-[${slideWidth}px]`
                            )}
                            key={`slide${index}`}
                        >
                            {slide}
                        </div>
                    ))}
                </div>
            </div>
            {fakeDots && (
                <div className='my-2 flex justify-center gap-2 '>
                    {slides.map((_, index) => (
                        <div
                            className={`h-[6px] w-[6px] rounded-full ${
                                index !== selectedIndex
                                    ? 'bg-gray-400'
                                    : blackDot
                                      ? 'bg-black'
                                      : 'bg-primary'
                            }`}
                            key={`slide${index}`}
                        />
                    ))}
                </div>
            )}
            {!withoutDots && !fakeDots && (
                <div className='my-2 flex justify-center'>
                    <div className='flex space-x-4'>
                        {scrollSnaps.map((_, index) => (
                            <DotButton
                                key={index}
                                aria-label={`${index}-dot`}
                                onClick={() => onDotButtonClick(index)}
                                className={`h-[6px] w-[6px] cursor-pointer rounded-full sm:h-2 sm:w-2 ${
                                    index !== selectedIndex
                                        ? 'bg-gray-400'
                                        : blackDot
                                          ? 'bg-black'
                                          : 'bg-primary'
                                }`}
                            />
                        ))}
                    </div>
                </div>
            )}
        </section>
    );
};

export default EmblaCarousel;
