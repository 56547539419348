export type Discoverable = {
  discovery_actual_at?: number;
};

const onlineIntervalMillis = 10 * 60000; //10 minutes

export function isUserOnline(
  {discovery_actual_at}: Discoverable,
  now: number,
): boolean {
  return (
    discovery_actual_at !== undefined &&
    Math.abs(discovery_actual_at - now) < onlineIntervalMillis
  );
}

const recentIntervalMillis = 24 * 60 * 60000; //1 day

export function isUserRecent(
  {discovery_actual_at}: Discoverable,
  now: number,
): boolean {
  return Math.abs(discovery_actual_at! - now) < recentIntervalMillis;
}
