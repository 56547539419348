import React from 'react';
import { twMerge } from 'tailwind-merge';

import MailIcon from '@/assets/icons/mail.svg?react';
import { useScreenDetector } from '@/hooks/useScreenDetector';
import { getMediaUrl } from '@/utils/getMediaUrl';
import { scaleFont, scaleSize } from '@/utils/scaleSizeAndFont';
import LogoWhiteIcon from '@assets/icons/logo-white.svg?react';
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import {
    AUTH_EVENTS,
    analyticsEvent,
    trackDefaultRegistrationEvent,
} from '@shared/services/analytics';
import { AppRoutes } from '@utils/routes.ts';

interface AuthSectionProps {
    handleSignInWithEmail: () => void;
    googleLogin: (response: CredentialResponse) => Promise<void>;
}

const AuthSection: React.FC<AuthSectionProps> = ({
    handleSignInWithEmail,
    googleLogin,
}) => {
    const { isMobile } = useScreenDetector();

    const imageSrc = isMobile
        ? getMediaUrl('landing/top_mob.webp')
        : getMediaUrl('landing/top_desk.webp');

    const handleTrackSignByGoogle = () => {
        analyticsEvent(AUTH_EVENTS.SIGN_IN_WITH_GOOGLE_PRESSED);
        trackDefaultRegistrationEvent(AUTH_EVENTS.SIGN_IN_WITH_GOOGLE_PRESSED);
    };

    return (
        <div className='relative h-screen w-screen'>
            <div
                className={twMerge(
                    'absolute top-0 left-0 h-full w-full bg-cover bg-top transition-opacity'
                )}
                style={{
                    backgroundImage: `url(${imageSrc})`,
                }}
            />

            <div className='relative z-10 flex h-full select-none flex-col items-center justify-around pb-10 text-center text-secondary'>
                <LogoWhiteIcon
                    className='absolute top-0 left-4 sm:top-4 sm:left-11'
                    width={scaleSize(isMobile ? 72 : 120)}
                    fill='white'
                />
                <h1
                    className='mt-[30vh] flex justify-center text-center font-bold text-secondary'
                    style={{
                        fontSize: scaleFont(isMobile ? 36 : 72),
                        lineHeight: `${scaleFont(isMobile ? 42 : 90)}px`,
                        textShadow:
                            '2px 2px 5px rgba(0, 0, 0, 0.3), -2px -2px 5px rgba(0, 0, 0, 0.2)',
                    }}
                >
                    Find your <br /> international <br /> soulmate
                </h1>
                <div>
                    <div className='relative flex w-full justify-center'>
                        <div
                            style={{
                                fontSize: scaleFont(isMobile ? 12 : 14),
                            }}
                        >
                            By continuing, you agree to the
                            <a
                                href={AppRoutes.TermsOfService}
                                className='ml-1 cursor-pointer font-normal text-white hover:text-white'
                                target='_blank'
                                rel='noreferrer'
                            >
                                Terms of Service
                            </a>
                            ,
                            <div>
                                <a
                                    href={AppRoutes.PrivacyPolicy}
                                    className='mr-1 ml-1 cursor-pointer font-normal text-white hover:text-white'
                                    target='_blank'
                                    rel='noreferrer'
                                >
                                    Privacy Policy
                                </a>
                                and
                                <a
                                    href='/community-guidelines'
                                    className='mr-1 ml-1 cursor-pointer font-normal text-white hover:text-white'
                                    target='_blank'
                                    rel='noreferrer'
                                >
                                    Community Guidelines
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className='mt-7 flex flex-col items-center gap-4'>
                        <div className='flex h-[50px] w-[80vw] max-w-[400px] items-center overflow-hidden rounded-lg bg-white p-2 md:w-[96vw]'>
                            <div
                                className='w-full'
                                onClick={handleTrackSignByGoogle}
                            >
                                <GoogleLogin
                                    onSuccess={googleLogin}
                                    onError={() =>
                                        console.log('Google Login Failed')
                                    }
                                    width={Math.min(
                                        Math.ceil(window.innerWidth * 0.76),
                                        388
                                    )}
                                    ux_mode='popup'
                                    text='continue_with'
                                    logo_alignment='center'
                                />
                            </div>
                        </div>
                        <button
                            className='flex h-[50px] w-[80vw] max-w-[400px] items-center justify-center rounded-lg border border-gray-300 bg-transparent px-4 py-3 font-bold text-secondary shadow-sm md:w-[96vw]'
                            onClick={handleSignInWithEmail}
                        >
                            <span className='mr-4'>
                                <MailIcon width={20} height={20} fill='white' />
                            </span>{' '}
                            Use e-mail
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AuthSection;
