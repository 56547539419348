import MultiSelectItem from '@/components/common/MultiSelecetItem';
import { Interests } from '@/domain';
import { useScreenDetector } from '@/hooks/useScreenDetector';
import { interestsNames } from '@/utils/interestsNames';
import { scaleFont, scaleSize } from '@/utils/scaleSizeAndFont';
import React, { useState } from 'react';
import { twMerge } from 'tailwind-merge';

interface MultiSelectEditProps {
    title: string;
    items: Interests[];
    selectedItems: Interests[];
    setSelectedItems: React.Dispatch<React.SetStateAction<Interests[]>>;
    counter: number;
    limit: number;
    isUserDataFlow?: boolean;
}

const MultiSelect: React.FC<MultiSelectEditProps> = ({
    title,
    items,
    selectedItems,
    setSelectedItems,
    counter,
    limit,
    isUserDataFlow = false,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const { isMobile } = useScreenDetector();

    const handleItemClick = (item: Interests) => {
        setSelectedItems((prevSelectedItems) => {
            if (prevSelectedItems.includes(item)) {
                return prevSelectedItems.filter((i) => i !== item);
            }
            if (counter < limit) {
                return [...prevSelectedItems, item];
            }
            return prevSelectedItems;
        });
    };

    const handleContainerClick = () => {
        setIsOpen(!isOpen);
    };

    const displayedItems = isOpen ? items : items.slice(0, 6);

    return (
        <div
            className={twMerge(
                'relative flex w-full items-center self-center rounded-lg bg-white p-2 text-black',
                isUserDataFlow && 'bg-transparent p-0 py-2 text-white'
            )}
        >
            <div className='flex w-full flex-col'>
                <h2
                    className={' font-semibold'}
                    style={{
                        fontSize: `${isMobile ? scaleFont(18) : 20}px`,
                        marginBottom: `${scaleSize(16)}px`,
                        marginTop: `${scaleSize(19)}px`,
                    }}
                >
                    {title}
                </h2>
                <div className='flex flex-wrap gap-1 transition-all duration-500 ease-in-out'>
                    {displayedItems.map((item) => (
                        <MultiSelectItem
                            key={item}
                            item={interestsNames[item as Interests]}
                            isSelected={selectedItems.includes(item)}
                            onClick={() => handleItemClick(item)}
                            isUserDataFlow={isUserDataFlow}
                        />
                    ))}
                </div>
                {items.length > 6 && (
                    <button
                        onClick={handleContainerClick}
                        className='font-bold'
                        style={{
                            fontSize: `${scaleFont(14)}px`,
                            marginTop: scaleSize(20),
                            marginBottom: scaleSize(20),
                        }}
                    >
                        {isOpen ? 'Show less' : 'Show more'}
                    </button>
                )}
            </div>
        </div>
    );
};

export default MultiSelect;
