export const editImageSrc = (src: string, size: 'small' | 'tiny') => {
    const extensionIndex = src.lastIndexOf('.');
    if (extensionIndex === -1) return src;

    const prefix = src.substring(0, extensionIndex);
    const suffix = src.substring(extensionIndex);

    if (prefix.endsWith('_small') || prefix.endsWith('_tiny')) {
        return src;
    }

    return `${prefix}_${size}${suffix}`;
};
