import { DEBUG } from '@shared/config';
import {
    logCustomEvent as facebookLogCustomEvent,
    logEvent as facebookLogEvent,
} from '@shared/lib/facebook';
import { logEvent as firebaseLogEvent } from '@shared/lib/firebase';

export function analyticsEvent(
    name: string,
    params?: { [name: string]: string }
): void {
    if (DEBUG) {
        console.log('Analytics event:', name);
    }

    firebaseLogEvent(name, params);
    facebookLogCustomEvent(name, params);
}

export function analyticsScreen(componentName: string): void {
    firebaseLogEvent(`screen_${componentName}`, {
        screen_name: componentName,
        screen_class: componentName,
    });
}

export function analyticsSignIn(method: string): void {
    firebaseLogEvent('sign_in', { method });
    facebookLogCustomEvent('sign_in', { method });
}

export function analyticsSignUp(method: string): void {
    firebaseLogEvent('sign_up', { method });
    facebookLogCustomEvent('sign_up', { method });
}

export function analyticsPurchase(
    price: number,
    id: number,
    sku: string
): void {
    firebaseLogEvent(`purchase_${sku}`, {
        value: price,
        items: [
            {
                sku: sku,
                item_id: id,
            },
        ],
    });
    facebookLogEvent('Purchase', {
        currency: 'USD',
        value: price,
        items: [
            {
                sku: sku,
                item_id: id,
            },
        ],
    });
}
