import React, { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import CircularProgress from './CircularProgress';

interface CircularLoaderProps {
    percentage: number;
    size: number;
    strokeWidth: number;
    children: React.ReactNode;
    hidePercentage?: boolean;
    notLoading?: boolean;
    fromDiscovery?: boolean;
}

const CircularLoader: React.FC<CircularLoaderProps> = ({
    percentage,
    size,
    strokeWidth,
    children,
    hidePercentage = false,
    notLoading = false,
    fromDiscovery = false,
}) => {
    const [currentPercent, setCurrentPercent] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentPercent((prev) => {
                if (prev >= percentage) {
                    clearInterval(intervalId);
                    return percentage;
                }
                return prev + 1;
            });
        }, 50);
        return () => clearInterval(intervalId);
    }, [percentage]);

    const progressColor = fromDiscovery
        ? '#fbde66'
        : percentage >= 75
          ? 'url(#gradient)'
          : '#51aed4';

    return (
        <div className='relative ' style={{ width: size, height: size }}>
            <CircularProgress
                percentage={currentPercent}
                size={size}
                strokeWidth={strokeWidth}
                bgcolor='#f3f3f3'
                color={progressColor}
                notLoading={notLoading}
                fromDiscovery={fromDiscovery}
            />
            <div className='flex items-center justify-center'>
                <div className='-translate-y-[50%] -translate-x-[50%] absolute top-[50%] left-[50%]'>
                    {children}
                </div>
                {!hidePercentage && (
                    <div
                        className={twMerge(
                            'absolute bottom-[-3%] rounded-xl bg-primary pr-2 pl-2 font-bold text-white text-xs',
                            currentPercent === 0 && 'bg-bgGray text-black',
                            percentage >= 75 && 'bg-gold-gradient text-black'
                        )}
                    >
                        {currentPercent}%
                    </div>
                )}
            </div>
        </div>
    );
};

export default CircularLoader;
