export const scrollToBottom = (
    containerRef: React.RefObject<HTMLDivElement>
) => {
    if (containerRef.current) {
        containerRef.current.scrollTo({
            top:
                containerRef.current.scrollHeight +
                containerRef.current.clientHeight,
            behavior: 'smooth',
        });
    }
};
