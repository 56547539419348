import { EmblaCarouselType } from 'embla-carousel';

type UsePrevNextButtonsType = {
    onPrevButtonClick: () => void;
    onNextButtonClick: () => void;
};

export const usePrevNextButtons = (
    emblaApi: EmblaCarouselType | undefined
): UsePrevNextButtonsType => {
    const onPrevButtonClick = () => {
        if (!emblaApi) return;
        emblaApi.scrollPrev();
    };

    const onNextButtonClick = () => {
        if (!emblaApi) return;
        emblaApi.scrollNext();
    };

    return {
        onPrevButtonClick,
        onNextButtonClick,
    };
};
