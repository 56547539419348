import { PRODUCTION_MODE } from './consts';
import { getEnvVar } from './util';

export { FIREBASE_CONFIG } from './firebaseConfig';

export const DEBUG = getEnvVar('MODE', PRODUCTION_MODE) !== PRODUCTION_MODE;

export const BUILD_ID = getEnvVar('VITE_BUILD_ID', import.meta.env.MODE);

export const FIREBASE_VAPID_KEY =
    'BE4Ka_ZuikcAcZmeTX2WrXXGj0c02D_dVwoeBhXZiM0vOn8pZvWym7XkQH4gjM6WNo-6wVHMrXb18HSqsc5x7gg';

export const FACEBOOK_PIXEL_ID = getEnvVar(
    'FACEBOOK_PIXEL_ID',
    '777996777182719'
);
