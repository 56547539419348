import HeartsIcon from '@/assets/icons/hearts-icon.svg?react';
import UserDataFlowLayout from '@/components/layouts/UserDataFlowLayout';
import { useScreenDetector } from '@/hooks/useScreenDetector';
import { scaleFont, scaleSize } from '@/utils/scaleSizeAndFont';
import { UserInfoStepProps } from './userInfoConfig';

const BuildProfile: React.FC<UserInfoStepProps> = ({ onNext }) => {
    const { isMobile } = useScreenDetector();
    return (
        <UserDataFlowLayout>
            <div className='flex h-full w-full flex-col items-center justify-between text-white'>
                <div className='flex h-[70vh] flex-col items-center justify-evenly text-white'>
                    <HeartsIcon
                        width={scaleSize(150)}
                        height={scaleSize(150)}
                    />
                    <h2
                        className='w-[300px] text-center font-extrabold md:w-[500px] '
                        style={{
                            fontSize: `${isMobile ? scaleFont(22) : 36}px`,
                        }}
                    >
                        You're here to find your international soulmate!
                    </h2>
                    <div className='mb-16 flex flex-col items-center'>
                        <p
                            className='max-w-[500px] text-center'
                            style={{
                                marginTop: `${scaleSize(12)}px`,
                                fontSize: `${isMobile ? scaleFont(17) : 20}px`,
                                lineHeight: `${scaleFont(22)}px`,
                            }}
                        >
                            Wonderful. Spending time on your profile helps you
                            meet someone very special.
                        </p>
                    </div>
                </div>
                <button
                    type='button'
                    onClick={() => onNext()}
                    className='h-[50px] w-full max-w-96 rounded-lg bg-white font-bold text-black'
                    style={{
                        marginBottom: `${scaleSize(20)}px`,
                    }}
                >
                    Build my profle
                </button>
            </div>
        </UserDataFlowLayout>
    );
};

export default BuildProfile;
