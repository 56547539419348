import MaritalStatus from '@/assets/icons/marital-status.svg?react';
import { UserMaritalStatus } from '@/domain';
import { getEnumOptions } from '@/utils/getEnumOptions';
import React from 'react';
import SingleSelectStep from '../../common/SingleSelectStep';
import UserDataFlowLayout from '../../layouts/UserDataFlowLayout';
import { UserInfoStepProps } from './userInfoConfig';

const MaritallStatusStep: React.FC<UserInfoStepProps> = ({ onNext }) => {
    return (
        <UserDataFlowLayout withBarProgress>
            <div className='-mb-[5px] h-[5px] w-full bg-white/50'>
                <div className='h-[5px] w-[50%] bg-white' />
            </div>
            <SingleSelectStep
                LogoComponent={MaritalStatus}
                title='What your marital status?'
                options={getEnumOptions(UserMaritalStatus)}
                withSkip
                onNext={onNext}
                field='marital_status'
                fromEdit={false}
            />
        </UserDataFlowLayout>
    );
};

export default MaritallStatusStep;
