import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import PencilIcon from '@/assets/icons/pencil.svg?react';
import { WebMatchMessage } from '@/entities/match';
import { copyToClipboard } from '@/utils/copyToClipboard';
import { downloadImage } from '@/utils/downloadImage';
import { LOCAL_STORAGE } from '@/utils/localStorage';
import { AppRoutes } from '@/utils/routes';
import { deleteChatMessage } from '@/workers/sharedWorkerInstance';
import Bin from '@assets/icons/bin.svg?react';
import Copy from '@assets/icons/copy-icon.svg?react';
import CopyTranslate from '@assets/icons/copy-translate.svg?react';
import Report from '@assets/icons/report.svg?react';
import Save from '@assets/icons/save-icon.svg?react';
import ViewOriginal from '@assets/icons/view-original.svg?react';
import { analyticsEvent } from '@shared/services/analytics';

interface ModalProps {
    isOpen: boolean;
    modalPosition: {
        left: boolean | null;
        bottom: boolean | null;
        value: number;
    };
    isMyMessage?: boolean;
    setHoldModal: (value: boolean) => void;
    setEditMessage: (value: WebMatchMessage) => void;
    handleTranslate: any;
    message: WebMatchMessage;
    isChatClosed: boolean;
    isTranslateMessage: boolean;
    blur: boolean;
    fileToken: string;
    selectedChatId: number;
}

const ChatsMessageModal: React.FC<ModalProps> = ({
    isOpen,
    modalPosition,
    isMyMessage,
    setHoldModal,
    setEditMessage,
    handleTranslate,
    message,
    isChatClosed,
    isTranslateMessage,
    blur,
    fileToken,
    selectedChatId,
}) => {
    const navigate = useNavigate();
    const token = localStorage.getItem(LOCAL_STORAGE.AuthToken) || '';
    const actions = [
        {
            title: 'Copy',
            icon: <Copy height={18} />,
            event: () => {
                if (message.content) {
                    copyToClipboard(message.content);
                }
                setHoldModal(false);
            },
        },
        {
            title: 'Edit',
            icon: <PencilIcon height={18} width={18} fill='#3F3F3F' />,
            event: async () => {
                setEditMessage(message);
                setHoldModal(false);
            },
        },
        {
            title: 'Save image',
            icon: <Save height={18} width={18} />,
            event: async () => {
                if (message.file_url) {
                    await downloadImage(message.file_url, fileToken);
                }
                setHoldModal(false);
            },
        },
        {
            title: 'Delete',
            icon: <Bin height={32} width={18} />,
            event: async () => {
                if (!token) {
                    return;
                }
                if (message.file_url) {
                    message.isLoading = true;
                }
                try {
                    await deleteChatMessage(message.id, selectedChatId);
                    analyticsEvent('DeleteMessage', { unsent: '0' });
                } catch (error) {
                    console.error(error);
                }
                setHoldModal(false);
            },
        },
        {
            title: 'Copy original',
            icon: <Copy />,
            event: async () => {
                if (message.content) {
                    copyToClipboard(message.content);
                }
                setHoldModal(false);
            },
        },
        {
            title: 'Copy translation',
            icon: <CopyTranslate />,
            event: async () => {
                if (message.content_tr) {
                    copyToClipboard(message.content_tr);
                }
                setHoldModal(false);
            },
        },
        {
            title: 'Show original',
            icon: <ViewOriginal />,
            event: async () => {
                handleTranslate();
                setHoldModal(false);
            },
        },
        {
            title: 'Translate',
            icon: <ViewOriginal />,
            event: async () => {
                handleTranslate();
                setHoldModal(false);
            },
        },
        {
            title: 'Report',
            icon: <Report />,
            event: async () => {
                navigate(`${AppRoutes.Report}/${selectedChatId}/${message.id}`);
                setHoldModal(false);
            },
        },
    ];

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    const filteredActions = useMemo(
        () =>
            actions.filter((action) => {
                if (isMyMessage) {
                    if (message.file_url) {
                        if (blur) {
                            return;
                        }
                        return (
                            action.title === 'Save image' ||
                            (!isChatClosed && action.title === 'Delete') ||
                            (message.content && action.title === 'Copy') ||
                            (!isChatClosed &&
                                message.content &&
                                action.title === 'Edit')
                        );
                    }
                    return (
                        action.title === 'Copy' ||
                        (!isChatClosed && action.title === 'Edit') ||
                        (!isChatClosed && action.title === 'Delete')
                    );
                }
                if (message.file_url) {
                    if (blur) {
                        return message.content && action.title === 'Copy';
                    }
                    return (
                        action.title === 'Save image' ||
                        (!isChatClosed && action.title === 'Report') ||
                        (message.content &&
                            !isTranslateMessage &&
                            action.title === 'Copy') ||
                        (message.content_tr &&
                            isTranslateMessage &&
                            action.title === 'Copy original') ||
                        (message.content_tr &&
                            isTranslateMessage &&
                            action.title === 'Copy translation')
                    );
                }
                if (message.content_tr && isTranslateMessage) {
                    return (
                        action.title === 'Copy original' ||
                        action.title === 'Copy translation' ||
                        (!isChatClosed && action.title === 'Show original') ||
                        (!isChatClosed && action.title === 'Report')
                    );
                }
                return (
                    action.title === 'Copy' ||
                    (!isChatClosed && action.title === 'Translate') ||
                    (!isChatClosed && action.title === 'Report')
                );
            }),
        [blur, isTranslateMessage, message]
    );

    if (!isOpen) return null;

    return (
        <>
            <div
                className='fixed top-0 left-0 z-50 h-screen w-screen'
                onClick={() => setHoldModal(false)}
            />
            <ul
                className={twMerge(
                    'absolute z-50 flex w-40 flex-col overflow-hidden rounded bg-white shadow-xl',
                    modalPosition.left !== null
                        ? modalPosition.left
                            ? 'left-0 sm:left-[15%]'
                            : 'right-0 sm:right-[10%]'
                        : isMyMessage
                          ? 'right-0'
                          : ''
                )}
                style={{
                    ...(modalPosition.bottom === true
                        ? { top: `${modalPosition.value}px` }
                        : modalPosition.bottom === false
                          ? { bottom: `${modalPosition.value}px` }
                          : {}),
                }}
            >
                {filteredActions.map((action) => (
                    <li
                        key={action.title}
                        onClick={action.event}
                        className={twMerge(
                            'flex cursor-pointer items-center justify-between bg-white leading-0 hover:bg-slate-400',
                            action.title === 'Delete' ||
                                action.title === 'Report'
                                ? 'px-2 py-1 text-error'
                                : 'p-2'
                        )}
                    >
                        {action.title} {action.icon}
                    </li>
                ))}
            </ul>
        </>
    );
};

export default ChatsMessageModal;
