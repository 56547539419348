import ArrowRight from '@/assets/icons/arrow-right.svg?react';
import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import Button from '../common/Button/Button';

export interface ButtonProps {
    text: string;
    textColor: string;
    onClick?: () => void;
}

interface ProfilePreviewModalProps {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    buttons: ButtonProps[] | undefined;
    type?: string;
    className?: string;
    isRounded?: boolean;
    shadowCopy?: boolean;
}

const ProfilePreviewModal: React.FC<ProfilePreviewModalProps> = ({
    isOpen,
    setIsOpen,
    buttons,
    type,
    className,
    isRounded = false,
    shadowCopy = false,
}) => {
    const [isAnimating, setIsAnimating] = useState(false);
    const [isClosing, setIsClosing] = useState(false);

    const handleClose = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        if (isOpen) {
            setIsClosing(true);
            setIsAnimating(true);
        } else {
            setIsAnimating(false);
            const timeout = setTimeout(() => setIsClosing(false), 500);
            return () => clearTimeout(timeout);
        }
    }, [isOpen]);

    return (
        (isOpen || isClosing) && (
            <div
                onClick={handleClose}
                className={twMerge(
                    'absolute inset-0 z-[55] w-full justify-center overflow-y-hidden bg-gray-500 bg-opacity-50',
                    isRounded && 'rounded-ss-xl rounded-se-xl',
                    type === 'basic' && isRounded && 'my-[7vh] sm:my-[5vh]',
                    shadowCopy && 'opacity-0'
                )}
            >
                <div
                    className={twMerge(
                        'flex w-full flex-col justify-end',
                        className
                    )}
                >
                    <div
                        className={twMerge(
                            'flex w-full flex-col items-center gap-2'
                        )}
                    >
                        <div
                            className={twMerge(
                                'flex w-full flex-col items-center rounded-ss-2xl rounded-se-2xl bg-white transition-transform duration-500',
                                isAnimating ? 'translate-y-0' : 'translate-y-96'
                            )}
                        >
                            {type === 'basic' && (
                                <div className='px-4 py-2 text-center text-sm'>
                                    <p className='mb-1 font-bold text-icon'>
                                        Block this person
                                    </p>
                                    <span className=' text-grayIcon'>
                                        When you block someone, you won't see
                                        this person again and they won't see
                                        your profile - unless they've already
                                        seen your profile while swiping
                                    </span>
                                </div>
                            )}
                            {buttons
                                ? buttons.map((button, index) => (
                                      <Button
                                          key={index}
                                          className={`text-${button.textColor} w-full px-5 py-3 font-semibold`}
                                          onClick={button.onClick}
                                      >
                                          <div className='flex items-center justify-between'>
                                              <div className='flex items-center'>
                                                  {button.text}
                                              </div>
                                              <div>
                                                  <ArrowRight
                                                      width={20}
                                                      height={20}
                                                  />
                                              </div>
                                          </div>
                                      </Button>
                                  ))
                                : null}
                            <Button
                                className='w-full px-5 py-3 font-semibold'
                                onClick={() => ''}
                            >
                                <div className='flex items-center justify-between'>
                                    <div className='flex items-center'>
                                        Cancel
                                    </div>
                                    <div className='mr-[2px] text-[16px]'>
                                        &#x2715;
                                    </div>
                                </div>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    );
};

export default ProfilePreviewModal;
