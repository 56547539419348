import ArrowForfardFull from '@/assets/icons/arrow-forward-full.svg?react';
import Loader from '@/assets/icons/loading.svg?react';
import { useScreenDetector } from '@/hooks/useScreenDetector';
import { scaleSize } from '@/utils/scaleSizeAndFont';
import React from 'react';
import { twMerge } from 'tailwind-merge';

interface NextButtonProps {
    disabled: boolean;
    onNext: () => void;
    withToolTip?: boolean;
    isLoading?: boolean;
    inEditModal?: boolean;
}

const NextButton: React.FC<NextButtonProps> = ({
    disabled,
    onNext,
    withToolTip,
    isLoading,
    inEditModal,
}) => {
    const { isMobile } = useScreenDetector();
    return (
        <button
            className={twMerge(
                'absolute right-8 bottom-10 flex items-center justify-center rounded-full bg-bgGray text-secondary shadow-sm',
                withToolTip && 'bottom-20',
                disabled && 'opacity-50',
                isLoading && 'cursor-default p-0',
                inEditModal && 'bg-primary/35'
            )}
            style={{
                width: `${isMobile ? scaleSize(60) : 80}px`,
                height: `${isMobile ? scaleSize(60) : 80}px`,
            }}
            onClick={onNext}
            disabled={disabled || isLoading}
        >
            {isLoading ? (
                <div className='flex h-[40px] w-[40px] items-center justify-center'>
                    <Loader width={scaleSize(50)} height={scaleSize(50)} />
                </div>
            ) : (
                <ArrowForfardFull
                    width={scaleSize(28)}
                    height={scaleSize(28)}
                />
            )}
        </button>
    );
};

export default React.memo(NextButton);
