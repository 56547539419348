import { scaleFont, scaleSize } from '@/utils/scaleSizeAndFont';
import React from 'react';

interface RegisterCardProps {
    image: string;
    title: string;
    description: string;
}

const RegisterCard: React.FC<RegisterCardProps> = ({
    image,
    title,
    description,
}) => {
    return (
        <div
            className='flex w-full flex-col items-center justify-center text-center'
            style={{
                paddingTop: scaleSize(32),
                paddingBottom: scaleSize(32),
                paddingLeft: scaleSize(16),
                paddingRight: scaleSize(16),
                gap: scaleSize(20),
            }}
        >
            <img
                src={image}
                width={scaleSize(150)}
                height={scaleSize(150)}
                alt='advantage_image'
                loading='lazy'
            />
            <div
                className='font-semibold text-primary'
                style={{
                    fontSize: scaleFont(20),
                }}
            >
                {title}
            </div>
            <div
                style={{
                    fontSize: scaleFont(16),
                }}
            >
                {description}
            </div>
        </div>
    );
};

export default RegisterCard;
