import { scaleFont, scaleSize } from '@/utils/scaleSizeAndFont';
import React from 'react';
import { twMerge } from 'tailwind-merge';
import TextInput from '../TextInput/TextInput';

interface BirthdayInputsProps {
    day: string;
    month: string;
    year: string;
    handleDayChange: (value: string) => void;
    handleMonthChange: (value: string) => void;
    handleYearChange: (value: string) => void;
    handleFocus?: () => void;
    handleNoFocus?: () => void;
    showTooltip: boolean;
    isValidBirthdayField: boolean;
    dayInputRef: React.RefObject<HTMLInputElement>;
    monthInputRef: React.RefObject<HTMLInputElement>;
    yearInputRef: React.RefObject<HTMLInputElement>;
    handleKeyDown: (
        value: string,
        e: React.KeyboardEvent<HTMLInputElement>,
        nextRef: React.RefObject<HTMLInputElement>
    ) => void;
    handleKeyUp: (
        value: string,
        e: React.KeyboardEvent<HTMLInputElement>,
        nextRef: React.RefObject<HTMLInputElement>
    ) => void;
    focus?: boolean;
    whiteTips?: boolean;
    withBorder?: boolean;
    disabled?: boolean;
}
const BirthdayInputs: React.FC<BirthdayInputsProps> = ({
    day,
    month,
    year,
    handleDayChange,
    handleMonthChange,
    handleYearChange,
    handleFocus,
    handleNoFocus,
    isValidBirthdayField,
    dayInputRef,
    monthInputRef,
    yearInputRef,
    handleKeyDown,
    handleKeyUp,
    focus = false,
    whiteTips = false,
    withBorder = false,
    disabled = false,
}) => {
    return (
        <div className='flex max-w-md flex-row gap-3'>
            <div className='basis-3/12'>
                <TextInput
                    className={twMerge(
                        'w-full rounded-xl border-stroke bg-white p-3 text-center font-bold text-[20px] text-black focus:outline-none',
                        focus && 'border border-primary',
                        withBorder && 'border',
                        year.length === 4 &&
                            !isValidBirthdayField &&
                            'text-red-600'
                    )}
                    name='day'
                    type='text'
                    inputMode='numeric'
                    value={day || ''}
                    pattern='[0-9]*'
                    onChange={(e) => handleDayChange(e.target.value)}
                    placeholder='DD'
                    minLength={1}
                    maxLength={2}
                    onFocus={handleFocus}
                    onBlur={handleNoFocus}
                    ref={dayInputRef}
                    onKeyUp={(e) => handleKeyUp(day, e, monthInputRef)}
                    disabled={disabled}
                    style={{
                        height: `${scaleSize(50)}px`,
                        paddingLeft: `${scaleSize(14)}px`,
                        paddingTop: `${scaleSize(12)}px`,
                        paddingBottom: `${scaleSize(12)}px`,
                        fontSize: `${scaleFont(18)}px`,
                    }}
                />
                <p
                    className={twMerge(
                        'mt-1 text-xs',
                        whiteTips && 'text-white'
                    )}
                    style={{
                        marginLeft: `${scaleSize(10)}px`,
                        fontSize: `${scaleFont(12)}px`,
                    }}
                >
                    Day
                </p>
            </div>
            <div className='basis-3/12'>
                <TextInput
                    className={twMerge(
                        'w-full rounded-xl border-stroke bg-white p-3 text-center font-bold text-[20px] text-black focus:outline-none',
                        focus && 'border border-primary',
                        withBorder && 'border',
                        year.length === 4 &&
                            !isValidBirthdayField &&
                            'text-red-600'
                    )}
                    name='month'
                    type='text'
                    inputMode='numeric'
                    value={month || ''}
                    pattern='[0-9]*'
                    onChange={(e) => handleMonthChange(e.target.value)}
                    placeholder='MM'
                    minLength={1}
                    maxLength={2}
                    onFocus={handleFocus}
                    onBlur={handleNoFocus}
                    onKeyDown={(e) => handleKeyDown(month, e, dayInputRef)}
                    onKeyUp={(e) => handleKeyUp(month, e, yearInputRef)}
                    ref={monthInputRef}
                    disabled={disabled}
                    style={{
                        height: `${scaleSize(50)}px`,
                        paddingLeft: `${scaleSize(14)}px`,
                        paddingTop: `${scaleSize(12)}px`,
                        paddingBottom: `${scaleSize(12)}px`,
                        fontSize: `${scaleFont(18)}px`,
                    }}
                />
                <p
                    className={twMerge(
                        'mt-1 text-xs',
                        whiteTips && 'text-white'
                    )}
                    style={{
                        marginLeft: `${scaleSize(10)}px`,
                        fontSize: `${scaleFont(12)}px`,
                    }}
                >
                    Month
                </p>
            </div>
            <div className='relative basis-6/12'>
                <TextInput
                    className={twMerge(
                        'w-full rounded-xl border-stroke bg-white p-3 text-center font-bold text-[20px] text-black focus:outline-none',
                        focus && 'border border-primary',
                        withBorder && 'border',
                        year.length === 4 &&
                            !isValidBirthdayField &&
                            'text-red-600'
                    )}
                    name='year'
                    type='text'
                    inputMode='numeric'
                    value={year || ''}
                    pattern='[0-9]*'
                    onChange={(e) => handleYearChange(e.target.value)}
                    placeholder='YYYY'
                    minLength={4}
                    maxLength={4}
                    onFocus={handleFocus}
                    onBlur={handleNoFocus}
                    onKeyDown={(e) => {
                        handleKeyDown(year, e, monthInputRef);
                        if (
                            e.key === 'Enter' &&
                            yearInputRef &&
                            yearInputRef.current
                        ) {
                            yearInputRef.current.blur();
                        }
                    }}
                    ref={yearInputRef}
                    disabled={disabled}
                    style={{
                        height: `${scaleSize(50)}px`,
                        paddingLeft: `${scaleSize(14)}px`,
                        paddingTop: `${scaleSize(12)}px`,
                        paddingBottom: `${scaleSize(12)}px`,
                        fontSize: `${scaleFont(18)}px`,
                    }}
                />
                <p
                    className={twMerge(
                        'mt-1 text-xs',
                        whiteTips && 'text-white'
                    )}
                    style={{
                        marginLeft: `${scaleSize(10)}px`,
                        fontSize: `${scaleFont(12)}px`,
                    }}
                >
                    Year
                </p>
            </div>
        </div>
    );
};

export default BirthdayInputs;
