import EarthIcon from '@/assets/icons/earth-icon.svg?react';
import RedHeart from '@/assets/icons/heart-red.svg?react';
import Shield from '@/assets/icons/shield.svg?react';
import MultiSelectItem from '@/components/common/MultiSelecetItem';
import { isUserOnline, unpackUserInterests } from '@/domain';
import userStore from '@/entities/user/store';
import { useScreenDetector } from '@/hooks/useScreenDetector';
import { interestsNames } from '@/utils/interestsNames';
import { scaleFont, scaleSize } from '@/utils/scaleSizeAndFont';
import { useEffect, useRef, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import 'react-loading-skeleton/dist/skeleton.css';
import { handleGoToPayWall } from '@/utils/handleGoToPayWall';

export const TWELWE_HOURS = 1000 * 60 * 60 * 12;

const LikedUserCard: React.FC<{
    user?: any;
    users?: any;
    withoutMX?: boolean;
    setSelectedUser?: (arg: any) => void;
}> = ({ user, users, withoutMX = false, setSelectedUser }) => {
    const cardRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    const { isMobile } = useScreenDetector();
    const [width, setWidth] = useState<number>(0);
    const [showSkeleton, setShowSkeleton] = useState<boolean>(true);

    const visibleUser = users?.users?.filter(
        (us: any) => us?.id === user?.user_id
    )[0];
    const interests: number[] = unpackUserInterests(
        userStore.home?.me?.user || {}
    );
    const userInterests = unpackUserInterests(user?.user || visibleUser || {});
    const filteredInterests = userInterests.filter((interest: number) =>
        interests?.includes(interest)
    );
    const isOnline =
        isUserOnline(user?.user || visibleUser || 0, Date.now()) || false;
    const isVerified = user?.user?.verified || visibleUser?.verified;
    const maxNameChars =
        Math.floor((width - 45 - (16 * 0.6 * 4) / (16 * 0.6)) / 9.6) -
        2 -
        (isOnline ? 2 : 0) -
        (isVerified ? 2 : 0);
    const userName =
        visibleUser && visibleUser?.first_name?.length <= maxNameChars
            ? visibleUser?.first_name
            : `${visibleUser?.first_name.slice(0, maxNameChars)}...`;

    const placeholderWidth = user?.id % 100 > 30 ? user?.id % 100 : 100;

    const handleClick = () => {
        if (visibleUser && setSelectedUser) {
            setSelectedUser({
                list: user,
                user: visibleUser,
                type: 'basic',
            });
        } else {
            handleGoToPayWall(navigate, 0);
        }
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (cardRef.current) {
            setWidth(cardRef.current?.offsetWidth);
        }
    }, [cardRef.current?.offsetWidth]);

    if (!user) {
        return (
            <div
                ref={cardRef}
                className={twMerge(
                    '-mt-4 relative w-[48%] overflow-hidden rounded-xl sm:w-[340px]',
                    !withoutMX && 'mx-auto'
                )}
                style={{
                    height: `${width * 1.483}px`,
                }}
            >
                <Skeleton className='h-full w-full rounded-xl' />
            </div>
        );
    }

    return (
        <div
            ref={cardRef}
            className={twMerge(
                'relative w-[48%] overflow-hidden rounded-xl sm:w-[340px]',
                !withoutMX && 'mx-auto'
            )}
            style={{
                height: `${width * 1.483}px`,
            }}
        >
            {visibleUser && (
                <div className='absolute flex max-h-8 w-full justify-between p-2 sm:w-80 sm:p-4'>
                    {visibleUser.country_code ? (
                        <ReactCountryFlag
                            svg
                            alt={`${visibleUser?.country_code}`}
                            countryCode={`${visibleUser.country_code}`}
                            style={{
                                borderRadius: '50%',
                                width: `${isMobile ? 28 : 32}px`,
                                height: `${isMobile ? 28 : 32}px`,
                                objectFit: 'cover',
                                zIndex: 20,
                            }}
                        />
                    ) : (
                        <EarthIcon />
                    )}
                    {user.type === 'super_smash' && (
                        <RedHeart
                            className='h-8 w-8'
                            fill='#F15873'
                            stroke='white'
                        />
                    )}
                </div>
            )}
            <div className='-ml-1 absolute bottom-10 z-30 flex max-h-2 w-44 items-center gap-1 px-2 sm:mt-[395px] sm:w-80 sm:px-4'>
                {filteredInterests.length > 0 && (
                    <MultiSelectItem
                        key={filteredInterests[0]}
                        item={interestsNames[filteredInterests[0]]}
                        isSelected
                        onClick={() => null}
                        match
                        small
                        className='h-6 cursor-default border-0 px-2 sm:px-4'
                    />
                )}
            </div>
            <div
                className='gradient-shadow absolute bottom-0 z-30 flex h-8 items-center gap-1 rounded-b-xl px-2 pb-[5px] font-bold text-white sm:mt-[418px] sm:w-[340px] sm:px-4 sm:pb-[10px] sm:text-lg'
                style={{
                    width: `${width}px`,
                }}
            >
                {visibleUser ? (
                    <span
                        className='z-40 font-bold'
                        style={{
                            fontSize: `${isMobile ? scaleFont(14) : 16}px`,
                        }}
                    >
                        {isMobile ? userName : visibleUser?.first_name}
                        {visibleUser?.age && `, ${visibleUser?.age}`}
                    </span>
                ) : (
                    <span
                        className='z-40 rounded-xl bg-gray-300 blur-none'
                        style={{
                            height: `${isMobile ? scaleSize(8) : 12}px`,
                            width: `${placeholderWidth}px`,
                        }}
                    />
                )}
                {isVerified && (
                    <Shield
                        style={{
                            height: `${isMobile ? scaleSize(18) : 24}px`,
                            width: `${isMobile ? scaleSize(18) : 24}px`,
                        }}
                    />
                )}
                {isOnline && (
                    <div className='z-10 h-4 w-4 rounded-full border-2 border-white bg-lime-400' />
                )}
            </div>
            <div className='relative flex h-full w-full'>
                {showSkeleton && (
                    <Skeleton
                        className='absolute z-50'
                        height={width * 1.483}
                        width={width}
                    />
                )}
                {userStore.home?.pub_urls_query && (
                    <img
                        src={`${
                            user.user?.avatar_url ||
                            (visibleUser?.avatar_urls?.[0] ??
                                'default-avatar-url')
                        }?${userStore.home?.pub_urls_query}`}
                        alt='avatar'
                        className={twMerge(
                            'h-full w-full object-cover',
                            visibleUser ? '' : 'blur-xl',
                            showSkeleton ? 'opacity-0' : 'opacity-100'
                        )}
                        onClick={handleClick}
                        onLoad={() => setShowSkeleton(false)}
                        onContextMenu={(e) => e.preventDefault()}
                    />
                )}
            </div>
        </div>
    );
};

export default LikedUserCard;
