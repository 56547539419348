import { useEffect, useRef } from 'react';

import { sendToken } from '@/api/user';
import userStore from '@/entities/user/store';
import { LOCAL_STORAGE } from '@/utils/localStorage';
import { getToken, isSupportedMessaging } from '@shared/lib/firebase';

export function useGenerateFirebaseToken() {
    const initOnceRef = useRef(false);

    useEffect(() => {
        if (initOnceRef.current) {
            return;
        }

        initOnceRef.current = true;

        if (localStorage.getItem('fireToken')) {
            return;
        }

        (async () => {
            const firebaseToken = localStorage.getItem('fireToken');
            const supportedMessaging = await isSupportedMessaging();

            if (window.Cypress || firebaseToken || !supportedMessaging) {
                return;
            }

            if (Notification.permission !== 'granted') {
                console.warn('Notifications are not granted.');
                return;
            }

            try {
                const token = await getToken();
                const authToken = localStorage.getItem(LOCAL_STORAGE.AuthToken);

                if (token && authToken) {
                    await sendToken(
                        localStorage.getItem(LOCAL_STORAGE.AuthToken) || '',
                        token
                    );
                }
                localStorage.setItem('fireToken', token);
                userStore.setUserToken(token);
            } catch (error) {
                console.error(error);
            }
        })();
    }, []);
}
