export const downloadImage = async (url: string, fileToken: string) => {
    try {
        const response = await fetch(`${url}?${fileToken}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const blob = await response.blob();
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = url.split('/').pop() || 'downloaded_image';
        link.click();
        window.URL.revokeObjectURL(link.href);
    } catch (error) {
        console.error('Error downloading the image:', error);
    }
};
