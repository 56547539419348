import React, { useState } from 'react';

import BirthdayInputs from '@/components/common/BirthdayInputs/BirthdayInputs';
import Button from '@/components/common/Button/Button';
import Tooltip from '@/components/common/Tooltip';
import UserDataFlowLayout from '@/components/layouts/UserDataFlowLayout';
import useBirthdayStep from '@/hooks/useBirthdayStep';
import { scaleSize } from '@/utils/scaleSizeAndFont';
import { analyticsEvent, useAbRegistration } from '@shared/services/analytics';

import StepHeader from './StepHeader';
import { UserInfoStepProps } from './userInfoConfig';

const BirthdayStep: React.FC<UserInfoStepProps> = ({ onNext, isLite }) => {
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const { trackAbRegistrationEvent } = useAbRegistration();

    const {
        month,
        day,
        year,
        age,
        isValidBirthdayField,
        showTooltip,
        monthInputRef,
        dayInputRef,
        yearInputRef,
        fullFilled,
        handleMonthChange,
        handleDayChange,
        handleYearChange,
        handleKeyDown,
        handleKeyUp,
    } = useBirthdayStep();

    const handleNext = () => {
        if (isValidBirthdayField && age !== null) {
            setShowConfirmationModal(true);
            localStorage.setItem('age', age.toString());
            analyticsEvent('SignUp_Dob_Change');
            trackAbRegistrationEvent('SignUp_Dob_Change');
        }
    };

    const handleConfirmAge = () => {
        setShowConfirmationModal(false);
        onNext();
    };

    return (
        <UserDataFlowLayout
            disableNextButton={!isValidBirthdayField || !fullFilled}
            onNext={handleNext}
            withBarProgress
            isLoading={showConfirmationModal}
        >
            <div className='flex flex-col'>
                <StepHeader
                    title='Your date of birth'
                    description='Users will only see your age'
                    barProgress={isLite ? 60 : 30}
                />
                <div
                    className='px-4'
                    style={{
                        marginTop: `${scaleSize(30)}px`,
                    }}
                >
                    <BirthdayInputs
                        day={day}
                        month={month}
                        year={year}
                        handleDayChange={handleDayChange}
                        handleMonthChange={handleMonthChange}
                        handleYearChange={handleYearChange}
                        showTooltip={showTooltip}
                        isValidBirthdayField={isValidBirthdayField}
                        dayInputRef={dayInputRef}
                        monthInputRef={monthInputRef}
                        yearInputRef={yearInputRef}
                        handleKeyDown={handleKeyDown}
                        handleKeyUp={handleKeyUp}
                        whiteTips
                    />
                    {showTooltip && !isValidBirthdayField && (
                        <Tooltip message='Enter the correct date' />
                    )}
                </div>
            </div>

            {showConfirmationModal && (
                <div className='fixed top-0 left-0 z-[100] flex h-full w-full items-center justify-center bg-gray-500 bg-opacity-50'>
                    <div className='flex h-[200px] w-[300px] flex-col justify-around rounded-lg bg-white p-4 text-center shadow-lg md:w-[400px]'>
                        <p className='font-bold text-black text-lg'>
                            Are you {age} years old?
                        </p>
                        <p className='mt-2 text-gray-600 text-md'>
                            Make sure you have entered the correct age
                        </p>
                        <div className='mt-4 flex justify-center gap-4'>
                            <Button
                                variant='cancel'
                                label='Change'
                                onClick={() => setShowConfirmationModal(false)}
                            />
                            <Button
                                variant='primary'
                                label='Confirm'
                                onClick={handleConfirmAge}
                            />
                        </div>
                    </div>
                </div>
            )}
        </UserDataFlowLayout>
    );
};

export default BirthdayStep;
