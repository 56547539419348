import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import 'react-lazy-load-image-component/src/effects/blur.css';

import { getMatchUserData } from '@/api/user';
import Gender from '@/assets/icons/gender.svg?react';
import Language from '@/assets/icons/language.svg?react';
import Location from '@/assets/icons/location.svg?react';
import Passport from '@/assets/icons/passport.svg?react';
import School from '@/assets/icons/school.svg?react';
import Shield from '@/assets/icons/shield.svg?react';
import Work from '@/assets/icons/work.svg?react';
import MultiSelectItem from '@/components/common/MultiSelecetItem';
import {
    Ethnicities,
    Interests,
    Nationalities,
    SupportedLang,
    UserDetailed,
    unpackUserInterests,
} from '@/domain';
import matchesStore from '@/entities/match/store';
import userStore from '@/entities/user/store';
import { WebUserDetailed } from '@/entities/user/types';
import { useScreenDetector } from '@/hooks/useScreenDetector';
import { getDeviceType } from '@/utils/getDeviceType';
import { interestsNames } from '@/utils/interestsNames';
import { supportedLanguagesNames } from '@/utils/languageNames';
import { supportedCode2Languages } from '@/utils/languages';
import { unpackUserLanguages } from '@/utils/languages';
import { LOCAL_STORAGE, fetCitiesById } from '@/utils/localStorage';
import { nationalitiesNames, nationalityCodes } from '@/utils/nationalityNames';
import { AppRoutes } from '@/utils/routes';
import { scaleSize } from '@/utils/scaleSizeAndFont';
import { analyticsEvent } from '@shared/services/analytics';

import { goals } from '../auth/userInfoSteps/GoalStep';
import Button from '../common/Button/Button';
import ProfilePreviewActions from './ProfilePreviewActions';
import ProfilePreviewHeader from './ProfilePreviewHeader';
import UserProfileSection from './UserProfileSection';
import { StepConfig, stepConfigs } from './stepConfigs';

interface MyData {
    values?: number;
    height_sm?: number;
    weight_kg?: number;
    [key: string]: any;
}
const ProfilePreview: React.FC<{
    type: string;
    modal?: boolean;
    actionOnClick?: () => void;
    actionSize?: number;
    opacityVisible?: number;
    userData: UserDetailed | null;
    setIsOpenModalDots?: React.Dispatch<React.SetStateAction<boolean>>;
    setRenderedMainUser?: React.Dispatch<React.SetStateAction<boolean>>;
    headRef?: React.MutableRefObject<HTMLDivElement | null>;
    scrollY?: number;
    fromLikedYou?: boolean;
    source?: string;
    isClosed?: boolean;
}> = observer(
    ({
        modal,
        type,
        actionOnClick,
        actionSize,
        opacityVisible,
        userData,
        setIsOpenModalDots,
        setRenderedMainUser,
        headRef,
        scrollY,
        fromLikedYou = false,
        source,
        isClosed = false,
    }) => {
        const mainRef = useRef<HTMLDivElement | null>(null);
        const [user, setUser] = useState<WebUserDetailed | null>(null);
        const [myData, setMyData] = useState<MyData>({});
        const [isImageLoaded, setIsImageLoaded] = useState(false);
        const userAgent = navigator.userAgent.toLowerCase();
        const isSafari =
            userAgent.includes('safari') && !userAgent.includes('chrome');
        const notMe = userData?.nickname !== userStore?.userData?.nickname;
        const navigate = useNavigate();
        const isFetching = useRef(false);
        const handleLoadedImage = () => {
            setIsImageLoaded(true);
            if (setRenderedMainUser) {
                setRenderedMainUser(true);
            }
        };
        // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
        useEffect(() => {
            if (userStore?.userData) {
                setMyData(userStore?.userData);
            }
        }, [userStore.userData]);

        // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
        useEffect(() => {
            const fetchUserData = async () => {
                if (isFetching.current) return;
                isFetching.current = true;
                try {
                    const authToken =
                        localStorage.getItem(LOCAL_STORAGE.AuthToken) || '';
                    if (!authToken) {
                        console.error('Auth token is missing');
                        return;
                    }

                    if (type === 'myProfile') {
                        setUser(userStore.userData);
                        return;
                    }
                    if (!modal) {
                        setUser(userData);
                        return;
                    }
                    const usersData = await getMatchUserData(
                        authToken,
                        userData?.nickname || '',
                        source || 'match'
                    );
                    matchesStore.setNewMatch({
                        match: usersData.result.match,
                        user: usersData.result.user,
                    });
                    matchesStore.setSelectedChat(usersData.result.match);
                    setUser(usersData.result.user);
                } catch (error) {
                    console.error('Error fetching match user data:', error);
                } finally {
                    isFetching.current = false;
                }
            };
            fetchUserData();
        }, [userData?.nickname]);

        const [selectedInterests, setSelectedInterests] = useState<
            Interests[] | null
        >(null);
        const [selectedLanguages, setSelectedLanguages] = useState<
            SupportedLang[] | null
        >(null);
        const [selectedEthnicities, setSelectedEthnicities] = useState<
            Ethnicities[] | null
        >(null);
        const [selectedNationalities, setSelectedNationalities] = useState<
            Nationalities[] | null
        >(null);

        useEffect(() => {
            setSelectedInterests(
                user?.interests ? unpackUserInterests(user) : null
            );
            const languageCodesArray = Object.keys(supportedCode2Languages);
            const langIndex = languageCodesArray.indexOf(user?.lang || '');
            setSelectedLanguages(
                user?.languages
                    ? unpackUserLanguages(user)
                    : langIndex
                      ? [langIndex]
                      : null
            );
            setSelectedEthnicities(
                user?.ethnicities ? user?.ethnicities : null
            );
            setSelectedNationalities(
                user?.nationalities ? user?.nationalities : null
            );
        }, [user]);

        const userGoal = goals.find((item) => item?.value === user?.goal);
        const nationality = Object.keys(nationalityCodes).find((key) => {
            return (
                nationalityCodes[
                    key as unknown as keyof typeof nationalityCodes
                ] === user?.country_code?.toUpperCase()
            );
        }) as unknown as Nationalities | undefined;
        const modalWidth = headRef?.current?.clientWidth;
        const { isMobile } = useScreenDetector();
        const device = getDeviceType();

        if (!userData) {
            return;
        }

        return (
            <div className='h-full w-full' ref={mainRef}>
                {user?.avatar_urls ? (
                    <div
                        className={twMerge(
                            'relative mt-9 flex h-full flex-col items-end gap-6',
                            modal && 'mt-0 max-w-[500px] overflow-y-auto',
                            type === 'basic' && isSafari
                                ? 'mt-0 mb-16'
                                : 'mt-0 mb-8',
                            type === 'basic' && modal && 'mb-0 sm:mb-0',
                            !notMe && 'mb-16'
                        )}
                    >
                        {modal && type !== 'basic' && (
                            <div className='fixed top-[30vh] z-50 mr-2 h-24 w-1 rounded-xl bg-icon/30'>
                                <div
                                    className='h-7 w-1 rounded-xl bg-white'
                                    style={{
                                        transform: `translateY(${scrollY}px)`,
                                        transition: 'transform 0.1s ',
                                        boxShadow:
                                            '0 2px 4px rgba(0, 0, 0, 0.3)',
                                    }}
                                />
                            </div>
                        )}
                        <div className='relative flex w-fit flex-col'>
                            <div className='gradient-shadow-top -mt-[96px] absolute top-24 z-[50] h-[100px] w-full rounded-xl' />
                            {modalWidth && (
                                <ProfilePreviewHeader
                                    user={userData}
                                    modal={modal}
                                    opacityVisible={opacityVisible}
                                    width={modalWidth}
                                />
                            )}
                            {user?.avatar_urls && (
                                <div className='relative h-[700px] w-full'>
                                    {modal ? (
                                        <LazyLoadImage
                                            effect='blur'
                                            alt='main_user_image'
                                            src={`${user?.avatar_urls[0]}?${userStore.home?.pub_urls_query}`}
                                            placeholderSrc={`${user?.avatar_urls[0]
                                                .split('.jpg')
                                                .join(
                                                    '_tiny.jpg'
                                                )}?${userStore.home?.pub_urls_query}`}
                                            className={twMerge(
                                                'h-[700px] w-[662px] rounded-xl object-cover object-top',
                                                isImageLoaded || !modal
                                                    ? 'opacity-100'
                                                    : 'opacity-0'
                                            )}
                                            onLoad={handleLoadedImage}
                                        />
                                    ) : (
                                        <img
                                            src={`${user?.avatar_urls[0]}?${userStore.home?.pub_urls_query}`}
                                            alt='main_user_image'
                                            className={twMerge(
                                                'h-[700px] w-[662px] rounded-xl object-cover object-top',
                                                isImageLoaded
                                                    ? 'opacity-100'
                                                    : 'opacity-0'
                                            )}
                                            onLoad={handleLoadedImage}
                                        />
                                    )}
                                    {!isImageLoaded && modal && (
                                        <Skeleton
                                            className='absolute z-50'
                                            style={{
                                                top: '50%',
                                                left: '50%',
                                                transform:
                                                    'translate(-50%, -50%)',
                                            }}
                                            width={662}
                                            height={700}
                                        />
                                    )}
                                    <div className='absolute inset-0 opacity-50' />
                                </div>
                            )}
                        </div>
                        <UserProfileSection className='px-4' title='My goal'>
                            <div className='flex gap-2 rounded-xl bg-lightPrimary px-4 py-2'>
                                {userGoal?.icon({ width: 25, height: 25 })}
                                {userGoal?.label}
                            </div>
                        </UserProfileSection>
                        {user?.about && (
                            <UserProfileSection
                                className='px-4'
                                title='About me'
                            >
                                <div className='flex w-full break-words text-start'>
                                    {user?.about?.replace(/,/g, ', ')}
                                </div>
                            </UserProfileSection>
                        )}
                        <UserProfileSection className='px-4' title='My basics'>
                            <div className='flex flex-wrap gap-1'>
                                <MultiSelectItem
                                    item={
                                        user?.gender === 'F' ? 'Female' : 'Male'
                                    }
                                    isSelected
                                    logo={<Gender width={20} height={20} />}
                                    onClick={() => null}
                                    match={
                                        user?.gender ===
                                            userStore?.userData?.gender && notMe
                                    }
                                    className='cursor-default border-0'
                                />
                                {stepConfigs?.map((value: StepConfig) => {
                                    if (
                                        !value ||
                                        !value.key ||
                                        !value.options
                                    ) {
                                        return null;
                                    }
                                    if (
                                        !user?.[
                                            value?.key as keyof UserDetailed
                                        ]
                                    ) {
                                        return null;
                                    }
                                    const index: number = user?.[
                                        value?.key as keyof UserDetailed
                                    ] as number;
                                    if (!index) {
                                        return null;
                                    }

                                    let displayValue: string;
                                    switch (value?.key) {
                                        case 'height_sm':
                                            displayValue = `${Math.floor(user?.height_sm!)} cm`;
                                            break;
                                        case 'weight_kg':
                                            displayValue = `${Math.floor(user?.weight_kg!)} kg`;
                                            break;
                                        default:
                                            displayValue =
                                                value?.options[index - 1];
                                            break;
                                    }
                                    if (!displayValue || !index) {
                                        return null;
                                    }
                                    return (
                                        <MultiSelectItem
                                            key={value?.key}
                                            item={displayValue}
                                            isSelected
                                            type='basic'
                                            logo={
                                                value.LogoComponent ? (
                                                    <value.LogoComponent
                                                        width={20}
                                                        height={20}
                                                    />
                                                ) : null
                                            }
                                            onClick={() => null}
                                            match={
                                                myData[value?.key] === index &&
                                                notMe
                                            }
                                            className='cursor-default border-0'
                                        />
                                    );
                                })}
                            </div>
                        </UserProfileSection>
                        <div
                            className={twMerge(
                                'flex w-full items-center',
                                !selectedNationalities &&
                                    !selectedEthnicities &&
                                    'hidden'
                            )}
                        >
                            <div className='flex w-full flex-col gap-2'>
                                {selectedEthnicities && (
                                    <UserProfileSection
                                        className={twMerge('gap-1 px-4')}
                                        title='My ethnicities'
                                        multiSelect
                                    >
                                        <div className='flex flex-wrap gap-1 rounded-lg bg-white'>
                                            {selectedEthnicities.map((item) => (
                                                <MultiSelectItem
                                                    key={`user_eth${item}`}
                                                    item={Ethnicities[item]}
                                                    type='eth'
                                                    isSelected
                                                    onClick={() => null}
                                                    match={
                                                        userStore?.userData?.ethnicities?.includes(
                                                            item
                                                        ) && notMe
                                                    }
                                                    className='cursor-default border-0'
                                                />
                                            ))}
                                        </div>
                                    </UserProfileSection>
                                )}
                                {selectedNationalities &&
                                    selectedEthnicities && (
                                        <p className='h-[1px] border border-strokeh' />
                                    )}
                                {selectedNationalities && (
                                    <UserProfileSection
                                        className={twMerge('gap-1 px-4')}
                                        title='My nationalities'
                                        multiSelect
                                    >
                                        <div className='flex flex-wrap gap-1 rounded-lg bg-white'>
                                            {selectedNationalities.map(
                                                (item) => (
                                                    <MultiSelectItem
                                                        key={`user_nat${item}`}
                                                        item={
                                                            Nationalities[item]
                                                        }
                                                        type='nat'
                                                        isSelected
                                                        onClick={() => null}
                                                        match={
                                                            userStore?.userData?.nationalities?.includes(
                                                                item
                                                            ) && notMe
                                                        }
                                                        className='cursor-default border-0'
                                                        code={item}
                                                    />
                                                )
                                            )}
                                        </div>
                                    </UserProfileSection>
                                )}
                            </div>
                            {selectedNationalities && selectedEthnicities && (
                                <Passport
                                    style={{
                                        height: `${isMobile ? scaleSize(46) : 70}px`,
                                        marginRight: `${scaleSize(16)}px`,
                                    }}
                                />
                            )}
                        </div>
                        {selectedInterests && (
                            <UserProfileSection
                                className='px-4'
                                title='My interests'
                            >
                                <div className='flex flex-wrap gap-1 rounded-lg bg-white'>
                                    {selectedInterests.map((item) => {
                                        const interests = unpackUserInterests(
                                            userStore?.userData || {}
                                        );
                                        return (
                                            <MultiSelectItem
                                                key={item}
                                                item={interestsNames[item]}
                                                isSelected
                                                onClick={() => null}
                                                match={
                                                    interests.includes(item) &&
                                                    notMe
                                                }
                                                className='cursor-default border-0'
                                            />
                                        );
                                    })}
                                </div>
                            </UserProfileSection>
                        )}
                        {user?.city_id && (
                            <UserProfileSection
                                className='px-4'
                                title='My location'
                                icon={<Location />}
                            >
                                <ReactCountryFlag
                                    className='md:ml-2 '
                                    alt={`${user?.country_code}`}
                                    countryCode={user?.country_code || ''}
                                />
                                {nationality &&
                                    nationalitiesNames[nationality] && (
                                        <span className='font-bold'>
                                            {nationalitiesNames[nationality]},
                                        </span>
                                    )}
                                {user?.city_id && (
                                    <span className='font-bold md:ml-1'>
                                        {user?.city ||
                                            fetCitiesById(user?.city_id)}
                                    </span>
                                )}
                            </UserProfileSection>
                        )}
                        {selectedLanguages && selectedLanguages[0] > -1 && (
                            <UserProfileSection
                                className='px-4'
                                title='Languages I know'
                                icon={
                                    <Language className='h-6 w-6 opacity-100' />
                                }
                                multiSelect
                            >
                                <div className='flex flex-wrap gap-1 rounded-lg bg-white'>
                                    {selectedLanguages.map((item) => {
                                        if (!userStore?.userData) {
                                            return null;
                                        }
                                        const languages = unpackUserLanguages(
                                            userStore?.userData
                                        );
                                        return (
                                            <MultiSelectItem
                                                key={item}
                                                item={
                                                    supportedLanguagesNames[
                                                        item
                                                    ]
                                                }
                                                type='lang'
                                                isSelected
                                                onClick={() => null}
                                                match={
                                                    languages.includes(item) &&
                                                    notMe
                                                }
                                                className='cursor-default border-0'
                                            />
                                        );
                                    })}
                                </div>
                            </UserProfileSection>
                        )}
                        <div className='max-w-full overflow-x-hidden'>
                            {user?.avatar_urls
                                ?.slice(1, user?.avatar_urls.length)
                                .map((image, index) => (
                                    <div
                                        key={image}
                                        className='flex w-full items-center justify-center'
                                    >
                                        <LazyLoadImage
                                            effect='blur'
                                            alt={`user_photo_${index + 1}`}
                                            src={`${image}?${userStore.home?.pub_urls_query}`}
                                            placeholderSrc={`${image.split('.jpg').join('_tiny.jpg')}?${
                                                userStore.home?.pub_urls_query
                                            }`}
                                            className='h-auto w-full'
                                            width={662}
                                        />
                                        <div className='absolute inset-0 opacity-50' />
                                    </div>
                                ))}
                        </div>
                        {(user?.work || user?.work_info) && (
                            <UserProfileSection
                                className='px-4'
                                title='My work'
                                icon={<Work />}
                            >
                                {user?.work}
                                {user?.work && user?.work_info && ', '}
                                {user?.work_info}
                            </UserProfileSection>
                        )}
                        {user?.school && (
                            <UserProfileSection
                                className='px-4'
                                title='My school'
                                icon={<School />}
                            >
                                {user?.school}
                                {user?.school && user?.school_info && ', '}
                                {user?.school_info}
                            </UserProfileSection>
                        )}
                        {user?.verified && (
                            <UserProfileSection
                                className='px-4'
                                title='My verification'
                            >
                                <Shield className='h-[25px] w-[25px]' />
                                <span>
                                    {user?.first_name} has been photo verified
                                </span>
                            </UserProfileSection>
                        )}

                        {notMe &&
                            !userStore?.userData?.verified &&
                            userData.verified && (
                                <div className='w-full px-4 text-start'>
                                    <div
                                        className='mb-1 flex h-10 w-full cursor-pointer items-center justify-between rounded-lg bg-verify px-4 font-bold text-white'
                                        onClick={() =>
                                            navigate(AppRoutes.Verification)
                                        }
                                    >
                                        <span>Pass verification</span>
                                        <div className='full h-5 w-5 rounded-full border-2 border-white bg-transparent' />
                                    </div>
                                    Verified users are trusted more
                                </div>
                            )}
                        {notMe && (
                            <div
                                className={twMerge(
                                    'flex w-full items-center',
                                    modal
                                        ? 'pb-0'
                                        : `${device === 'iOS' ? 'pb-10' : 'pb-28'}`,
                                    type === 'chat' && 'pb-8'
                                )}
                            >
                                {/* <UserProfileSection className ={modal ? 'px-4' : ''} title='Compliment'>
                                    <input
                                        className='min-h-10 w-full rounded-xl border border-primary px-2'
                                        placeholder='Write a compliment'
                                    />
                                    <Likes />
                                </UserProfileSection> */}
                                <Button
                                    className={twMerge(
                                        'z-20',
                                        isClosed && 'hidden'
                                    )}
                                    label='Block and Report'
                                    onClick={() => {
                                        analyticsEvent('Card_Options_Press');
                                        if (setIsOpenModalDots) {
                                            setIsOpenModalDots(true);
                                        }
                                    }}
                                />
                            </div>
                        )}
                        {modal && type === 'basic' && (
                            <div className=' h-[70px] w-full rounded-xl bg-gradient-to-b from-white to-placeholder' />
                        )}
                    </div>
                ) : (
                    <></>
                )}
                {modal && type !== 'basic' && isImageLoaded && (
                    <ProfilePreviewActions
                        type={type}
                        onClose={actionOnClick}
                        actionSize={actionSize}
                        nickname={user?.nickname}
                        actionToBottom={fromLikedYou}
                    />
                )}
            </div>
        );
    }
);

export default ProfilePreview;
