import StarSign from '@/assets/icons/star-sign.svg?react';
import { UserStarSign } from '@/domain';
import { getEnumOptions } from '@/utils/getEnumOptions';
import React from 'react';
import SingleSelectStep from '../../common/SingleSelectStep';
import UserDataFlowLayout from '../../layouts/UserDataFlowLayout';
import { UserInfoStepProps } from './userInfoConfig';

const StarSignStep: React.FC<UserInfoStepProps> = ({ onNext }) => {
    return (
        <UserDataFlowLayout withBarProgress>
            <div className='-mb-[5px] h-[5px] w-full bg-white/50'>
                <div className='h-[5px] w-[35%] bg-white' />
            </div>
            <SingleSelectStep
                LogoComponent={StarSign}
                title='What is your star sign?'
                options={getEnumOptions(UserStarSign)}
                withSkip
                onNext={onNext}
                field='star_sign'
                fromEdit={false}
            />
        </UserDataFlowLayout>
    );
};

export default StarSignStep;
