import { EmblaOptionsType } from 'embla-carousel';
import useEmblaCarousel from 'embla-carousel-react';
import React from 'react';

import { getMediaUrl } from '@/utils/getMediaUrl.ts';
import { analyticsEvent } from '@shared/services/analytics';

import Button from '../common/Button/Button.tsx';
import EmblaCarousel from '../common/Carousel/CarouselWrapper';
import { useDotButton } from '../common/Carousel/EmblaCarouselDotButton.tsx';
import Tip from '../common/Tip.tsx';
import { usePrevNextButtons } from './consts.ts';

interface TipsPhotoModalProps {
    isOpen: boolean;
    primaryText?: string;
    secondaryText?: string;
    onCancel?: () => void;
    options?: EmblaOptionsType;
}

const imagesData = [
    [
        { source: getMediaUrl('tips/traveling.webp'), success: true },
        { source: getMediaUrl('tips/surfing.webp'), success: true },
    ],
    [
        { source: getMediaUrl('tips/girl.webp'), success: true },
        { source: getMediaUrl('tips/people.webp'), success: false },
    ],
    [
        { source: getMediaUrl('tips/man.webp'), success: true },
        { source: getMediaUrl('tips/evening.webp'), success: false },
    ],
    [
        { source: getMediaUrl('tips/girl-outside.webp'), success: true },
        { source: getMediaUrl('tips/no-face.webp'), success: false },
    ],
    [
        { source: getMediaUrl('tips/girl-variety.webp'), success: true },
        { source: getMediaUrl('tips/girl-similar.webp'), success: false },
    ],
];
const slidesData = [
    {
        title: 'Show your interests',
        message: 'Show your lifestyle, travel or things you like doing.',
        analyticsText: 'interests',
    },
    {
        title: 'Show only yourself',
        message: 'Avoid adding friends or other people to your photo.',
        analyticsText: 'yourself_only',
    },
    {
        title: 'Clear photo of your face',
        message: 'Ensure your face is clearly visible in your photo.',
        analyticsText: 'clear_face',
    },
    {
        title: 'No text or promos',
        message: 'Avoid a photo with text, numbers, or promotions.',
        analyticsText: 'no_text',
    },
    {
        title: 'Show Variety',
        message:
            'Avoid repeats. Upload a variety of photos to keep things interesting.',
        analyticsText: 'variety',
    },
];

const TipsPhotoModal: React.FC<TipsPhotoModalProps> = ({
    isOpen,
    primaryText = 'Continue',
    secondaryText = 'Close',
    onCancel,
    options,
}) => {
    const [emblaRef, emblaApi] = useEmblaCarousel(options);
    const { onNextButtonClick } = usePrevNextButtons(emblaApi);
    const { selectedIndex, onDotButtonClick } = useDotButton(emblaApi);
    const isLastSlide = selectedIndex !== slidesData.length - 1;
    const handleOverlayClick = () => {
        if (onCancel) {
            onCancel();
            analyticsEvent('avatar_tips_close_pressed');
        }
    };

    if (!isOpen) return null;

    return (
        <div className='fixed top-0 left-0 z-30 flex h-full w-full items-center justify-center text-black'>
            <div
                className='fixed top-0 left-0 h-full w-full bg-gray-500 bg-opacity-50'
                onClick={handleOverlayClick}
            />
            <div className='fixed max-w-[96vw] content-center rounded-lg bg-white px-5 pt-10 text-center shadow-lg sm:max-w-[470px]'>
                <EmblaCarousel
                    forwardedRef={emblaRef}
                    slides={slidesData.map((slideData, index) => (
                        <Slide
                            key={index}
                            images={imagesData[selectedIndex]}
                            title={slideData.title}
                            message={slideData.message}
                        />
                    ))}
                    emblaApi={emblaApi}
                />
                <div className='mx-auto my-6 flex max-w-[300px] flex-col justify-center gap-2'>
                    <Button
                        className='px-4 py-2'
                        variant='primary'
                        label={isLastSlide ? primaryText : 'Done'}
                        onClick={
                            isLastSlide
                                ? () => {
                                      analyticsEvent(
                                          `avatar_tip_${slidesData[selectedIndex].analyticsText}_next_pressed`
                                      );
                                      onNextButtonClick();
                                  }
                                : () => {
                                      analyticsEvent(
                                          'avatar_tips_done_pressed'
                                      );
                                      onCancel?.();
                                  }
                        }
                    />
                    <Button
                        label={
                            isLastSlide ? secondaryText : 'See these tips again'
                        }
                        onClick={
                            isLastSlide
                                ? handleOverlayClick
                                : () => {
                                      analyticsEvent(
                                          'avatar_tips_see_again_pressed'
                                      );
                                      onDotButtonClick(0);
                                  }
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default TipsPhotoModal;

interface Image {
    source: string;
    success: boolean;
}

interface SlideProps {
    images: Image[];
    title: string;
    message: string;
}

const Slide: React.FC<SlideProps> = ({ images, title, message }) => (
    <div className='mb-4 px-4'>
        <div className='mb-4 flex justify-center gap-2 text-gray-600 text-sm sm:gap-4'>
            {images.map((image, i) => (
                <Tip
                    aling='bottom'
                    key={i + image.source}
                    isSuccess={image.success}
                >
                    <img
                        alt={`tip ${image.source}`}
                        src={image.source}
                        loading='lazy'
                        className='h-[145px] rounded-lg sm:h-[172px]'
                    />
                </Tip>
            ))}
        </div>
        <h2 className='select-none font-semibold text-2xl'>{title}</h2>
        <span className='select-none'>{message}</span>
    </div>
);
