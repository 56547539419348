import InterestsMultiSelect from '@/components/common/InterestsMultiSelect';
import UserDataFlowLayout from '@/components/layouts/UserDataFlowLayout';
import { Interests, packUserInterests } from '@/domain';
import { useScreenDetector } from '@/hooks/useScreenDetector';
import { scaleFont, scaleSize } from '@/utils/scaleSizeAndFont';
import React, { useCallback } from 'react';
import { twMerge } from 'tailwind-merge';
import { goals } from './GoalStep';
import { UserInfoStepProps } from './userInfoConfig';

const InterestsStep: React.FC<UserInfoStepProps> = ({ onNext }) => {
    const currentGoal = localStorage.getItem('goal');
    const shorterFlow =
        currentGoal !== goals[0].value && currentGoal !== goals[1].value;
    const handleGoNext = useCallback(
        (interests?: Interests[]) => {
            if (interests) {
                localStorage.setItem(
                    'interests',
                    JSON.stringify(packUserInterests(interests))
                );
            }

            onNext(shorterFlow);
        },
        [onNext, shorterFlow]
    );
    const { isMobile } = useScreenDetector();

    return (
        <UserDataFlowLayout withBottomShadow withBarProgress>
            <div className='flex flex-col overflow-auto'>
                <div className='h-[5px] w-full flex-shrink-0 bg-white/50'>
                    <div
                        className={twMerge(
                            'h-full w-[10%] bg-white',
                            shorterFlow && 'w-[85%]'
                        )}
                    />
                </div>
                <div className='flex flex-col overflow-y-auto px-4 pb-8'>
                    <div>
                        <h1
                            className=' font-extrabold'
                            style={{
                                marginTop: `${scaleSize(40)}px`,
                                fontSize: `${isMobile ? scaleFont(22) : 36}px`,
                            }}
                        >
                            Your Interests
                        </h1>
                        <h2
                            style={{
                                marginTop: `${scaleSize(12)}px`,
                                fontSize: `${scaleFont(17)}px`,
                                lineHeight: `${scaleFont(22)}px`,
                            }}
                        >
                            Pick up to 15 things you love. It'll help you match
                            with people who love them too.
                        </h2>
                    </div>

                    <InterestsMultiSelect
                        handleAction={handleGoNext}
                        isUserDataFlow
                    />
                    <button
                        onClick={() => handleGoNext()}
                        className='absolute bottom-8 left-8 z-10 text-black'
                    >
                        Skip
                    </button>
                </div>
            </div>
        </UserDataFlowLayout>
    );
};

export default InterestsStep;
