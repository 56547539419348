import TriangleAttention from '@/assets/icons/attention-triangle.svg?react';
import { scaleFont } from '@/utils/scaleSizeAndFont';
import React from 'react';

interface DeclineAreaProps {
    price: number;
}

const DeclineArea: React.FC<DeclineAreaProps> = ({ price }) => {
    return (
        <div className='mx-auto mt-4 flex w-full flex-col items-center gap-4'>
            <div className='flex w-full justify-between rounded-[10px] bg-attentionLight p-4 font-bold'>
                <p
                    style={{
                        fontSize: scaleFont(17),
                    }}
                >
                    {`USD ${price}`}
                </p>
                <p
                    className='text-attention'
                    style={{
                        fontSize: scaleFont(15),
                    }}
                >
                    Unpaid
                </p>
            </div>
            <div className='flex w-full items-center gap-2'>
                <TriangleAttention />
                <p
                    className='font-semibold'
                    style={{
                        fontSize: scaleFont(20),
                    }}
                >
                    Payment declined.
                </p>
            </div>
            <div
                className='flex w-full flex-col justify-between gap-2 rounded-[10px] border border-attention bg-attentionLight p-4'
                style={{
                    fontSize: scaleFont(17),
                }}
            >
                <p className='font-semibold'>
                    Your account was not debited for the service.
                </p>
                <p>
                    The payment was declined - contact your bank’s support for
                    more information. Please try to use another card or choose
                    another payment method.{' '}
                </p>
            </div>
        </div>
    );
};

export default DeclineArea;
