import { Continent } from '@/domain';
import discoveryStore from '@/entities/discovery/store';
import userStore from '@/entities/user/store';
import { useScreenDetector } from '@/hooks/useScreenDetector';
import { handleGoToPayWall } from '@/utils/handleGoToPayWall';
import { scaleSize } from '@/utils/scaleSizeAndFont';
import { updateUserAction } from '@/workers/sharedWorkerInstance';
import { memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import TextInput from '../common/TextInput/TextInput';

export type CheckBoxData = {
    text: string;
    propName: string;
    isNegativeValue: boolean;
};

interface CheckboxProps {
    checkboxData: CheckBoxData;
    checked: boolean;
    setUserValue?: React.Dispatch<
        React.SetStateAction<{
            match_gender: string;
            match_age_from: number;
            match_age_to: number;
            filter_verified_only: boolean;
            filter_continents: Continent[];
            discover_near: boolean;
        }>
    >;
    subscribed: boolean;
    withoutReqest?: boolean;
    onlyWithPlus?: boolean;
}

const SettingsCheckbox: React.FC<CheckboxProps> = ({
    checkboxData,
    checked = false,
    setUserValue,
    subscribed,
    withoutReqest = false,
    onlyWithPlus = false,
}: CheckboxProps) => {
    const navigate = useNavigate();
    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    const handleCheck = useCallback(() => {
        if (
            onlyWithPlus &&
            userStore?.home?.me?.subscription?.type !== 'plus'
        ) {
            if (checkboxData.propName === 'filter_verified_only') {
                handleGoToPayWall(navigate, 6);
            } else alert('Buy a plan');
        } else if (setUserValue) {
            setUserValue((prev: any) => {
                const updatedValue = {
                    ...prev,
                    filter_verified_only: !checked,
                };
                return updatedValue;
            });
            discoveryStore.updateFilterVerifiedOnly(!checked);
        }
    }, [onlyWithPlus, userStore?.home?.me?.subscription?.type, checked]);
    const { isMobile } = useScreenDetector();
    const handleClick = () => {
        if (checkboxData.propName === 'incognito') {
            handleGoToPayWall(navigate, 4);
        } else {
            handleGoToPayWall(navigate, 9);
        }
    };
    return (
        <div
            className='flex w-full cursor-pointer items-center justify-between rounded-xl bg-white'
            style={{
                paddingTop: `${scaleSize(8)}px`,
                paddingBottom: `${scaleSize(8)}px`,
                paddingLeft: `${scaleSize(16)}px`,
                paddingRight: `${scaleSize(16)}px`,
            }}
            onClick={() => {
                if (withoutReqest) {
                    return handleCheck();
                }
                return subscribed
                    ? updateUserAction(
                          checkboxData.propName,
                          checkboxData.isNegativeValue ? checked : !checked
                      )
                    : handleClick();
            }}
        >
            <span
                className='font-semibold'
                style={{
                    fontSize: `${isMobile ? scaleSize(14) : 16}px`,
                }}
            >
                {checkboxData.text}
            </span>
            <div
                className='relative mr-2 inline-block select-none align-middle transition duration-200 ease-in'
                style={{
                    width: `${isMobile ? scaleSize(40) : 56}px`,
                }}
            >
                <TextInput
                    type='checkbox'
                    name={`${checkboxData.propName}-toggle`}
                    id={`${checkboxData.propName}-toggle`}
                    className={twMerge(
                        'toggle-checkbox absolute block appearance-none rounded-full border-2 bg-white p-0 focus:outline-none',
                        checked && 'right-0 left-auto border-secondGold',
                        !checked && 'right-auto left-0 border-[#d1d5db]'
                    )}
                    style={{
                        height: `${isMobile ? scaleSize(24) : 32}px`,
                        width: `${isMobile ? scaleSize(24) : 32}px`,
                    }}
                    readOnly
                    checked={checked}
                />

                <label
                    htmlFor={`${checkboxData.propName}-toggle`}
                    className={`block cursor-pointer overflow-hidden rounded-full transition-colors duration-200 ease-in ${
                        checked ? 'bg-gold-gradient' : 'bg-inactive'
                    }`}
                    style={{
                        height: `${isMobile ? scaleSize(24) : 32}px`,
                    }}
                />
            </div>
        </div>
    );
};

export default memo(SettingsCheckbox);
