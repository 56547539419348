import Lottie from 'lottie-react';
import React, {
    SetStateAction,
    Dispatch,
    useEffect,
    useRef,
    useState,
    useMemo,
} from 'react';
import Skeleton from 'react-loading-skeleton';
import { twMerge } from 'tailwind-merge';

import { sendMessage } from '@/api/chat';
import CheckMark from '@/assets/icons/check-mark.svg?react';
import DoubleCheckMark from '@/assets/icons/double-check.svg?react';
import TranslatorIcon from '@/assets/icons/translator.svg?react';
import Undo from '@/assets/icons/undo.svg?react';
import Clock from '@/assets/lotties/myMessageClock.json';
import {
    Match,
    MatchMessage,
    MatchMessageFlags,
    MatchMessageType,
    User,
    hasFlag,
    noFlag,
} from '@/domain';
import { WebMatchMessage } from '@/entities/match';
import userStore from '@/entities/user/store';
import { scrollToBottom } from '@/utils/ScrollToBottom';
import { getMediaUrl } from '@/utils/getMediaUrl';
import { LOCAL_STORAGE } from '@/utils/localStorage';
import { scaleSize } from '@/utils/scaleSizeAndFont';
import {
    firstTranslateMessage,
    sensitiveRevealMessage,
    translateMessage,
    //   sendReactionMessage,
} from '@/workers/sharedWorkerInstance';
import { analyticsEvent } from '@shared/services/analytics';

import Avatar from '../common/CircleAvatar/Avatar';
import ChatsMessageModal from '../modals/ChatsMessageModal';
import ChatsModal from '../modals/ChatsModal';

// WTF???
TranslatorIcon;

interface ChatMessageProps {
    containerRef?: any;
    message: WebMatchMessage;
    selectedChat: Match;
    fileToken: string;
    setIsOpenAttentionFileModal: Dispatch<
        SetStateAction<'' | 'text' | 'photo'>
    >;
    setEditMessage: React.Dispatch<
        React.SetStateAction<MatchMessage | undefined>
    >;
    isTranslated: boolean;
    user: User | undefined;
    setIsOpenProfilePreview: React.Dispatch<React.SetStateAction<boolean>>;
    setAddTranslateMessage: (arg: boolean) => void;
    setShowPhotoModal: (arg: string) => void;
    isChatClosed?: boolean;
    isBlured: boolean;
}

const ChatMessage: React.FC<ChatMessageProps> = ({
    containerRef,
    message,
    selectedChat,
    fileToken,
    setIsOpenAttentionFileModal,
    setEditMessage,
    isTranslated,
    user,
    setIsOpenProfilePreview,
    setAddTranslateMessage,
    setShowPhotoModal,
    isChatClosed = false,
    isBlured,
}) => {
    const [src, setSrc] = useState<string>(`${message.file_url}?${fileToken}`);
    const [blur, setBlur] = useState<boolean>(isBlured);
    const [sensetivePhotModal, setSensetivePhotModal] =
        useState<boolean>(false);
    const messageRef = useRef<HTMLDivElement>(null);
    const messageContainerRef = useRef<HTMLDivElement>(null);
    const holdTimerRef = useRef<number | undefined>(undefined);
    const [holdModal, setHoldModal] = useState<boolean>(false);
    const [showSkeleton, setShowSkeleton] = useState<boolean>(true);
    const [modalPosition, setModalPosition] = useState<{
        bottom: boolean | null;
        left: boolean | null;
        value: number;
    }>({
        bottom: null,
        left: null,
        value: 0,
    });
    const [isTranslateMessage, setIsTranslateMessage] =
        useState<boolean>(isTranslated);
    const unsupportedMessage =
        message.type !== MatchMessageType.textOrImage &&
        message.type !== MatchMessageType.compliment;
    const isMyMessage =
        message.sender_user_id !== selectedChat.user_id ||
        message.fromMe === false;
    const isReadMessages =
        selectedChat?.last_read_at &&
        message.created_at < selectedChat?.last_read_at;
    const suspiciousMessage = hasFlag(
        message.flags,
        MatchMessageFlags.FLAG_SUSPICIOUS
    );

    const handleTranslate = async () => {
        if (!message.content_tr && userStore.userData) {
            firstTranslateMessage({
                id: message.id,
                chat_id: selectedChat.id,
                tr_lang: userStore.userData.tr_lang || 'en',
            });
        } else {
            translateMessage({
                id: message.id,
                chat_id: selectedChat.id,
                visible: noFlag(
                    message.flags,
                    MatchMessageFlags.FLAG_TR_V2_VISIBLE
                ),
            });
        }
        analyticsEvent('message_translate');
        setIsTranslateMessage((prev: boolean) => !prev);
    };

    const handleResendMessage = async () => {
        try {
            if (!message.content) {
                return;
            }
            await sendMessage(
                localStorage.getItem(LOCAL_STORAGE.AuthToken) || '',
                message.content,
                selectedChat.id
            );
            analyticsEvent('RetryMessage', { ui: '1' });
        } catch (error) {
            setIsOpenAttentionFileModal('text');
            console.error('Error resending message:', error);
        }
    };

    const handleCalcModalPoosition = () => {
        if (
            messageRef.current &&
            !unsupportedMessage &&
            !(
                hasFlag(message.flags, MatchMessageFlags.FLAG_SENSITIVE) &&
                noFlag(
                    message.flags,
                    MatchMessageFlags.FLAG_SENSITIVE_REVEALED
                ) &&
                !message.content
            )
        ) {
            const rect = messageRef.current.getBoundingClientRect();
            const showBelow = window.innerHeight - rect.bottom - 67 > 130;
            const showAbove = rect.top - 67 > 130;
            setModalPosition({
                bottom: showBelow ? true : showAbove ? false : null,
                left: showBelow || showAbove ? null : isMyMessage,
                value: messageRef.current.clientHeight + 8,
            });
            setHoldModal(true);
        }
    };

    const handleHoldStart = () => {
        holdTimerRef.current = window.setTimeout(() => {
            handleCalcModalPoosition();
        }, 800);
    };

    const handleHoldEnd = () => {
        if (holdTimerRef.current) {
            clearTimeout(holdTimerRef.current);
        }
    };
    const handleHoldMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        if (isChatClosed && blur && !message.content) {
            return;
        }
        holdTimerRef.current = window.setTimeout(() => {
            handleCalcModalPoosition();
        }, 300);
    };

    const handleMouseUpOrLeave = () => {
        if (holdTimerRef.current) {
            clearTimeout(holdTimerRef.current);
        }
    };

    const handleErrorSrc = () => {
        setSrc(`${message.file_url}`);
        setShowSkeleton(false);
    };

    //   const handleSendReaction = async (reaction: number) => {
    //     try {
    //       await sendReactionMessage({
    //         id: message.id,
    //         reaction: reaction,
    //         chat_id: selectedChat.id,
    //       });
    //     } catch (error) {
    //       console.log(error);
    //     }
    //   };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    const imageSize = useMemo(() => {
        if (messageContainerRef.current?.clientWidth) {
            return messageContainerRef.current?.clientWidth * 0.6;
        }
    }, [messageContainerRef.current]);

    useEffect(() => {
        setSrc(`${message.file_url}?${fileToken}`);
    }, [message, fileToken]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        setAddTranslateMessage(!isMyMessage && isTranslateMessage);
    }, [isTranslateMessage, isMyMessage]);

    useEffect(() => {
        if (!showSkeleton && containerRef) {
            scrollToBottom(containerRef);
        }
    }, [showSkeleton, containerRef]);

    return (
        <div
            ref={messageContainerRef}
            className={twMerge(
                'relative flex w-full select-none items-center gap-2',
                isMyMessage && 'justify-end',
                isTranslateMessage && message.content_tr && 'mb-2'
            )}
            onClick={() => console.log(message)}
            onContextMenu={(e) => e.preventDefault()}
        >
            {message.isError && (
                <div
                    className='cursor-pointer rounded-full bg-error p-1 opacity-100'
                    onClick={handleResendMessage}
                >
                    <Undo width={16} height={16} fill='white' />
                </div>
            )}
            <ChatsMessageModal
                isOpen={holdModal}
                modalPosition={modalPosition}
                isMyMessage={isMyMessage}
                setHoldModal={setHoldModal}
                setEditMessage={setEditMessage}
                handleTranslate={handleTranslate}
                message={message}
                isChatClosed={isChatClosed}
                isTranslateMessage={isTranslateMessage}
                blur={blur}
                fileToken={fileToken}
                selectedChatId={selectedChat.id}
            />
            {!isMyMessage && (
                <div
                    className={twMerge(
                        'flex h-full items-end',
                        isChatClosed && 'opacity-70'
                    )}
                >
                    <Avatar
                        src={`${user?.avatar_urls?.[0]}?${userStore.home?.pub_urls_query}`}
                        alt='rounded avatar'
                        imageSize='small'
                        size={scaleSize(35)}
                        onClick={() => setIsOpenProfilePreview(true)}
                    />
                </div>
            )}
            <div
                key={message.id}
                className={twMerge(
                    'max-w-[60%] p-3',
                    !isTranslateMessage && 'pb-1',
                    message.file_url && 'p-0',
                    isChatClosed && 'opacity-70',
                    isMyMessage
                        ? 'flex flex-col content-end justify-end self-end rounded-l-xl rounded-tr-xl rounded-br bg-primary text-secondary'
                        : suspiciousMessage
                          ? ' w-max rounded-r-xl rounded-tl-xl rounded-bl bg-navDarkGray text-left text-white'
                          : ' w-max rounded-r-xl rounded-tl-xl rounded-bl bg-gray-200 text-left'
                )}
                ref={messageRef}
                onMouseDown={(e: React.MouseEvent<HTMLDivElement>) =>
                    handleHoldMouseDown(e)
                }
                onMouseUp={handleMouseUpOrLeave}
                onMouseLeave={handleMouseUpOrLeave}
                onTouchStart={handleHoldStart}
                onTouchEnd={handleHoldEnd}
                onContextMenu={() => handleCalcModalPoosition()}
            >
                {message.file_url && (
                    <div className='relative'>
                        <div
                            className={twMerge(
                                'relative overflow-hidden',
                                isMyMessage
                                    ? 'rounded-l-xl rounded-tr-xl rounded-br'
                                    : 'rounded-r-xl rounded-tl-xl rounded-bl',
                                message.content && 'rounded-b-none'
                            )}
                        >
                            {showSkeleton && message.isLoading !== false && (
                                <Skeleton
                                    className='absolute top-0 left-0'
                                    width={imageSize}
                                    height={imageSize}
                                />
                            )}
                            <img
                                src={src}
                                className={twMerge(
                                    'object-cover',
                                    blur && 'blur-lg',
                                    showSkeleton && 'opacity-0'
                                )}
                                style={{
                                    width: imageSize,
                                    height: imageSize,
                                }}
                                alt='message_image'
                                onLoad={() => setShowSkeleton(false)}
                                onClick={() =>
                                    setShowPhotoModal(message.file_url || '')
                                }
                                onError={handleErrorSrc}
                            />
                        </div>
                        {blur && (
                            <div
                                className='absolute top-0 flex h-full w-full cursor-pointer flex-col items-center justify-center bg-transparent text-center font-semibold text-sm text-white'
                                onClick={() => setSensetivePhotModal(true)}
                            >
                                <p>This may be sensitive content </p>
                                <img
                                    src={getMediaUrl(
                                        'chat/messagesSensitiveVisibilityOff.webp'
                                    )}
                                    alt='messagesSensitiveVisibilityOff'
                                    className='w-8'
                                />
                            </div>
                        )}
                    </div>
                )}
                <div
                    className={twMerge(
                        'flex flex-col whitespace-pre-line break-words',
                        message.file_url && 'px-3',
                        unsupportedMessage
                            ? 'text-error text-sm italic'
                            : 'text-[15px] sm:text-base'
                    )}
                >
                    {unsupportedMessage
                        ? "This message type isn't supported. Please check it in the app."
                        : isMyMessage || !message.content_tr
                          ? message.content
                          : isTranslateMessage
                            ? message.content_tr
                            : message.content}
                </div>
                <div
                    className={twMerge(
                        'flex w-full justify-between gap-1 text-xs',
                        isMyMessage
                            ? 'items-center justify-end text-yellow-300'
                            : suspiciousMessage
                              ? ' text-white '
                              : ' text-gray-500'
                    )}
                >
                    {!isTranslateMessage &&
                        message.content &&
                        !isMyMessage &&
                        message.content_lang !==
                            userStore?.userData?.tr_lang && (
                            <span
                                className={twMerge(
                                    'z-40 cursor-pointer underline',
                                    message.file_url && 'pl-3'
                                )}
                                onClick={() => handleTranslate()}
                            >
                                Translate
                            </span>
                        )}
                    <div
                        className={twMerge(
                            'flex items-center gap-1',
                            message.file_url && 'pr-3',
                            message.file_url &&
                                !message.content &&
                                '-mt-12 -mb-6 p-2',
                            !isMyMessage && 'w-full justify-end'
                        )}
                    >
                        {message.modified && <span>edited</span>}
                        <span
                            className={twMerge(
                                'z-10',
                                blur && 'mb-1',
                                !isMyMessage &&
                                    message.file_url &&
                                    !message.content
                                    ? 'rounded-md bg-black/50 p-[2px] text-white'
                                    : ''
                            )}
                        >
                            {new Date(message.created_at).toLocaleTimeString(
                                [],
                                {
                                    hour: '2-digit',
                                    minute: '2-digit',
                                }
                            )}
                        </span>
                        <div className='z-10'>
                            {isMyMessage &&
                                !message.isLoading &&
                                userStore?.home?.me?.subscription?.type ===
                                    'plus' &&
                                !message.isError &&
                                (isReadMessages ? (
                                    <DoubleCheckMark />
                                ) : (
                                    <CheckMark width={20} height={20} />
                                ))}
                            {message.isLoading && (
                                <Lottie
                                    className='h-4 w-4'
                                    animationData={Clock}
                                    loop
                                />
                            )}
                        </div>
                    </div>
                </div>
                {((message.content_tr &&
                    message.sender_user_id === selectedChat.user_id &&
                    isTranslateMessage) ||
                    message.fromMe === false) && (
                    <div
                        className={twMerge(
                            'cursor-pointer whitespace-pre-line break-words rounded-br-[11px] border-2 border-gray-200 border-solid bg-white p-1 pl-3 text-gray-500 text-xs',
                            message.file_url
                                ? 'w-full'
                                : 'mr-[-12px] mb-[-12px] ml-[-12px] w-fill-available'
                        )}
                        onClick={() => {
                            setAddTranslateMessage(false);
                            handleTranslate();
                        }}
                    >
                        {isTranslateMessage
                            ? message.content
                            : message.content_tr}
                    </div>
                )}
            </div>
            <ChatsModal
                icon={
                    <img
                        src={getMediaUrl(
                            'chat/messagesSensitiveAttention.webp'
                        )}
                        alt='messagesSensitiveAttention'
                    />
                }
                isOpen={sensetivePhotModal}
                title='This photo could be sensitive. Are you sure you want to view?'
                confirmText="Don't view"
                onConfirm={() => {
                    setSensetivePhotModal(false);
                }}
                cancelText='View'
                onCancel={() => {
                    try {
                        sensitiveRevealMessage({
                            chat_id: selectedChat.id,
                            id: message.id,
                        });
                        setBlur(false);
                        setSensetivePhotModal(false);
                    } catch (error) {
                        console.error(error);
                    }
                }}
            >
                <div className='text-start'>
                    <p className=' mt-4 flex h-full gap-2'>
                        <img
                            src={getMediaUrl(
                                'chat/messagesSensitiveInfoBody.webp'
                            )}
                            alt='messagesSensitiveInfoBody'
                            className='h-8 '
                        />
                        <span className='h-full'>
                            Naked photos show the private body parts that are
                            usually covered by underwear or bathing suits.
                        </span>
                    </p>
                    <p className=' mt-4 flex h-full gap-2'>
                        <span className='mt-2 h-full text-[32px]'> 😔 </span>{' '}
                        <span className='h-full'>
                            It's not your fault, but naked photos can be used to
                            hurt you.
                        </span>
                    </p>
                </div>
            </ChatsModal>
        </div>
    );
};

export default ChatMessage;
