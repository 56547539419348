import { SupportedLang } from '@/domain';

export const supportedLanguagesNames: Readonly<{
    [language in SupportedLang]: string;
}> = {
    [SupportedLang.GERMAN]: 'German',
    [SupportedLang.HINDI]: 'Hindi',
    [SupportedLang.FILIPINO]: 'Filipino',
    [SupportedLang.LITHUANIAN]: 'Lithuanian',
    [SupportedLang.CROATIAN]: 'Croatian',
    [SupportedLang.LATVIAN]: 'Latvian',
    [SupportedLang.HUNGARIAN]: 'Hungarian',
    [SupportedLang.CHINESE_HANS]: 'Chinese (Simplified)',
    [SupportedLang.UKRAINIAN]: 'Ukrainian',
    [SupportedLang.INDONESIAN]: 'Indonesian',
    [SupportedLang.URDU]: 'Urdu',
    [SupportedLang.MALAYALAM]: 'Malayalam',
    [SupportedLang.MARATHI]: 'Marathi',
    [SupportedLang.MALAY]: 'Malay',
    [SupportedLang.GREEK]: 'Greek',
    [SupportedLang.ENGLISH]: 'English',
    [SupportedLang.ICELANDIC]: 'Icelandic',
    [SupportedLang.ITALIAN]: 'Italian',
    [SupportedLang.AMHARIC]: 'Amharic',
    [SupportedLang.HEBREW]: 'Hebrew',
    [SupportedLang.SPANISH]: 'Spanish',
    [SupportedLang.ESTONIAN]: 'Estonian',
    [SupportedLang.BASQUE]: 'Basque',
    [SupportedLang.ARABIC]: 'Arabic',
    [SupportedLang.PORTUGUESE_PORTUGAL]: 'Portuguese (Portugal)',
    [SupportedLang.VIETNAMESE]: 'Vietnamese',
    [SupportedLang.JAPANESE]: 'Japanese',
    [SupportedLang.ROMANIAN]: 'Romanian',
    [SupportedLang.DUTCH]: 'Dutch',
    [SupportedLang.NORWEGIAN]: 'Norwegian',
    [SupportedLang.FINNISH]: 'Finnish',
    [SupportedLang.RUSSIAN]: 'Russian',
    [SupportedLang.BULGARIAN]: 'Bulgarian',
    [SupportedLang.BENGALI]: 'Bengali',
    [SupportedLang.FRENCH]: 'French',
    [SupportedLang.SLOVAK]: 'Slovak',
    [SupportedLang.SLOVENIAN]: 'Slovenian',
    [SupportedLang.CATALAN]: 'Catalan',
    [SupportedLang.SERBIAN]: 'Serbian',
    [SupportedLang.KANNADA]: 'Kannada',
    [SupportedLang.SWEDISH]: 'Swedish',
    [SupportedLang.KOREAN]: 'Korean',
    [SupportedLang.SWAHILI]: 'Swahili',
    [SupportedLang.CHINESE_TAIWAN]: 'Chinese (Traditional)',
    [SupportedLang.PORTUGUESE_BRAZIL]: 'Portuguese (Brazil)',
    [SupportedLang.TAMIL]: 'Tamil',
    [SupportedLang.GUJARATI]: 'Gujarati',
    [SupportedLang.CZECH]: 'Czech',
    [SupportedLang.TELUGU]: 'Telugu',
    [SupportedLang.THAI]: 'Thai',
    [SupportedLang.WELSH]: 'Welsh',
    [SupportedLang.POLISH]: 'Polish',
    [SupportedLang.DANISH]: 'Danish',
    [SupportedLang.TURKISH]: 'Turkish',
    [SupportedLang.LUXEMBOURGISH]: 'Luxembourgish',
    [SupportedLang.PERSIAN_FARSI]: 'Persian (Fars)',
};
