import { createContext, useContext } from 'react';

import {
    trackDefaultRegistrationEvent,
    trackLiteRegistrationEvent,
} from './abRegistrationTestAnalytics';

type AbRegistrationContextType = {
    trackAbRegistrationEvent:
        | typeof trackDefaultRegistrationEvent
        | typeof trackLiteRegistrationEvent;
};

const AbRegistrationContext = createContext<AbRegistrationContextType | null>(
    null
);

export const AbRegistrationProvider = AbRegistrationContext.Provider;

export const useAbRegistration = () => {
    const context = useContext(AbRegistrationContext);

    if (!context) {
        throw new Error(
            'useAbRegistration must be used within a AbRegistrationProvider'
        );
    }

    return context;
};
