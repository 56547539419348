import { useCallback, useEffect, useRef, useState } from 'react';

import { calculateAge, isAdult, isValidDate } from '@/utils/validation';
import { isValidNumber } from '@shared/lib/number';

const useBirthday = () => {
    const [month, setMonth] = useState(
        () => localStorage.getItem('month') || ''
    );
    const [day, setDay] = useState(() => localStorage.getItem('day') || '');
    const [year, setYear] = useState(() => localStorage.getItem('year') || '');
    const [age, setAge] = useState<number | null>(null);
    const [isValidBirthdayField, setIsValidBirthdayField] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [showTooltip, setShowTooltip] = useState<boolean>(false);
    const [fullFilled, setFullFilled] = useState<boolean>(false);

    const monthInputRef = useRef<HTMLInputElement>(null);
    const dayInputRef = useRef<HTMLInputElement>(null);
    const yearInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (month.length === 0 || day.length === 0 || year.length < 4) {
            setFullFilled(false);
            return;
        }

        const calculatedAge = calculateAge(year, month, day);
        setAge(calculatedAge);
        setIsValidBirthdayField(
            isValidDate(month, day, year) && isAdult(calculatedAge)
        );
        setFullFilled(true);
    }, [month, day, year]);

    useEffect(() => {
        if (dayInputRef.current) {
            dayInputRef.current.focus();
        }
    }, []);

    useEffect(() => {
        setShowTooltip(
            !!month && !!day && year.length === 4 && !isValidBirthdayField
        );
    }, [day, month, year, isValidBirthdayField]);

    const handleMonthChange = useCallback((value: string) => {
        if (isValidNumber(value) || !value) {
            setMonth(value);
            localStorage.setItem('month', value);
        }
    }, []);

    const handleDayChange = useCallback((value: string) => {
        if (isValidNumber(value) || !value) {
            setDay(value);
            localStorage.setItem('day', value);
        }
    }, []);

    const handleYearChange = useCallback((value: string) => {
        if (isValidNumber(value) || !value) {
            setYear(value);
            localStorage.setItem('year', value);
        }
    }, []);

    const handleKeyDown = (
        value: string,
        e: React.KeyboardEvent<HTMLInputElement>,
        toFocus: React.RefObject<HTMLInputElement>
    ) => {
        if (value.length === 0 && e.key === 'Backspace') {
            if (toFocus.current) {
                toFocus.current.focus();
            }
        }
    };

    const handleKeyUp = (
        value: string,
        event: React.KeyboardEvent<HTMLInputElement>,
        toFocus: React.RefObject<HTMLInputElement>
    ) => {
        const name = event.currentTarget.name;

        if (event.key === 'Backspace' || event.key === 'Delete') {
            return;
        }

        const isFullFilledDay = (value: string) =>
            Number(value) > 3 || value.length === 2;
        const isFullFilledMonth = (value: string) =>
            Number(value) > 1 || value.length === 2;

        if (
            name === 'day' &&
            isFullFilledDay(value) &&
            !toFocus.current?.value
        ) {
            toFocus.current?.focus();
            return;
        }

        if (
            name === 'month' &&
            isFullFilledMonth(value) &&
            !toFocus.current?.value
        ) {
            toFocus.current?.focus();
            return;
        }
    };

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    return {
        month,
        day,
        year,
        age,
        isValidBirthdayField,
        isModalOpen,
        showTooltip,
        monthInputRef,
        dayInputRef,
        yearInputRef,
        fullFilled,
        setIsModalOpen,
        handleMonthChange,
        handleDayChange,
        handleYearChange,
        handleKeyDown,
        handleKeyUp,
        handleOpenModal,
    };
};

export default useBirthday;
