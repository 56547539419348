import * as Sentry from '@sentry/react';

export const initialize: typeof Sentry.init = ({ ...config }) => {
    return Sentry.init({
        tracesSampleRate: 1.0,
        tracePropagationTargets: [
            'localhost',
            /^https:\/\/yourserver\.io\/api/,
        ],
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
        ],
        ...config,
    });
};

export const setUser = Sentry.setUser;
