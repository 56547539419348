import React from 'react';
import { LOGIN_CARD } from './LoginConstant';
import RegisterCard from './RegisterCard';

interface AdvantageSectionProps {
    handleSignInWithEmail: () => void;
}

const AdvantageSection: React.FC<AdvantageSectionProps> = ({
    handleSignInWithEmail,
}) => {
    return (
        <div className='flex h-fit flex-col items-center bg-white pb-12 sm:max-h-[591px]'>
            <div className='mt-9 flex flex-col gap-4 sm:flex-row'>
                {LOGIN_CARD.map((card) => (
                    <RegisterCard
                        key={card.title}
                        image={card.image}
                        title={card.title}
                        description={card.description}
                    />
                ))}
            </div>
            <button
                className='flex h-[50px] w-[80vw] max-w-[400px] items-center justify-center rounded-lg border border-primary bg-primary px-4 py-3 font-bold text-secondary shadow-sm md:w-[96vw]'
                onClick={handleSignInWithEmail}
            >
                Try Now
            </button>
        </div>
    );
};

export default AdvantageSection;
