import { Observer } from 'mobx-react-lite';
import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import ArrowBackDark from '@/assets/icons/arrow-back-dark.svg?react';
import userStore from '@/entities/user/store';
import { useScreenDetector } from '@/hooks/useScreenDetector';
import { languages } from '@/utils/languages';
import { scaleFont, scaleSize } from '@/utils/scaleSizeAndFont';
import { analyticsEvent } from '@shared/services/analytics';

interface UniversalButtonProps {
    text: string;
    onClick?: () => void;
    href?: string;
    icon?: ReactNode;
    withoutArrow?: boolean;
    className?: string;
    withSMAnalytics?: boolean;
}

export const UniversalButton: React.FC<UniversalButtonProps> = ({
    text,
    onClick,
    href,
    icon,
    withoutArrow,
    className,
    withSMAnalytics = false,
}: UniversalButtonProps) => {
    const { isMobile } = useScreenDetector();
    return (
        <>
            {href ? (
                <a
                    className={twMerge(
                        'flex w-full items-center justify-between rounded-xl border border-inactive bg-white text-start font-semibold text-black sm:h-[50px] hover:text-black',
                        className
                    )}
                    style={{
                        paddingTop: `${scaleSize(8)}px`,
                        paddingBottom: `${scaleSize(8)}px`,
                        paddingLeft: `${scaleSize(16)}px`,
                        paddingRight: `${scaleSize(16)}px`,
                    }}
                    onClick={() => {
                        if (withSMAnalytics) {
                            analyticsEvent('SocialMedia_Press', {
                                source: text,
                            });
                        }
                    }}
                    href={href}
                    target='_blank'
                    rel='noreferrer'
                >
                    <div
                        className='flex items-center gap-2'
                        style={{
                            fontSize: `${isMobile ? scaleFont(14) : 16}px`,
                        }}
                    >
                        {icon}
                        {text}
                    </div>
                    {!withoutArrow && (
                        <div className='flex items-center'>
                            <ArrowBackDark
                                className='rotate-180'
                                height={isMobile ? scaleSize(24) : 32}
                            />
                        </div>
                    )}
                </a>
            ) : (
                <button
                    className={twMerge(
                        'flex w-full items-center justify-between rounded-xl border border-inactive bg-white text-start font-semibold',
                        className
                    )}
                    style={{
                        paddingTop: `${scaleSize(8)}px`,
                        paddingBottom: `${scaleSize(8)}px`,
                        paddingLeft: `${scaleSize(16)}px`,
                        paddingRight: `${scaleSize(16)}px`,
                    }}
                    onClick={onClick}
                >
                    <div
                        className='flex items-center gap-2'
                        style={{
                            fontSize: `${isMobile ? scaleFont(14) : 16}px`,
                        }}
                    >
                        {icon}
                        {text}
                    </div>

                    <div
                        className='flex items-center font-normal'
                        style={{
                            fontSize: `${isMobile ? scaleFont(14) : 16}px`,
                        }}
                    >
                        <Observer>
                            {() =>
                                text === 'Account' ? (
                                    <p>{userStore.userData?.email}</p>
                                ) : text === 'Translation language' &&
                                  userStore?.userData?.tr_lang ? (
                                    <p>
                                        {languages[userStore.userData.tr_lang]}
                                    </p>
                                ) : null
                            }
                        </Observer>
                        {!withoutArrow && (
                            <div className='flex items-center'>
                                <ArrowBackDark
                                    className='rotate-180'
                                    height={isMobile ? scaleSize(24) : 32}
                                />
                            </div>
                        )}
                    </div>
                </button>
            )}
        </>
    );
};
