import { useEffect, useRef } from 'react';

import { isSupportedMessagingSw } from '@shared/lib/firebase';
import { LOCAL_STORAGE } from '@utils/localStorage.ts';

type UseNotificationWorker = {
    skipInit?: boolean;
};

export function useRegisterFirebaseNotificationWorker({
    skipInit = false,
}: UseNotificationWorker) {
    const initOnceRef = useRef(false);

    useEffect(() => {
        if (
            initOnceRef.current ||
            skipInit ||
            !localStorage.getItem(LOCAL_STORAGE.AuthToken)
        ) {
            return;
        }

        initOnceRef.current = true;

        (async () => {
            if (!(await isSupportedMessagingSw())) {
                console.warn('Firebase service worker not supported');
                return;
            }

            const registerFirebaseMessagingWorker = async () => {
                try {
                    const registration = await navigator.serviceWorker.register(
                        '/firebase-messaging-sw.js'
                    );
                    console.log(
                        'Service Worker registered with scope:',
                        registration.scope
                    );
                } catch (error) {
                    console.error('Service Worker registration failed:', error);
                }
            };

            if (Notification.permission === 'granted') {
                registerFirebaseMessagingWorker();
                return;
            }

            Notification.requestPermission().then((permission) => {
                if (permission === 'granted') {
                    registerFirebaseMessagingWorker();
                    return;
                }

                console.warn(
                    'Notification permission not granted, service worker will not be registered.'
                );
            });
        })();
    }, [skipInit]);
}
