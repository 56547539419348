import React, { SVGProps, useCallback, useState } from 'react';

import Chat from '@/assets/icons/chat.svg?react';
import ExploreTheWorld from '@/assets/icons/explore-the-world.svg?react';
import Hearts from '@/assets/icons/hearts.svg?react';
import Plane from '@/assets/icons/plane.svg?react';
import Ring from '@/assets/icons/ring.svg?react';
import CheckboxInput from '@/components/common/CheckBoxInput';
import UserDataFlowLayout from '@/components/layouts/UserDataFlowLayout';
import { SelectableGoal, selectableGoals } from '@/domain';
import { useScreenDetector } from '@/hooks/useScreenDetector';
import { scaleFont, scaleSize } from '@/utils/scaleSizeAndFont';
import { analyticsEvent, useAbRegistration } from '@shared/services/analytics';

import { UserInfoStepProps } from './userInfoConfig';

export interface Goal {
    icon: React.FC<SVGProps<SVGSVGElement> & { title?: string | undefined }>;
    label: string;
    value: SelectableGoal;
    description: string;
}

export const goals: Goal[] = [
    {
        icon: Ring,
        label: 'Get married',
        value: selectableGoals[0],
        description: 'Find a person to get married to and build a joint future',
    },
    {
        icon: Hearts,
        label: 'Find a relationship',
        value: selectableGoals[1],
        description:
            "Meet a soulmate, start a relationship, and then we'll see",
    },
    {
        icon: Chat,
        label: 'Chat and meet friends',
        value: selectableGoals[2],
        description:
            'Chat with people on different topics without serious plans yet',
    },
    {
        icon: ExploreTheWorld,
        label: 'Learn other cultures',
        value: selectableGoals[3],
        description:
            'Learn something new about the world and practice languages',
    },
    {
        icon: Plane,
        label: 'Travel the world',
        value: selectableGoals[4],
        description: 'Meet travel partners and share experiences',
    },
];

const GoalStep: React.FC<UserInfoStepProps> = ({ onNext, isLite }) => {
    const [goal, setGoal] = useState(() => localStorage.getItem('goal') || '');

    const { trackAbRegistrationEvent } = useAbRegistration();

    const onGoalChange = useCallback(
        (newGoal: string) => {
            setGoal(newGoal);
            analyticsEvent(`goal_${newGoal}_selected`);
            trackAbRegistrationEvent(`goal_${newGoal}_selected`);
            localStorage.setItem('goal', newGoal);
        },
        [trackAbRegistrationEvent]
    );

    const { isMobile } = useScreenDetector();

    const handleNext = () => {
        if (isLite) {
            onNext();
        } else onNext(goal !== goals[0].value && goal !== goals[1].value);
    };

    return (
        <UserDataFlowLayout
            disableNextButton={!goal}
            onNext={handleNext}
            withBarProgress
        >
            <div className='h-full w-full overflow-y-auto'>
                <div className='flex flex-col'>
                    <div className='-mb-[5px] h-[5px] w-full rounded bg-white opacity-50' />
                    <div className='h-[5px] w-[75%] rounded bg-white' />
                    <div className='px-4'>
                        <h1
                            className='font-extrabold'
                            style={{
                                marginTop: `${scaleSize(40)}px`,
                                fontSize: `${isMobile ? scaleFont(22) : 36}px`,
                            }}
                        >
                            What is your goal?
                        </h1>
                        <h2
                            style={{
                                marginTop: `${scaleSize(12)}px`,
                                fontSize: `${scaleFont(17)}px`,
                                lineHeight: `${scaleFont(22)}px`,
                            }}
                        >
                            You can change your goal at any time
                        </h2>
                    </div>
                    <div
                        className='mb-4 flex flex-col px-4'
                        style={{
                            marginTop: `${scaleSize(30)}px`,
                        }}
                    >
                        {goals.map((item) => (
                            <div key={item.label}>
                                <CheckboxInput
                                    icon={item.icon}
                                    checked={goal === item.value}
                                    onChange={() => onGoalChange(item.value)}
                                    label={item.label}
                                    withCheckbox
                                    description={item.description}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </UserDataFlowLayout>
    );
};

export default GoalStep;
