import { ReactElement, memo } from 'react';
import { twMerge } from 'tailwind-merge';

interface CheckboxProps {
    icon?: ReactElement<any, any>;
    title: string;
    children: React.ReactNode;
    className?: string;
    multiSelect?: boolean;
}

const UserProfileSection: React.FC<CheckboxProps> = ({
    icon,
    title,
    children,
    className,
    multiSelect,
}: CheckboxProps) => {
    return (
        <div
            className={twMerge(
                'flex w-full flex-col items-start gap-2',
                className
            )}
        >
            <div className='flex gap-1 text-gray-400 '>
                {icon && <div className='h-6 w-6 opacity-50'>{icon}</div>}
                <p>{title}</p>
            </div>
            <div
                className={twMerge(
                    'flex w-full flex-wrap items-center font-bold',
                    multiSelect ? 'gap-0' : 'gap-1'
                )}
            >
                {children}
            </div>
        </div>
    );
};

export default memo(UserProfileSection);
