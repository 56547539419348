export const isValidEmail = (email: string): boolean => {
    const reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;
    try {
        return reg.test(email);
    } catch (_e) {
        return true;
    }
};

export const isValidName = (name: string): boolean => {
    return /^[a-zA-Z\s]+$/.test(name);
};

export const isValidDate = (
    month: string,
    day: string,
    year: string
): boolean => {
    const date = new Date(Number(year), Number(month) - 1, Number(day));

    return (
        date.getFullYear() === Number(year) &&
        date.getMonth() === Number(month) - 1 &&
        date.getDate() === Number(day)
    );
};

export const isAdult = (age: number) => {
    return age >= 18 && age <= 120;
};

export const calculateAge = (
    year: string,
    month: string,
    day: string
): number => {
    const birthDate = new Date(Number(year), Number(month) - 1, Number(day));
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (
        monthDiff < 0 ||
        (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
        age--;
    }

    return age;
};
