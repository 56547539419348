import Facebook from '@/assets/icons/facebook_wb.svg?react';
import Instagram from '@/assets/icons/instagram_wb.svg?react';
import TikTok from '@/assets/icons/tik-tok_wb.svg?react';
import { getMediaUrl } from '@/utils/getMediaUrl';
import { AppRoutes } from '@utils/routes.ts';

export const LOGIN_CARD = [
    {
        image: getMediaUrl('landing/single.webp'),
        title: '70% of users are looking for serious relationship',
        description:
            'Join a community where the majority of members are seeking meaningful and long-lasting connections.',
    },
    {
        image: getMediaUrl('landing/ver.webp'),
        title: '87% of users are photo-verified',
        description:
            'We ensure a safer and more authentic experience to help you build genuine relationships.',
    },
    {
        image: getMediaUrl('landing/girl.webp'),
        title: '60% of users are women',
        description:
            'This creates an inclusive environment, increasing your chances of finding someone who matches your preferences.',
    },
];

export const LOGIN_MEMBERS_CARD = [
    {
        image: getMediaUrl('landing/Josh.webp'),
        text: `“Genesis really is just a dream come true for me and the person I've been looking for my entire life!”`,
        name: 'Josh',
        firstCode: 'CO',
        secondCode: 'US',
    },
    {
        image: getMediaUrl('landing/Mona.webp'),
        text: '“Thanks to Bumpy, I have found my soulmate, my forever partner.”',
        name: 'Mona',
        firstCode: 'IN',
        secondCode: 'PH',
    },
    {
        image: getMediaUrl('landing/Damian.webp'),
        text: '“It all began with a BUMP, and from there our conversations flowed endlessly.”',
        name: 'Damian',
        firstCode: 'AR',
        secondCode: 'CO',
    },
    {
        image: getMediaUrl('landing/Shakila.webp'),
        text: `“He is from Brazil and I'm from Shri-Lanka and we meet on Bumpy. He cross the world for me.”`,
        name: 'Shakila',
        firstCode: 'LK',
        secondCode: 'BR',
    },
];

export const LOGIN_STEPS_CARD = [
    {
        image: getMediaUrl('landing/profile.webp'),
        title: 'Create your profile',
        description:
            'Share details about yourself and add stunning photos to showcase your personality',
    },
    {
        image: getMediaUrl('landing/discover.webp'),
        title: 'Discover people globally',
        description:
            'Swipe through profiles of verified users worldwide to find your perfect bump.',
    },
    {
        image: getMediaUrl('landing/translate.webp'),
        title: 'Connect beyond language',
        description:
            'Start meaningful conversations with auto-translation, removing all language barriers.',
    },
];

export const LOGIN_FOOTER = [
    {
        title: 'Terms of Service',
        link: AppRoutes.TermsOfService,
    },
    {
        title: 'Privacy Policy',
        link: AppRoutes.PrivacyPolicy,
    },
    {
        title: 'Cookie Policy',
        link: AppRoutes.CookiePolicy,
    },
    {
        title: 'Community Guidelines',
        link: AppRoutes.CommunityGuidelines,
    },
    {
        title: 'Ambassador Public Agreement',
        link: AppRoutes.AmbassadorAgreement,
    },
];

export const LOGIN_FOOTER_ICONS = [
    {
        icon: Facebook,
        link: 'https://www.facebook.com/bumpy.inc/',
    },
    {
        icon: Instagram,
        link: 'https://www.instagram.com/bumpy.app',
    },
    {
        icon: TikTok,
        link: 'https://www.tiktok.com/@bumpy.app',
    },
];
