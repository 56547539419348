import { Nationalities } from '@/domain';

export const nationalitiesNames: Readonly<{
    [nationality in Nationalities]: string;
}> = {
    [Nationalities.Afghanistan]: 'Afghanistan',
    [Nationalities.Albania]: 'Albania',
    [Nationalities.Algeria]: 'Algeria',
    [Nationalities.Andorra]: 'Andorra',
    [Nationalities.Angola]: 'Angola',
    [Nationalities.Antigua_And_Barbuda]: 'Antigua and Barbuda',
    [Nationalities.Argentina]: 'Argentina',
    [Nationalities.Armenia]: 'Armenia',
    [Nationalities.Australia]: 'Australia',
    [Nationalities.Austria]: 'Austria',
    [Nationalities.Azerbaijan]: 'Azerbaijan',
    [Nationalities.Bahamas]: 'Bahamas',
    [Nationalities.Bahrain]: 'Bahrain',
    [Nationalities.Bangladesh]: 'Bangladesh',
    [Nationalities.Barbados]: 'Barbados',
    [Nationalities.Belarus]: 'Belarus',
    [Nationalities.Belgium]: 'Belgium',
    [Nationalities.Belize]: 'Belize',
    [Nationalities.Benin]: 'Benin',
    [Nationalities.Bhutan]: 'Bhutan',
    [Nationalities.Bolivia]: 'Bolivia',
    [Nationalities.Bosnia_And_Herzegovina]: 'Bosnia and Herzegovina',
    [Nationalities.Botswana]: 'Botswana',
    [Nationalities.Brazil]: 'Brazil',
    [Nationalities.Brunei]: 'Brunei',
    [Nationalities.Bulgaria]: 'Bulgaria',
    [Nationalities.Burkina_Faso]: 'Burkina Faso',
    [Nationalities.Burundi]: 'Burundi',
    [Nationalities.Cabo_Verde]: 'Cabo Verde',
    [Nationalities.Cambodia]: 'Cambodia',
    [Nationalities.Cameroon]: 'Cameroon',
    [Nationalities.Canada]: 'Canada',
    [Nationalities.Central_African_Republic]: 'Central African Republic',
    [Nationalities.Chad]: 'Chad',
    [Nationalities.Chile]: 'Chile',
    [Nationalities.China]: 'China',
    [Nationalities.Colombia]: 'Colombia',
    [Nationalities.Comoros]: 'Comoros',
    [Nationalities.Congo_Drc]: 'Congo (DRC)',
    [Nationalities.Congo_Republic]: 'Congo (Republic)',
    [Nationalities.Costa_Rica]: 'Costa Rica',
    [Nationalities.Cote_Divoire]: "Côte d'Ivoire",
    [Nationalities.Croatia]: 'Croatia',
    [Nationalities.Cuba]: 'Cuba',
    [Nationalities.Cyprus]: 'Cyprus',
    [Nationalities.Czech_Republic]: 'Czech Republic',
    [Nationalities.Denmark]: 'Denmark',
    [Nationalities.Djibouti]: 'Djibouti',
    [Nationalities.Dominica]: 'Dominica',
    [Nationalities.Dominican_Republic]: 'Dominican Republic',
    [Nationalities.Ecuador]: 'Ecuador',
    [Nationalities.Egypt]: 'Egypt',
    [Nationalities.El_Salvador]: 'El Salvador',
    [Nationalities.Equatorial_Guinea]: 'Equatorial Guinea',
    [Nationalities.Eritrea]: 'Eritrea',
    [Nationalities.Estonia]: 'Estonia',
    [Nationalities.Eswatini]: 'Eswatini',
    [Nationalities.Ethiopia]: 'Ethiopia',
    [Nationalities.Fiji]: 'Fiji',
    [Nationalities.Finland]: 'Finland',
    [Nationalities.France]: 'France',
    [Nationalities.Gabon]: 'Gabon',
    [Nationalities.Gambia]: 'Gambia',
    [Nationalities.Georgia]: 'Georgia',
    [Nationalities.Germany]: 'Germany',
    [Nationalities.Ghana]: 'Ghana',
    [Nationalities.Greece]: 'Greece',
    [Nationalities.Grenada]: 'Grenada',
    [Nationalities.Guatemala]: 'Guatemala',
    [Nationalities.Guinea]: 'Guinea',
    [Nationalities.Guinea_Bissau]: 'Guinea-Bissau',
    [Nationalities.Guyana]: 'Guyana',
    [Nationalities.Haiti]: 'Haiti',
    [Nationalities.Honduras]: 'Honduras',
    [Nationalities.Hungary]: 'Hungary',
    [Nationalities.Iceland]: 'Iceland',
    [Nationalities.India]: 'India',
    [Nationalities.Indonesia]: 'Indonesia',
    [Nationalities.Iran]: 'Iran',
    [Nationalities.Iraq]: 'Iraq',
    [Nationalities.Ireland]: 'Ireland',
    [Nationalities.Israel]: 'Israel',
    [Nationalities.Italy]: 'Italy',
    [Nationalities.Jamaica]: 'Jamaica',
    [Nationalities.Japan]: 'Japan',
    [Nationalities.Jordan]: 'Jordan',
    [Nationalities.Kazakhstan]: 'Kazakhstan',
    [Nationalities.Kenya]: 'Kenya',
    [Nationalities.Kiribati]: 'Kiribati',
    [Nationalities.Kosovo]: 'Kosovo',
    [Nationalities.Kuwait]: 'Kuwait',
    [Nationalities.Kyrgyzstan]: 'Kyrgyzstan',
    [Nationalities.Laos]: 'Laos',
    [Nationalities.Latvia]: 'Latvia',
    [Nationalities.Lebanon]: 'Lebanon',
    [Nationalities.Lesotho]: 'Lesotho',
    [Nationalities.Liberia]: 'Liberia',
    [Nationalities.Libya]: 'Libya',
    [Nationalities.Liechtenstein]: 'Liechtenstein',
    [Nationalities.Lithuania]: 'Lithuania',
    [Nationalities.Luxembourg]: 'Luxembourg',
    [Nationalities.Madagascar]: 'Madagascar',
    [Nationalities.Malawi]: 'Malawi',
    [Nationalities.Malaysia]: 'Malaysia',
    [Nationalities.Maldives]: 'Maldives',
    [Nationalities.Mali]: 'Mali',
    [Nationalities.Malta]: 'Malta',
    [Nationalities.Marshall_Islands]: 'Marshall Islands',
    [Nationalities.Mauritania]: 'Mauritania',
    [Nationalities.Mauritius]: 'Mauritius',
    [Nationalities.Mexico]: 'Mexico',
    [Nationalities.Micronesia]: 'Micronesia',
    [Nationalities.Moldova]: 'Moldova',
    [Nationalities.Monaco]: 'Monaco',
    [Nationalities.Mongolia]: 'Mongolia',
    [Nationalities.Montenegro]: 'Montenegro',
    [Nationalities.Morocco]: 'Morocco',
    [Nationalities.Mozambique]: 'Mozambique',
    [Nationalities.Myanmar_Burma]: 'Myanmar (Burma)',
    [Nationalities.Namibia]: 'Namibia',
    [Nationalities.Nauru]: 'Nauru',
    [Nationalities.Nepal]: 'Nepal',
    [Nationalities.Netherlands]: 'Netherlands',
    [Nationalities.New_Zealand]: 'New Zealand',
    [Nationalities.Nicaragua]: 'Nicaragua',
    [Nationalities.Niger]: 'Niger',
    [Nationalities.Nigeria]: 'Nigeria',
    [Nationalities.North_Korea]: 'North Korea',
    [Nationalities.North_Macedonia]: 'North Macedonia',
    [Nationalities.Norway]: 'Norway',
    [Nationalities.Oman]: 'Oman',
    [Nationalities.Pakistan]: 'Pakistan',
    [Nationalities.Palau]: 'Palau',
    [Nationalities.Palestine]: 'Palestine',
    [Nationalities.Panama]: 'Panama',
    [Nationalities.Papua_New_Guinea]: 'Papua New Guinea',
    [Nationalities.Paraguay]: 'Paraguay',
    [Nationalities.Peru]: 'Peru',
    [Nationalities.Philippines]: 'Philippines',
    [Nationalities.Poland]: 'Poland',
    [Nationalities.Portugal]: 'Portugal',
    [Nationalities.Puerto_Rico]: 'Puerto Rico',
    [Nationalities.Qatar]: 'Qatar',
    [Nationalities.Romania]: 'Romania',
    [Nationalities.Russia]: 'Russia',
    [Nationalities.Rwanda]: 'Rwanda',
    [Nationalities.Saint_Kitts_And_Nevis]: 'Saint Kitts and Nevis',
    [Nationalities.Saint_Lucia]: 'Saint Lucia',
    [Nationalities.Saint_Vincent_And_The_Grenadines]:
        'Saint Vincent and the Grenadines',
    [Nationalities.Samoa]: 'Samoa',
    [Nationalities.San_Marino]: 'San Marino',
    [Nationalities.Sao_Tome_And_Principe]: 'Sao Tome and Principe',
    [Nationalities.Saudi_Arabia]: 'Saudi Arabia',
    [Nationalities.Senegal]: 'Senegal',
    [Nationalities.Serbia]: 'Serbia',
    [Nationalities.Seychelles]: 'Seychelles',
    [Nationalities.Sierra_Leone]: 'Sierra Leone',
    [Nationalities.Singapore]: 'Singapore',
    [Nationalities.Slovakia]: 'Slovakia',
    [Nationalities.Slovenia]: 'Slovenia',
    [Nationalities.Solomon_Islands]: 'Solomon Islands',
    [Nationalities.Somalia]: 'Somalia',
    [Nationalities.South_Africa]: 'South Africa',
    [Nationalities.South_Sudan]: 'South Sudan',
    [Nationalities.South_korea]: 'South Korea',
    [Nationalities.Spain]: 'Spain',
    [Nationalities.Sri_Lanka]: 'Sri Lanka',
    [Nationalities.Sudan]: 'Sudan',
    [Nationalities.Suriname]: 'Suriname',
    [Nationalities.Sweden]: 'Sweden',
    [Nationalities.Switzerland]: 'Switzerland',
    [Nationalities.Syria]: 'Syria',
    [Nationalities.Taiwan]: 'Taiwan',
    [Nationalities.Tajikistan]: 'Tajikistan',
    [Nationalities.Tanzania]: 'Tanzania',
    [Nationalities.Thailand]: 'Thailand',
    [Nationalities.Timor_Leste]: 'Timor-Leste',
    [Nationalities.Togo]: 'Togo',
    [Nationalities.Tonga]: 'Tonga',
    [Nationalities.Trinidad_And_Tobago]: 'Trinidad and Tobago',
    [Nationalities.Tunisia]: 'Tunisia',
    [Nationalities.Turkey]: 'Turkey',
    [Nationalities.Turkmenistan]: 'Turkmenistan',
    [Nationalities.Tuvalu]: 'Tuvalu',
    [Nationalities.Uganda]: 'Uganda',
    [Nationalities.Ukraine]: 'Ukraine',
    [Nationalities.United_Arab_Emirates]: 'United Arab Emirates',
    [Nationalities.United_Kingdom]: 'United Kingdom',
    [Nationalities.United_States]: 'United States',
    [Nationalities.Uruguay]: 'Uruguay',
    [Nationalities.Uzbekistan]: 'Uzbekistan',
    [Nationalities.Vanuatu]: 'Vanuatu',
    [Nationalities.Vatican_City]: 'Vatican City',
    [Nationalities.Venezuela]: 'Venezuela',
    [Nationalities.Vietnam]: 'Vietnam',
    [Nationalities.Yemen]: 'Yemen',
    [Nationalities.Zambia]: 'Zambia',
    [Nationalities.Zimbabwe]: 'Zimbabwe',
};

export const nationalityCodes: Readonly<{
    [nationality in Nationalities]: string;
}> = {
    [Nationalities.Afghanistan]: 'AF',
    [Nationalities.Albania]: 'AL',
    [Nationalities.Algeria]: 'DZ',
    [Nationalities.Andorra]: 'AD',
    [Nationalities.Angola]: 'AO',
    [Nationalities.Antigua_And_Barbuda]: 'AG',
    [Nationalities.Argentina]: 'AR',
    [Nationalities.Armenia]: 'AM',
    [Nationalities.Australia]: 'AU',
    [Nationalities.Austria]: 'AT',
    [Nationalities.Azerbaijan]: 'AZ',
    [Nationalities.Bahamas]: 'BS',
    [Nationalities.Bahrain]: 'BH',
    [Nationalities.Bangladesh]: 'BD',
    [Nationalities.Barbados]: 'BB',
    [Nationalities.Belarus]: 'BY',
    [Nationalities.Belgium]: 'BE',
    [Nationalities.Belize]: 'BZ',
    [Nationalities.Benin]: 'BJ',
    [Nationalities.Bhutan]: 'BT',
    [Nationalities.Bolivia]: 'BO',
    [Nationalities.Bosnia_And_Herzegovina]: 'BA',
    [Nationalities.Botswana]: 'BW',
    [Nationalities.Brazil]: 'BR',
    [Nationalities.Brunei]: 'BN',
    [Nationalities.Bulgaria]: 'BG',
    [Nationalities.Burkina_Faso]: 'BF',
    [Nationalities.Burundi]: 'BI',
    [Nationalities.Cabo_Verde]: 'CV',
    [Nationalities.Cambodia]: 'KH',
    [Nationalities.Cameroon]: 'CM',
    [Nationalities.Canada]: 'CA',
    [Nationalities.Central_African_Republic]: 'CF',
    [Nationalities.Chad]: 'TD',
    [Nationalities.Chile]: 'CL',
    [Nationalities.China]: 'CN',
    [Nationalities.Colombia]: 'CO',
    [Nationalities.Comoros]: 'KM',
    [Nationalities.Congo_Drc]: 'CD',
    [Nationalities.Congo_Republic]: 'CG',
    [Nationalities.Costa_Rica]: 'CR',
    [Nationalities.Cote_Divoire]: 'CI',
    [Nationalities.Croatia]: 'HR',
    [Nationalities.Cuba]: 'CU',
    [Nationalities.Cyprus]: 'CY',
    [Nationalities.Czech_Republic]: 'CZ',
    [Nationalities.Denmark]: 'DK',
    [Nationalities.Djibouti]: 'DJ',
    [Nationalities.Dominica]: 'DM',
    [Nationalities.Dominican_Republic]: 'DO',
    [Nationalities.Ecuador]: 'EC',
    [Nationalities.Egypt]: 'EG',
    [Nationalities.El_Salvador]: 'SV',
    [Nationalities.Equatorial_Guinea]: 'GQ',
    [Nationalities.Eritrea]: 'ER',
    [Nationalities.Estonia]: 'EE',
    [Nationalities.Eswatini]: 'SZ',
    [Nationalities.Ethiopia]: 'ET',
    [Nationalities.Fiji]: 'FJ',
    [Nationalities.Finland]: 'FI',
    [Nationalities.France]: 'FR',
    [Nationalities.Gabon]: 'GA',
    [Nationalities.Gambia]: 'GM',
    [Nationalities.Georgia]: 'GE',
    [Nationalities.Germany]: 'DE',
    [Nationalities.Ghana]: 'GH',
    [Nationalities.Greece]: 'GR',
    [Nationalities.Grenada]: 'GD',
    [Nationalities.Guatemala]: 'GT',
    [Nationalities.Guinea]: 'GN',
    [Nationalities.Guinea_Bissau]: 'GW',
    [Nationalities.Guyana]: 'GY',
    [Nationalities.Haiti]: 'HT',
    [Nationalities.Honduras]: 'HN',
    [Nationalities.Hungary]: 'HU',
    [Nationalities.Iceland]: 'IS',
    [Nationalities.India]: 'IN',
    [Nationalities.Indonesia]: 'ID',
    [Nationalities.Iran]: 'IR',
    [Nationalities.Iraq]: 'IQ',
    [Nationalities.Ireland]: 'IE',
    [Nationalities.Israel]: 'IL',
    [Nationalities.Italy]: 'IT',
    [Nationalities.Jamaica]: 'JM',
    [Nationalities.Japan]: 'JP',
    [Nationalities.Jordan]: 'JO',
    [Nationalities.Kazakhstan]: 'KZ',
    [Nationalities.Kenya]: 'KE',
    [Nationalities.Kiribati]: 'KI',
    [Nationalities.Kosovo]: 'XK',
    [Nationalities.Kuwait]: 'KW',
    [Nationalities.Kyrgyzstan]: 'KG',
    [Nationalities.Laos]: 'LA',
    [Nationalities.Latvia]: 'LV',
    [Nationalities.Lebanon]: 'LB',
    [Nationalities.Lesotho]: 'LS',
    [Nationalities.Liberia]: 'LR',
    [Nationalities.Libya]: 'LY',
    [Nationalities.Liechtenstein]: 'LI',
    [Nationalities.Lithuania]: 'LT',
    [Nationalities.Luxembourg]: 'LU',
    [Nationalities.Madagascar]: 'MG',
    [Nationalities.Malawi]: 'MW',
    [Nationalities.Malaysia]: 'MY',
    [Nationalities.Maldives]: 'MV',
    [Nationalities.Mali]: 'ML',
    [Nationalities.Malta]: 'MT',
    [Nationalities.Marshall_Islands]: 'MH',
    [Nationalities.Mauritania]: 'MR',
    [Nationalities.Mauritius]: 'MU',
    [Nationalities.Mexico]: 'MX',
    [Nationalities.Micronesia]: 'FM',
    [Nationalities.Moldova]: 'MD',
    [Nationalities.Monaco]: 'MC',
    [Nationalities.Mongolia]: 'MN',
    [Nationalities.Montenegro]: 'ME',
    [Nationalities.Morocco]: 'MA',
    [Nationalities.Mozambique]: 'MZ',
    [Nationalities.Myanmar_Burma]: 'MM',
    [Nationalities.Namibia]: 'NA',
    [Nationalities.Nauru]: 'NR',
    [Nationalities.Nepal]: 'NP',
    [Nationalities.Netherlands]: 'NL',
    [Nationalities.New_Zealand]: 'NZ',
    [Nationalities.Nicaragua]: 'NI',
    [Nationalities.Niger]: 'NE',
    [Nationalities.Nigeria]: 'NG',
    [Nationalities.North_Korea]: 'KP',
    [Nationalities.North_Macedonia]: 'MK',
    [Nationalities.Norway]: 'NO',
    [Nationalities.Oman]: 'OM',
    [Nationalities.Pakistan]: 'PK',
    [Nationalities.Palau]: 'PW',
    [Nationalities.Palestine]: 'PS',
    [Nationalities.Panama]: 'PA',
    [Nationalities.Papua_New_Guinea]: 'PG',
    [Nationalities.Paraguay]: 'PY',
    [Nationalities.Peru]: 'PE',
    [Nationalities.Philippines]: 'PH',
    [Nationalities.Poland]: 'PL',
    [Nationalities.Portugal]: 'PT',
    [Nationalities.Puerto_Rico]: 'PR',
    [Nationalities.Qatar]: 'QA',
    [Nationalities.Romania]: 'RO',
    [Nationalities.Russia]: 'RU',
    [Nationalities.Rwanda]: 'RW',
    [Nationalities.Saint_Kitts_And_Nevis]: 'KN',
    [Nationalities.Saint_Lucia]: 'LC',
    [Nationalities.Saint_Vincent_And_The_Grenadines]: 'VC',
    [Nationalities.Samoa]: 'WS',
    [Nationalities.San_Marino]: 'SM',
    [Nationalities.Sao_Tome_And_Principe]: 'ST',
    [Nationalities.Saudi_Arabia]: 'SA',
    [Nationalities.Senegal]: 'SN',
    [Nationalities.Serbia]: 'RS',
    [Nationalities.Seychelles]: 'SC',
    [Nationalities.Sierra_Leone]: 'SL',
    [Nationalities.Singapore]: 'SG',
    [Nationalities.Slovakia]: 'SK',
    [Nationalities.Slovenia]: 'SI',
    [Nationalities.Solomon_Islands]: 'SB',
    [Nationalities.Somalia]: 'SO',
    [Nationalities.South_Africa]: 'ZA',
    [Nationalities.South_Sudan]: 'SS',
    [Nationalities.South_korea]: 'KR',
    [Nationalities.Spain]: 'ES',
    [Nationalities.Sri_Lanka]: 'LK',
    [Nationalities.Sudan]: 'SD',
    [Nationalities.Suriname]: 'SR',
    [Nationalities.Sweden]: 'SE',
    [Nationalities.Switzerland]: 'CH',
    [Nationalities.Syria]: 'SY',
    [Nationalities.Taiwan]: 'TW',
    [Nationalities.Tajikistan]: 'TJ',
    [Nationalities.Tanzania]: 'TZ',
    [Nationalities.Thailand]: 'TH',
    [Nationalities.Timor_Leste]: 'TL',
    [Nationalities.Togo]: 'TG',
    [Nationalities.Tonga]: 'TO',
    [Nationalities.Trinidad_And_Tobago]: 'TT',
    [Nationalities.Tunisia]: 'TN',
    [Nationalities.Turkey]: 'TR',
    [Nationalities.Turkmenistan]: 'TM',
    [Nationalities.Tuvalu]: 'TV',
    [Nationalities.Uganda]: 'UG',
    [Nationalities.Ukraine]: 'UA',
    [Nationalities.United_Arab_Emirates]: 'AE',
    [Nationalities.United_Kingdom]: 'GB',
    [Nationalities.United_States]: 'US',
    [Nationalities.Uruguay]: 'UY',
    [Nationalities.Uzbekistan]: 'UZ',
    [Nationalities.Vanuatu]: 'VU',
    [Nationalities.Vatican_City]: 'VA',
    [Nationalities.Venezuela]: 'VE',
    [Nationalities.Vietnam]: 'VN',
    [Nationalities.Yemen]: 'YE',
    [Nationalities.Zambia]: 'ZM',
    [Nationalities.Zimbabwe]: 'ZW',
};

export function getCountryNameByCode(code: string): string | undefined {
    const countryEntry = Object.entries(nationalityCodes).find(
        ([_, value]) => value === code
    );
    return countryEntry
        ? nationalitiesNames[countryEntry[0] as unknown as Nationalities]
        : '';
}
