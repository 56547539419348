export const copyToClipboard = async (text: string) => {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    textArea.style.position = 'absolute';
    textArea.style.opacity = '0';
    textArea.style.left = '-9999px';
    document.body.appendChild(textArea);
    setTimeout(() => {
        textArea.select();
        try {
            const successful = document.execCommand('copy');
            if (!successful) {
                throw new Error('Copy command failed');
            }
        } catch (err) {
            console.error('Error copying text: ', err);
        }
        document.body.removeChild(textArea);
    }, 50);
};
