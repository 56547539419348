import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import { observer } from 'mobx-react-lite';
import React, {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import ArrowDown from '@/assets/icons/arrow-forward-full.svg?react';
import LikesCompliment from '@/assets/icons/likes-compliment.svg?react';
import typingAnimation from '@/assets/lotties/typingDots.json';
import {
    MatchMessage,
    MatchMessageFlags,
    MatchMessageType,
    hasFlag,
    noFlag,
} from '@/domain';
import { WebMatchMessage } from '@/entities/match';
import matchesStore from '@/entities/match/store';
import { WebUserDetailed } from '@/entities/user';
import userStore from '@/entities/user/store';
import { useScreenDetector } from '@/hooks/useScreenDetector';
import useScrollEffect from '@/hooks/useScrollEffect';
import { scrollToBottom } from '@/utils/ScrollToBottom';
import { LOCAL_STORAGE } from '@/utils/localStorage';
import { AppRoutes } from '@/utils/routes';
import { scaleFont, scaleSize } from '@/utils/scaleSizeAndFont';
import { paginateChat } from '@/workers/sharedWorkerInstance';
import { analyticsEvent } from '@shared/services/analytics';

import Avatar from '../common/CircleAvatar/Avatar';
import ChatPhotoModal from '../modals/ChatPhotoModal';
import ChatMessage from './ChatMessage';

export const EXPRESS_MESSAGES = [
    'Hello 👋 What did you want to be as a child? Has this dream come true?',
    `Hello 👋 Tell me three interesting facts about your country, and I'll tell you about mine!`,
    'Hello 👋 How many languages do you know?',
];

interface ChatMessagesListProps {
    setIsOpenAttentionFileModal: Dispatch<
        SetStateAction<'' | 'text' | 'photo'>
    >;
    setEditMessage: React.Dispatch<
        React.SetStateAction<MatchMessage | undefined>
    >;
    setInputText: React.Dispatch<React.SetStateAction<string>>;
    containerRef: React.RefObject<HTMLDivElement>;
    inputContainerRef: React.RefObject<HTMLDivElement>;
    setIsOpenProfilePreview: React.Dispatch<React.SetStateAction<boolean>>;
    user: WebUserDetailed;
    closed: boolean;
}

const ChatMessagesList: React.FC<ChatMessagesListProps> = observer(
    ({
        setIsOpenAttentionFileModal,
        setEditMessage,
        setInputText,
        containerRef,
        inputContainerRef,
        setIsOpenProfilePreview,
        user,
        closed,
    }) => {
        const { isMobile } = useScreenDetector();
        const messagesStartRef = useRef<HTMLDivElement>(null);
        const messagesEndRef = useRef<HTMLDivElement>(null);
        const logoLottieRef = useRef<LottieRefCurrentProps>(null);
        const navigate = useNavigate();
        const [prevMessages, setPrevMessages] = useState<MatchMessage[]>([]);
        const [isFetching, setIsFetching] = useState(false);
        const [prevScrollHeight, setPrevScrollHeight] = useState<number>(0);
        const [inputHeight, setInputHeight] = useState<number>(0);
        const lastMessageId = useRef(0);
        const [showPhotoModal, setShowPhotoModal] = useState<string>('');
        const [isOpenModalCloseChat, setIsOpenModalCloseChat] =
            useState<boolean>(false);
        const [translatedMessageIds, setTranslatedMessageIds] = useState<
            number[]
        >([]);
        const { fromBottom } = useScrollEffect(containerRef, true);
        const selectedChat = matchesStore.selectedChat;
        const interactingUsersIds = matchesStore.interactingUsersIds;
        const messages = useMemo(
            () => selectedChat?.last_messages || [],
            [selectedChat]
        );
        const defaultOptions = {
            loop: true,
            style: { width: 40 },
        };
        const isTyping = interactingUsersIds.includes(selectedChat?.id || 0);
        const lastSuspiciousMessageId = messages.filter(
            (message: WebMatchMessage) =>
                hasFlag(message.flags, MatchMessageFlags.FLAG_SUSPICIOUS) &&
                message.sender_user_id === selectedChat?.user_id
        )?.[0]?.id;
        const lastTranslatedMessageId = Math.max(...translatedMessageIds);

        const formatDate = (dateString: number) => {
            return new Date(dateString).toLocaleDateString([], {
                month: 'long',
                day: 'numeric',
                year: 'numeric',
            });
        };

        const isDifferentDay = (
            currentMessage: MatchMessage,
            previousMessage: MatchMessage
        ) => {
            if (!previousMessage) return true;
            const currentDate = new Date(
                currentMessage.created_at
            ).toDateString();
            const previousDate = new Date(
                previousMessage.created_at
            ).toDateString();

            return currentDate !== previousDate;
        };

        // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
        const handleScroll = useCallback(() => {
            if (
                !selectedChat?.id ||
                selectedChat?.fetched ||
                !containerRef.current ||
                isFetching
            )
                return;
            if (
                containerRef.current.scrollTop === 0 &&
                selectedChat.last_messages &&
                selectedChat.last_messages.length > 0
            ) {
                setIsFetching(true);
                setPrevScrollHeight(fromBottom);
                setTimeout(() => {
                    paginateChat(selectedChat.id);
                }, 100);
            }
        }, [isFetching, matchesStore.selectedChat, fromBottom]);

        // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
        useEffect(() => {
            setTimeout(() => {
                scrollToBottom(containerRef);
            }, 500);
        }, [selectedChat?.id, containerRef.current]);

        useEffect(() => {
            if (prevMessages?.length === messages.length) return;
            setPrevMessages(messages);
            const container = containerRef.current;
            if (
                prevMessages?.length + 1 === messages.length &&
                fromBottom < 150
            ) {
                setTimeout(() => {
                    scrollToBottom(containerRef);
                }, 300);
            }
            if (
                container &&
                lastMessageId.current === messages[0]?.id &&
                prevScrollHeight !== container.scrollHeight
            ) {
                container.scrollTop =
                    container.scrollHeight -
                    fromBottom -
                    container.clientHeight;
            }
            lastMessageId.current = messages[0]?.id;
            setIsFetching(false);
        }, [
            containerRef,
            containerRef.current,
            messages,
            prevMessages,
            prevScrollHeight,
            fromBottom,
        ]);

        useEffect(() => {
            const container = containerRef.current;
            if (container) {
                container.addEventListener('scroll', handleScroll);
                return () =>
                    container.removeEventListener('scroll', handleScroll);
            }
        }, [containerRef.current, handleScroll]);

        useEffect(() => {
            setInputHeight(inputContainerRef.current?.offsetHeight || 0);
        }, [inputContainerRef.current?.offsetHeight]);

        // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
        useEffect(() => {
            if (isTyping && containerRef.current) {
                const { scrollTop, clientHeight, scrollHeight } =
                    containerRef.current;
                if (scrollTop + clientHeight >= scrollHeight - 45) {
                    containerRef.current.scrollTo({
                        top: containerRef.current.scrollTop + 40,
                        behavior: 'smooth',
                    });
                }
            }
        }, [isTyping]);

        if (!selectedChat) {
            return (
                <div className='flex h-[80vh] items-center justify-center'>
                    Select a chat to view messages
                </div>
            );
        }

        return (
            <div
                className={twMerge(
                    'relative mt-2 flex h-full flex-col gap-1 overflow-x-hidden p-4 pt-0',
                    isFetching && !selectedChat.fetched
                        ? 'overflow-y-hidden'
                        : 'overflow-y-auto'
                )}
                ref={containerRef}
            >
                <div ref={messagesStartRef} className='flex-1' />
                {messages.length > 0 ? (
                    messages
                        .slice()
                        .reverse()
                        .map((message: MatchMessage, index: number) => {
                            const messagesRev = messages.slice().reverse();
                            const showDate = isDifferentDay(
                                message,
                                messagesRev[index - 1]
                            );
                            const isTranslated = hasFlag(
                                message.flags,
                                MatchMessageFlags.FLAG_TR_V2_VISIBLE
                            );
                            const isBlured =
                                hasFlag(
                                    message.flags,
                                    MatchMessageFlags.FLAG_SENSITIVE
                                ) &&
                                noFlag(
                                    message.flags,
                                    MatchMessageFlags.FLAG_SENSITIVE_REVEALED
                                );
                            return (
                                <div
                                    className={twMerge(
                                        'flex flex-col items-center',
                                        message.sender_user_id !==
                                            messagesRev[index - 1]
                                                ?.sender_user_id && 'mt-1'
                                    )}
                                    key={message.id}
                                >
                                    {showDate && (
                                        <div
                                            className='mb-2 select-none font-semibold text-gray-500'
                                            style={{
                                                fontSize: `${isMobile ? scaleFont(12) : 14}px`,
                                                lineHeight: `${isMobile ? scaleFont(16) : 18}px`,
                                            }}
                                        >
                                            {formatDate(message.created_at)}
                                        </div>
                                    )}
                                    {message.type ===
                                        MatchMessageType.compliment &&
                                        message.sender_user_id !==
                                            userStore.userData?.id && (
                                            <div className='mb-2 flex w-full items-center justify-center gap-2 font-semibold text-like'>
                                                <LikesCompliment
                                                    fill='#F15873'
                                                    width={scaleSize(20)}
                                                    height={scaleSize(20)}
                                                />
                                                <span
                                                    style={{
                                                        fontSize: `${isMobile ? scaleFont(12) : 14}px`,
                                                    }}
                                                >
                                                    {`${user.first_name} sent you a compliment`}
                                                </span>
                                            </div>
                                        )}
                                    <ChatMessage
                                        containerRef={
                                            index === messages.length - 1
                                                ? containerRef
                                                : null
                                        }
                                        fileToken={
                                            selectedChat?.files_urls_query
                                        }
                                        message={message}
                                        selectedChat={selectedChat}
                                        setIsOpenAttentionFileModal={
                                            setIsOpenAttentionFileModal
                                        }
                                        setEditMessage={setEditMessage}
                                        setShowPhotoModal={setShowPhotoModal}
                                        isTranslated={isTranslated}
                                        isBlured={isBlured}
                                        user={user}
                                        isChatClosed={closed}
                                        setIsOpenProfilePreview={
                                            setIsOpenProfilePreview
                                        }
                                        setAddTranslateMessage={(
                                            value: boolean
                                        ) => {
                                            if (value && message.content_tr) {
                                                setTranslatedMessageIds(
                                                    (prev) => [
                                                        ...prev,
                                                        message.id,
                                                    ]
                                                );
                                            } else {
                                                setTranslatedMessageIds(
                                                    (prev) =>
                                                        prev.filter(
                                                            (id) =>
                                                                id !==
                                                                message.id
                                                        )
                                                );
                                            }
                                        }}
                                    />
                                    {message.id === lastTranslatedMessageId && (
                                        <div className='-mt-2 flex w-full items-start'>
                                            <span
                                                onClick={() => {
                                                    analyticsEvent(
                                                        'want_top_quality_translator_pressed',
                                                        {
                                                            source: 'chat',
                                                        }
                                                    );
                                                    localStorage.setItem(
                                                        'carousel_index',
                                                        '2'
                                                    );
                                                    navigate(AppRoutes.PayWall);
                                                }}
                                                className='z-20 cursor-pointer font-semibold text-primary text-sm'
                                                style={{
                                                    fontSize: scaleFont(12),
                                                    paddingLeft: `calc(${scaleSize(35)}px + 8px)`,
                                                }}
                                            >
                                                Do you want to get a better
                                                translation?
                                            </span>
                                        </div>
                                    )}
                                    {message.id === lastSuspiciousMessageId &&
                                        !closed && (
                                            <div className='flex w-full items-start'>
                                                <span
                                                    onClick={() =>
                                                        setIsOpenModalCloseChat(
                                                            true
                                                        )
                                                    }
                                                    className='z-20 cursor-pointer font-semibold text-error text-sm'
                                                    style={{
                                                        fontSize: scaleFont(12),
                                                        paddingLeft: `calc(${scaleSize(35)}px + 8px)`,
                                                    }}
                                                >
                                                    Does the message seem
                                                    suspicious?
                                                </span>
                                            </div>
                                        )}
                                </div>
                            );
                        })
                ) : (
                    <div className='flex h-full select-none flex-col justify-end'>
                        <p className='mx-auto text-grayIcon text-sm'>
                            Choose express-message to start conversaion
                        </p>

                        <div className='justify-centers flex flex-col rounded-xl border border-gray-300 px-3'>
                            {EXPRESS_MESSAGES.map(
                                (text: string, index: number) => (
                                    <div
                                        key={text + index}
                                        className={twMerge(
                                            'cursor-pointer border-gray-300 py-3',
                                            index !== 2 && 'border-b'
                                        )}
                                        onClick={() => setInputText(text)}
                                    >
                                        {text}
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                )}
                <div ref={messagesEndRef} />
                {userStore?.home?.me?.subscription?.type !== 'plus' &&
                    messages[0]?.sender_user_id === userStore?.userData?.id && (
                        <div className='flex w-full justify-end'>
                            <p
                                className='z-20 cursor-pointer font-semibold text-primary'
                                style={{
                                    fontSize: scaleFont(12),
                                }}
                                onClick={() => {
                                    localStorage.setItem(
                                        LOCAL_STORAGE.CarouselIndex,
                                        '3'
                                    );
                                    navigate(AppRoutes.PayWall);
                                }}
                            >
                                Has {user?.first_name} seen your message?
                            </p>
                        </div>
                    )}
                {isTyping && (
                    <div className='flex gap-2'>
                        <Avatar
                            src={`${user?.avatar_urls?.[0]}?${userStore.home?.pub_urls_query}`}
                            alt='rounded avatar'
                            imageSize='small'
                            size={scaleSize(35)}
                            onClick={() => setIsOpenProfilePreview(true)}
                        />
                        <div className='w-fit rounded-r-xl rounded-tl-xl rounded-bl bg-gray-200 p-3'>
                            <Lottie
                                {...defaultOptions}
                                animationData={typingAnimation}
                                lottieRef={logoLottieRef}
                            />
                        </div>
                    </div>
                )}
                {isOpenModalCloseChat && (
                    <div className='fixed inset-0 z-50 flex items-center justify-center'>
                        <div
                            className='fixed inset-0 bg-black opacity-50'
                            onClick={() => setIsOpenModalCloseChat(false)}
                        ></div>
                        <div className='relative z-10 flex w-[300px] flex-col gap-4 rounded-xl bg-white p-4 shadow-lg'>
                            <h2 className='font-bold'>Warning!</h2>
                            <span>
                                Do you want to report {user?.first_name}?
                            </span>
                            <div className='flex justify-end gap-5'>
                                <button
                                    className='font-semibold text-primary'
                                    onClick={() => {
                                        analyticsEvent(
                                            'sensitive_report_and_unmatch_pressed'
                                        );
                                        navigate(
                                            `${AppRoutes.Report}/${matchesStore?.selectedChat?.id}/${lastSuspiciousMessageId}`
                                        );
                                    }}
                                >
                                    REPORT
                                </button>
                                <button
                                    className='font-semibold text-primary'
                                    onClick={() =>
                                        setIsOpenModalCloseChat(false)
                                    }
                                >
                                    CANCEL
                                </button>
                            </div>
                        </div>
                    </div>
                )}
                {fromBottom > 50 && (
                    <button
                        className={twMerge(
                            'fixed right-7 z-20 rotate-90 rounded-full bg-gray-100 p-2 sm:right-1/2 sm:translate-x-[330px]'
                        )}
                        style={{
                            bottom: inputHeight + 15,
                        }}
                        onClick={() => scrollToBottom(containerRef)}
                    >
                        <ArrowDown
                            className={closed ? 'opacity-50' : 'opacity-100'}
                            style={{
                                width: `${isMobile ? scaleSize(40) - 16 : 30}`,
                                height: `${isMobile ? scaleSize(40) - 16 : 30}`,
                            }}
                        />
                    </button>
                )}
                {showPhotoModal && (
                    <ChatPhotoModal
                        isOpen={showPhotoModal}
                        onClose={() => setShowPhotoModal('')}
                        match={selectedChat}
                        user={user}
                    />
                )}
            </div>
        );
    }
);

export default ChatMessagesList;
