import { Continent, Match } from '@/domain';
import { makeAutoObservable, observable } from 'mobx';

export interface ViewedUser {
    id: number;
    time: number;
}

type LocationSettings = {
    filter_continents: Continent[];
    filter_option: 'country' | 'world';
    discover_near: boolean;
};

class DiscoveryStore {
    users_list: any = {
        users: observable([]),
        users_viewed: observable([]),
    };
    searchSettings: {
        match_gender: string;
        match_age_from: number;
        match_age_to: number;
        filter_verified_only: boolean | null;
    } = {
        match_gender: '',
        match_age_from: 0,
        match_age_to: 0,
        filter_verified_only: null,
    };
    locationSettings: any = {
        filter_continents: observable([]),
        filter_option: observable([]),
        discover_near: observable([]),
    };
    refresh: boolean | null = null;
    constructor() {
        makeAutoObservable(this);
    }

    setUsers(users: Match[]) {
        this.users_list.users = users;
    }

    addViewedUser(user: ViewedUser) {
        if (
            user &&
            !this.users_list.users_viewed.some(
                (existingUser: ViewedUser) => existingUser.id === user.id
            )
        ) {
            this.users_list.users_viewed.unshift(user);
        }
    }
    deleteUserFromViewedUsers(id: number) {
        this.users_list.users_viewed = this.users_list.users_viewed.filter(
            (u: ViewedUser) => u.id !== id
        );
    }
    setLoactionSettings(data: LocationSettings) {
        this.locationSettings = data;
    }
    clearLoactionSettings() {
        this.locationSettings = {};
    }
    clearSearchSettings() {
        this.searchSettings = {
            match_gender: '',
            match_age_from: 0,
            match_age_to: 0,
            filter_verified_only: null,
        };
    }
    updateMatchGender(gender: string) {
        this.searchSettings.match_gender = gender;
    }

    updateMatchAgeFrom(age: number) {
        this.searchSettings.match_age_from = age;
    }

    updateMatchAgeTo(age: number) {
        this.searchSettings.match_age_to = age;
    }

    updateFilterVerifiedOnly(verified: boolean) {
        this.searchSettings.filter_verified_only = verified;
    }
    updateRefresh(refresh: boolean) {
        this.refresh = refresh;
    }
}

const discoveryStore = new DiscoveryStore();
export default discoveryStore;
