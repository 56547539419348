import {Continent} from './Continent';
import {Discoverable} from './Discoverable';
import {Gender} from './Gender';
import {Goal} from './Goal';
import {Id} from './Model';

export type User = Id &
  Discoverable & {
    gender: Gender;
    goal: Goal;
    nickname: string;
    avatars: number[];
    avatar_urls?: string[];
    first_name: string;
    age?: number;
    country_code?: string;
    city_id?: number;
    verified: boolean;
    email?: string;
    work?: string;
    school?: string;
    lang: string;
    continent?: Continent;
    tr_lang: string;
    dob?: string; //DD.MM.YYYY
    interests?: number[];
    new_here?: boolean;
  };

export function getUserLangCorrected(user: User): string {
  if (user.lang === 'ua') {
    // on backend side we named our lang with wrong codes
    return 'uk';
  }
  return user.lang;
}

type UserId = {
  user_id?: number;
};

export function findByUserId<T extends UserId>(
  userId: number,
  list: T[],
): T | undefined {
  for (const item of list) {
    if (item.user_id === userId) {
      return item;
    }
  }
}

export function isFirstNameSaveable(firstName: string): boolean {
  return firstName.trim().length > 0;
}

export function isFirstNameValid(name: string): boolean {
  const reg = /^[^0-9!@#$%^&*()_+{}\[\]:;<>,.?~\/\p{P}\p{S}\p{M}\p{N}\p{C}]*$/u;
  try {
    return reg.test(name);
  } catch (e) {
    return true;
  }
}

export function userNameAndAge(user: User): string {
  let result = user.first_name;
  if (user.age) {
    result += ', ' + user.age;
  }
  return result;
}

export function userAvatarsLength(user: User): number {
  if (user.avatar_urls) {
    return user.avatar_urls.length;
  }
  return user.avatars.length;
}
