import { getDeviceType } from '@/utils/getDeviceType';
import React, { useCallback, useEffect } from 'react';
import { twMerge } from 'tailwind-merge';
import CloseButton from '../auth/CloseButton';
import ProfilePreviewModal, { ButtonProps } from './ProfilePreviewModal';

export interface ModalProps {
    isOpen: boolean;
    title?: string;
    text?: string;
    children?: React.ReactNode;
    confirmText?: string;
    cancelText?: string;
    onClose?: () => void;
    onConfirm?: () => void;
    onCancel?: () => void;
    withCloseButton?: boolean;
    setScrollPosition?: React.Dispatch<React.SetStateAction<number>>;
    className?: string;
    mainDivRef?: React.MutableRefObject<HTMLDivElement | null>;
    childDivRef?: React.MutableRefObject<HTMLDivElement | null>;
    isOpenModalDots?: boolean;
    setIsOpenModalDots?: React.Dispatch<React.SetStateAction<boolean>>;
    buttons?: ButtonProps[];
    style?: React.CSSProperties;
    zIndex?: number;
    fromLikedYou?: boolean;
}

const Modal: React.FC<ModalProps> = ({
    isOpen,
    onClose,
    title,
    text,
    children,
    confirmText = 'Confirm',
    cancelText = 'Cancel',
    onConfirm,
    onCancel,
    withCloseButton = false,
    setScrollPosition,
    className,
    mainDivRef,
    childDivRef,
    isOpenModalDots = false,
    setIsOpenModalDots = () => '',
    buttons,
    style,
    zIndex = 40,
    fromLikedYou = false,
}) => {
    const handleScroll = useCallback(() => {
        if (mainDivRef?.current && setScrollPosition) {
            setScrollPosition(mainDivRef?.current.scrollTop);
        }
    }, [mainDivRef?.current, setScrollPosition]);

    useEffect(() => {
        const div = mainDivRef?.current;
        if (div) {
            div.addEventListener('scroll', handleScroll);
        }
    }, [mainDivRef?.current, handleScroll]);

    if (!isOpen) {
        return null;
    }

    const device = getDeviceType();

    return (
        <div
            ref={fromLikedYou ? mainDivRef : childDivRef}
            className={twMerge(
                'fixed inset-0 flex flex-col items-center justify-center ',
                !fromLikedYou && 'bg-gray-500 bg-opacity-50',
                zIndex && `z-${zIndex}`
            )}
            onClick={() => !isOpenModalDots && onClose && onClose()}
        >
            <div style={style}>
                <div
                    ref={!fromLikedYou ? mainDivRef : childDivRef}
                    className={twMerge(
                        'relative my-[7vh] max-h-[86vh] w-fit max-w-[94vw] rounded-xl bg-white p-4 text-center shadow-lg sm:my-[5vh] sm:max-h-[90vh]',
                        !isOpenModalDots && 'overflow-auto',
                        isOpenModalDots && 'overflow-y-hidden',
                        device === 'iOS' && 'overscroll-none',
                        className
                    )}
                    onClick={(e) => e.stopPropagation()}
                >
                    {withCloseButton && (
                        <div className='-my-5 flex justify-end'>
                            <CloseButton onClose={onClose} />
                        </div>
                    )}
                    {title && (
                        <h2 className='font-bold text-black text-lg'>
                            {title}
                        </h2>
                    )}
                    {text && (
                        <div className='mt-1 max-w-[300px] text-gray-600 text-sm'>
                            {text}
                        </div>
                    )}
                    {children && (
                        <div className={withCloseButton ? 'mt-4' : ''}>
                            {children}
                        </div>
                    )}
                    {(onConfirm || onCancel) && (
                        <div className='mt-6 flex justify-center'>
                            {onConfirm && (
                                <button
                                    className='mx-4 rounded-md bg-green-500 px-4 py-2 text-white'
                                    onClick={onConfirm}
                                >
                                    {confirmText}
                                </button>
                            )}
                            {onCancel && (
                                <button
                                    className='rounded-md bg-gray-300 px-4 py-2 text-gray-700'
                                    onClick={onCancel}
                                >
                                    {cancelText}
                                </button>
                            )}
                        </div>
                    )}
                </div>
                <ProfilePreviewModal
                    isOpen={isOpenModalDots}
                    setIsOpen={setIsOpenModalDots}
                    buttons={buttons}
                    type='basic'
                    isRounded={!fromLikedYou}
                    className='-translate-x-1/2 absolute bottom-0 left-1/2 z-[60] sm:max-w-[500px]'
                />
            </div>
        </div>
    );
};

export default Modal;
