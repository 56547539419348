import { GoogleOAuthProvider } from '@react-oauth/google';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { initPixel } from '@shared/lib/facebook';
import App from './app/App.tsx';

initPixel();

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <BrowserRouter>
            <GoogleOAuthProvider clientId='882932835543-bh4k989ssd3tdot31c9rftft5u20lbvs.apps.googleusercontent.com'>
                <App />
            </GoogleOAuthProvider>
        </BrowserRouter>
    </React.StrictMode>
);
