import { Match, MatchesInitialDTO, User, UserDetailed } from '@/domain';
import { WebUserDetailed } from '@/entities/user/types';
import { getChatById } from '@/utils/getChatById';
import { replaceObjectInArrayById } from '@/utils/replaceObjectInArrayById';
import { makeAutoObservable, observable, reaction } from 'mobx';

interface ExtendedMatch extends Match {
    fetched?: string;
}

export interface LikedOrViewedList {
    list: Match[];
    users: WebUserDetailed[];
    total_count?: number;
    totalCount?: number;
}
interface LikedOrViewedUser {
    list: Match;
    user: User;
}
interface NewMatch {
    match: Match | null;
    user: UserDetailed | null;
}
class MatchesStore {
    selectedChat: ExtendedMatch | null = null;
    matches: any = {
        list_new: observable([]),
        list_open: observable([]),
        users: observable([]),
        unreads: observable({ visits: 0, reactions: 0, matches: 0 }),
    };
    interactingUsersIds: number[] = [];

    liked_and_viewed: {
        liked: LikedOrViewedList;
        viewed: LikedOrViewedList;
    } = {
        liked: {
            list: observable([]),
            users: observable([]),
            total_count: 0,
        },
        viewed: {
            list: observable([]),
            users: observable([]),
            total_count: 0,
        },
    };
    newMatch: NewMatch = {
        match: null,
        user: null,
    };
    newUserMessage: number | null = null;
    newBumpUser: User | null = null;
    newLikedUser: LikedOrViewedUser | null = null;
    newViewedUser: LikedOrViewedUser | null = null;
    bonusSuperSmash: number | null = null;
    reportUser: User | null = null;

    constructor() {
        makeAutoObservable(this);
        this.initializeReactions();
    }

    setIsUserInteracting(interacting: boolean, chatId: number) {
        if (interacting) {
            this.interactingUsersIds = [...this.interactingUsersIds, chatId];
        } else {
            this.interactingUsersIds = this.interactingUsersIds.filter(
                (id) => id !== chatId
            );
        }
    }

    setLikedUsers(users: LikedOrViewedList) {
        this.liked_and_viewed.liked = {
            list: users.list || [],
            users: users.users || [],
            total_count: users.total_count,
        };
    }

    setViewedUsers(users: LikedOrViewedList) {
        this.liked_and_viewed.viewed = {
            list: users.list || [],
            users: users.users || [],
            total_count: users.total_count,
        };
    }

    setMatches(matches: MatchesInitialDTO) {
        this.matches = {
            ...this.matches,
            ...matches,
        };
    }

    setNewMatch(match: NewMatch) {
        this.newMatch = {
            match: match.match || null,
            user: match.user || null,
        };
    }
    setNewChat(chat: Match) {
        const isInNewList = this.matches.list_new.find(
            (el: Match) => el.id === chat.id
        );
        if (isInNewList) {
            this.matches.list_new = replaceObjectInArrayById(
                this.matches.list_new,
                chat
            );
        } else {
            this.matches.list_open = replaceObjectInArrayById(
                this.matches.list_open,
                chat
            );
        }
        this.setIsUserInteracting(false, chat.id);
    }

    setSelectedChat(chat: Match) {
        this.selectedChat = chat;
    }

    initializeReactions() {
        reaction(
            () =>
                this.matches.list_new.map((chat: Match) =>
                    chat.last_messages?.slice()
                ),
            () => this.updateSelectedChat()
        );

        reaction(
            () =>
                this.matches.list_open.map((chat: Match) =>
                    chat?.last_messages?.slice()
                ),
            () => this.updateSelectedChat()
        );
    }

    updateSelectedChat() {
        if (this.selectedChat) {
            const updatedChat = getChatById(this.selectedChat.id, this.matches);
            if (updatedChat) {
                this.selectedChat = updatedChat;
            }
        }
    }
    updateUnreadsMatches(count: number) {
        this.matches.unreads.matches = Math.max(0, count);
    }
    setNewUserMessage(message?: number) {
        this.newUserMessage = message || null;
    }
    setNewBumpUser(user?: User) {
        this.newBumpUser = user || null;
    }
    setNewLikedUser(user?: LikedOrViewedUser) {
        this.newLikedUser = user || null;
    }
    setNewViewedUser(user?: LikedOrViewedUser) {
        this.newViewedUser = user || null;
    }
    setBonusSuperSmash(message?: number) {
        this.bonusSuperSmash = message || null;
    }
    setReportUser(user?: User) {
        this.reportUser = user || null;
    }
}

const matchesStore = new MatchesStore();
export default matchesStore;
