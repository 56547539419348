import { ErrorBoundary } from '@sentry/react';
import React from 'react';
import { Suspense } from 'react';
import { useMatch } from 'react-router-dom';

import Loader from '@/assets/icons/loading.svg?react';
import { AppRoutes as APP_ROUTES } from '@utils/routes';

import AppRoutes from './AppRoutes';
import { useRegisterFirebaseNotificationWorker } from './hooks/useRegisterFirebaseNotificationWorker';
import { useSendPostponedFirebaseHtmlEvents } from './hooks/useSendPostponedFirebaseHtmlEvents';
import { useSentry } from './hooks/useSentry';
import { useTrackScreenToFirebaseAnalytics } from './hooks/useTrackScreenToFirebaseAnalytics';
import { useWorker } from './hooks/useWorker';
import AppError from './ui/AppError';

import './index.css';

const App: React.FC = () => {
    const payWallStandalonePage = Boolean(useMatch(APP_ROUTES.PayWallMob));
    const skipInitScripts = Boolean(window.Cypress) || payWallStandalonePage;

    useRegisterFirebaseNotificationWorker({ skipInit: skipInitScripts });
    useSentry({ skipInit: skipInitScripts });
    useWorker({ skipInit: skipInitScripts });

    useTrackScreenToFirebaseAnalytics({ skipInit: Boolean(window.Cypress) });
    useSendPostponedFirebaseHtmlEvents();

    return (
        <ErrorBoundary fallback={AppError}>
            <div className='flex h-full w-screen justify-center bg-white'>
                <Suspense fallback={<Loader className='inset-0 h-8 w-8' />}>
                    <AppRoutes />
                </Suspense>
            </div>
        </ErrorBoundary>
    );
};

export default App;
