import { observer } from 'mobx-react-lite';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Area } from 'react-easy-crop';
import { twMerge } from 'tailwind-merge';

import { validateUserAvatar } from '@/api/auth';
import { addPhoto, deletePhoto } from '@/api/user';
import Cross from '@/assets/icons/cross-in-circle.svg?react';
import Loader from '@/assets/icons/loading.svg?react';
import CropModal from '@/components/modals/ImageCropModal';
import userStore from '@/entities/user/store';
import { useScreenDetector } from '@/hooks/useScreenDetector';
import { changePhotoPossition } from '@/utils/changeUserPhoto';
import { downscaleImage, maxSizePx } from '@/utils/image';
import { LOCAL_STORAGE } from '@/utils/localStorage';
import { scaleSize } from '@/utils/scaleSizeAndFont';
import { analyticsEvent } from '@shared/services/analytics';

import Button from '../Button/Button';

const ItemType = 'PHOTO_ITEM';

interface PhotoItemProps {
    id: number;
    index: number;
    moveItem: (dragIndex: number, hoverIndex: number, drop?: boolean) => void;
    onPhotoChange: (hasPhoto: boolean, token?: string) => void;
    photoCount: number;
    photoStatus: boolean;
    isLoading: boolean;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    setShowModal: (show: boolean) => void;
    setModalPhotoPreview: (preview: string | undefined) => void;
    children: ReactNode;
    setPhotoStatus?: React.Dispatch<React.SetStateAction<boolean[]>>;
    onEditPage?: boolean;
}

const PhotoItem: React.FC<PhotoItemProps> = observer(
    ({
        id,
        index,
        moveItem,
        onPhotoChange,
        photoCount,
        photoStatus,
        isLoading,
        setIsLoading,
        setShowModal,
        setModalPhotoPreview,
        children,
        onEditPage,
    }) => {
        const [photoPreview, setPhotoPreview] = useState<string | null>(null);
        const [photoToken, setPhotoToken] = useState<string | null>(null);
        const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
        const [imageSrc, setImageSrc] = useState<any>(null);
        const [isCropModalOpen, setIsCropModalOpen] = useState(false);
        const [imageBytes, setImageBytes] = useState<any>();
        const [croppedImage, setCroppedImage] = useState<any>();
        const fileInputRef = useRef<HTMLInputElement>(null);
        const { isMobile } = useScreenDetector();
        if (photoCount === 0) {
            localStorage.removeItem('avatars');
        }

        // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
        useEffect(() => {
            if (userStore?.userData?.avatar_urls?.[index]) {
                setPhotoPreview(
                    `${userStore?.userData?.avatar_urls?.[index]}?${userStore.home?.pub_urls_query}`
                );
            }
        }, [userStore?.userData?.avatar_urls]);

        const [{ isDragging }, drag] = useDrag({
            type: ItemType,
            item: { id, index },
            canDrag: !!photoPreview,
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
            end: (item, monitor) => {
                if (!monitor.didDrop()) {
                    updateAvatarsPosition(item.index, index);
                }
            },
        });

        const [, drop] = useDrop({
            accept: ItemType,
            hover: (item: { index: number }) => {
                if (!isDragging && item.index !== index && photoStatus) {
                    moveItem(item.index, index);
                    if (onEditPage) {
                        changePhotoPossition(item.index, index);
                    }
                }
            },
            drop: (item: { index: number }) => {
                if (item.index !== index && photoStatus) {
                    updateAvatarsPosition(item.index, index);
                    item.index = index;
                    changePhotoPossition(item.index, index);
                }
            },
        });

        const updateAvatarsPosition = (
            dragIndex: number,
            hoverIndex: number
        ) => {
            const avatarUrls = JSON.parse(
                localStorage.getItem('avatars') || '[]'
            );
            const movedUrl = avatarUrls[dragIndex];
            avatarUrls.splice(dragIndex, 1);
            avatarUrls.splice(hoverIndex, 0, movedUrl);
            localStorage.setItem('avatars', JSON.stringify(avatarUrls));
        };
        const handleCropComplete = async (croppedAreaPixels: Area) => {
            const canvasEle = document.createElement('canvas');
            canvasEle.width = croppedAreaPixels.width;
            canvasEle.height = croppedAreaPixels.height;
            const context = canvasEle.getContext('2d');

            const imageObj = new Image();
            imageObj.src = imageSrc;
            imageObj.onload = () => {
                context?.drawImage(
                    imageObj,
                    croppedAreaPixels.x,
                    croppedAreaPixels.y,
                    croppedAreaPixels.width,
                    croppedAreaPixels.height,
                    0,
                    0,
                    croppedAreaPixels.width,
                    croppedAreaPixels.height
                );

                const croppedImageUrl = canvasEle.toDataURL('image/jpeg');
                setCroppedImage(croppedImageUrl);
                setModalPhotoPreview(croppedImageUrl);

                downscaleImage(croppedImageUrl, async (blob) => {
                    if (blob) {
                        const arrayBuffer = await blob.arrayBuffer();
                        console.log('ImageBytes:', arrayBuffer.byteLength);
                        setImageBytes(arrayBuffer);
                    }
                });

                setIsCropModalOpen(false);
            };
        };

        useEffect(() => {
            if (imageBytes) {
                handleSendPhoto();
            }
        }, [imageBytes]);

        const handleSendPhoto = async () => {
            try {
                setIsLoading(true);
                if (onEditPage) {
                    const result = await addPhoto(
                        localStorage.getItem(LOCAL_STORAGE.AuthToken) || '',
                        imageBytes,
                        photoCount
                    );
                    userStore.setUserData(result.result.me.user);
                    userStore.setMeData(result.result.me);
                    analyticsEvent('EditProfile_Avatar_Changed');
                } else {
                    const imageToken = await validateUserAvatar(imageBytes);
                    onPhotoChange(true, imageToken.result.token);
                    const avatars = JSON.parse(
                        localStorage.getItem('avatars') || '[]'
                    );
                    avatars.push(imageToken.result.token);
                    localStorage.setItem('avatars', JSON.stringify(avatars));
                    setPhotoToken(imageToken.result.token);
                }
                setIsLoading(false);
                setPhotoPreview(croppedImage);
            } catch (error) {
                console.error('Validation error:', error);
                setIsLoading(false);
                setShowModal(true);
                setPhotoPreview(null);
            }
            localStorage.removeItem(LOCAL_STORAGE.BlockRefresh);
            setIsLoading(false);
        };

        const handlePhotoChange = async (
            event: React.ChangeEvent<HTMLInputElement>
        ) => {
            if (typeof FileReader === 'undefined') {
                console.error(
                    'FileReader is not supported in this environment.'
                );
                return;
            }
            localStorage.setItem(LOCAL_STORAGE.BlockRefresh, 'true');
            const file = event.target.files?.[0];
            if (!file) {
                setIsLoading(false);
                setPhotoPreview(null);
                setModalPhotoPreview(undefined);
                setShowModal(false);
                onPhotoChange(false);
                return;
            }

            const reader = new FileReader();
            reader.onload = () => {
                const originalDataUrl = reader.result as string;

                const image = new Image();
                image.onload = () => {
                    const { width, height } = image;
                    const maxSize = maxSizePx;
                    if ((width > maxSize || height > maxSize) && isMobile) {
                        const scale = Math.min(
                            maxSize / width,
                            maxSize / height
                        );
                        const newWidth = Math.floor(width * scale);
                        const newHeight = Math.floor(height * scale);
                        console.log(
                            'old w: ',
                            width,
                            'old h:',
                            height,
                            'new w:',
                            newWidth,
                            'new h',
                            newHeight
                        );

                        const canvas = document.createElement('canvas');
                        canvas.width = newWidth;
                        canvas.height = newHeight;
                        const ctx = canvas.getContext('2d');

                        if (ctx) {
                            ctx.drawImage(image, 0, 0, newWidth, newHeight);
                            const downscaledDataUrl = canvas.toDataURL(
                                'image/jpeg',
                                0.75
                            );

                            setImageSrc(downscaledDataUrl);
                        } else {
                            setImageSrc(originalDataUrl);
                        }
                    } else {
                        setImageSrc(originalDataUrl);
                    }

                    setIsCropModalOpen(true);
                };

                image.onerror = () => {
                    console.error('Error loading the image.');
                };

                image.src = originalDataUrl;
            };

            reader.onerror = (error) => {
                console.error('Error reading the file', error);
            };

            reader.readAsDataURL(file);
        };

        const handleButtonClick = () => {
            fileInputRef.current?.click();
        };

        const handleDelete = async () => {
            setDeleteLoading(true);
            if (onEditPage) {
                try {
                    const result = await deletePhoto(
                        localStorage.getItem(LOCAL_STORAGE.AuthToken) || '',
                        userStore.userData?.avatars[index] || 9
                    );
                    userStore.setUserData(result.result.me.user);
                    userStore.setMeData(result.result.me);
                } catch (error) {
                    console.error(error);
                }
            } else if (photoToken) {
                const avatars = JSON.parse(
                    localStorage.getItem('avatars') || '[]'
                );

                const updatedAvatars = avatars.filter(
                    (token: string) => token !== photoToken
                );

                localStorage.setItem('avatars', JSON.stringify(updatedAvatars));
            }
            setTimeout(() => {
                setDeleteLoading(false);
                setPhotoPreview(null);
                setModalPhotoPreview(undefined);
                onPhotoChange(false);
            }, 200);
        };
        const opacity = isDragging ? 0.4 : 1;
        const label = index === 0 ? 'Main' : `${index + 1}`;
        return (
            <>
                <CropModal
                    isOpen={isCropModalOpen}
                    onClose={() => {
                        setIsCropModalOpen(false);
                        setIsLoading(false);
                        fileInputRef.current!.value = '';
                    }}
                    imageSrc={imageSrc}
                    onCropComplete={handleCropComplete}
                />
                <div
                    ref={(node) => drag(drop(node))}
                    style={{ opacity, transform: 'rotate(0deg)' }}
                    className='flex flex-col items-center rounded-2xl'
                >
                    <div className='relative z-30 cursor-pointer rounded-2xl'>
                        {photoPreview ? (
                            <>
                                <img
                                    src={photoPreview}
                                    alt='Photo_Preview'
                                    className='h-[39vw] w-[29vw] rounded-xl object-cover sm:h-44 sm:w-36'
                                />
                                {index !== 0 && (
                                    <button
                                        onClick={handleDelete}
                                        className={twMerge(
                                            'absolute top-2 right-2 rounded-full text-center transition-all',
                                            deleteLoading
                                                ? 'bg-white'
                                                : 'bg-primary'
                                        )}
                                        style={{
                                            width: scaleSize(26),
                                            height: scaleSize(26),
                                        }}
                                    >
                                        {deleteLoading ? (
                                            <Loader
                                                className='relative'
                                                style={{
                                                    width: scaleSize(26),
                                                    height: scaleSize(26),
                                                }}
                                            />
                                        ) : (
                                            <Cross
                                                className='-top-[1px] -left-[1px] relative h-[24px] w-[24px]'
                                                style={{
                                                    width: scaleSize(28),
                                                    height: scaleSize(28),
                                                }}
                                            />
                                        )}
                                    </button>
                                )}
                            </>
                        ) : (
                            <Button
                                onClick={handleButtonClick}
                                disabled={isLoading}
                                className={twMerge(
                                    'flex h-[39vw] w-[29vw] items-center justify-center rounded-xl sm:h-44 sm:w-36 disabled:hover:border-white hover:border-black',
                                    onEditPage ? 'bg-placeholder' : 'bg-white'
                                )}
                            >
                                {children}
                            </Button>
                        )}
                        <input
                            type='file'
                            accept='image/*'
                            ref={fileInputRef}
                            onChange={handlePhotoChange}
                            className='hidden'
                        />
                        <span className='absolute bottom-2 left-2 rounded-xl bg-white px-2 py-1 text-black text-xs'>
                            {label}
                        </span>
                    </div>
                </div>
            </>
        );
    }
);

export default PhotoItem;
