import EarthIcon from '@/assets/icons/earth-icon.svg?react';
import Heart from '@/assets/icons/heart-red.svg?react';
import Hello from '@/assets/icons/yellow-hand.svg?react';
import userStore from '@/entities/user/store';
import { scaleSize } from '@/utils/scaleSizeAndFont';
import { observer } from 'mobx-react-lite';
import ReactCountryFlag from 'react-country-flag';
import Avatar from '../common/CircleAvatar/Avatar';

interface NewLikeNotificationsProps {
    data: any;
    subscription?: boolean;
    isSuperSmash?: boolean;
}

const NewLikeNotifications: React.FC<NewLikeNotificationsProps> = observer(
    ({ data, subscription = false, isSuperSmash = false }) => {
        return (
            <>
                <div className='relative'>
                    {data.data.list.is_visible &&
                    data.data.user?.country_code ? (
                        <ReactCountryFlag
                            svg
                            className='absolute bottom-0 z-20'
                            alt={`${data.data?.country_code}`}
                            countryCode={`${data.data.user?.country_code}`}
                            style={{
                                borderRadius: '50%',
                                width: `${scaleSize(18)}px`,
                                height: `${scaleSize(18)}px`,
                                objectFit: 'cover',
                            }}
                        />
                    ) : (
                        <EarthIcon className='absolute bottom-0' />
                    )}
                    {data.data.list.is_visible && (
                        <div
                            className='absolute z-10 rounded-full border-2 border-white bg-lime-400'
                            style={{
                                right: `-${scaleSize(6)}px`,
                                top: `${scaleSize(10)}px`,
                                width: `${scaleSize(12)}px`,
                                height: `${scaleSize(12)}px`,
                            }}
                        />
                    )}
                    <Avatar
                        src={`${data.data?.list?.user?.avatar_url || data.data?.user?.avatar_urls[0]}?${userStore.home?.pub_urls_query}`}
                        alt='rounded avatar'
                        blur={!data.data?.list?.is_visible}
                        size={scaleSize(40)}
                        imageSize='small'
                    />
                </div>
                <span style={{ fontSize: scaleSize(14) }}>
                    {isSuperSmash
                        ? `${data.data?.user?.first_name} has given you a special sympathy - Like`
                        : subscription
                          ? `${data.data?.user?.first_name} just sent you Hello`
                          : 'You got Hello from someone'}
                </span>
                {subscription && !isSuperSmash && (
                    <Hello width={scaleSize(28)} />
                )}
                {isSuperSmash && <Heart fill='white' width={scaleSize(28)} />}
            </>
        );
    }
);

export default NewLikeNotifications;
