import SexualOrient from '@/assets/icons/sexual-oriet.svg?react';
import { UserSexOrientation } from '@/domain';
import { getEnumOptions } from '@/utils/getEnumOptions';
import React from 'react';
import SingleSelectStep from '../../common/SingleSelectStep';
import UserDataFlowLayout from '../../layouts/UserDataFlowLayout';
import { UserInfoStepProps } from './userInfoConfig';

const SexualOrientationStep: React.FC<UserInfoStepProps> = ({ onNext }) => {
    return (
        <UserDataFlowLayout withBarProgress>
            <div className='-mb-[5px] h-[5px] w-full bg-white/50'>
                <div className='h-[5px] w-[30%] bg-white' />
            </div>
            <SingleSelectStep
                LogoComponent={SexualOrient}
                title='What is your sexual
                    orientation?'
                options={getEnumOptions(UserSexOrientation)}
                withSkip
                onNext={onNext}
                field='sex_orientation'
                fromEdit={false}
            />
        </UserDataFlowLayout>
    );
};

export default SexualOrientationStep;
