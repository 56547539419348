import { observer } from 'mobx-react-lite';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { sendReactionDiscovery } from '@/api/user';
import Chat from '@/assets/icons/chat-nav.svg?react';
import Heart from '@/assets/icons/heart-red.svg?react';
import PencilIcon from '@/assets/icons/pencil.svg?react';
import matchesStore from '@/entities/match/store';
import { LOCAL_STORAGE } from '@/utils/localStorage';
import { AppRoutes } from '@/utils/routes';
import { useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

const ProfilePreviewActions: React.FC<{
    type?: string;
    onClose?: () => void;
    className?: string;
    actionSize?: number;
    nickname?: string;
    handleActions?: any;
    shadowCopy?: boolean;
    actionToBottom?: boolean;
}> = observer(
    ({
        type,
        onClose,
        className,
        actionSize = 50,
        nickname,
        handleActions,
        shadowCopy = false,
        actionToBottom = false,
    }) => {
        const navigate = useNavigate();

        const sendReaction = async (side: string) => {
            const reactions: Record<string, string> = {
                right: 'smash',
                left: 'pass',
                top: 'super_smash',
            };
            try {
                const res = await sendReactionDiscovery(
                    localStorage.getItem(LOCAL_STORAGE.AuthToken) || '',
                    reactions[side],
                    nickname || ''
                );
                matchesStore.setMatches({
                    ...matchesStore.matches,
                    list_new: [
                        res.result.match,
                        ...matchesStore.matches.list_new,
                    ],
                    users: [res.result.user, ...matchesStore.matches.users],
                    unreads: {
                        ...matchesStore.matches.unreads,
                        matches: matchesStore.matches.unreads.matches + 1,
                    },
                });
            } catch (error: any) {
                console.log(error.response?.data?.m);
            }
        };
        const actions = [
            {
                name: 'EditMyData',
                icon: (
                    <PencilIcon
                        width={actionSize}
                        height={actionSize}
                        fill='#3F3F3F'
                    />
                ),
                event: () => navigate(AppRoutes.EditProfile),
            },
            {
                name: 'Chat',
                icon: (
                    <Chat
                        fill='#51aed4'
                        width={actionSize}
                        height={actionSize}
                    />
                ),
                event: handleActions || onClose,
            },
            {
                name: 'Pass',
                icon: (
                    <div
                        className='font-bold text-error'
                        style={{
                            width: actionSize,
                            height: actionSize,
                            fontSize: actionSize,
                        }}
                    >
                        &#x2715;
                    </div>
                ),
                event: () => sendReaction('left'),
            },
            {
                name: 'SuperSmash',
                icon: (
                    <Heart
                        fill='#F15873'
                        width={actionSize}
                        height={actionSize}
                    />
                ),
                event: () => sendReaction('top'),
            },
            {
                name: 'Smash',
                icon: (
                    <div
                        style={{
                            width: actionSize,
                            height: actionSize,
                            fontSize: actionSize,
                        }}
                    >
                        👋
                    </div>
                ),
                event: () => sendReaction('right'),
            },
        ];

        const filteredActions = actions.filter((action) => {
            switch (type) {
                case 'myProfile':
                    return action.name === 'EditMyData';
                case 'chat':
                    return action.name === 'Chat';
                case 'basic':
                    return ['Pass', 'SuperSmash', 'Smash'].includes(
                        action.name
                    );
                default:
                    return false;
            }
        });

        return (
            <div
                className={twMerge(
                    '-translate-x-1/2 gradient-shadow fixed left-1/2 z-20 mb-[7vh] flex w-[94vw] max-w-[500px] transform items-center justify-center rounded-ee-xl rounded-es-xl pb-4 sm:mb-[5vh]',
                    className,
                    actionToBottom ? '-bottom-7 sm:bottom-0' : 'bottom-0',
                    type === 'basic' && 'w-full',
                    type === 'myProfile' && shadowCopy && 'mb-3'
                )}
            >
                {filteredActions.map((action) => (
                    <div
                        key={action.name}
                        onClick={() => {
                            if (action.event) {
                                action.event();
                            }
                        }}
                        className={twMerge(
                            'z-30 mx-auto flex cursor-pointer items-center justify-center rounded-full bg-white p-2 text-center text-4xl leading-none transition-all duration-300',
                            type !== 'basic' &&
                                actionSize % 4 !== 0 &&
                                `h-${actionSize}w-${actionSize}`
                        )}
                    >
                        {action.icon}
                    </div>
                ))}
            </div>
        );
    }
);

export default ProfilePreviewActions;
