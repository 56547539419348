import { scaleFont, scaleSize } from '@/utils/scaleSizeAndFont';
import React from 'react';

interface SelectionBoxProps {
    title: string;
    children: React.ReactNode;
}

const SelectionBox: React.FC<SelectionBoxProps> = ({ title, children }) => {
    return (
        <div
            className='flex w-full justify-center'
            style={{
                marginTop: `${scaleSize(28)}px`,
            }}
        >
            <div className='flex w-full flex-col'>
                <div
                    className='mb-1 ml-4'
                    style={{
                        fontSize: `${scaleFont(14)}px`,
                        lineHeight: `${scaleFont(18)}px`,
                    }}
                >
                    <p>{title}</p>
                </div>
                <div className='flex w-full space-x-4 rounded-xl'>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default SelectionBox;
