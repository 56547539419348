// Keep this config separately for allow importing in the service worker without other config context
export const FIREBASE_CONFIG = {
    apiKey: 'AIzaSyAtukC83mE7aqONLOJMyoTkJuYIqyBgr8w',
    authDomain: 'smash-the-dating-app.firebaseapp.com',
    databaseURL: 'https://smash-the-dating-app.firebaseio.com',
    projectId: 'smash-the-dating-app',
    storageBucket: 'smash-the-dating-app.firebasestorage.app',
    messagingSenderId: '882932835543',
    appId: '1:882932835543:web:b4e4340b31061295eebc92',
    measurementId: 'G-Z3Q2XV1G3Z',
};
