import { useEffect, useState } from 'react';

export const useScreenDetector = () => {
    const [width, setWidth] = useState(window.innerWidth);

    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);

        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);
    const screnWidth = window.innerWidth;
    const screnHeight = window.innerHeight;
    const isMobile = width <= 640;
    const isTablet = width <= 1024;
    const isDesktop = width > 1024;

    return { isMobile, isTablet, isDesktop, screnWidth, screnHeight };
};
