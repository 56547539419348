import { NavigateFunction } from 'react-router-dom';
import { LOCAL_STORAGE } from './localStorage';
import { AppRoutes } from './routes';

export const handleGoToPayWall = (
    navigate: NavigateFunction,
    index: number
) => {
    localStorage.setItem(LOCAL_STORAGE.CarouselIndex, `${index}`);
    navigate(AppRoutes.PayWall);
};
