import { autorun } from 'mobx';
import { useEffect, useRef } from 'react';

import userStore from '@/entities/user';
import { BUILD_ID } from '@/shared/config';
import { initialize, setUser } from '@shared/lib/sentry';

type UseSentryInit = {
    skipInit?: boolean;
};

export function useSentry({ skipInit }: UseSentryInit) {
    const initOnceRef = useRef(false);

    useEffect(() => {
        if (initOnceRef.current || skipInit) {
            return;
        }

        initOnceRef.current = true;

        initialize({
            dsn: 'https://19368426f3ee9da895832810e0e8df52@o4508879134785536.ingest.de.sentry.io/4508879538159696',
            release: BUILD_ID,
            environment: import.meta.env.MODE,
        });
    }, [skipInit]);

    useEffect(() => {
        if (!initOnceRef.current || skipInit) {
            return;
        }

        const dispose = autorun(() => {
            const userId = userStore.userData?.id;

            setUser({
                id: userId,
            });
        });

        return dispose;
    }, [skipInit]);
}
