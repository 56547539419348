export type Goal =
  | 'unknown'
  | 'chat'
  | 'networking'
  | 'learn'
  | 'travel'
  | 'relationship'
  | 'marriage';

export type SelectableGoal = Exclude<Goal, 'unknown'>;

export const selectableGoals: SelectableGoal[] = [
  'marriage',
  'relationship',
  'chat',
  'learn',
  'travel',
];

export enum GoalBit {
  CHAT = 1, //1
  NETWORKING, //2
  LEARN, //4
  TRAVEL, //8
  RELATIONSHIP, //16
  MARRIAGE, //32
}

const bitStart = GoalBit.CHAT;
const bitEnd = GoalBit.MARRIAGE;

const bitToGoal: {[bit in GoalBit]: SelectableGoal} = {
  [GoalBit.CHAT]: 'chat',
  [GoalBit.NETWORKING]: 'networking',
  [GoalBit.LEARN]: 'learn',
  [GoalBit.TRAVEL]: 'travel',
  [GoalBit.RELATIONSHIP]: 'relationship',
  [GoalBit.MARRIAGE]: 'marriage',
};

export function unpackGoalsBits(goalsBits?: GoalBit): SelectableGoal[] {
  const result: SelectableGoal[] = [];
  if (goalsBits) {
    for (let i = bitStart; i <= bitEnd; ++i) {
      if ((goalsBits & (1 << i)) !== 0) {
        result.push(bitToGoal[i]);
      }
    }
  }
  return result;
}

const goalToBit: {[goal in SelectableGoal]: GoalBit} = {
  chat: GoalBit.CHAT,
  networking: GoalBit.NETWORKING,
  learn: GoalBit.LEARN,
  travel: GoalBit.TRAVEL,
  relationship: GoalBit.RELATIONSHIP,
  marriage: GoalBit.MARRIAGE,
};

export function packGoalsBits(goals: SelectableGoal[]): number {
  let result = 0;
  for (const goal of goals) {
    result |= 1 << goalToBit[goal];
  }
  return result;
}
