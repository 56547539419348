import {
    Home,
    Me,
    Subscription,
    SubscriptionType,
    UserDetailed,
    VerificationStatus,
    userAvatarsLength,
} from '@/domain';
import { makeAutoObservable, runInAction } from 'mobx';
import { WebUserDetailed } from './types';

class UserStore {
    userData: WebUserDetailed | null = null;
    home: any;
    userToken: string | null = null;
    verifyNotification: string | null = null;
    constructor() {
        makeAutoObservable(this);
    }

    setUserData(userData: UserDetailed) {
        this.userData = userData;
    }

    setHomeData(home: Home) {
        this.home = home;
    }

    setUserToken(token: string) {
        this.userToken = token;
    }

    setTotalReactions(count: number) {
        if (this.home?.reactions?.total_count) {
            this.home.reactions.total_count = count;
        } else {
            console.warn('Home or reactions is not defined');
        }
    }

    setMeData(me: Me) {
        this.home.me = me;
    }

    setSubscribtionData(subscription: Subscription) {
        this.home.me.subscription = subscription;
    }

    setSubscribtionType(type: SubscriptionType) {
        this.home.me.subscription.type = type;
    }

    setOnboardingsData(onboardings: string[]) {
        this.home.onboardings = onboardings;
    }

    setVerificationStatus(status: VerificationStatus) {
        if (status === 'DONE') {
            if (this.userData) {
                this.userData.verified = true;
            }
        } else {
            if (this.userData) {
                this.userData.verified = false;
            }
        }
        this.home.me.settings.verification_status = status;
    }
    setVerifyNotification(message?: string) {
        this.verifyNotification = message || null;
    }
    get fillProgress(): number {
        const user = this?.userData;

        if (!user) return 0;

        let progress = 0;
        if (user.avatars) {
            progress = userAvatarsLength(user) * 3.125; //25
        }
        if (user.about && user.about.length > 0) {
            progress += 5; //30
        }
        if (user.school) {
            progress += 5; //35
        }
        if (user.school_info) {
            progress += 5; //40
        }
        if (user.work) {
            progress += 5; //45
        }
        if (user.work_info) {
            progress += 5; //50
        }
        if (user.interests && user.interests.length > 0) {
            progress += 5; //55
        }
        if (user.languages) {
            progress += 5; //60
        }
        if (user.verified) {
            progress += 7.5; //67.5
        }
        if (user.height_sm !== 0) {
            progress += 2.5; //70
        }
        if (user.weight_kg !== 0) {
            progress += 2.5; //72.5
        }
        if (user.sex_orientation !== 0) {
            progress += 2.5; //75
        }
        if (user.star_sign !== 0) {
            progress += 2.5; //77.5
        }
        if (user.exercise !== 0) {
            progress += 2.5; //80
        }
        if (user.education_level !== 0) {
            progress += 2.5; //82.5
        }
        if (user.marital_status !== 0) {
            progress += 2.5; //85
        }
        if (user.kids !== 0) {
            progress += 2.5; //87.5
        }
        if (user.drinking !== 0) {
            progress += 2.5; //90
        }
        if (user.smoking !== 0) {
            progress += 2.5; //92.5
        }
        if (user.pets !== 0) {
            progress += 2.5; //95
        }
        if (user.religion !== 0) {
            progress += 2.5; //97.5
        }
        if (user.values !== 0) {
            progress += 2.5; //100
        }
        if (progress > 100) {
            progress = 100;
        }
        return Math.round(progress);
    }
    updateSettings(newSettings: Partial<typeof this.home.me.settings>) {
        runInAction(() => {
            this.home.me.settings = {
                ...this.home.me.settings,
                ...newSettings,
            };
        });
    }
}

const userStore = new UserStore();
export default userStore;
