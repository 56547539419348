import {Continent} from './Continent';
import {CountryData} from './Country';
import {MatchGender} from './Gender';
import {GoalBit} from './Goal';
import {isArraySimpleEqual, packBits, unpackBits} from './Model';
import {Notifications} from './Notifications';
import {ReviewStatus} from './ReviewStatus';
import {SupportedLang, SupportedLanguagesEnd} from './SupportedLang';

export type VerificationStatus = 'NONE' | 'PENDING' | 'DONE' | 'ERROR';

export enum AmbassadorStatus {
  NONE = 0,
  PROMPT = 1,
  SUBMITTED,
  APPROVED,
  APPROVE_REVIEWED,
  DECLINED,
  DECLINE_REVIEWED,
}

export enum MatchesFilter {
  ACTIVE = 0,
  UNREAD = 1,
  ALL = 2,
}

export enum FilterFlags {
  AGE_EXT = 1,
  LANGS_EXT = 2,
  COUNTRIES_EXT = 4,
  GOALS_EXT = 8,
}

export type Settings = {
  match_gender: MatchGender;
  distance_visible: boolean;
  age_visible: boolean;
  match_age_from: number;
  match_age_to: number;
  verification_status: VerificationStatus;
  discover_near: boolean;
  incognito: boolean;
  review_status_android: ReviewStatus;
  review_status_android_at?: number;
  review_status_ios: ReviewStatus;
  review_status_ios_at?: number;
  filter_continents: Continent[];
  filter_verified_only: boolean;
  name_modified: boolean;
  birthday_modified: boolean;
  gender_modified: boolean;
  online_visible: boolean;
  city_sync_at?: number;
  tr_matches: boolean;
  notifications: Notifications;
  filter_langs: number[];
  filter_goals?: GoalBit;
  filter_countries?: CountryData[];
  filter_flags: number;
  best_avatar: boolean;
  ambassador_status?: AmbassadorStatus;
  matches_filter: MatchesFilter;
};

export const defaultSettings: Settings = {
  match_gender: 'U',
  distance_visible: true,
  age_visible: true,
  match_age_from: 18,
  match_age_to: 60,
  verification_status: 'NONE',
  discover_near: false,
  incognito: false,
  review_status_android: 'NONE',
  review_status_ios: 'NONE',
  filter_continents: [],
  filter_verified_only: false,
  filter_flags: 0,
  name_modified: false,
  birthday_modified: false,
  gender_modified: false,
  online_visible: false,
  tr_matches: true,
  notifications: ~0 ^ Notifications.Visit_Remote,
  best_avatar: true,
  matches_filter: MatchesFilter.ACTIVE,
  filter_langs: [0, 0],
};

export function packFilterLanguages(languages: SupportedLang[]): number[] {
  return packBits(languages, 2);
}

export function unpackFilterLanguages(
  languages?: SupportedLang[],
): SupportedLang[] {
  if (languages) {
    const unpacked = unpackBits(languages);
    const filtered = unpacked.filter((value) => value <= SupportedLanguagesEnd);
    if (filtered.length > 0) {
      return filtered;
    }
  }
  return [];
}

export function areDiscoverySettingsEqual(a: Settings, b: Settings): boolean {
  return (
    a.match_age_from === b.match_age_from &&
    a.match_age_to === b.match_age_to &&
    a.match_gender === b.match_gender &&
    a.discover_near === b.discover_near &&
    a.filter_verified_only === b.filter_verified_only &&
    a.filter_flags === b.filter_flags &&
    isArraySimpleEqual(a.filter_langs || [], b.filter_langs || []) &&
    a.filter_goals === b.filter_goals &&
    isArraySimpleEqual(a.filter_countries || [], b.filter_countries || []) &&
    isArraySimpleEqual(a.filter_continents, b.filter_continents)
  );
}
