import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { getLikedYou, getMatchUserData } from '@/api/user';
import ErrorIcon from '@/assets/icons/error-outlined.svg?react';
import Heart from '@/assets/icons/heart-red.svg?react';
import Shield from '@/assets/icons/shield.svg?react';
import { Match, User, UserDetailed } from '@/domain';
import matchesStore from '@/entities/match/store';
import userStore from '@/entities/user/store';
import { useScreenDetector } from '@/hooks/useScreenDetector';
import { handleGoToPayWall } from '@/utils/handleGoToPayWall';
import { LOCAL_STORAGE } from '@/utils/localStorage';
import { AppRoutes } from '@/utils/routes';
import { scaleSize } from '@/utils/scaleSizeAndFont';
import { analyticsEvent } from '@shared/services/analytics';

import BumpModal from '../modals/BumpModal';
import LikedYouModals from '../modals/LikedYouModals';
import NotificationModals from '../modals/NotificationModals';
import NewBumpNotifications from './NewBumpNotification';
import NewLikeNotifications from './NewLikeNotification';
import NewMessageNotifications from './NewMessageNotification';
import NewViewNotifications from './NewViewNotification';

type SelectedUsersType = {
    list: Match;
    user: UserDetailed;
    type: string;
};
interface NotificationProps {
    userId?: number;
}

const Notifications: React.FC<NotificationProps> = observer(({ userId }) => {
    const navigate = useNavigate();
    const hasRun = useRef(false);
    const [showNotification, setShowNotification] = useState(false);
    const [isSuperSmash, setIsSuperSmash] = useState(false);
    const [subscription, setSubscription] = useState(false);
    const [showVerificationModal, setShowVerificationModal] = useState(0);
    const [notificationList, setNotificationList] = useState<any[]>([]);
    const [order, setOrder] = useState<number>(0);
    const [enableNotifications, setEnableNotifications] =
        useState<boolean>(false);
    const [selectedUsers, setSelectedUsers] = useState<SelectedUsersType[]>([]);
    const [bumpUser, setBumpUser] = useState<any | null>();
    const [isOpenBumpModal, setIsOpenBumpModal] = useState<boolean>(false);
    const { isMobile } = useScreenDetector();
    const userNickname = localStorage.getItem(LOCAL_STORAGE.UserNickname);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        setSubscription(userStore?.home?.me?.subscription?.type === 'plus');
    }, [userStore?.home?.me?.subscription?.type]);

    const resetNotification = (callback: () => void) => {
        setShowNotification(true);
        setTimeout(() => {
            setShowNotification(false);
            callback();
            if (order !== 0) {
                analyticsEvent(`LocalPush_${notificationList[order]?.type}`);
                setOrder(order + 1);
            }
            if (isSuperSmash) {
                setIsSuperSmash(false);
            }
        }, 2500);
    };

    const handleClickView = (item: any) => {
        if (subscription) {
            setSelectedUsers((prev) => [
                ...prev,
                {
                    list: item?.list,
                    user: item?.user,
                    type: item?.source === 'match' ? 'chat' : 'basic',
                },
            ]);
        } else {
            handleGoToPayWall(navigate, 1);
        }
    };

    const handleClickLike = (item: any, superSmash: boolean) => {
        if (subscription || superSmash) {
            setSelectedUsers((prev) => [
                ...prev,
                {
                    list: item?.list,
                    user: item?.user,
                    type: 'basic',
                },
            ]);
        } else {
            handleGoToPayWall(navigate, 0);
        }
    };

    const createNotification = (
        text: string,
        link: string,
        icon?: string,
        superSmash?: boolean
    ) => {
        if (!('Notification' in window)) {
            return;
        }
        Notification.requestPermission().then((result) => {
            if (result === 'granted') {
                navigator.serviceWorker.ready.then((registration) => {
                    registration.showNotification(text, {
                        icon:
                            icon ||
                            'https://play-lh.googleusercontent.com/qyC1Mq8uj6VbHLG9SjNfoYEw9PFE_HTdoEbwnj3BjpuDcVk5_x4kfU2M4hnuHHWZWeBE',
                    });

                    registration.addEventListener('notificationclick', () => {
                        localStorage.setItem(
                            LOCAL_STORAGE.BlockRefresh,
                            'true'
                        );
                        window.focus();
                        if (link === 'liked') {
                            setTimeout(() => {
                                handleClickLike(
                                    matchesStore.newLikedUser,
                                    superSmash || false
                                );
                            }, 300);
                        } else if (link === 'viewed') {
                            setTimeout(() => {
                                handleClickView(matchesStore.newViewedUser);
                            }, 300);
                        } else {
                            navigate(link);
                        }
                    });
                });
            }
        });
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (order === 0 && notificationList[0]) {
            resetNotification(() => {
                setOrder(order + 1);
            });
        } else if (notificationList[order]?.type) {
            resetNotification(() => null);
        }
    }, [notificationList, order]);

    useEffect(() => {
        if ('Notification' in window) {
            if (Notification.permission === 'granted') {
                setEnableNotifications(true);
            } else if (Notification.permission !== 'denied') {
                Notification.requestPermission().then((permission) => {
                    setEnableNotifications(permission === 'granted');
                });
            }
        }
    }, []);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (userStore.verifyNotification) {
            analyticsEvent(
                `Verification_${userStore.home?.me?.settings?.verification_status}`
            );
            setNotificationList([
                ...notificationList,
                {
                    type: 'verification',
                    data:
                        userStore.home?.me?.settings?.verification_status ===
                        'DONE',
                },
            ]);
        }
    }, [
        userStore.verifyNotification,
        userStore.home?.me?.settings?.verification_status,
    ]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (matchesStore.bonusSuperSmash) {
            setNotificationList([
                ...notificationList,
                { type: 'superSmash', data: null },
            ]);
        }
    }, [matchesStore.bonusSuperSmash]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (matchesStore.newViewedUser) {
            if (document.visibilityState === 'visible') {
                setNotificationList([
                    ...notificationList,
                    { type: 'viewed', data: matchesStore.newViewedUser },
                ]);
            }
        }
    }, [matchesStore.newViewedUser]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (matchesStore.newLikedUser) {
            const superSmash =
                matchesStore.newLikedUser?.list?.type === 'super_smash';
            setIsSuperSmash(superSmash);
            if (document.visibilityState === 'visible') {
                setNotificationList([
                    ...notificationList,
                    { type: 'hello', data: matchesStore.newLikedUser },
                ]);
            } else {
                let text: string;
                let icon: string | undefined;
                if (superSmash) {
                    text = `${matchesStore.newLikedUser.user.first_name} sent you Like 🤍`;
                    icon = `${matchesStore.newLikedUser.user.avatar_urls?.[0]}?${userStore.home?.pub_urls_query}`;
                } else if (subscription) {
                    text = `${matchesStore.newLikedUser.user.first_name} ssent you Hello 👋`;
                    icon = `${matchesStore.newLikedUser.user.avatar_urls?.[0]}?${userStore.home?.pub_urls_query}`;
                } else text = 'Someone sent you Hello 👋';
                if (enableNotifications) {
                    createNotification(text, 'liked', icon, superSmash);
                }
            }
        }
    }, [matchesStore.newLikedUser]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (
            matchesStore.newUserMessage &&
            userId !== matchesStore.newUserMessage
        ) {
            if (document.visibilityState === 'visible') {
                setNotificationList([
                    ...notificationList,
                    { type: 'message', data: matchesStore.newUserMessage },
                ]);
            } else {
                const newUserMessage = matchesStore.matches.users.find(
                    (el: User) => el.id === matchesStore.newUserMessage
                );
                if (
                    enableNotifications &&
                    newUserMessage?.avatar_urls &&
                    newUserMessage?.avatar_urls[0]
                ) {
                    createNotification(
                        'You have a new message 💬',
                        `${AppRoutes.Chat}/${matchesStore.newUserMessage}`,
                        `${newUserMessage?.avatar_urls[0]}?${userStore.home?.pub_urls_query}`
                    );
                }
            }
        }
    }, [matchesStore.newUserMessage]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (matchesStore.newBumpUser) {
            if (document.visibilityState === 'visible') {
                setNotificationList([
                    ...notificationList,
                    { type: 'bump', data: matchesStore.newBumpUser },
                ]);
            } else {
                createNotification(
                    'You have a new bump',
                    `${matchesStore.newBumpUser.avatar_urls?.[0]}?${userStore.home?.pub_urls_query}`,
                    `${AppRoutes.Chat}/${matchesStore.newBumpUser.id}`
                );
            }
        }
    }, [matchesStore.newBumpUser]);

    useEffect(() => {
        const shared = localStorage.getItem(LOCAL_STORAGE.Shared);
        const authToken = localStorage.getItem(LOCAL_STORAGE.AuthToken) || '';
        const getData = async () => {
            const usersData = await getLikedYou(authToken);

            const user = usersData.result.users.find(
                (us: User) => us?.nickname === userNickname
            );
            const list = usersData.result.list.find(
                (l: Match) => l?.user_id === user?.id
            );

            if (
                userNickname &&
                user &&
                selectedUsers.length === 0 &&
                !hasRun.current
            ) {
                hasRun.current = true;
                setSelectedUsers((prev) => [
                    ...prev,
                    {
                        list: list,
                        user: user,
                        type: 'basic',
                    },
                ]);
            }
        };

        const getPushUser = async () => {
            if (userNickname && !hasRun.current && selectedUsers.length === 0) {
                hasRun.current = true;
                try {
                    const usersData = await getMatchUserData(
                        authToken,
                        userNickname
                    );
                    if (usersData.result.user.id === userStore.userData?.id) {
                        analyticsEvent('SharedProfile_OpenSelf');
                    } else analyticsEvent('SharedProfile_Open');
                    setSelectedUsers((prev) => [
                        ...prev,
                        {
                            list: usersData.result.user,
                            user: usersData.result.user,
                            type: 'basic',
                        },
                    ]);
                    localStorage.removeItem(LOCAL_STORAGE.Shared);
                    localStorage.removeItem(LOCAL_STORAGE.Page);
                } catch (error) {
                    console.error(error);
                }
            }
        };
        if (userNickname) {
            if (shared) {
                getPushUser();
            } else {
                getData();
            }
        }
    }, [userNickname, selectedUsers.length]);

    if (
        !notificationList[order] &&
        !showVerificationModal &&
        !selectedUsers[0]?.list &&
        !isOpenBumpModal
    )
        return null;

    const handleClick = () => {
        switch (notificationList[order]?.type) {
            case 'bump':
                navigate(`${AppRoutes.Chat}/${matchesStore.newBumpUser?.id}`);
                break;
            case 'message':
                if (matchesStore.newUserMessage) {
                    navigate(
                        `${AppRoutes.Chat}/${matchesStore.newUserMessage}`
                    );
                }
                break;
            case 'hello':
                handleClickLike(notificationList[order].data, isSuperSmash);
                break;
            case 'viewed':
                handleClickView(notificationList[order].data);
                break;
            case 'verification':
                if (
                    userStore.home?.me?.settings?.verification_status === 'DONE'
                ) {
                    setShowNotification(false);
                    setShowVerificationModal(1);
                } else setShowVerificationModal(2);
                break;
            default:
                break;
        }
        analyticsEvent(`notifications_${notificationList[order]?.type}_opened`);
        setShowNotification(false);
    };

    const renderNotificationContent = () => {
        if (notificationList[order]?.type === 'bump') {
            return <NewBumpNotifications data={notificationList[order]} />;
        }
        if (notificationList[order]?.type === 'message') {
            const newUserMessage = matchesStore.matches.users.find(
                (el: User) => el.id === notificationList[order].data
            );
            if (!newUserMessage?.avatar_urls) {
                return (
                    <span style={{ fontSize: scaleSize(14) }}>
                        Somebody sent you a message
                    </span>
                );
            }
            return (
                <>
                    <NewMessageNotifications data={newUserMessage} />
                </>
            );
        }
        if (notificationList[order]?.type === 'hello') {
            return (
                <NewLikeNotifications
                    data={notificationList[order]}
                    subscription={subscription}
                    isSuperSmash={isSuperSmash}
                />
            );
        }
        if (notificationList[order]?.type === 'viewed') {
            return (
                <NewViewNotifications
                    data={notificationList[order]}
                    subscription={subscription}
                />
            );
        }
        if (notificationList[order]?.type === 'superSmash') {
            return (
                <>
                    <span style={{ fontSize: scaleSize(14) }}>
                        You have received free daily Likes
                    </span>
                    <Heart fill='white' width={scaleSize(28)} />
                </>
            );
        }
        if (notificationList[order]?.type === 'verification') {
            return (
                <>
                    {notificationList[order].data ? (
                        <Shield
                            fill='white'
                            width={scaleSize(28)}
                            height={scaleSize(28)}
                        />
                    ) : (
                        <ErrorIcon
                            width={scaleSize(28)}
                            height={scaleSize(28)}
                        />
                    )}
                    <span style={{ fontSize: scaleSize(14) }}>
                        {notificationList[order].data
                            ? 'Verification comleted'
                            : 'Verification failed'}
                    </span>
                </>
            );
        }
    };

    return (
        <div>
            <NotificationModals
                isOpen={!!showVerificationModal}
                showVerificationModal={showVerificationModal}
                setShowVerificationModal={setShowVerificationModal}
            />
            {selectedUsers.map((selected, index) => (
                <LikedYouModals
                    key={index}
                    isOpenModal={!!selected.list}
                    setIsOpenModal={() =>
                        setSelectedUsers((prev) =>
                            prev.filter((_, i) => i !== index)
                        )
                    }
                    user={selected.list}
                    visibleUser={selected.user}
                    isLiked
                    setLiked={() => ''}
                    setViewed={() => ''}
                    type={selected.type}
                    setBumpUser={setBumpUser}
                    setIsOpenBumpModal={setIsOpenBumpModal}
                    fromNotification={index === selectedUsers.length - 1}
                />
            ))}
            <BumpModal
                isOpen={isOpenBumpModal}
                user={bumpUser?.user}
                matchId={bumpUser?.match?.id}
                onClose={() => {
                    setIsOpenBumpModal(false);
                }}
            />
            <div
                className={twMerge(
                    'fixed top-1 z-[70] w-full px-4 transition-all duration-1000 sm:top-4 sm:w-[470px]',
                    showNotification && 'animate-slideIn'
                )}
                style={{
                    left: `calc(50% - ${isMobile ? 0 : 130}px)`,
                }}
            >
                {!isOpenBumpModal &&
                    showNotification &&
                    renderNotificationContent() && (
                        <div
                            className={twMerge(
                                'flex w-full items-center gap-4 rounded-xl bg-white text-black shadow-xl hover:cursor-pointer hover:shadow-2xl',
                                (isSuperSmash ||
                                    notificationList[order]?.type ===
                                        'superSmash') &&
                                    'bg-like text-white',
                                matchesStore.newBumpUser &&
                                    'bg-primary-gradient text-white'
                            )}
                            style={{
                                padding: `${scaleSize(12)}px ${scaleSize(18)}px`,
                                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.25)',
                            }}
                            onClick={handleClick}
                        >
                            {renderNotificationContent()}
                        </div>
                    )}
            </div>
        </div>
    );
});

export default Notifications;
