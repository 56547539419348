export const getDeviceType = () => {
    const ua = navigator.userAgent;

    if (/android/i.test(ua)) {
        return 'Android';
    }

    if (/iPad|iPhone|iPod/.test(ua) && !(window as any).MSStream) {
        return 'iOS';
    }

    if (/Windows Phone/i.test(ua)) {
        return 'Windows Phone';
    }

    if (/Macintosh|MacIntel|MacPPC|Mac68K/.test(ua)) {
        return 'Mac';
    }

    if (/Win32|Win64|Windows|WinCE/.test(ua)) {
        return 'Windows';
    }

    if (/Linux/i.test(ua)) {
        return 'Linux';
    }

    return 'Unknown';
};
