import { handleLogout } from '@/utils/auth';
import { WorkerActions } from '../workerActions';

const handledActions = [WorkerActions.Logout, WorkerActions.Redirect];

export const handleGeneralEvent = (event: MessageEvent) => {
    const { action, url } = event.data;

    if (!handledActions.includes(action)) return;

    if (action === WorkerActions.Logout) {
        handleLogout();
    } else if (action === WorkerActions.Redirect) {
        window.location.href = url;
    }
};
