export enum AppRoutes {
    Home = '/',
    Login = '/login',
    SignInEmail = '/email-signin',
    Chats = '/chats',
    Chat = '/chat',
    ErrorVPN = '/error-vpn',
    ErrorBanned = '/error-banned',
    UserInfo = '/user-info',
    UserInfoLite = '/user-info-lite',
    BuildInfo = '/build-info',
    People = '/people',
    LikedPeople = '/liked-people',
    Subscription = '/subscription',
    PayWall = '/pay-wall',
    PayWallMob = '/pay-wall-standalone',
    Push = '/push',
    TermsOfServiceMobile = '/terms-of-service',
    PrivacyPolicyMobile = '/privacy-policy',
    TermsOfService = '/terms',
    PrivacyPolicy = '/privacy',
    CookiePolicy = '/cookie-policy',
    CommunityGuidelines = '/community-guidelines',
    AmbassadorAgreement = '/ambassador-public-agreement',

    UnderMaintenance = '/under-maintenance',
    Verification = '/verification',
    Settings = '/settings',
    SearchSettings = '/search-settings',
    Account = '/account',
    FAQ = '/faq',
    Location = '/location',
    SocialMedia = '/social-media',
    EditProfile = '/edit-profile',
    EditInterests = '/edit-interests',
    EditLanguages = '/edit-languages',
    EditEthnicities = '/edit-ethnicities',
    EditNationalities = '/edit-nationalities',
    Translator = '/translator',
    TranslateChat = '/translate-chat',
    TranslationLanguage = '/translation-language',
    Report = '/report',
}
