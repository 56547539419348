import discoverControlHello from '@/assets/lotties/discoverControlHello.json';
import discoverControlLike from '@/assets/lotties/discoverControlLike.json';
import discoverControlPass from '@/assets/lotties/discoverControlPass.json';
import userStore from '@/entities/user/store';
import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef } from 'react';
import { twMerge } from 'tailwind-merge';
import CircularLoader from '../common/CircleAvatar/CircleLoader';

const DiscoveryActions: React.FC<{
    type?: 'left' | 'right' | 'top';
    translate?: number;
    actionSize?: number;
    handleActions?: any;
    showLikeIcon?: boolean;
}> = observer(
    ({
        type,
        translate = 0,
        actionSize = 50,
        handleActions,
        showLikeIcon = false,
    }) => {
        const passLottieRef = useRef<LottieRefCurrentProps>(null);
        const helloLottieRef = useRef<LottieRefCurrentProps>(null);
        const likeLottieRef = useRef<LottieRefCurrentProps>(null);
        const minSize = actionSize > 40 ? actionSize : 40;

        const defaultOptions = {
            loop: false,
            style: { width: minSize, height: minSize },
        };

        const freeSmash =
            userStore.home?.me?.balance?.free_smashes_bonus_next_at;
        const plusPlan = userStore.home?.me?.subscription?.type === 'plus';
        const now = new Date().getTime();
        const targetTime = new Date(freeSmash).getTime();
        const remainingTime = Math.max(targetTime - now, 0);
        const time =
            userStore.home?.me?.balance?.smashes === 0
                ? (remainingTime * 100) / 43200000
                : 0;
        const actions = [
            {
                name: 'Pass',
                icon: (
                    <Lottie
                        {...defaultOptions}
                        animationData={discoverControlPass}
                        lottieRef={passLottieRef}
                    />
                ),
                event: () => handleActions('left'),
            },
            {
                name: 'SuperSmash',
                icon: (
                    <Lottie
                        {...defaultOptions}
                        animationData={discoverControlLike}
                        lottieRef={likeLottieRef}
                    />
                ),
                event: () => handleActions('top'),
            },
            {
                name: 'Smash',
                icon:
                    time !== 0 && !plusPlan ? (
                        <CircularLoader
                            percentage={100 - time}
                            size={minSize + 6}
                            strokeWidth={4}
                            hidePercentage
                            fromDiscovery
                        >
                            <Lottie
                                {...defaultOptions}
                                animationData={discoverControlHello}
                                lottieRef={helloLottieRef}
                            />
                        </CircularLoader>
                    ) : (
                        <Lottie
                            {...defaultOptions}
                            animationData={discoverControlHello}
                            lottieRef={helloLottieRef}
                        />
                    ),
                event: () => {
                    handleActions('right');
                },
            },
        ];

        useEffect(() => {
            if (type === 'top' && likeLottieRef.current) {
                likeLottieRef.current.play();
            }
        }, [type]);

        useEffect(() => {
            if (!showLikeIcon) {
                likeLottieRef.current?.goToAndStop(0, true);
            }
        }, [showLikeIcon]);

        // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
        useEffect(() => {
            if (
                translate === 0 &&
                helloLottieRef.current &&
                likeLottieRef.current
            ) {
                helloLottieRef.current.goToAndStop(0, false);
                likeLottieRef.current.goToAndStop(0, false);
            }
            if (passLottieRef.current && helloLottieRef.current) {
                const totalFrames = discoverControlPass.op;
                const frame = (translate / 6) * totalFrames;

                if (type === 'left') {
                    passLottieRef.current.goToAndStop(
                        Math.min(frame, totalFrames - 1),
                        true
                    );
                } else {
                    helloLottieRef.current.goToAndStop(
                        Math.min(frame, totalFrames - 1),
                        true
                    );
                }
            }
        }, [translate]);

        return (
            <div
                className={twMerge(
                    'flex h-full w-full flex-row items-center justify-center gap-[50px] sm:gap-[100px]'
                )}
            >
                {actions.map((action) => (
                    <div
                        key={action.name}
                        onClick={() => action.event()}
                        className='cursor-pointer rounded-full '
                    >
                        {action.icon}
                    </div>
                ))}
            </div>
        );
    }
);

export default DiscoveryActions;
