import Cross from '@/assets/icons/cross.svg?react';
import { Match, MatchMessage } from '@/domain';
import userStore from '@/entities/user/store';
import { WebUserDetailed } from '@/entities/user/types';
import useEmblaCarousel from 'embla-carousel-react';
import React, { useMemo } from 'react';
import EmblaCarousel from '../common/Carousel/CarouselWrapper';
import { useDotButton } from '../common/Carousel/EmblaCarouselDotButton';
import Modal from './Modal';

interface ModalProps {
    isOpen: string;
    onClose?: () => void;
    match?: Match;
    user: WebUserDetailed | null | undefined;
}

const ChatPhotoModal: React.FC<ModalProps> = ({
    isOpen,
    onClose,
    match,
    user,
}) => {
    let imagesArray: any;
    let photoIndex: number;
    if (match) {
        imagesArray = match?.last_messages
            ?.slice()
            .reverse()
            .filter((message) => !!message.file_url) as MatchMessage[];
        photoIndex = useMemo(
            () =>
                imagesArray?.findIndex(
                    (image: MatchMessage) => image.file_url === isOpen
                ) || 0,
            [imagesArray, isOpen]
        );
    } else {
        imagesArray = user?.avatar_urls;
        photoIndex = useMemo(
            () =>
                imagesArray?.findIndex((image: string) => image === isOpen) ||
                0,
            [imagesArray, isOpen]
        );
    }

    const [emblaRef, emblaApi] = useEmblaCarousel({
        startIndex: photoIndex,
    });
    const { selectedIndex } = useDotButton(emblaApi);

    if (!isOpen || !imagesArray) return null;

    return (
        <Modal
            isOpen={!!isOpen}
            onClose={onClose}
            className='my-0 h-full max-h-screen max-w-[100vw] select-none overflow-hidden overscroll-none rounded-none p-0 sm:my-0 sm:max-h-screen sm:w-[726px]'
            style={{
                height: '100%',
            }}
        >
            <div className='h-full w-screen max-w-full bg-black'>
                <div className='relative flex gap-4 py-2'>
                    <Cross
                        onClick={onClose}
                        className='-translate-y-1/2 absolute top-1/2 left-3 w-fit cursor-pointer'
                        fill='#ffffff'
                    />
                    <div className='flex w-full flex-col text-white'>
                        <div className='flex h-full w-full justify-center'>
                            {`${user?.first_name}, ${user?.age ? user?.age : ''}`}
                        </div>
                        <div className='flex w-full justify-center'>
                            {`${selectedIndex + 1} of ${imagesArray.length}`}
                        </div>
                    </div>
                </div>
                <EmblaCarousel
                    forwardedRef={emblaRef}
                    emblaApi={emblaApi}
                    withoutDots
                    className='flex h-full items-center justify-center'
                    slides={imagesArray.map((image: any) => (
                        <div
                            key={image.file_url || image}
                            className='flex w-full justify-center overflow-hidden'
                            style={{
                                height: 'calc(100vh  - 64px)',
                            }}
                        >
                            <img
                                alt='chat img'
                                className='w-full object-contain'
                                src={`${image.file_url || image}?${match?.files_urls_query || userStore.home?.pub_urls_query}`}
                            />
                        </div>
                    ))}
                />
            </div>
        </Modal>
    );
};

export default ChatPhotoModal;
