import Education from '@/assets/icons/education-level.svg?react';
import { UserEducationLevel } from '@/domain';
import { getEnumOptions } from '@/utils/getEnumOptions';
import React from 'react';
import SingleSelectStep from '../../common/SingleSelectStep';
import UserDataFlowLayout from '../../layouts/UserDataFlowLayout';
import { UserInfoStepProps } from './userInfoConfig';

const EducationLevelStep: React.FC<UserInfoStepProps> = ({ onNext }) => {
    return (
        <UserDataFlowLayout withBarProgress>
            <div className='-mb-[5px] h-[5px] w-full bg-white/50'>
                <div className='h-[5px] w-[45%] bg-white' />
            </div>
            <SingleSelectStep
                LogoComponent={Education}
                title='What your education level?'
                options={getEnumOptions(UserEducationLevel)}
                withSkip
                onNext={onNext}
                field='education_level'
                fromEdit={false}
            />
        </UserDataFlowLayout>
    );
};

export default EducationLevelStep;
