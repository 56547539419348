import CheckMark from '@/assets/icons/check-mark.svg?react';
import ErrorOutlined from '@/assets/icons/error-outlined.svg?react';
import TextInput from '@/components/common/TextInput/TextInput';
import UserDataFlowLayout from '@/components/layouts/UserDataFlowLayout';
import Modal from '@/components/modals/Modal';
import { useEmailSignIn } from '@/hooks/useEmailSignIn';
import { useScreenDetector } from '@/hooks/useScreenDetector';
import { EMAIL_SIGN_IN_STEPS } from '@/pages/EmailSignIn';
import { LOCAL_STORAGE } from '@/utils/localStorage';
import { scaleFont, scaleSize } from '@/utils/scaleSizeAndFont';
import { secondsToMinutes } from '@/utils/secondsToMinutes';
import React, { useEffect, useMemo, useRef } from 'react';
import { INITIAL_CODE_COUNTDOWN } from './CodeStep';

interface EmailStepProps {
    setStep: (step: EMAIL_SIGN_IN_STEPS) => void;
    onNext?: (isShorterFlow?: boolean) => void;
}

const EmailStep: React.FC<EmailStepProps> = ({ setStep, onNext }) => {
    const {
        email,
        isValidEmailField,
        noTries,
        setNoTries,
        emailLimints,
        setEmailLimints,
        isLoading,
        handleEmailChange,
        handleEmailSubmit,
    } = useEmailSignIn(setStep, onNext);

    const emailInputRef = useRef<HTMLInputElement | null>(null);
    useEffect(() => {
        emailInputRef.current?.focus();
    }, []);

    // const onGoBack = useCallback(() => {
    //     if (isLite && onBack) {
    //         onBack();
    //     } else navigate(AppRoutes.Login);
    // }, [navigate, isLite, onBack]);

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            handleEmailSubmit();
            emailInputRef.current?.blur();
        }
    };
    const { isMobile } = useScreenDetector();
    const modalText = useMemo(() => {
        if (noTries) {
            return `You have used all tries, please try again in 
                ${secondsToMinutes(
                    INITIAL_CODE_COUNTDOWN -
                        Math.ceil(
                            (Date.now() -
                                +localStorage.getItem(
                                    LOCAL_STORAGE.CodeCountdownStartedAt
                                )!) /
                                1000
                        )
                )}`;
        }
        if (emailLimints) {
            return 'Please try again in 10 seconds';
        }
        return '';
    }, [noTries, emailLimints]);
    return (
        <UserDataFlowLayout
            disableNextButton={!isValidEmailField || isLoading}
            onNext={handleEmailSubmit}
            isLoading={isLoading}
        >
            <div className='flex h-screen flex-col gap-3'>
                <h1
                    className='font-extrabold'
                    style={{
                        fontSize: `${isMobile ? scaleFont(22) : 36}px`,
                    }}
                >
                    Enter your e-mail
                </h1>
                <p
                    className='leading-6'
                    style={{
                        marginTop: `${scaleSize(12)}px`,
                        fontSize: `${scaleFont(17)}px`,
                        lineHeight: `${scaleFont(22)}px`,
                    }}
                >
                    We will use this to create an account and login
                </p>
                <div className='relative'>
                    <div className='flex w-full items-center justify-between rounded-xl bg-white text-[20px] text-black leading-[26px]'>
                        <TextInput
                            type='email'
                            value={email}
                            ref={emailInputRef}
                            onChange={handleEmailChange}
                            placeholder='example@gmail.com'
                            onKeyDown={handleKeyDown}
                            style={{
                                height: `${scaleSize(50)}px`,
                                paddingLeft: `${scaleSize(14)}px`,
                                paddingTop: `${scaleSize(12)}px`,
                                paddingBottom: `${scaleSize(12)}px`,
                                fontSize: `${scaleFont(18)}px`,
                            }}
                        />
                        {email &&
                            (isValidEmailField ? (
                                <CheckMark
                                    fill='#19C809'
                                    width={scaleSize(35)}
                                    height={scaleSize(50)}
                                    className='mr-2'
                                />
                            ) : (
                                <ErrorOutlined
                                    className='mr-2'
                                    width={scaleSize(35)}
                                    height={scaleSize(50)}
                                />
                            ))}
                    </div>

                    <p className='text-[14px] leading-[22px]'>
                        {email && !isValidEmailField
                            ? 'Your email address is incorrect.'
                            : ''}
                    </p>
                </div>
            </div>
            <Modal
                isOpen={noTries || emailLimints}
                cancelText='Okay'
                onCancel={() => {
                    if (noTries) {
                        setNoTries(false);
                    } else {
                        setEmailLimints(false);
                    }
                }}
                title={emailLimints ? 'Email spam limit' : ''}
                text={modalText}
            ></Modal>
        </UserDataFlowLayout>
    );
};

export default EmailStep;
