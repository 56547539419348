import React from 'react';
import { twMerge } from 'tailwind-merge';

interface CloseButtonProps {
    onClose?: () => void;
    className?: string;
}

const CloseButton: React.FC<CloseButtonProps> = ({ onClose, className }) => {
    return (
        <button className={twMerge('text-[36px]', className)} onClick={onClose}>
            &#x2715;
        </button>
    );
};

export default React.memo(CloseButton);
