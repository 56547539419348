import React from 'react';

interface TooltipProps {
    message: string;
}

const Tooltip: React.FC<TooltipProps> = ({ message }) => {
    return (
        <div className='relative flex items-center justify-center'>
            <div className='absolute top-full left-5 flex items-center'>
                <div className='bg-amber-500 px-4 py-2 text-secondary text-sm shadow-lg'>
                    {message}
                </div>
                <div className='absolute bottom-full ml-2 h-0 w-0 border-r-8 border-r-transparent border-b-8 border-b-amber-500 border-l-8 border-l-transparent'></div>
            </div>
        </div>
    );
};

export default Tooltip;
