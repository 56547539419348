import { Continent } from '@/domain';
import discoveryStore from '@/entities/discovery/store';
import { scaleSize } from '@/utils/scaleSizeAndFont';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { twMerge } from 'tailwind-merge';

interface Option {
    label: string;
    value: string;
}

interface ChangeLookingForProps {
    userValue: {
        match_gender: string;
        match_age_from: number;
        match_age_to: number;
        filter_verified_only: boolean;
        filter_continents: Continent[];
        discover_near: boolean;
    };
    setUserValue: React.Dispatch<React.SetStateAction<any>>;
}

const ChangeLookingFor: React.FC<ChangeLookingForProps> = observer(
    ({ userValue, setUserValue }) => {
        const options: Option[] = [
            { label: 'Men', value: 'M' },
            { label: 'Women', value: 'F' },
            { label: 'Both', value: 'U' },
        ];

        const handleOptionClick = (optionValue: string) => {
            setUserValue(
                (prev: {
                    match_gender: string;
                    match_age_from: number;
                    match_age_to: number;
                    filter_verified_only: boolean;
                    filter_continents: Continent[];
                    discover_near: boolean;
                }) => {
                    const updatedValue = {
                        ...prev,
                        match_gender: optionValue,
                    };
                    return updatedValue;
                }
            );
            discoveryStore.updateMatchGender(optionValue);
        };

        return (
            <div
                className='flex w-full space-x-4 rounded-xl border border-inactive bg-white'
                style={{ height: `${scaleSize(44)}px` }}
            >
                {options.map((option) => (
                    <div
                        key={option.value}
                        onClick={() => handleOptionClick(option.value)}
                        className={twMerge(
                            'flex w-1/3 cursor-pointer flex-col items-center justify-center rounded-[11px] bg-white transition',
                            userValue.match_gender === option.value &&
                                'border-1 border-primary'
                        )}
                    >
                        <span
                            className={twMerge(
                                ' font-semibold text-gray-500 text-md',
                                userValue.match_gender === option.value &&
                                    ' text-primary'
                            )}
                            style={{ fontSize: `${scaleSize(18)}px` }}
                        >
                            {option.label}
                        </span>
                    </div>
                ))}
            </div>
        );
    }
);

export default ChangeLookingFor;
