import SkyLogo from '@/assets/icons/logo-white.svg?react';
import Mail from '@/assets/icons/mail.svg?react';
import { AppRoutes } from '@/utils/routes';
import { scaleFont } from '@/utils/scaleSizeAndFont';
import { useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { LOGIN_FOOTER, LOGIN_FOOTER_ICONS } from './LoginConstant';

const Footer = ({ hasShadow = false }) => {
    const navigate = useNavigate();
    return (
        <div
            className={twMerge(
                'flex h-fit flex-col gap-10 bg-white px-12 pt-10 pb-12 shadow-md sm:max-h-[591px] sm:flex-row sm:justify-between sm:px-20',
                hasShadow && 'mt-10 shadow-[0_-2px_4px_rgba(0,0,0,0.1)]'
            )}
        >
            <div className='flex flex-col gap-[60px] sm:flex-row'>
                <div className=' flex flex-col gap-6'>
                    <span
                        className='font-semibold'
                        style={{
                            fontSize: scaleFont(20),
                        }}
                    >
                        Legal
                    </span>
                    <ul className='flex flex-col gap-4'>
                        {LOGIN_FOOTER.map((item) => (
                            <li key={item.title}>
                                <a
                                    href={item.link}
                                    className='cursor-pointer font-normal text-black hover:text-black'
                                    style={{
                                        fontSize: scaleFont(16),
                                    }}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                >
                                    {item.title}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className='flex flex-col gap-6 sm:justify-end'>
                    <span
                        className='font-semibold'
                        style={{
                            fontSize: scaleFont(20),
                        }}
                    >
                        Contact Us
                    </span>
                    <div className='bg-black'></div>
                    <a
                        href='mailto:support@bumpy.app'
                        className='flex cursor-pointer items-center font-normal text-black hover:text-black'
                        style={{
                            fontSize: scaleFont(16),
                        }}
                    >
                        <Mail className='mr-1' />
                        <u>support@bumpy.app</u>
                    </a>
                </div>
            </div>
            <div className='flex flex-col items-center justify-between gap-8 sm:items-end'>
                <SkyLogo
                    fill='#52AED4'
                    className='cursor-pointer'
                    onClick={() => navigate(AppRoutes.Home)}
                />
                <div className='flex flex-col gap-[30px]'>
                    <span
                        className='text-center font-semibold sm:w-full sm:text-start'
                        style={{
                            fontSize: scaleFont(20),
                        }}
                    >
                        Follow us
                    </span>
                    <ul className='flex gap-8'>
                        {LOGIN_FOOTER_ICONS.map((item) => (
                            <li key={item.link}>
                                <a
                                    className='z-50 cursor-pointer'
                                    href={item.link}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    aria-label={item.link}
                                >
                                    <item.icon />
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Footer;
