import createAxiosInstance from './axios';

const user_device = {
    os: 'web',
};

export async function signIn(
    email: string,
    flow?: string,
    code?: string,
    navigate?: (path: string) => void,
    onNext?: () => void
): Promise<any> {
    const axiosInstance = createAxiosInstance({ navigate, onNext });
    const bodyObj =
        flow && code
            ? {
                  flow,
                  email,
                  code,
                  user_device,
              }
            : { email };
    const response = await axiosInstance.post('/sign/in', bodyObj);
    return response.data;
}

export async function validateAbout(about: string): Promise<any> {
    const axiosInstance = createAxiosInstance({});
    const bodyObj = { about };
    const response = await axiosInstance.post('/about/validate', bodyObj);
    return response.data;
}

export async function validateUserAvatar(
    imageBinary: string | ArrayBuffer
): Promise<any> {
    const axiosInstance = createAxiosInstance({});

    const response = await axiosInstance.post('/sign/up/avatar', imageBinary, {
        headers: {
            'Content-Type': 'image/*',
        },
        timeout: 30000,
    });
    return response.data;
}

export async function googleSignIn(
    id_token: string,
    userData?: any,
    navigate?: (path: string) => void
): Promise<any> {
    const axiosInstance = createAxiosInstance({ navigate });

    const formData = new FormData();

    let data = {
        flow: userData ? 'up' : 'in',
        id_token,
        user_device,
    };
    if (userData) {
        data = {
            ...data,
            ...userData,
            dob: `${userData.day}.${userData.month}.${userData.year}`,
        };
    }

    formData.append('content', JSON.stringify(data));

    const response = await axiosInstance.post('/sign/in/google', formData);
    return response.data;
}

export async function freezeAccount(token: string): Promise<any> {
    const axiosInstance = createAxiosInstance({ token });
    const response = await axiosInstance.post('/users/me/freeze');
    return response.data;
}

export async function registerAccount(content: any): Promise<any> {
    const axiosInstance = createAxiosInstance({});
    axiosInstance.defaults.withCredentials = true;

    const formData = new FormData();
    formData.append(
        'content',
        JSON.stringify({
            ...content,
            dob: `${content.day}.${content.month}.${content.year}`,
            flow: 'up',
            user_device,
            lang: 'en',
        })
    );
    const response = await axiosInstance.post('/sign/up', formData);
    return response.data;
}
