import EarthIcon from '@/assets/icons/earth-icon.svg?react';
import userStore from '@/entities/user/store';
import { scaleSize } from '@/utils/scaleSizeAndFont';
import { observer } from 'mobx-react-lite';
import ReactCountryFlag from 'react-country-flag';
import Avatar from '../common/CircleAvatar/Avatar';

interface NewBumpNotificationsProps {
    data: any;
}

const NewBumpNotifications: React.FC<NewBumpNotificationsProps> = observer(
    ({ data }) => {
        return (
            <>
                <div className='relative'>
                    {data.data?.country_code ? (
                        <ReactCountryFlag
                            svg
                            className='absolute bottom-0 z-20'
                            alt={`${data.data?.country_code}`}
                            countryCode={`${data.data?.country_code}`}
                            style={{
                                borderRadius: '50%',
                                width: `${scaleSize(18)}px`,
                                height: `${scaleSize(18)}px`,
                                objectFit: 'cover',
                            }}
                        />
                    ) : (
                        <EarthIcon className='absolute bottom-0' />
                    )}
                    <Avatar
                        src={`${data.data.avatar_urls[0]}?${userStore.home?.pub_urls_query}`}
                        alt='rounded avatar'
                        size={scaleSize(40)}
                        imageSize='small'
                    />
                </div>
                <div className='flex flex-col'>
                    <span
                        className='font-semibold'
                        style={{ fontSize: scaleSize(14) }}
                    >
                        New Bump!
                    </span>
                    <span style={{ fontSize: scaleSize(12) }}>
                        {`Start a chat with ${data.data.first_name}`}
                    </span>
                </div>
            </>
        );
    }
);

export default NewBumpNotifications;
