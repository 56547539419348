import { sendMessage } from '@/api/chat';
import Letter from '@/assets/icons/letter.svg?react';
import Shield from '@/assets/icons/shield.svg?react';
import { Match, MatchMessageType } from '@/domain';
import matchesStore from '@/entities/match/store';
import userStore from '@/entities/user/store';
import { LOCAL_STORAGE } from '@/utils/localStorage';
import { sendChatMessage } from '@/workers/sharedWorkerInstance';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { twMerge } from 'tailwind-merge';
import CloseButton from '../auth/CloseButton';
import { EXPRESS_MESSAGES } from '../chat/ChatMessagesList';

interface BumpModalProps {
    isOpen: boolean;
    user: any;
    matchId: number;
    onClose: () => void;
}

const BumpModal: React.FC<BumpModalProps> = ({
    isOpen,
    user,
    matchId,
    onClose,
}) => {
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const screenRef = useRef<HTMLDivElement>(null);
    const [message, setMessage] = useState('');
    const [isInputFocused, setIsInputFocused] = useState(false);
    const [isSendingMessage, setIsSendingMessage] = useState<boolean>(false);
    const handleInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setMessage(e.target.value);
    };

    const handleSendMessage = async () => {
        if (isSendingMessage) {
            return;
        }
        setIsSendingMessage(true);
        try {
            const result = await sendMessage(
                localStorage.getItem(LOCAL_STORAGE.AuthToken) || '',
                message,
                matchId
            );
            const newMessage = {
                sender_user_id: userStore.userData?.id || 0,
                type: MatchMessageType.textOrImage,
                flags: result?.result.new_message_flags,
                content: message,
                message_tr: result?.result.new_message_tr,
                fromMe: true,
                created_at: result?.result.new_message_created_at,
                id: result?.result.new_message_id,
            };
            sendChatMessage(newMessage, matchId);
            const selectedNewMatch = matchesStore.matches?.list_new?.find(
                (match: Match) => match.id === matchId
            );
            if (selectedNewMatch) {
                matchesStore.setMatches({
                    ...matchesStore.matches,
                    list_open: [
                        { ...selectedNewMatch, last_messages: [newMessage] },
                        ...(matchesStore.matches?.list_open || []),
                    ],
                    list_new: matchesStore.matches.list_new.filter(
                        (match: Match) => match.id !== matchId
                    ),
                });
            }
        } catch (error) {
            console.error(error);
        }
        setMessage('');
        onClose();
    };
    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        const textarea = textAreaRef.current;
        if (textarea) {
            textarea.style.height = 'auto';
            const height = textarea.scrollHeight;
            const adjustedHeight =
                height > 172 ? 172 : height > 42 ? height : 42;
            textarea.style.height = `${adjustedHeight}px`;
        }
    }, [message]);
    if (!isOpen) return null;

    return (
        <div
            className='fixed inset-0 z-50 flex h-full flex-col items-center justify-center bg-gray-500 bg-opacity-50'
            onClick={onClose}
            ref={screenRef}
        >
            <div
                className={twMerge(
                    'relative h-full w-full rounded-xl bg-white text-center shadow-lg sm:w-fit'
                )}
                onClick={(e) => e.stopPropagation()}
            >
                <div>
                    <div className='absolute flex h-full w-full flex-col justify-around text-white'>
                        <CloseButton
                            className='absolute top-2 left-3 z-50 leading-none sm:top-6 sm:left-7'
                            onClose={onClose}
                        />
                        <div className='gradient-shadow-top-light pb-6 font-bold text-[46px] text-primary italic'>
                            <p className='animate-scaleFade'>It`s a </p>
                            <p className='animate-scaleAndFade text-[64px]'>
                                BUMP{' '}
                            </p>
                        </div>
                        <div>
                            <div
                                className={twMerge(
                                    'flex items-center justify-center gap-1'
                                )}
                            >
                                {user?.country_code ? (
                                    <ReactCountryFlag
                                        svg
                                        alt={`${user.country_code}`}
                                        countryCode={`${user.country_code.toUpperCase()}`}
                                        style={{
                                            borderRadius: '50%',
                                            width: '35px',
                                            height: '35px',
                                            objectFit: 'cover',
                                        }}
                                    />
                                ) : (
                                    <p className='text-xl'>🌐</p>
                                )}
                                <p className='text-xl'>
                                    {user?.first_name}, {user?.age}
                                </p>
                                {user?.verified && (
                                    <Shield className='h-[25px] w-[25px]' />
                                )}
                            </div>
                            <p className='font-semibold text-sm'>
                                You like {user?.gender === 'F' ? 'her' : 'him'}!
                            </p>
                            <p className='font-semibold text-sm'>
                                Send {user?.gender === 'F' ? 'her' : 'him'} an
                                express-message
                            </p>
                            <div className='mx-auto mt-2 w-[90%] rounded-xl bg-white text-black'>
                                <div className='flex items-center rounded-xl border border-gray-300 bg-black/10'>
                                    <textarea
                                        ref={textAreaRef}
                                        rows={1}
                                        className='w-full resize-none bg-transparent p-2 focus:outline-none'
                                        placeholder='Type a message'
                                        value={message}
                                        onChange={(e) => handleInputChange(e)}
                                        onFocus={() => setIsInputFocused(true)}
                                        onBlur={() => setIsInputFocused(false)}
                                    />
                                    {message.length <= 0 || isSendingMessage ? (
                                        <Letter fill='#bfbfbf' />
                                    ) : (
                                        <Letter
                                            fill='#51aed4'
                                            onClick={handleSendMessage}
                                        />
                                    )}
                                </div>
                                {!isInputFocused && (
                                    <div>
                                        {EXPRESS_MESSAGES.map((mes, index) => (
                                            <p
                                                key={mes}
                                                className={twMerge(
                                                    'mx-3 cursor-pointer py-3 text-start text-sm sm:text-base',
                                                    index !==
                                                        EXPRESS_MESSAGES.length -
                                                            1 &&
                                                        'border-gray-300 border-b'
                                                )}
                                                onClick={() => setMessage(mes)}
                                            >
                                                {mes}
                                            </p>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <img
                        src={`${user?.avatar_urls[0]}?${userStore.home?.pub_urls_query}`}
                        alt='user avatar'
                        className='h-screen w-[730px] object-cover'
                    />
                </div>
            </div>
        </div>
    );
};

export default BumpModal;
