import React, { ChangeEvent, useEffect, useState } from 'react';

import { validateAbout } from '@/api/auth';
import Loader from '@/assets/icons/loading-white.svg?react';
import Button from '@/components/common/Button/Button';
import UserDataFlowLayout from '@/components/layouts/UserDataFlowLayout';
import ButtonWithModalTips from '@/components/modals/ButtonWithModalTips';
import Modal from '@/components/modals/Modal';
import { useScreenDetector } from '@/hooks/useScreenDetector';
import { scaleFont, scaleSize } from '@/utils/scaleSizeAndFont';
import { analyticsEvent, useAbRegistration } from '@shared/services/analytics';

import { UserInfoStepProps } from './userInfoConfig';

const MoreAboutYou: React.FC<UserInfoStepProps> = ({ onNext }) => {
    const [text, setText] = useState('');
    const [leftSymbols, setLeftSymbols] = useState(500);
    const [disable, setDisable] = useState(true);
    const [openInvalidAboutMe, setOpenInvalidAboutMe] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { trackAbRegistrationEvent } = useAbRegistration();

    useEffect(() => {
        setLeftSymbols(500 - text.length);
    }, [text]);

    const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        const inputValue = e.target.value;
        if (inputValue.length <= 500) {
            setText(inputValue);
            setLeftSymbols(500 - inputValue.length);
            setDisable(inputValue.length < 1);
        }
    };
    const handleConfirmAbout = async () => {
        setIsLoading(true);
        try {
            const data = await validateAbout(text);
            analyticsEvent('StepAbout_validate');
            trackAbRegistrationEvent('StepAbout_validate');

            if (data.result.valid) {
                localStorage.setItem('about', text);
                onNext();
            } else {
                setOpenInvalidAboutMe(true);
            }
        } catch (error) {
            console.error('Request failed:', error);
        }
        setIsLoading(false);
    };

    const handleKeyDown = async (
        e: React.KeyboardEvent<HTMLTextAreaElement>
    ) => {
        if (e.key === 'Enter' && !disable) {
            e.preventDefault();
            await handleConfirmAbout();
        }
    };
    const { isMobile } = useScreenDetector();
    return (
        <UserDataFlowLayout withBarProgress>
            <div className='-mb-[5px] h-[5px] w-full bg-white/50'>
                <div className='h-full w-[85%] bg-white opacity-100 sm:mt-0' />
            </div>
            <div className='flex h-full w-full flex-col justify-start gap-6 px-4 pt-8 pb-4 sm:gap-8'>
                <div className='flex flex-col gap-4'>
                    <h2
                        className='mt-2 font-extrabold text-secondary'
                        style={{
                            fontSize: `${isMobile ? scaleFont(22) : 36}px`,
                        }}
                    >
                        Add more about you
                    </h2>
                    <span
                        className='text-secondary'
                        style={{
                            marginTop: `${scaleSize(12)}px`,
                            fontSize: `${scaleFont(17)}px`,
                            lineHeight: `${scaleFont(22)}px`,
                        }}
                    >
                        Let other users know more about you. You can change this
                        information at any time.
                    </span>
                </div>
                <div className='flex h-full flex-col gap-2'>
                    <div className='relative flex flex-col rounded-lg border-2 border-gray-500 bg-white px-4 py-2'>
                        <textarea
                            className='resize-none bg-white text-black focus:outline-none'
                            style={{
                                height: `${scaleSize(100)}px`,
                                fontSize: `${Math.max(scaleFont(16), 16)}px`,
                            }}
                            value={text}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            placeholder='Tell something about yourself...'
                        />
                        <span className='absolute right-2 bottom-2 text-gray-500 text-sm'>
                            {leftSymbols}
                        </span>
                    </div>
                    <div className='flex w-full items-start'>
                        <ButtonWithModalTips
                            className='m-0 text-navDarkGray'
                            analyticsName='StepAbout'
                        />
                    </div>
                </div>
                {isLoading ? (
                    <div className='flex w-full items-center justify-center '>
                        <Loader
                            width={isMobile ? 35 : 50}
                            height={isMobile ? 35 : 50}
                            fill='white'
                        />
                    </div>
                ) : (
                    <div className='flex w-full justify-between'>
                        <Button
                            className='mx-0 font-normal text-black'
                            onClick={() => onNext()}
                            label='Skip'
                        />
                        <Button
                            onClick={handleConfirmAbout}
                            disabled={disable}
                            className={`text-black ${disable && 'text-gray-500'} mx-0`}
                            label='Done'
                        />
                    </div>
                )}
            </div>
            <Modal
                isOpen={openInvalidAboutMe}
                title='Warning!'
                cancelText='Okay'
                onCancel={() => setOpenInvalidAboutMe(false)}
                text='Your info violated Community Guidelines. For more details,
                    please see profile safety tips.'
            />
        </UserDataFlowLayout>
    );
};

export default MoreAboutYou;
