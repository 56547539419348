import { Match } from '@/domain';

export const replaceObjectInArrayById = (array: Match[], newObject: Match) => {
    const index = array.findIndex((item: Match) => item.id === newObject.id);
    if (index !== -1) {
        array[index] = newObject;
    } else {
        console.warn('Object with the given id not found in the array.');
        return [newObject, ...array];
    }
    return array;
};
