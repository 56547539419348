export enum AUTH_EVENTS {
    SIGN_IN_WITH_GOOGLE_PRESSED = 'continue_with_google_pressed',
    SIGN_IN_WITH_EMAIL_PRESSED = 'continue_with_email_pressed',
    SIGN_IN = 'sign_in',
    SIGN_UP = 'sign_up',
    EMAIL_CONFIRM_RESEND_PRESS = 'EmailConfirm_ResendPress',
}

export enum SCREENS {
    SIGN = 'Sign',
}
