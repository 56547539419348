import { UserReligion, UserValues } from '@/domain';

export function getEnumOptions(
    enumObj: object,
    disableSort?: boolean
): string[] {
    const numberWords: { [key: string]: string } = {
        one: '1',
        two: '2',
        three: '3',
        four: '4',
        five: '5',
    };

    const formattedOptions = Object.keys(enumObj)
        .filter((key) => Number.isNaN(Number(key)) && key !== 'NONE')
        .map((enumKey) => {
            let formatedEnum =
                enumKey.charAt(0) + enumKey.slice(1).toLowerCase();

            if (enumObj === UserValues) {
                formatedEnum = formatedEnum.replaceAll('_', ' & ');
                formatedEnum = formatedEnum
                    .split(' ')
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ');
            } else {
                formatedEnum = formatedEnum.replaceAll('_', ' ');
            }

            if (formatedEnum.toLowerCase() === 'no') {
                return 'None';
            }
            if (formatedEnum.toLowerCase() === 'world & improvement') {
                return 'World Improvement';
            }
            if (formatedEnum.toLowerCase() === 'self & realization') {
                return 'Self Realization';
            }
            if (formatedEnum.toLowerCase() === 'child free') {
                return 'Childfree';
            }

            formatedEnum = formatedEnum
                .split(' ')
                .map((word) => numberWords[word.toLowerCase()] || word)
                .join(' ');

            return formatedEnum;
        });

    if (!disableSort) {
        return formattedOptions.sort((a, b) => {
            if (enumObj === UserValues || enumObj === UserReligion) {
                return a.localeCompare(b);
            }
            return 0;
        });
    }
    return formattedOptions;
}
