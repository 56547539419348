import React, { useState } from 'react';
import Cropper, { Area } from 'react-easy-crop';
import Button from '../common/Button/Button';
import Modal from './Modal';

interface CropModalProps {
    isOpen: boolean;
    onClose: () => void;
    imageSrc: string;
    onCropComplete: (croppedAreaPixels: Area) => void;
}

const CropModal: React.FC<CropModalProps> = ({
    isOpen,
    onClose,
    imageSrc,
    onCropComplete,
}) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(
        null
    );

    return (
        <Modal
            isOpen={isOpen}
            className='z-[50000] h-screen max-h-screen w-screen max-w-screen rounded-none sm:max-h-screen'
        >
            <Cropper
                image={imageSrc}
                crop={crop}
                zoom={zoom}
                aspect={3 / 4}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={(croppedArea, croppedAreaPixels) => {
                    if (croppedArea) {
                        setCroppedAreaPixels(croppedAreaPixels);
                    }
                }}
                style={{
                    containerStyle: {
                        height: '100%',
                        backgroundColor: 'black',
                    },
                }}
            />
            <div className='fixed bottom-0 left-0 flex w-full justify-center'>
                <Button className='w-full text-white' onClick={onClose}>
                    Cancel
                </Button>
                <Button
                    className='w-full text-primary'
                    onClick={() => {
                        if (croppedAreaPixels) {
                            onCropComplete(croppedAreaPixels);
                        }
                    }}
                >
                    Save
                </Button>
            </div>
        </Modal>
    );
};

export default CropModal;
