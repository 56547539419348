import { type FallbackRender } from '@sentry/react';
import { useCallback, useEffect } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';

import { DEBUG } from '@shared/config';
import { AppRoutes as APP_ROUTER } from '@utils/routes';

const AppError: FallbackRender = ({ resetError, error }) => {
    const navigate = useNavigate();
    const homePage = useMatch({ path: APP_ROUTER.Home, end: true });
    const stayOnPage = homePage || DEBUG;

    const handleReload = useCallback(() => {
        window.location.reload();
    }, []);

    useEffect(() => {
        if (stayOnPage) {
            return;
        }

        navigate(APP_ROUTER.Home);
        resetError();
    }, [stayOnPage, navigate, resetError]);

    if (DEBUG) {
        throw error;
    }

    return (
        <div className='not-found flex h-screen w-screen flex-col items-center justify-center bg-white'>
            <h2 className='mb-4 font-bold text-2xl text-error'>
                We’re sorry this error occurred. Please try again later
            </h2>
            {stayOnPage && (
                <button
                    onClick={handleReload}
                    className='rounded border-1 bg-white px-4 py-2'
                >
                    Reload
                </button>
            )}
        </div>
    );
};

export default AppError;
