import { useCallback, useEffect, useRef, useState } from 'react';

export const useSwipeAndClose = (
    mainDivRef: React.RefObject<HTMLDivElement>,
    isOpen: boolean,
    onClose: () => void
) => {
    const startY = useRef<number>(0);
    const startX = useRef(0);
    const isScrolling = useRef<null | boolean>(null);
    const [translateY, setTranslateY] = useState<number>(0);

    const isScrolledDown = () => {
        if (mainDivRef.current) {
            return mainDivRef.current.scrollTop > 0;
        }
        return false;
    };

    const animateClose = () => {
        const step = 25;
        const targetY = window.innerHeight;

        const animate = () => {
            setTranslateY((prevY) => {
                if (prevY + step >= targetY) {
                    setTimeout(() => {
                        onClose();
                        setTranslateY(0);
                    }, 100);
                    return targetY;
                }
                requestAnimationFrame(animate);
                return prevY + step;
            });
        };

        requestAnimationFrame(animate);
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    const handleMouseDown = useCallback((event: MouseEvent) => {
        if (isScrolledDown()) return;

        startY.current = event.clientY;
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    }, []);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    const handleTouchStart = useCallback((event: TouchEvent) => {
        if (isScrolledDown()) return;
        startX.current = event.touches[0].clientX;
        startY.current = event.touches[0].clientY;
        isScrolling.current = null;
        document.addEventListener('touchmove', handleTouchMove);
        document.addEventListener('touchend', handleTouchEnd);
    }, []);

    const handleMouseMove = (event: MouseEvent) => {
        const deltaY = event.clientY - startY.current;
        if (mainDivRef.current && deltaY > 0) {
            setTranslateY(deltaY);
        }
    };

    const handleTouchMove = (event: TouchEvent) => {
        const deltaX = event.touches[0].clientX - startX.current;
        const deltaY = event.touches[0].clientY - startY.current;
        if (isScrolling.current === null) {
            isScrolling.current = Math.abs(deltaY) < Math.abs(deltaX);
        }
        if (isScrolling.current) {
            return;
        }
        if (mainDivRef.current && deltaY > 0) {
            setTranslateY(deltaY);
        }
    };

    const handleMouseUp = (event: MouseEvent) => {
        event.preventDefault();
        const deltaY = event.clientY - startY.current;
        if (deltaY > 100) {
            animateClose();
        } else {
            setTranslateY(0);
        }
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
    };

    const handleTouchEnd = (event: TouchEvent) => {
        event.preventDefault();
        const deltaY = event.changedTouches[0].clientY - startY.current;
        if (deltaY > 100 && !isScrolling.current) {
            animateClose();
        } else {
            setTranslateY(0);
        }
        document.removeEventListener('touchmove', handleTouchMove);
        document.removeEventListener('touchend', handleTouchEnd);
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        const mainDiv = mainDivRef.current;
        if (mainDiv && isOpen) {
            mainDiv.addEventListener('mousedown', handleMouseDown);
            mainDiv.addEventListener('touchstart', handleTouchStart);
        }
        return () => {
            if (mainDiv) {
                mainDiv.removeEventListener('mousedown', handleMouseDown);
                mainDiv.removeEventListener('touchstart', handleTouchStart);
            }
        };
    }, [handleMouseDown, handleTouchStart, isOpen]);

    return { translateY };
};
