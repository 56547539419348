import ErrorIcon from '@/assets/icons/error-outlined.svg?react';
import Shield from '@/assets/icons/shield.svg?react';
import userStore from '@/entities/user/store';
import { AppRoutes } from '@/utils/routes';
import { scaleSize } from '@/utils/scaleSizeAndFont';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../common/Button/Button';
import Modal from './Modal';

interface ModalProps {
    isOpen: boolean;
    showVerificationModal?: number;
    setShowVerificationModal: (num: number) => void;
}

const NotificationModals: React.FC<ModalProps> = ({
    isOpen = false,
    showVerificationModal,
    setShowVerificationModal,
}) => {
    const navigate = useNavigate();
    if (!isOpen) return null;

    return (
        <>
            <Modal
                isOpen={!!showVerificationModal}
                className='overflow-hidden sm:max-w-[500px]'
            >
                <div
                    className='relative mx-auto w-fit'
                    style={{
                        marginTop: scaleSize(40),
                    }}
                >
                    <img
                        src={`${userStore.userData?.avatar_urls?.[0]}?${userStore.home?.pub_urls_query}`}
                        alt='photo_verifiaction'
                        className='max-h-[200px] rounded-xl'
                    />
                    {showVerificationModal === 1 ? (
                        <Shield
                            width={scaleSize(28)}
                            height={scaleSize(28)}
                            className='absolute right-2 bottom-2'
                        />
                    ) : (
                        <div className='absolute top-0 left-0 flex h-full w-full items-center justify-center rounded-xl bg-black/40'>
                            <ErrorIcon
                                width={scaleSize(30)}
                                height={scaleSize(30)}
                            />
                        </div>
                    )}
                </div>
                <div className='flex flex-col items-center'>
                    <span
                        className='font-semibold'
                        style={{
                            marginTop: scaleSize(32),
                            marginBottom: scaleSize(8),
                            fontSize: scaleSize(20),
                        }}
                    >
                        {showVerificationModal === 1 && 'Verification comleted'}
                        {showVerificationModal === 2 && 'Verification failed'}
                    </span>
                    <span
                        style={{
                            marginBottom: scaleSize(40),
                            fontSize: scaleSize(17),
                        }}
                    >
                        {showVerificationModal === 1
                            ? 'Your profile has been verified so now other users will see that you are a real person.'
                            : "We were unable to verify your profile. Maybe your photo did't match the one showed in the picture? Please try again."}
                    </span>
                    <Button
                        label={`${showVerificationModal === 1 ? 'Okay' : 'Pass verification'}`}
                        className='min-w-48 bg-verify text-white'
                        style={{
                            marginBottom:
                                showVerificationModal === 1
                                    ? scaleSize(70)
                                    : scaleSize(20),
                        }}
                        onClick={() => {
                            if (showVerificationModal === 2) {
                                navigate(AppRoutes.Verification);
                            }
                            setShowVerificationModal(0);
                        }}
                    />
                    {showVerificationModal === 2 && (
                        <>
                            <Button
                                label='Later'
                                className='min-w-48 bg-white text-verify'
                                style={{
                                    marginBottom: scaleSize(40),
                                }}
                                onClick={() => setShowVerificationModal(0)}
                            />
                            <a
                                href='mailto:support@bumpy.app'
                                className='text-gray-500 underline'
                            >
                                Contact customer support
                            </a>
                        </>
                    )}
                </div>
            </Modal>
        </>
    );
};

export default NotificationModals;
