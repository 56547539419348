import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

import BuildInfo from '@pages/BuildInfo';
import EmailSignIn from '@pages/EmailSignIn';
import Login from '@pages/Login';
import PayWallStandalone from '@pages/PayWallStandalone';
import SearchSettings from '@pages/SearchSettings';
import UserInfo from '@pages/UserInfo';
import UserInfoLite from '@pages/UserInfoLite';
import LocationFilter from '@pages/locationFilter';
import {
    AbRegistrationProvider,
    trackDefaultRegistrationEvent,
    trackLiteRegistrationEvent,
} from '@shared/services/analytics';
import {
    mobileAddress,
    mobileCompany,
    webAddress,
    webCompany,
} from '@utils/companies';
import { AppRoutes as APP_ROUTES } from '@utils/routes';

import ProtectedLayout from './layouts/ProtectedLayout';

// Lazy load the components
const EditInterests = lazy(() => import('@pages/EditInterests'));
const EditLanguages = lazy(() => import('@pages/EditLanguages'));
const EditProfile = lazy(() => import('@pages/EditProfile'));
const Settigns = lazy(() => import('@pages/Settings'));
const Translator = lazy(() => import('@pages/Translator'));
const TranslateChat = lazy(() => import('@pages/TranslateChat'));
const TranslationLanguage = lazy(() => import('@pages/TranslationLanguage'));
const Chat = lazy(() => import('@pages/Chat'));
const Chats = lazy(() => import('@pages/Chats'));
const Home = lazy(() => import('@pages/Home'));
const NotFound = lazy(() => import('@pages/NotFound'));
const Account = lazy(() => import('@pages/Account'));
const FAQ = lazy(() => import('@pages/FAQ'));
const EditEthnicities = lazy(() => import('@pages/EditEthnicities'));
const EditNationalities = lazy(() => import('@pages/EditNationalities'));
const SocialMedia = lazy(() => import('@pages/SocialMedia'));
const Verification = lazy(() => import('@pages/Verification'));
const UnderMaintenance = lazy(() => import('../pages/UnderMaintenance'));
const ErrorBanned = lazy(() => import('../pages/errors/Banned'));
const ErrorVPN = lazy(() => import('../pages/errors/VPN'));
const Report = lazy(() => import('@pages/Report'));
const Discovery = lazy(() => import('@pages/Discovery'));
const LikedYou = lazy(() => import('@pages/LikedYou'));
const PayWall = lazy(() => import('@pages/PayWall'));
const Subscription = lazy(() => import('@pages/Subscription'));
const Push = lazy(() => import('@pages/Push'));
const AmbassadorAgreement = lazy(() => import('@pages/AmbassadorAgreement'));
const PrivacyPolicy = lazy(() => import('@pages/PrivacyPolicy'));
const CookiePolicy = lazy(() => import('@pages/CookiePolicy'));
const TermsOfService = lazy(() => import('@pages/TermsOfService'));
const CommunityGuidelines = lazy(() => import('@pages/CommunityGuidelines'));

export default function AppRoutes() {
    return (
        <Routes>
            <Route
                path={APP_ROUTES.UnderMaintenance}
                element={<UnderMaintenance />}
            />
            <Route path={APP_ROUTES.BuildInfo} element={<BuildInfo />} />
            <Route
                path={APP_ROUTES.PayWallMob}
                element={<PayWallStandalone />}
            />
            <Route path={APP_ROUTES.ErrorBanned} element={<ErrorBanned />} />
            <Route path={APP_ROUTES.ErrorVPN} element={<ErrorVPN />} />

            <Route path={APP_ROUTES.Login} element={<Login />} />
            <Route
                path={APP_ROUTES.AmbassadorAgreement}
                element={<AmbassadorAgreement />}
            />
            <Route
                path={APP_ROUTES.PrivacyPolicy}
                element={<PrivacyPolicy />}
            />
            <Route
                path={APP_ROUTES.PrivacyPolicyMobile}
                element={<PrivacyPolicy />}
            />
            <Route path={APP_ROUTES.CookiePolicy} element={<CookiePolicy />} />
            <Route
                path={APP_ROUTES.TermsOfService}
                element={
                    <TermsOfService
                        company={webCompany}
                        address={webAddress}
                        privacyUrl={APP_ROUTES.PrivacyPolicy}
                    />
                }
            />
            <Route
                path={APP_ROUTES.TermsOfServiceMobile}
                element={
                    <TermsOfService
                        company={mobileCompany}
                        address={mobileAddress}
                        privacyUrl={APP_ROUTES.PrivacyPolicyMobile}
                    />
                }
            />
            <Route
                path={APP_ROUTES.CommunityGuidelines}
                element={<CommunityGuidelines />}
            />
            <Route path={APP_ROUTES.SignInEmail} element={<EmailSignIn />} />

            <Route
                path={APP_ROUTES.UserInfo}
                element={
                    <AbRegistrationProvider
                        value={{
                            trackAbRegistrationEvent:
                                trackDefaultRegistrationEvent,
                        }}
                    >
                        <UserInfo />
                    </AbRegistrationProvider>
                }
            />
            <Route
                path={APP_ROUTES.UserInfoLite}
                element={
                    <AbRegistrationProvider
                        value={{
                            trackAbRegistrationEvent:
                                trackLiteRegistrationEvent,
                        }}
                    >
                        <UserInfoLite />
                    </AbRegistrationProvider>
                }
            />

            <Route
                path={APP_ROUTES.EditProfile}
                element={
                    <ProtectedLayout withoutNav>
                        <EditProfile />
                    </ProtectedLayout>
                }
            />
            <Route
                path={APP_ROUTES.EditInterests}
                element={
                    <ProtectedLayout withoutNav>
                        <EditInterests />
                    </ProtectedLayout>
                }
            />
            <Route
                path={APP_ROUTES.EditLanguages}
                element={
                    <ProtectedLayout withoutNav>
                        <EditLanguages />
                    </ProtectedLayout>
                }
            />
            <Route
                path={APP_ROUTES.EditEthnicities}
                element={
                    <ProtectedLayout withoutNav>
                        <EditEthnicities />
                    </ProtectedLayout>
                }
            />
            <Route
                path={APP_ROUTES.EditNationalities}
                element={
                    <ProtectedLayout withoutNav>
                        <EditNationalities />
                    </ProtectedLayout>
                }
            />
            <Route
                path={APP_ROUTES.Verification}
                element={
                    <ProtectedLayout withoutNav>
                        <Verification />
                    </ProtectedLayout>
                }
            />
            <Route
                path={APP_ROUTES.Settings}
                element={
                    <ProtectedLayout withoutNav>
                        <Settigns />
                    </ProtectedLayout>
                }
            />
            <Route
                path={APP_ROUTES.SearchSettings}
                element={
                    <ProtectedLayout withoutNav>
                        <SearchSettings />
                    </ProtectedLayout>
                }
            />
            <Route
                path={APP_ROUTES.Location}
                element={
                    <ProtectedLayout withoutNav>
                        <LocationFilter />
                    </ProtectedLayout>
                }
            />
            <Route
                path={APP_ROUTES.SocialMedia}
                element={
                    <ProtectedLayout withoutNav>
                        <SocialMedia />
                    </ProtectedLayout>
                }
            />
            <Route
                path={APP_ROUTES.Translator}
                element={
                    <ProtectedLayout withoutNav>
                        <Translator />
                    </ProtectedLayout>
                }
            />
            <Route
                path={APP_ROUTES.TranslationLanguage}
                element={
                    <ProtectedLayout withoutNav>
                        <TranslationLanguage />
                    </ProtectedLayout>
                }
            />
            <Route
                path={APP_ROUTES.Account}
                element={
                    <ProtectedLayout withoutNav>
                        <Account />
                    </ProtectedLayout>
                }
            />
            <Route
                path={`${APP_ROUTES.Subscription}`}
                element={
                    <ProtectedLayout withoutNav>
                        <Subscription />
                    </ProtectedLayout>
                }
            />
            <Route
                path={APP_ROUTES.FAQ}
                element={
                    <ProtectedLayout withoutNav>
                        <FAQ />
                    </ProtectedLayout>
                }
            />
            <Route
                path={`${APP_ROUTES.Report}/:id/:nMsgID`}
                element={
                    <ProtectedLayout withoutNav>
                        <Report />
                    </ProtectedLayout>
                }
            />
            <Route
                path={`${APP_ROUTES.PayWall}/:type?`}
                element={
                    <ProtectedLayout withoutNav>
                        <PayWall />
                    </ProtectedLayout>
                }
            />
            <Route
                path={APP_ROUTES.Home}
                element={
                    <ProtectedLayout>
                        <Home />
                    </ProtectedLayout>
                }
            />
            <Route
                path={APP_ROUTES.People}
                element={
                    <ProtectedLayout>
                        <Discovery />
                    </ProtectedLayout>
                }
            />
            <Route
                path={APP_ROUTES.LikedPeople}
                element={
                    <ProtectedLayout>
                        <LikedYou />
                    </ProtectedLayout>
                }
            />
            <Route
                path={APP_ROUTES.Chats}
                element={
                    <ProtectedLayout>
                        <Chats />
                    </ProtectedLayout>
                }
            />
            <Route
                path={`${APP_ROUTES.Chat}/:id`}
                element={
                    <ProtectedLayout withoutNav>
                        <Chat />
                    </ProtectedLayout>
                }
            />
            <Route
                path={`${APP_ROUTES.Push}`}
                element={
                    <ProtectedLayout withoutNav>
                        <Push />
                    </ProtectedLayout>
                }
            />
            <Route
                path={`${APP_ROUTES.TranslateChat}/:id`}
                element={
                    <ProtectedLayout withoutNav>
                        <TranslateChat />
                    </ProtectedLayout>
                }
            />
            <Route path='*' element={<NotFound />} />
        </Routes>
    );
}
