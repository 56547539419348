// @ts-nocheck
import { FACEBOOK_PIXEL_ID, DEBUG } from '@shared/config';

export function initPixel() {
    if (window.fbq) {
        return;
    }

    ((
        f: any,
        b: Document,
        e: string,
        v: string,
        n?: any,
        t?: HTMLScriptElement,
        s?: HTMLElement
    ) => {
        if (f.fbq) return;
        n = f.fbq = (...args: any[]) => {
            n.callMethod ? n.callMethod.apply(n, args) : n.queue.push(args);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n.push || (() => {});
        n.loaded = true;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e) as HTMLScriptElement;
        t.async = true;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode?.insertBefore(t, s);
    })(
        window,
        document,
        'script',
        'https://connect.facebook.net/en_US/fbevents.js'
    );

    window.fbq('set', 'autoConfig', true, FACEBOOK_PIXEL_ID);
    // Initialize the Pixel
    window.fbq('init', FACEBOOK_PIXEL_ID);

    // Track page views
    window.fbq('track', 'PageView');

    if (DEBUG) {
        console.log('init useFacebookPixel');
    }
}
