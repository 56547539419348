import Lottie from 'lottie-react';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import { googleSignIn, registerAccount } from '@/api/auth';
import Loader from '@/assets/lotties/globalLoader.json';
import {
    USER_INFO_COMPONENTS,
    USER_INFO_STEPS,
    UserInfoStepComponentType,
} from '@/components/auth/userInfoSteps/userInfoConfig';
import Modal from '@/components/modals/Modal';
import { handleLoginSuccess } from '@/utils/auth';
import { LOCAL_STORAGE, getAllLocalStorageItems } from '@/utils/localStorage';
import { AppRoutes } from '@/utils/routes';
import { analyticsEvent } from '@shared/services/analytics';
import {
    AUTH_EVENTS,
    analyticsSignUp,
    useAbRegistration,
} from '@shared/services/analytics';
import { defaultRegistrationTrackScreen } from '@shared/services/analytics';

type UserInfoSteps = (typeof USER_INFO_STEPS)[keyof typeof USER_INFO_STEPS];
const shorterFlowConfig: Partial<Record<UserInfoSteps, UserInfoSteps>> = {
    [USER_INFO_STEPS.GOAL]: USER_INFO_STEPS.INTERESTS,
    [USER_INFO_STEPS.INTERESTS]: USER_INFO_STEPS.PHOTO_UPLOAD,
};

const UserInfo: React.FC = () => {
    const navigate = useNavigate();
    const hasRun = useRef(false);
    const stepsArray = useMemo(() => Object.values(USER_INFO_STEPS), []);
    const initStep = useMemo(() => {
        const savedStep = localStorage.getItem(LOCAL_STORAGE.UserInfoStep);
        if (!savedStep) {
            return stepsArray[0];
        }

        const stepIndex = stepsArray.indexOf(savedStep as UserInfoSteps);
        if (stepIndex === -1) {
            return stepsArray[0];
        }

        return stepsArray[stepIndex];
    }, [stepsArray]);

    const [step, setStep] = useState<USER_INFO_STEPS>(initStep);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { trackAbRegistrationEvent } = useAbRegistration();

    useEffect(() => {
        if (!hasRun.current) {
            hasRun.current = true;
            analyticsEvent('SignIn_UserNotRegistered');
        }

        if (localStorage.getItem(LOCAL_STORAGE.UserInfoStep)) {
            setStep(
                localStorage.getItem(
                    LOCAL_STORAGE.UserInfoStep
                ) as USER_INFO_STEPS
            );
        }
        if (
            !localStorage.getItem(LOCAL_STORAGE.EmailSignIn) &&
            !localStorage.getItem(LOCAL_STORAGE.GoogleToken)
        ) {
            navigate(AppRoutes.Login);
        }
    }, [navigate]);

    const createUser = useCallback(async () => {
        try {
            let userResponse: any;
            setIsLoading(true);
            if (localStorage.getItem(LOCAL_STORAGE.GoogleToken)) {
                const id_token = localStorage.getItem(
                    LOCAL_STORAGE.GoogleToken
                );
                localStorage.removeItem(LOCAL_STORAGE.GoogleToken);
                userResponse = await googleSignIn(
                    id_token!,
                    getAllLocalStorageItems()
                );
                analyticsSignUp('google');
                trackAbRegistrationEvent(AUTH_EVENTS.SIGN_UP, {
                    method: 'google',
                });
            } else {
                userResponse = await registerAccount(getAllLocalStorageItems());
                analyticsSignUp('email');
                trackAbRegistrationEvent(AUTH_EVENTS.SIGN_UP, {
                    method: 'email',
                });
            }
            handleLoginSuccess(userResponse.result, navigate);
        } catch (error: any) {
            setIsLoading(false);
            console.error('Request failed:', error);
            setTimeout(() => {
                alert(`Request failed: ${error.response?.data?.m}`);
                localStorage.clear;
                navigate(AppRoutes.Login);
            }, 100);
        }
    }, [navigate, trackAbRegistrationEvent]);

    const handleNext = useCallback(
        (isShorterFlow?: boolean) => {
            if (step === stepsArray[stepsArray.length - 1]) {
                localStorage.removeItem(LOCAL_STORAGE.UserInfoStep);
                createUser();
                return;
            }
            const currentIndex = stepsArray.indexOf(step);
            const newStep =
                isShorterFlow && step in shorterFlowConfig
                    ? shorterFlowConfig[step]
                    : stepsArray[currentIndex + 1];
            setStep(newStep!);
            localStorage.setItem(LOCAL_STORAGE.UserInfoStep, newStep!);
        },
        [step, stepsArray, createUser]
    );

    const handleBack = useCallback(() => {
        if (step === stepsArray[0]) {
            navigate(AppRoutes.Login);
            return;
        }
        const currentIndex = stepsArray.indexOf(step);
        const newStep = stepsArray[currentIndex - 1];
        setStep(newStep);
        localStorage.setItem(LOCAL_STORAGE.UserInfoStep, newStep);
    }, [step, navigate, stepsArray]);

    // Todo after AB test should to moved into dedicated hook
    useEffect(() => {
        const eventsBySteps = {
            [USER_INFO_STEPS.NAME]: 'StepFirstName',
            [USER_INFO_STEPS.BIRTHDAY]: 'StepDob',
            [USER_INFO_STEPS.GENDER]: 'StepGender',
            [USER_INFO_STEPS.LOOKING_FOR]: 'StepMatchGender',
            [USER_INFO_STEPS.GOAL]: 'StepGoal',
            [USER_INFO_STEPS.INTERESTS]: 'StepInterests',
            [USER_INFO_STEPS.HEIGHT]: 'StepMoreAboutMe_height',
            [USER_INFO_STEPS.WEIGHT]: 'StepMoreAboutMe_weight',
            [USER_INFO_STEPS.SEX_ORIENTATION]:
                'StepMoreAboutMe_sex_orientation',
            [USER_INFO_STEPS.STAR_SIGN]: 'StepMoreAboutMe_star_sign',
            [USER_INFO_STEPS.EXERCISE]: 'StepMoreAboutMe_exercise',
            [USER_INFO_STEPS.EDUCATION_LEVEL]:
                'StepMoreAboutMe_education_level',
            [USER_INFO_STEPS.MARITAL_STATUS]: 'StepMoreAboutMe_marital_status',
            [USER_INFO_STEPS.KIDS]: 'StepMoreAboutMe_kids',
            [USER_INFO_STEPS.DRINK]: 'StepMoreAboutMe_drink',
            [USER_INFO_STEPS.SMOKE]: 'StepMoreAboutMe_smoke',
            [USER_INFO_STEPS.PETS]: 'StepMoreAboutMe_pets',
            [USER_INFO_STEPS.RELIGION]: 'StepMoreAboutMe_religion',
            [USER_INFO_STEPS.VALUES]: 'StepMoreAboutMe_values',
            [USER_INFO_STEPS.ABOUT]: 'StepMoreAboutMe_about',
            [USER_INFO_STEPS.PHOTO_UPLOAD]: 'StepAvatars',
        };

        const eventName =
            eventsBySteps[step as keyof typeof eventsBySteps] ||
            'unknown_event';

        analyticsEvent(eventName);
        defaultRegistrationTrackScreen(eventName);
    }, [step]);

    const StepComponent: UserInfoStepComponentType = useMemo(
        () => USER_INFO_COMPONENTS[step],
        [step]
    );

    return (
        <div className='text-secondary'>
            <StepComponent onNext={handleNext} onBack={handleBack} />
            <Modal isOpen={isLoading} className='px-10 py-6 text-black'>
                <Lottie className='h-24 w-24' animationData={Loader} loop />
                Loading...
            </Modal>
        </div>
    );
};

export default UserInfo;
