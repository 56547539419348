import React, { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

interface ModalProps {
    isOpen: boolean;
    title?: string;
    children: ReactNode;
    confirmText?: string;
    cancelText?: string;
    onConfirm?: () => void;
    onCancel?: () => void;
    onClose?: () => void;
    icon: ReactNode;
    isVerify?: boolean;
}

const ChatsModal: React.FC<ModalProps> = ({
    isOpen,
    title,
    children,
    confirmText = 'Confirm',
    cancelText,
    onConfirm,
    onCancel,
    onClose,
    icon,
    isVerify,
}) => {
    if (!isOpen) return null;

    return (
        <div
            className='fixed top-0 left-0 z-50 flex h-full w-full items-center justify-center bg-gray-500 bg-opacity-50'
            onClick={onClose}
        >
            <div className='flex w-[350px] flex-col items-center gap-2 rounded-lg bg-white p-4 text-center shadow-lg'>
                {icon}
                <h2 className='font-bold text-black text-lg'>{title}</h2>
                <div className='mt-1 text-gray-600 text-sm'>{children}</div>
                <div className='mt-6 flex w-full flex-col items-center px-6'>
                    {onConfirm && (
                        <button
                            className={twMerge(
                                'mb-4 w-full rounded-md bg-primary px-4 py-2 text-white',
                                isVerify && 'bg-verify'
                            )}
                            onClick={onConfirm}
                        >
                            {confirmText}
                        </button>
                    )}
                    {cancelText && (
                        <button
                            className={twMerge(
                                'w-fit cursor-pointer rounded-md px-4 py-2 text-primary',
                                isVerify && 'text-verify'
                            )}
                            onClick={onCancel}
                        >
                            {cancelText}
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ChatsModal;
