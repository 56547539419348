import { POSTPONED_FIREBASE_EVENTS } from './consts';
import { get, remove } from './sessionStorage';

type PostponedFirebaseEvent = {
    name: string;
    params?: object;
};

export const consumePostponedFirebaseEvents = () => {
    const value = get<PostponedFirebaseEvent[]>(POSTPONED_FIREBASE_EVENTS);
    if (value === null) {
        return [];
    }
    remove(POSTPONED_FIREBASE_EVENTS);

    return value;
};
