import React, { ReactNode } from 'react';

interface ModalProps {
    show: boolean;
    onClose: () => void;
    children: ReactNode;
}

const PhotoValidateModal: React.FC<ModalProps> = ({
    show,
    onClose,
    children,
}) => {
    if (!show) return null;

    return (
        <div className='fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-black bg-opacity-60'>
            <div className='relative h-fit w-96 rounded-lg bg-white p-6'>
                <button
                    className='absolute top-2 right-2 text-gray-500 hover:text-gray-700'
                    onClick={onClose}
                >
                    &#x2715;
                </button>
                <div className='mt-4'>{children}</div>
            </div>
        </div>
    );
};

export default PhotoValidateModal;
