import { areDiscoverySettingsEqual } from '@/domain';
import discoveryStore from '@/entities/discovery/store';
import userStore from '@/entities/user/store';
import { handleLogout } from '@/utils/auth';
import { WorkerActions } from '../workerActions';

const handledActions = [
    WorkerActions.GetData,
    WorkerActions.SocketMessage,
    WorkerActions.Banned,
];

enum SocketMessageTypes {
    Me = 'me',
    MeSubscription = 'me.subscription',
}
export const handleUserDataEvent = async (event: MessageEvent) => {
    const { action, data, dataKey } = event.data;
    if (!handledActions.includes(action)) return;
    if (action === WorkerActions.GetData) {
        switch (dataKey) {
            case 'userData':
                userStore.setUserData(data?.me?.user);
                userStore.setHomeData(data);
                break;
        }
    } else if (action === WorkerActions.SocketMessage) {
        switch (data.res) {
            case SocketMessageTypes.Me:
                if (data.action === 'verified') {
                    userStore.setVerificationStatus(data.model.status);
                    userStore.setVerifyNotification(data.model.status);
                    setTimeout(() => userStore.setVerifyNotification(), 3000);
                } else if (data.action === 'changed') {
                    let sameSettings: boolean;
                    if (userStore.home?.me?.settings && data.model?.settings) {
                        sameSettings = areDiscoverySettingsEqual(
                            userStore.home?.me?.settings,
                            data.model.settings
                        );
                    } else sameSettings = true;
                    if (!sameSettings) {
                        discoveryStore.updateRefresh(true);
                    }
                    userStore.setUserData(data.model.user);
                    userStore.setMeData(data.model);
                }
                break;
            case SocketMessageTypes.MeSubscription:
                if (data.action === 'changed') {
                    userStore.setSubscribtionData(data.model);
                }
                break;
            case 'unreads':
                userStore.setHomeData({
                    ...userStore.home,
                    unreads: data.model,
                });
                break;
            case 'reaction.total_count':
                if (!userStore.home?.reactions) {
                    userStore.setHomeData({
                        ...userStore.home,
                        reactions: {},
                    });
                    setTimeout(() => {
                        userStore.setTotalReactions(data.model.value);
                    }, 300);
                } else {
                    userStore.setTotalReactions(data.model.value);
                }
                break;
        }
    } else if (action === WorkerActions.Banned) {
        handleLogout(data.model.id, data.model.user_id, data.model.until);
    }
};
