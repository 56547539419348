import Ruller from '@/assets/icons/ruller.svg?react';
import SelectInput from '@/components/common/SelectInput';
import UserDataFlowLayout from '@/components/layouts/UserDataFlowLayout';
import { useScreenDetector } from '@/hooks/useScreenDetector';
import { scaleFont } from '@/utils/scaleSizeAndFont';
import React, { useMemo, useState } from 'react';
import { UserInfoStepProps } from './userInfoConfig';

const HeightStep: React.FC<UserInfoStepProps> = ({ onNext }) => {
    const [height, setHeight] = useState<number | null>(null);
    const heightList: number[] = useMemo(
        () =>
            Array(130)
                .fill(0)
                .map((_, index) => 100 + index),
        []
    );
    if (height) {
        localStorage.setItem('height_sm', height.toString());
    }
    const { isMobile } = useScreenDetector();
    return (
        <UserDataFlowLayout onNext={onNext} withBarProgress>
            <div className='-mb-[5px] h-[5px] w-full bg-white opacity-50' />
            <div className='h-[5px] w-[20%] bg-white' />
            <div className='flex flex-col items-center py-8'>
                <Ruller />
                <div className='my-8 flex flex-col'>
                    <h1
                        className='mb-4 font-extrabold'
                        style={{
                            fontSize: `${isMobile ? scaleFont(22) : 24}px`,
                        }}
                    >
                        What is your height?
                    </h1>
                </div>
                <div className='flex flex-col gap-2'>
                    <SelectInput
                        options={heightList}
                        units='cm'
                        value={172}
                        setSelect={setHeight}
                    />
                    <button
                        onClick={() => {
                            setHeight(null);
                            onNext();
                        }}
                        className='p-4 text-black/50'
                    >
                        Skip
                    </button>
                </div>
            </div>
        </UserDataFlowLayout>
    );
};

export default HeightStep;
