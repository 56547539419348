export enum CardBrand {
    Verve = 'verve',
    Visa = 'visa',
    Mastercard = 'mastercard',
    Maestro = 'maestro',
    AmericanExpress = 'american-express',
    Jcb = 'jcb',
    DinersClub = 'diners-club',
    Discover = 'discover',
    Aura = 'aura',
    Elo = 'elo',
    Hipercard = 'hipercard',
    CartesBancaires = 'cartes-bancaires',
    Rupay = 'rupay',
    BCCard = 'bc-card',
    UnionPay = 'unionpay',
    Dankort = 'dankort',
    GPNCard = 'gpn-card',
    Troy = 'troy',
    ThaiPaymentNetwork = 'thai-payment-network',
    MADA = 'mada',
    Bancontact = 'bancontact',
    Interac = 'interac',
    Bajaj = 'bajaj',
    Paypal = 'paypal',
}
