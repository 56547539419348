import Button from '@components/common//Button/Button';
import Modal, { ModalProps } from '@components/modals/Modal';
import { CardBrand } from '@shared/lib/payment';
import { capitalize } from '@shared/lib/string';

type NotAllowedCardBrand = {
    reason: 'notAllowedCardBrand';
    allowedCardBrands: CardBrand[];
};

type ValidationFailed = {
    reason: 'validationFailed';
};

type Unknown = {
    reason: 'unknown';
};

type PaymentRestrictedModalProps = Pick<ModalProps, 'isOpen' | 'onClose'> &
    (NotAllowedCardBrand | ValidationFailed | Unknown);

type TitleResolverOverrides = {
    notAllowedCardBrand: (allowedCardBrands: CardBrand[]) => string;
};

export type PaymentRestrictedModalReason =
    PaymentRestrictedModalProps['reason'];

type TitleResolver = {
    [K in PaymentRestrictedModalReason]: K extends keyof TitleResolverOverrides
        ? TitleResolverOverrides[K]
        : () => string;
};

type BodyResolver = {
    [K in PaymentRestrictedModalReason]: () => string;
};

const TITLE_RESOLVER: TitleResolver = {
    notAllowedCardBrand: (allowedCardBrands: CardBrand[]) => {
        return `We currently accept ${getDisplayingCards(
            allowedCardBrands as CardBrand[]
        )} only`;
    },
    validationFailed: () => 'Your card is not supported',
    unknown: () => 'Unknown error',
};

const BODY_RESOLVER: BodyResolver = {
    notAllowedCardBrand: () => 'Please use a card from one of these providers',
    validationFailed: () => 'Please try a different card to continue',
    unknown: () => '',
};

const getDisplayingCards = (cardBrands: CardBrand[]) => {
    const capitalizedBrands = cardBrands.map(capitalize);

    if (capitalizedBrands.length <= 2) {
        return capitalizedBrands.join(' and ');
    }

    return capitalizedBrands.join(', ');
};

export default function PaymentRestrictedModal(
    props: PaymentRestrictedModalProps
) {
    const { reason, isOpen, onClose } = props;

    let title = '';

    switch (reason) {
        case 'notAllowedCardBrand': {
            const { allowedCardBrands } = props;

            title = TITLE_RESOLVER[reason](allowedCardBrands);
            break;
        }
        default:
            title = TITLE_RESOLVER[reason]();
            break;
    }

    const body = BODY_RESOLVER[reason]();

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            className='px-4 py-8 text-center'
            title={title}
        >
            <p className='mt-4 text-sm'>{body}</p>
            <Button
                variant='primary'
                className='mt-7 w-auto min-w-36'
                onClick={onClose}
            >
                Ok
            </Button>
        </Modal>
    );
}
