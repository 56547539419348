import { useCallback, useState } from 'react';

export function useModal<ValueType>(defaultOpen = false) {
    const [open, setOpen] = useState(defaultOpen);
    const [value, setValue] = useState<ValueType>();

    const show = useCallback((value?: ValueType) => {
        setOpen(true);
        setValue(value);
    }, []);

    const hide = useCallback(() => {
        setOpen(false);
        setValue(undefined);
    }, []);

    return { open, show, hide, value };
}
