import {
    deleteToken as firebaseDeleteToken,
    getToken as firebaseGetToken,
    getMessaging,
    isSupported,
} from 'firebase/messaging';
import { isSupported as isSupportedSw } from 'firebase/messaging/sw';

import { FIREBASE_VAPID_KEY } from '@/shared/config';

import { app } from './app';

const getMessagingApp = async () => {
    if (await isSupportedMessaging()) {
        return getMessaging(app);
    }

    return null;
};

export const isSupportedMessaging = isSupported;
export const isSupportedMessagingSw = isSupportedSw;

export const getToken = async () => {
    try {
        const messagingApp = await getMessagingApp();
        if (!messagingApp) {
            return '';
        }

        const token = await firebaseGetToken(messagingApp, {
            vapidKey: FIREBASE_VAPID_KEY,
        });

        return token;
    } catch (error) {
        console.error('Problem get firebase token', { error });
        return '';
    }
};

export const deleteToken = async () => {
    const messagingApp = await getMessagingApp();

    if (!messagingApp) {
        return false;
    }

    return firebaseDeleteToken(messagingApp);
};
