export const setTranslateX = (
    element: HTMLElement | null,
    newTranslateX: number
): void => {
    if (!element) return;

    const currentTranslateY = getTranslateYValue(element) || 0;
    const currentRotate = getRotateValue(element) || 0;

    const newTransform = `translateX(${newTranslateX}px) translateY(${currentTranslateY}px) rotate(${currentRotate}deg)`;
    element.style.transform = newTransform;
};

export const setTranslateY = (
    element: HTMLElement | null,
    newTranslateY: number
): void => {
    if (!element) return;

    const currentTranslateX = getTranslateXValue(element) || 0;
    const currentRotate = getRotateValue(element) || 0;

    const newTransform = `translateX(${currentTranslateX}px) translateY(${newTranslateY}px) rotate(${currentRotate}deg)`;
    element.style.transform = newTransform;
};

export const setRotate = (
    element: HTMLElement | null,
    newRotate: number
): void => {
    if (!element) return;

    const currentTranslateX = getTranslateXValue(element) || 0;
    const currentTranslateY = getTranslateYValue(element) || 0;

    const newTransform = `translateX(${currentTranslateX}px) translateY(${currentTranslateY}px) rotate(${newRotate}deg)`;
    element.style.transform = newTransform;
};

export const getTranslateXValue = (element: HTMLElement | null) => {
    if (!element) return null;

    const transform = window
        .getComputedStyle(element)
        .getPropertyValue('transform');

    if (!transform || transform === 'none') {
        return 0;
    }

    const matrixValues = transform.match(/matrix\(([^)]+)\)/);

    if (matrixValues) {
        const matrixArray = matrixValues[1].split(', ');
        const translateX = matrixArray[4];
        return Number.parseFloat(translateX);
    }

    return null;
};

export const getTranslateYValue = (
    element: HTMLElement | null
): number | null => {
    if (!element) return null;

    const transform = window
        .getComputedStyle(element)
        .getPropertyValue('transform');

    if (!transform || transform === 'none') {
        return 0;
    }

    const matrixValues = transform.match(/matrix\(([^)]+)\)/);

    if (matrixValues) {
        const matrixArray = matrixValues[1].split(', ');
        const translateY = matrixArray[5];
        return Number.parseFloat(translateY);
    }

    return null;
};

export const getRotateValue = (element: HTMLElement | null): number | null => {
    if (!element) return null;

    const transform = window
        .getComputedStyle(element)
        .getPropertyValue('transform');

    if (!transform || transform === 'none') {
        return 0;
    }

    const matrixValues = transform.match(/matrix\(([^)]+)\)/);
    if (matrixValues) {
        const matrixArray = matrixValues[1].split(', ');

        const a = Number.parseFloat(matrixArray[0]);
        const b = Number.parseFloat(matrixArray[1]);

        const angle = Math.atan2(b, a);
        return angle * (180 / Math.PI);
    }

    const rotateRegex = /rotate\((-?\d+\.?\d*)deg\)/;
    const rotateMatch = transform.match(rotateRegex);

    if (rotateMatch) {
        return Number.parseFloat(rotateMatch[1]);
    }

    return 0;
};
