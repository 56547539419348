import { WebMatchMessage } from '@/entities/match';
import { SharedState, exportFromNewToOpen, findChatById } from './sharedState';
import { postMessageToConnections } from './utils';
import { WorkerActions } from './workerActions';

let ws: WebSocket | null = null;
let pingInterval: number | undefined = undefined;

const URL = import.meta.env.VITE_FLY_URL || 'fly.bumpy.app';

console.log('FLY_URL:', URL);

export const connectWebSocket = (
    authToken: string,
    port: MessagePort,
    connections: MessagePort[]
) => {
    if (ws) {
        return;
    }

    ws = new WebSocket(`wss://${URL}?Authorization=${authToken}`);

    ws.onopen = () => {
        port.postMessage({ action: WorkerActions.SocketConnected });
        const wsPing = () => {
            if (ws?.readyState === WebSocket.OPEN) {
                ws.send('{}');
                port.postMessage({ action: WorkerActions.SocketPing });
            }
        };

        pingInterval = self.setInterval(wsPing, 20000);
        wsPing();
    };

    ws.onmessage = (message) => {
        const msgData = JSON.parse(message.data);
        handleWebSocketMessage(msgData, connections, port);

        if (connections.length > 0) {
            return postMessageToConnections(
                connections,
                WorkerActions.SocketMessage,
                msgData
            );
        }

        port.postMessage({
            action: WorkerActions.SocketMessage,
            data: msgData,
        });
    };

    ws.onerror = (error) => {
        port.postMessage({
            action: WorkerActions.SocketError,
            data: error,
        });
    };

    ws.onclose = () => {
        port.postMessage({ action: WorkerActions.SocketClosed });
        ws = null;

        if (pingInterval) {
            clearInterval(pingInterval);
            pingInterval = undefined;
        }
    };
};

enum MessageActions {
    Sent = 'sent',
    Received = 'received',
    ReceivedModified = 'received.modified',
    Reaction = 'reaction',
    Read = 'read',
    ReadReceipt = 'read_receipt',
    Deleted = 'deleted',
}

const handleWebSocketMessage = (
    msgData: { action: string; at: number; res: string; model: any },
    connections: MessagePort[],
    port: MessagePort
) => {
    switch (msgData.res) {
        case 'unreads':
            if (!SharedState.matches) {
                SharedState.matches = {
                    unreads: { visits: 0, reactions: 0, matches: 0 },
                    list_new: [],
                    list_open: [],
                    users: [],
                };
            }
            SharedState.matches.unreads = msgData.model;
            break;
        case 'match.message': {
            const chat = findChatById(msgData.model.match_id);
            if (chat) {
                chat.is_read = false;
                if (
                    msgData.action === MessageActions.Received ||
                    msgData.action === MessageActions.Sent
                ) {
                    chat.last_at = Date.now();
                    chat.last_messages.unshift(msgData.model);
                    if (msgData.action === MessageActions.Received) {
                        SharedState.matches.unreads.matches =
                            SharedState.matches.unreads.matches + 1;
                    }
                } else if (msgData.action === MessageActions.ReceivedModified) {
                    chat.last_messages = chat.last_messages.map(
                        (el: WebMatchMessage) => {
                            if (el.id === msgData.model.id) {
                                return msgData.model;
                            }
                            return el;
                        }
                    );
                } else if (msgData.action === MessageActions.Deleted) {
                    chat.last_messages = chat.last_messages.filter(
                        (el: WebMatchMessage) =>
                            el.id !== msgData.model.message_id
                    );
                    chat.is_read = true;
                } else if (msgData.action === MessageActions.Reaction) {
                    const message = chat.last_messages.find(
                        (el: WebMatchMessage) => el.id === msgData.model.id
                    );
                    message.reaction = msgData.model.reaction;
                }
                if (connections.length > 0) {
                    return postMessageToConnections(
                        connections,
                        WorkerActions.UpdateChat,
                        chat
                    );
                }
                port.postMessage({
                    action: WorkerActions.UpdateChat,
                    data: chat,
                });
            }
            break;
        }
        case 'match': {
            const chat = findChatById(msgData.model.match_id);
            if (chat) {
                if (msgData.action === MessageActions.ReadReceipt) {
                    chat.last_read_at = msgData.model.last_at;
                } else if (msgData.action === MessageActions.Read) {
                    chat.is_read = true;
                    chat.last_read_me_at = msgData.model.last_at;
                } else if (msgData.action === 'deleted') {
                    chat.status = 'CLOSED';
                    chat.closed_by_user_id = chat.user_id;
                    chat.pending_delete_at = new Date(
                        Date.now() + 3 * 24 * 60 * 60 * 1000
                    );
                    exportFromNewToOpen(chat.id);
                }
                if (connections.length > 0) {
                    return postMessageToConnections(
                        connections,
                        WorkerActions.UpdateChat,
                        chat
                    );
                }
                port.postMessage({
                    action: WorkerActions.UpdateChat,
                    data: chat,
                });
            }
            if (
                msgData.action === 'received' &&
                msgData.model.event_type === 'new_match'
            ) {
                SharedState.matches.list_new.unshift(msgData.model.match);
                SharedState.matches.users.unshift(msgData.model.user);
                if (connections.length > 0) {
                    return postMessageToConnections(
                        connections,
                        WorkerActions.UpdateMatches,
                        msgData.model
                    );
                }
                port.postMessage({
                    action: WorkerActions.UpdateMatches,
                    data: msgData.model,
                });
            }

            break;
        }
        case 'me': {
            if (msgData.action === 'verified' && SharedState.userData) {
                SharedState.userData.me.settings.verification_status =
                    msgData.model.status;
            } else if (msgData.action === 'changed' && SharedState.userData) {
                SharedState.userData.me = msgData.model;
            } else if (msgData.action === 'banned') {
                if (connections.length > 0) {
                    return postMessageToConnections(
                        connections,
                        WorkerActions.UpdateChat,
                        msgData
                    );
                }
                port.postMessage({
                    action: WorkerActions.UpdateChat,
                    data: msgData,
                });
            }
            break;
        }
        case 'user': {
            if (msgData.action === 'online') {
                const chat = findChatById(msgData.model.id);
                if (chat) {
                    chat.discovery_actual_at = msgData.at;
                    if (connections.length > 0) {
                        return postMessageToConnections(
                            connections,
                            WorkerActions.UpdateChat,
                            chat
                        );
                    }
                    port.postMessage({
                        action: WorkerActions.UpdateChat,
                        data: chat,
                    });
                }
            }
            break;
        }
    }
};
