import React, { useState } from 'react';
import BackButton from '../auth/BackButton';

import { twMerge } from 'tailwind-merge';
import NextButton from '../auth/NextButton';
import MobileLayout from './MobileLayout';
interface InputPageProps {
    children: React.ReactNode;
    onBack?: () => void;
    onNext?: () => void;
    disableNextButton?: boolean;
    withBottomShadow?: boolean;
    withToolTip?: boolean;
    isLoading?: boolean;
    withBarProgress?: boolean;
}

const UserDataFlowLayout: React.FC<InputPageProps> = ({
    children,
    onBack,
    onNext,
    disableNextButton = false,
    withBottomShadow = false,
    withToolTip = false,
    isLoading = false,
    withBarProgress = false,
}) => {
    const [showNextButton, setShowNextButton] = useState<boolean>(false);
    return (
        <MobileLayout
            className={twMerge(
                'bg-primary-gradient px-4 pt-0 pb-0',
                withBarProgress ? 'px-0' : 'pt-16'
            )}
            fullScreen
            setMounted={setShowNextButton}
        >
            {onBack && (
                <div className='absolute top-4 left-4 w-[80px] pt-[2px]'>
                    <BackButton onGoBack={onBack} />
                </div>
            )}
            {children}
            {onNext && showNextButton && (
                <NextButton
                    disabled={disableNextButton}
                    onNext={onNext}
                    withToolTip={withToolTip}
                    isLoading={isLoading}
                />
            )}

            {withBottomShadow && (
                <>
                    <div className='absolute right-0 bottom-12 h-[40px] w-full overflow-auto bg-gradient-to-b from-transparent to-primary' />
                    <div className='absolute right-0 bottom-0 h-[48px] w-full overflow-auto bg-primary' />
                </>
            )}
        </MobileLayout>
    );
};

export default React.memo(UserDataFlowLayout);
