import { Goal } from '@/domain';
import { ViewedUser } from '@/entities/discovery/store';
import createAxiosInstance from './axios';

export async function fetchUser(token: string, head: any): Promise<any> {
    const axiosInstance = createAxiosInstance({ token });
    const response = await axiosInstance.get('/home', {
        headers: head,
    });
    return response.data;
}

export async function updateUserProp(
    token: string,
    propName: string,
    newValue: boolean | string | number | number[] | Goal | undefined
): Promise<any> {
    const axiosInstance = createAxiosInstance({ token });
    const response = await axiosInstance.put('/users', {
        [propName]: newValue,
    });
    return response.data;
}

export async function updateUser(token: string, data: any): Promise<any> {
    const axiosInstance = createAxiosInstance({ token });
    const response = await axiosInstance.put('/users', data);
    return response.data;
}

export async function switchPhotos(
    token: string,
    firstId: number,
    secondId: number
): Promise<any> {
    const axiosInstance = createAxiosInstance({ token });
    const response = await axiosInstance.patch(
        `/users/avatar?one=${firstId}&two=${secondId}`
    );
    return response.data;
}

export async function addPhoto(
    token: string,
    data: File,
    index: number
): Promise<any> {
    const axiosInstance = createAxiosInstance({ token });
    const response = await axiosInstance.post(
        `users/avatar?index=${index}`,
        data,
        {
            headers: {
                'Content-Type': 'image/*',
            },
            timeout: 30000,
        }
    );
    return response.data;
}
export async function deletePhoto(token: string, id: number): Promise<any> {
    const axiosInstance = createAxiosInstance({ token });
    const response = await axiosInstance.delete(`users/avatar?id=${id}`);
    return response.data;
}

export async function activateOnboardings(token: string): Promise<any> {
    const axiosInstance = createAxiosInstance({ token });
    const response = await axiosInstance.delete('/onboardings');
    return response.data;
}

export async function changeOnboardings(
    token: string,
    key: string
): Promise<any> {
    const axiosInstance = createAxiosInstance({ token });
    const response = await axiosInstance.post(`/onboardings?key=${key}`);
    return response.data;
}

export async function deleteAccount(
    token: string,
    reason: string
): Promise<any> {
    const axiosInstance = createAxiosInstance({ token });

    const queryParams = new URLSearchParams({ reason });

    const response = await axiosInstance.delete(
        `/users/me?${queryParams.toString()}`
    );
    return response.data;
}

export async function getMe(token: string): Promise<any> {
    const axiosInstance = createAxiosInstance({ token });
    const response = await axiosInstance.get('/users/me');
    return response.data;
}

export async function cancelBupm(token: string, id: number): Promise<any> {
    const axiosInstance = createAxiosInstance({ token });
    const response = await axiosInstance.patch(
        '/matches?action=close&skip_report=1',
        { id: id }
    );
    return response.data;
}

export async function cancelBupmReason(
    token: string,
    data: { id: number; comment: string; reason: string }
): Promise<any> {
    const axiosInstance = createAxiosInstance({ token });

    const response = await axiosInstance.delete('/matches', {
        data,
    });

    return response.data;
}
export async function reportDiscoveryUser(
    token: string,
    data: { nickname: string; comment: string; reason: string }
): Promise<any> {
    const axiosInstance = createAxiosInstance({ token });

    const response = await axiosInstance.post('/users/report', data);

    return response.data;
}

export async function getMatchUserData(
    token: string,
    nickname: string,
    source?: string
): Promise<any> {
    const axiosInstance = createAxiosInstance({ token });
    const response = await axiosInstance.get(
        `/users?nickname=${nickname}&source=${source}&include_sent_reaction=1&include_match=1`
    );
    return response.data;
}

export async function getShowCaseData(
    token: string,
    source: string
): Promise<any> {
    const axiosInstance = createAxiosInstance({ token });
    const response = await axiosInstance.get('/showcases', {
        params: { category_name: source },
    });
    // source : Boost,Like,Gold,Reveal
    return response.data;
}

export async function getDiscoveryUsersData(
    token: string,
    near: boolean,
    usersToIgnore: ViewedUser[] = []
): Promise<any> {
    const axiosInstance = createAxiosInstance({ token });
    const ignoreUserIds = usersToIgnore.map((user) => user.id);
    const ignoreUserIdsParam =
        ignoreUserIds.length > 0
            ? `ignore_user_ids=${ignoreUserIds.join(',')}`
            : '';
    const queryString = `?near=${near ? '1' : '0'}${ignoreUserIdsParam ? `&${ignoreUserIdsParam}` : ''}`;

    const response = await axiosInstance.get(
        `/discover/relevant${queryString}`
    );
    return response.data;
}

export async function sendReactionDiscovery(
    token: string,
    type: string,
    nickname: string,
    source?: string
): Promise<any> {
    const axiosInstance = createAxiosInstance({ token });
    const response = await axiosInstance.post('/reactions', {
        nickname: nickname,
        reaction: type,
        source: source || 'discover',
    });
    return response.data;
}

export async function deleteReactionDiscovery(token: string): Promise<any> {
    const axiosInstance = createAxiosInstance({ token });
    const response = await axiosInstance.delete('/reactions');
    return response.data;
}

export async function getLikedYou(token: string, from?: number): Promise<any> {
    const axiosInstance = createAxiosInstance({ token });
    const url = from ? `/reactions?from=${from}` : '/reactions';
    const response = await axiosInstance.get(url);
    return response.data;
}

export async function getViewedYou(token: string, from?: number): Promise<any> {
    const axiosInstance = createAxiosInstance({ token });
    const url = from
        ? `/visits?visits_count=1&from=${from}`
        : '/visits?visits_count=1';
    const response = await axiosInstance.get(url);
    return response.data;
}

export async function readLikedYouTab(
    token: string,
    tab: 'visits' | 'reactions'
): Promise<any> {
    const axiosInstance = createAxiosInstance({ token });
    const response = await axiosInstance.put(`/${tab}`);
    return response.data;
}

export async function shareProfile(
    token: string,
    nickname?: string
): Promise<any> {
    const axiosInstance = createAxiosInstance({ token });
    const response = await axiosInstance.post('/users/share', {
        nickname: nickname,
        type: 'tell_friend',
    });
    return response.data;
}

export async function sendToken(
    token: string,
    authToken: string
): Promise<any> {
    const axiosInstance = createAxiosInstance({ token });
    const response = await axiosInstance.post('/fly', {
        os: 'web',
        type: 'fcm',
        reg_id: authToken,
    });
    return response.data;
}

export async function getMerchantData(
    token: string,
    sku: string
): Promise<any> {
    const axiosInstance = createAxiosInstance({ token });
    const response = await axiosInstance.post('/subscriptions', {
        sku: sku,
    });

    return response.data;
}

export async function cancelSubscriptions(token: string): Promise<any> {
    const axiosInstance = createAxiosInstance({ token });
    const response = await axiosInstance.delete('/subscriptions');

    return response.data;
}
