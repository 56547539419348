import { useScreenDetector } from '@/hooks/useScreenDetector';
import { scaleFont, scaleSize } from '@/utils/scaleSizeAndFont';
import React from 'react';
import ReactCountryFlag from 'react-country-flag';
import Avatar from '../common/CircleAvatar/Avatar';

interface RegisterMembersCardProps {
    image: string;
    text: string;
    name: string;
    firstCode: string;
    secondCode: string;
}

const RegisterMembersCard: React.FC<RegisterMembersCardProps> = ({
    image,
    text,
    name,
    firstCode,
    secondCode,
}) => {
    const { isMobile } = useScreenDetector();
    return (
        <div
            className='flex w-full flex-col items-center justify-center text-center'
            style={{
                gap: scaleSize(20),
            }}
        >
            <div className='relative'>
                <Avatar src={image} size={scaleSize(250)} alt='image' />
                <ReactCountryFlag
                    svg
                    alt={`${firstCode}`}
                    countryCode={firstCode}
                    style={{
                        width: scaleSize(60),
                        height: scaleSize(60),
                        top: scaleSize(160),
                    }}
                    className='absolute left-0 rounded-full object-cover'
                />
                <ReactCountryFlag
                    svg
                    alt={`${secondCode}`}
                    countryCode={secondCode}
                    style={{
                        width: scaleSize(60),
                        height: scaleSize(60),
                        bottom: scaleSize(160),
                    }}
                    className='absolute right-0 rounded-full object-cover'
                />
            </div>
            <div
                className='font-bold'
                style={{
                    fontSize: scaleFont(isMobile ? 16 : 18),
                }}
            >
                {text}
            </div>
            <div
                style={{
                    fontSize: scaleFont(isMobile ? 14 : 18),
                }}
            >
                {name}
            </div>
        </div>
    );
};

export default RegisterMembersCard;
