import Pets from '@/assets/icons/pets.svg?react';
import { UserPets } from '@/domain';
import { getEnumOptions } from '@/utils/getEnumOptions';
import React from 'react';
import SingleSelectStep from '../../common/SingleSelectStep';
import UserDataFlowLayout from '../../layouts/UserDataFlowLayout';
import { UserInfoStepProps } from './userInfoConfig';

const PetsStep: React.FC<UserInfoStepProps> = ({ onNext }) => {
    return (
        <UserDataFlowLayout withBarProgress>
            <div className='-mb-[5px] h-[5px] w-full bg-white/50'>
                <div className='h-[5px] w-[70%] bg-white' />
            </div>
            <SingleSelectStep
                LogoComponent={Pets}
                title='Do you have pets?'
                options={getEnumOptions(UserPets)}
                withSkip
                onNext={onNext}
                field='pets'
                fromEdit={false}
            />
        </UserDataFlowLayout>
    );
};

export default PetsStep;
