import { useEffect } from 'react';

import { logEvent } from '@shared/lib/firebase';
import { consumePostponedFirebaseEvents } from '@shared/lib/sessionStorage';

export function useSendPostponedFirebaseHtmlEvents() {
    useEffect(() => {
        consumePostponedFirebaseEvents().forEach(({ name, params }) => {
            logEvent(name, params);
        });
    }, []);
}
