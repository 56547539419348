import Lottie from 'lottie-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { registerAccount } from '@/api/auth';
import Loader from '@/assets/lotties/globalLoader.json';
import {
    USER_INFO_LITE_COMPONENTS,
    USER_INFO_LITE_STEPS,
} from '@/components/auth/userInfoSteps/userInfoConfig';
import Modal from '@/components/modals/Modal';
import { handleLoginSuccess } from '@/utils/auth';
import { LOCAL_STORAGE, getAllLocalStorageItems } from '@/utils/localStorage';
import { AppRoutes } from '@/utils/routes';
import {
    analyticsEvent,
    liteRegistrationTrackScreen,
} from '@shared/services/analytics';
import {
    AUTH_EVENTS,
    analyticsSignUp,
    useAbRegistration,
} from '@shared/services/analytics';

type UserInfoLiteSteps =
    (typeof USER_INFO_LITE_STEPS)[keyof typeof USER_INFO_LITE_STEPS];
const shorterFlowConfig: Partial<Record<UserInfoLiteSteps, UserInfoLiteSteps>> =
    {
        [USER_INFO_LITE_STEPS.CODE]: USER_INFO_LITE_STEPS.INTERESTS,
        [USER_INFO_LITE_STEPS.INTERESTS]: USER_INFO_LITE_STEPS.PHOTO_UPLOAD,
    };

const UserInfoLite: React.FC = () => {
    const navigate = useNavigate();
    const stepsArray = useMemo(() => Object.values(USER_INFO_LITE_STEPS), []);
    const initStep = useMemo(() => {
        const savedStep = localStorage.getItem(LOCAL_STORAGE.UserInfoStep);
        if (!savedStep) {
            return stepsArray[0];
        }

        const stepIndex = stepsArray.indexOf(savedStep as UserInfoLiteSteps);
        if (stepIndex === -1) {
            return stepsArray[0];
        }

        return stepsArray[stepIndex];
    }, [stepsArray]);

    const [step, setStep] = useState<USER_INFO_LITE_STEPS>(initStep);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { trackAbRegistrationEvent } = useAbRegistration();

    const createUser = useCallback(async () => {
        try {
            setIsLoading(true);
            const userResponse = await registerAccount(
                getAllLocalStorageItems()
            );
            analyticsSignUp('email');
            trackAbRegistrationEvent(AUTH_EVENTS.SIGN_UP, {
                method: 'email',
            });
            handleLoginSuccess(userResponse.result, navigate);
        } catch (error: any) {
            setIsLoading(false);
            console.error('Request failed:', error);
            setTimeout(() => {
                alert(`Request failed: ${error.response?.data?.m}`);
                localStorage.clear();
                navigate(AppRoutes.Login);
            }, 100);
        }
    }, [navigate, trackAbRegistrationEvent]);

    const handleNext = useCallback(
        (isShorterFlow?: boolean) => {
            if (step === stepsArray[stepsArray.length - 1]) {
                localStorage.removeItem(LOCAL_STORAGE.UserInfoStep);
                createUser();
                return;
            }
            const currentIndex = stepsArray.indexOf(step);
            const newStep =
                isShorterFlow && step in shorterFlowConfig
                    ? shorterFlowConfig[step]
                    : stepsArray[currentIndex + 1];
            setStep(newStep!);
            localStorage.setItem(LOCAL_STORAGE.UserInfoStep, newStep!);
        },
        [step, stepsArray, createUser]
    );

    // Todo after AB test should to moved into dedicated hook
    useEffect(() => {
        const eventsBySteps = {
            [USER_INFO_LITE_STEPS.GENDER]: 'StepGender',
            [USER_INFO_LITE_STEPS.LOOKING_FOR]: 'StepMatchGender',
            [USER_INFO_LITE_STEPS.NAME]: 'StepFirstName',
            [USER_INFO_LITE_STEPS.BIRTHDAY]: 'StepDob',
            [USER_INFO_LITE_STEPS.GOAL]: 'StepGoal',
            [USER_INFO_LITE_STEPS.EMAIL]: 'StepEmail',
            [USER_INFO_LITE_STEPS.CODE]: 'StepCode',
            [USER_INFO_LITE_STEPS.INTERESTS]: 'StepInterests',
            [USER_INFO_LITE_STEPS.PHOTO_UPLOAD]: 'StepAvatars',
        };

        const eventName =
            eventsBySteps[step as keyof typeof eventsBySteps] ||
            'unknown_event';

        analyticsEvent(eventName);
        liteRegistrationTrackScreen(eventName);
    }, [step]);

    const handleBack = useCallback(() => {
        if (step === stepsArray[0]) {
            navigate(AppRoutes.Login);
            return;
        }
        const currentIndex = stepsArray.indexOf(step);
        const newStep = stepsArray[currentIndex - 1];
        setStep(newStep);
        localStorage.setItem(LOCAL_STORAGE.UserInfoStep, newStep);
    }, [step, navigate, stepsArray]);

    const StepComponent: any = useMemo(
        () => USER_INFO_LITE_COMPONENTS[step],
        [step]
    );

    return (
        <div className='text-secondary'>
            <StepComponent
                onNext={handleNext}
                onBack={handleBack}
                isLite={true}
            />
            <Modal isOpen={isLoading} className='px-10 py-6 text-black'>
                <Lottie className='h-24 w-24' animationData={Loader} loop />
                Loading...
            </Modal>
        </div>
    );
};

export default UserInfoLite;
