import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { analyticsScreen } from '@shared/services/analytics';

type UseFirebaseAnalytic = {
    skipInit?: boolean;
};

export function useTrackScreenToFirebaseAnalytics({
    skipInit,
}: UseFirebaseAnalytic) {
    const location = useLocation();

    useEffect(() => {
        if (skipInit) {
            return;
        }

        const currentScreen =
            location.pathname === '/'
                ? 'home'
                : location.pathname.substring(1).replace(/\/.*$/, '');

        analyticsScreen(currentScreen);
    }, [location, skipInit]);
}
