import {Ethnicities} from './Ethnicities';
import {Interests, InterestsEnd} from './Interests';
import {packBits, unpackBits} from './Model';
import {Nationalities} from './Nationalities';
import {Prompt} from './Prompts';
import {SupportedLang} from './SupportedLang';
import {User} from './User';

export enum UserStarSign {
  NONE,
  ARIES,
  TAURUS,
  GEMINI,
  CANCER,
  LEO,
  VIRGO,
  LIBRA,
  SCORPIO,
  SAGITTARIUS,
  CAPRICORN,
  AQUARIUS,
  PISCES,
}
export const UserStarSignBegin = UserStarSign.ARIES;
export const UserStarSignEnd = UserStarSign.PISCES;

export enum UserSexOrientation {
  NONE,
  HETEROSEXUAL,
  BISEXUAL,
  HOMOSEXUAL,
  ASEXUAL,
  PANSEXUAL,
  POLYSEXUAL,
  OMNISEXUAL,
}
export const UserSexOrientationBegin = UserSexOrientation.HETEROSEXUAL;
export const UserSexOrientationEnd = UserSexOrientation.OMNISEXUAL;

export enum UserEducationLevel {
  NONE,
  HIGH_SCHOOL,
  TECH_SCHOOL,
  IN_COLLEGE,
  UNDERGRADUATE_DEGREE,
  IN_GRAD_SCHOOL,
  GRADUATE_DEGREE,
}
export const UserEducationLevelBegin = UserEducationLevel.HIGH_SCHOOL;
export const UserEducationLevelEnd = UserEducationLevel.GRADUATE_DEGREE;

export enum UserMaritalStatus {
  NONE,
  SINGLE,
  MARRIED,
  IN_A_RELATIONSHIP,
  DIVORCED,
}
export const UserMaritalStatusBegin = UserMaritalStatus.SINGLE;
export const UserMaritalStatusEnd = UserMaritalStatus.DIVORCED;

export enum UserExercise {
  NONE,
  LIFESTYLE,
  REGULARLY,
  OFTEN,
  SOMETIMES,
  NEVER,
}
export const UserExerciseBegin = UserExercise.LIFESTYLE;
export const UserExerciseEnd = UserExercise.NEVER;

export enum UserKids {
  NONE,
  NOT_YET,
  CHILD_FREE,
  ONE,
  TWO,
  THREE,
  MORE_THEN_THREE,
}
export const UserKidsBegin = UserKids.NOT_YET;
export const UserKidsEnd = UserKids.MORE_THEN_THREE;

export enum UserDrinking {
  NONE,
  OFTEN,
  ON_HOLIDAYS,
  SOMETIMES,
  NEVER,
}
export const UserDrinkingBegin = UserDrinking.OFTEN;
export const UserDrinkingEnd = UserDrinking.NEVER;

export enum UserSmoking {
  NONE,
  REGULARLY,
  WHEN_DRINK,
  SOMETIMES,
  NEVER,
}
export const UserSmokingBegin = UserSmoking.REGULARLY;
export const UserSmokingEnd = UserSmoking.NEVER;

export enum UserReligion {
  NONE,
  BUDDHISM,
  CHRISTIANITY,
  ISLAM,
  JUDAISM,
  ATHEISM,
  OTHER,
  HINDUISM,
}
export const UserReligionBegin = UserReligion.BUDDHISM;
export const UserReligionEnd = UserReligion.HINDUISM;

export enum UserPets {
  NONE,
  CATS,
  DOGS,
  BIRDS,
  FISH,
  RABBITS,
  SNAKES,
  TURTLES,
  HAMSTERS,
  NO,
}
export const UserPetsBegin = UserPets.CATS;
export const UserPetsEnd = UserPets.NO;

export enum UserValues {
  NONE,
  FAMILY_KIDS,
  CAREER_MONEY,
  SELF_REALIZATION,
  ENTERTAINMENT_LEISURE,
  SCIENCE_RESEARCH,
  WORLD_IMPROVEMENT,
  BEAUTY_ART,
  FAME_INFLUENCE,
}
export const UserValuesBegin = UserValues.FAMILY_KIDS;
export const UserValuesEnd = UserValues.FAME_INFLUENCE;

export type UserDetailedMoreAboutMeFields = {
  height_sm: number;
  weight_kg: number;
  sex_orientation: UserSexOrientation;
  star_sign: UserStarSign;
  exercise: UserExercise;
  education_level: UserEducationLevel;
  marital_status: UserMaritalStatus;
  kids: UserKids;
  drinking: UserDrinking;
  smoking: UserSmoking;
  pets: UserPets;
  religion: UserReligion;
  values: UserValues;
};

export type UserDetailed = User & {
  about?: string;
  dob?: string;
  work_info?: string;
  school_info?: string;
  languages?: number[];
  nationalities?: Nationalities[];
  ethnicities?: Ethnicities[];
  prompts?: Prompt[];
} & UserDetailedMoreAboutMeFields;

export function unpackUserInterests({
  interests,
}: {
  interests?: Interests[];
}): Interests[] {
  if (interests) {
    const unpacked = unpackBits(interests);
    const filtered = unpacked.filter((value) => value <= InterestsEnd);
    if (filtered.length > 0) {
      return filtered;
    }
  }
  return [];
}

export function packUserInterests(interests: Interests[]): number[] {
  return packBits(interests, 6);
}

export function packUserLanguages(languages: SupportedLang[]): number[] {
  return packBits(languages, 2);
}
