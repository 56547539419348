import { useScreenDetector } from '@/hooks/useScreenDetector';
import { scaleFont, scaleSize } from '@/utils/scaleSizeAndFont';
import React from 'react';

interface RegisterStepsCardProps {
    image: string;
    title: string;
    description: string;
}

const RegisterStepsCard: React.FC<RegisterStepsCardProps> = ({
    image,
    title,
    description,
}) => {
    const { isMobile } = useScreenDetector();
    return (
        <div
            className='relative flex w-full flex-col items-center justify-center text-center'
            style={{
                gap: scaleSize(20),
                maxWidth: scaleSize(320),
            }}
        >
            <img
                src={image}
                width={scaleSize(320)}
                height={scaleSize(350)}
                alt='step_image'
                loading='lazy'
            />
            <div
                className='font-bold'
                style={{
                    fontSize: scaleFont(isMobile ? 16 : 20),
                }}
            >
                {title}
            </div>
            <div
                style={{
                    fontSize: scaleFont(isMobile ? 14 : 16),
                }}
            >
                {description}
            </div>
        </div>
    );
};

export default RegisterStepsCard;
