import { City } from '@/domain';

export enum LOCAL_STORAGE {
    EmailSignIn = 'email',
    AuthToken = 'authToken',
    User = 'user',
    CodeCountdownStartedAt = 'codeCountdownStartedAt',
    CodeTriesLeft = 'codeTriesLeft',
    UserInfoStep = 'userInfoStep',
    Code = 'code',
    GoogleToken = 'googleToken',
    BlockRefresh = 'blockRefresh',
    UserNickname = 'userNickname',
    WelcomePaywall = 'welcomePaywall',
    Page = 'page',
    Shared = 'shared',
    UserId = 'userId',
    CarouselIndex = 'carouselIndex',
    Tab = 'tab',
    Nickname = 'nickname',
    DiscoveryUsers = 'discoveryUsers',
    UsersFetchedTime = 'usersFetchedTime',
    Build = 'build',
    SearchFilter = 'searchFilter',
    Cities = 'cities',
}

export const getAllLocalStorageItems = () => {
    const storageObj: { [key: string]: string | null } = {};
    for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key) {
            const value = localStorage.getItem(key);
            try {
                storageObj[key] = JSON.parse(value!);
            } catch (_e) {
                storageObj[key] = value;
            }
        }
    }
    return storageObj;
};

export const fetCitiesById = (id: number) => {
    const storedData = localStorage.getItem(LOCAL_STORAGE.Cities);
    const storedCities = storedData ? JSON.parse(storedData) : [];
    const cityName = storedCities.find((city: City) => city.id === id);

    return cityName?.name;
};
