import { RefObject, useEffect, useState } from 'react';

const useScrollEffect = (ref?: RefObject<HTMLDivElement>, isOpen?: boolean) => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [actionsSize, setActionSize] = useState<number>(50);
    const [divRef, setDivRef] = useState<number>(0);
    const [opacityVisible, setOpacityVisible] = useState<number>(1);
    const [scrollY, setScrollY] = useState(0);
    const [fromBottom, setFromBottom] = useState<number>(0);

    useEffect(() => {
        const handleScroll = () => {
            if (ref?.current) {
                setScrollPosition(ref.current.scrollTop);
            }
        };

        if (ref?.current) {
            ref.current.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (ref?.current) {
                ref.current.removeEventListener('scroll', handleScroll);
            }
        };
    }, [ref?.current]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (ref?.current) {
            setDivRef(ref.current.scrollHeight - ref.current.clientHeight);
            const scrollPosition = ref?.current.scrollTop;
            const maxScrollPosition =
                ref?.current.scrollHeight - ref?.current.clientHeight;
            setFromBottom(maxScrollPosition - scrollPosition);
            setScrollY(scrollPosition / (maxScrollPosition / 68));
        }

        if (scrollPosition < 200) {
            const newActionSize = 50 - scrollPosition / 10;
            setActionSize(Math.min(newActionSize, 50));
            setOpacityVisible(Math.max(1 - scrollPosition / 150, 0));
        } else if (divRef - scrollPosition < 250) {
            const newActionSize = 50 - (divRef - scrollPosition) / 15;
            setActionSize(Math.min(newActionSize, 50));
        }
        if (divRef - scrollPosition < divRef / 3) {
            setOpacityVisible(0.01);
        }
    }, [ref?.current, scrollPosition, isOpen]);

    return {
        actionsSize,
        opacityVisible,
        setDivRef,
        setScrollPosition,
        scrollY,
        fromBottom,
    };
};

export default useScrollEffect;
