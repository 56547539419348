import React, { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

interface InputPageProps {
    children: React.ReactNode;
    className?: string;
    whiteBG?: boolean;
    style?: React.CSSProperties;
    windowHeight?: number;
    fullScreen?: boolean;
    setMounted?: React.Dispatch<React.SetStateAction<boolean>>;
}

const MobileLayout: React.FC<InputPageProps> = ({
    children,
    className,
    whiteBG,
    style,
    windowHeight,
    fullScreen = false,
    setMounted,
}) => {
    const [containerHeight, setContainerHeight] = useState<string | undefined>(
        undefined
    );

    useEffect(() => {
        const updateHeight = () => {
            const vh = window.innerHeight || window.visualViewport?.height;
            if (setMounted) {
                setMounted(true);
            }
            setContainerHeight(`${windowHeight ? windowHeight : vh}px`);
        };

        updateHeight();
        window.addEventListener('resize', updateHeight);

        return () => window.removeEventListener('resize', updateHeight);
    }, [windowHeight, setMounted]);

    return (
        <div
            className={twMerge(
                'flex w-screen justify-center bg-lightPrimary',
                whiteBG && 'bg-white',
                !containerHeight && fullScreen && 'min-h-screen'
            )}
            style={{ height: containerHeight }}
        >
            <div
                className={twMerge(
                    'relative m-0 flex min-h-full w-full max-w-[726px] flex-col bg-white p-8 px-4 pt-[12vh] lg:min-w-[726px]',
                    className
                )}
                style={style}
            >
                {children}
            </div>
        </div>
    );
};

export default React.memo(MobileLayout);
