export enum Nationalities { //DO NOT DELETE OR UPDATE, ONLY APPEND
  Afghanistan = 1,
  Albania,
  Algeria,
  Andorra,
  Angola,
  Antigua_And_Barbuda,
  Argentina,
  Armenia,
  Australia,
  Austria,
  Azerbaijan,
  Bahamas,
  Bahrain,
  Bangladesh,
  Barbados,
  Belarus,
  Belgium,
  Belize,
  Benin,
  Bhutan,
  Bolivia,
  Bosnia_And_Herzegovina,
  Botswana,
  Brazil,
  Brunei,
  Bulgaria,
  Burkina_Faso,
  Burundi,
  Cabo_Verde,
  Cambodia,
  Cameroon,
  Canada,
  Central_African_Republic,
  Chad,
  Chile,
  China,
  Colombia,
  Comoros,
  Congo_Drc,
  Congo_Republic,
  Costa_Rica,
  Cote_Divoire,
  Croatia,
  Cuba,
  Cyprus,
  Czech_Republic,
  Denmark,
  Djibouti,
  Dominica,
  Dominican_Republic,
  Ecuador,
  Egypt,
  El_Salvador,
  Equatorial_Guinea,
  Eritrea,
  Estonia,
  Eswatini,
  Ethiopia,
  Fiji,
  Finland,
  France,
  Gabon,
  Gambia,
  Georgia,
  Germany,
  Ghana,
  Greece,
  Grenada,
  Guatemala,
  Guinea,
  Guinea_Bissau,
  Guyana,
  Haiti,
  Honduras,
  Hungary,
  Iceland,
  India,
  Indonesia,
  Iran,
  Iraq,
  Ireland,
  Israel,
  Italy,
  Jamaica,
  Japan,
  Jordan,
  Kazakhstan,
  Kenya,
  Kiribati,
  Kosovo,
  Kuwait,
  Kyrgyzstan,
  Laos,
  Latvia,
  Lebanon,
  Lesotho,
  Liberia,
  Libya,
  Liechtenstein,
  Lithuania,
  Luxembourg,
  Madagascar,
  Malawi,
  Malaysia,
  Maldives,
  Mali,
  Malta,
  Marshall_Islands,
  Mauritania,
  Mauritius,
  Mexico,
  Micronesia,
  Moldova,
  Monaco,
  Mongolia,
  Montenegro,
  Morocco,
  Mozambique,
  Myanmar_Burma,
  Namibia,
  Nauru,
  Nepal,
  Netherlands,
  New_Zealand,
  Nicaragua,
  Niger,
  Nigeria,
  North_Korea,
  North_Macedonia,
  Norway,
  Oman,
  Pakistan,
  Palau,
  Palestine,
  Panama,
  Papua_New_Guinea,
  Paraguay,
  Peru,
  Philippines,
  Poland,
  Portugal,
  Qatar,
  Romania,
  Russia,
  Rwanda,
  Saint_Kitts_And_Nevis,
  Saint_Lucia,
  Saint_Vincent_And_The_Grenadines,
  Samoa,
  San_Marino,
  Sao_Tome_And_Principe,
  Saudi_Arabia,
  Senegal,
  Serbia,
  Seychelles,
  Sierra_Leone,
  Singapore,
  Slovakia,
  Slovenia,
  Solomon_Islands,
  Somalia,
  South_Africa,
  South_Sudan,
  South_korea,
  Spain,
  Sri_Lanka,
  Sudan,
  Suriname,
  Sweden,
  Switzerland,
  Syria,
  Taiwan,
  Tajikistan,
  Tanzania,
  Thailand,
  Timor_Leste,
  Togo,
  Tonga,
  Trinidad_And_Tobago,
  Tunisia,
  Turkey,
  Turkmenistan,
  Tuvalu,
  Uganda,
  Ukraine,
  United_Arab_Emirates,
  United_Kingdom,
  United_States,
  Uruguay,
  Uzbekistan,
  Vanuatu,
  Vatican_City,
  Venezuela,
  Vietnam,
  Yemen,
  Zambia,
  Zimbabwe,
  Puerto_Rico,
}
export const NationalitiesBegin = Nationalities.Afghanistan;
export const NationalitiesEnd = Nationalities.Puerto_Rico;
